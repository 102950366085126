import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getMemberOnBoardingMissionPath = (groupId: number) =>
  groupClient.api.GroupHomeApi.getapiV1GroupsIdMembersOnboardingGetPath(groupId);

export const useGetMemberOnBoardingMission = (groupId: string) => {
  const fetchInstance = useFetchInstance();
  const getMemberOnBoardingMission = groupClient.api.GroupHomeApi.apiV1GroupsIdMembersOnboardingGet(
    {
      axios: fetchInstance,
    }
  );

  const numberGroupId = Number(groupId);
  const path = getMemberOnBoardingMissionPath(numberGroupId);
  const { data, refetch, isInitialLoading } = useQuery(
    [path],
    () => getMemberOnBoardingMission(numberGroupId),
    {
      suspense: true,
    }
  );

  return {
    data,
    refetch,
    isLoading: isInitialLoading,
  };
};
