import { GroupPhotoPostPresentation } from '@community-group/api/lib/group/models';
import { Image, ImageType } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import React, { ImgHTMLAttributes, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useCallback } from 'react';
import { Pagination, Virtual, Zoom } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import * as s from './ImageViewerSlider.css';

export type ImageViewerImageListType = {
  id: string;
  imageUrl: string;
  isPinned?: boolean;
  postId?: number;
  post?: GroupPhotoPostPresentation;
};

export type ImageViewerType = {
  imageList: ImageViewerImageListType[];
  initialIndex: number;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
};

export type ImageViewerSliderOptions = {
  isVirtual?: boolean;
  isLazyLoad?: boolean;
};

const ImageViewerSlider = ({
  imageData,
  objectFit = 'contain',
  setCurrentImage,
  onClickImage,
  renderBottom,
  slideStyle,
  zoomable = false,
  children,
  firstSliderChildren,
  lastSliderChildren,
  options = {
    isVirtual: true,
    isLazyLoad: true,
  },
}: {
  imageData: ImageViewerType;
  objectFit?: 'cover' | 'contain';
  setCurrentImage?: React.Dispatch<React.SetStateAction<ImageViewerImageListType | undefined>>;
  onClickImage?: (image: ImageViewerImageListType, index: number) => void;
  renderBottom?: (image: ImageViewerImageListType) => React.ReactNode;
  slideStyle?: React.CSSProperties;
  zoomable?: boolean;
  children?: React.ReactNode;
  firstSliderChildren?: React.ReactNode;
  lastSliderChildren?: React.ReactNode;
  options?: ImageViewerSliderOptions;
}) => {
  const {
    imageList: images,
    initialIndex,
    fetchNextPage: fetchNextPhotoList,
    isFetchingNextPage,
    hasNextPage: hasNextPhotoList,
  } = imageData;

  const prevImageRef = useRef<ImageViewerImageListType[] | undefined>();
  const swiperRef = useRef<SwiperClass>();

  const handleSlideChange = useCallback(
    (swiper) => {
      setCurrentImage?.(images[swiper.activeIndex]);
      if (
        images?.length - swiper.activeIndex < 5 &&
        !isFetchingNextPage &&
        hasNextPhotoList &&
        fetchNextPhotoList
      ) {
        fetchNextPhotoList();
        return;
      }
    },
    [fetchNextPhotoList, hasNextPhotoList, images, isFetchingNextPage, setCurrentImage]
  );

  const [showSlider, setShowSlider] = useState(false);

  useLayoutEffect(() => {
    setShowSlider(true);
    if (images[0]?.id !== prevImageRef.current?.[0]?.id) {
      prevImageRef.current = images;
      swiperRef.current?.slideTo(0);
    }

    return () => setShowSlider(false);
  }, [images]);

  const module = useMemo(() => {
    const modulesArr = [Pagination];
    if (zoomable) modulesArr.push(Zoom);
    if (options.isVirtual) modulesArr.push(Virtual);
    return modulesArr;
  }, [zoomable, options.isVirtual]);

  const backgroundColor = useMemo(
    () => (objectFit === 'cover' ? vars.$scale.color.gray200 : vars.$static.color.staticBlack),
    [objectFit]
  );

  const ImageComponents = useCallback(
    (props: ImgHTMLAttributes<HTMLImageElement> & ImageType) => {
      return options.isLazyLoad ? <Image {...props} /> : <img {...props} />;
    },
    [options.isLazyLoad]
  );

  if (!showSlider) return null;

  return (
    <Swiper
      className={clsx(s.MainSwiperStyle, 'imageViewerSlider')}
      onSwiper={(swiper: SwiperClass) => (swiperRef.current = swiper)}
      virtual={
        options.isVirtual && {
          enabled: true,
          addSlidesBefore: 2,
          addSlidesAfter: 2,
        }
      }
      modules={module}
      zoom={zoomable}
      pagination={{
        dynamicBullets: true,
        dynamicMainBullets: 1,
      }}
      centeredSlides={true}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      onInit={(swiper) => {
        handleSlideChange(swiper);
      }}
      onSlideChange={handleSlideChange}
      initialSlide={Number(initialIndex) ?? 0}
    >
      {firstSliderChildren}
      {images.map((slideContent, index) => {
        const imageIdKey = slideContent?.id ? encodeURIComponent(slideContent?.id) : index;
        return (
          <SwiperSlide key={imageIdKey} virtualIndex={index}>
            <div
              className={zoomable ? 'swiper-zoom-container' : ''}
              style={{
                width: '100%',
                height: '100%',
                background: backgroundColor,
                willChange: 'all',
                ...slideStyle,
              }}
              onClick={() => onClickImage?.(slideContent, index)}
            >
              <ImageComponents
                style={{
                  objectFit,
                  objectPosition: 'center',
                }}
                key={slideContent.id}
                src={slideContent.imageUrl}
                alt="이미지"
                aria-hidden="true"
                width={'100%'}
                height={'100%'}
              />
            </div>
            {renderBottom?.(slideContent)}
          </SwiperSlide>
        );
      })}
      {children}
      {lastSliderChildren}
    </Swiper>
  );
};

export default ImageViewerSlider;
