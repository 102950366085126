type MemberRole = 'superHost' | 'host' | 'manager' | 'member' | 'none';

const ROLES = {
  SUPER_HOST: 'superHost',
  HOST: 'host',
  MEMBER: 'member',
  NONE: 'none',
} as const;

export const isSuperHost = (role?: MemberRole) => {
  if (role === ROLES.SUPER_HOST) return true;
  return false;
};

export const isNotSuperHost = (role: MemberRole) => {
  if (role !== ROLES.SUPER_HOST) return true;
  return false;
};

export const isHigherManager = (role: MemberRole = 'none') => {
  const managerRoles = ['superHost', 'host', 'manager'] as MemberRole[];

  return managerRoles.includes(role);
};

export const isNotMember = (role?: MemberRole) => {
  if (role === ROLES.NONE) return true;
  return false;
};

export const isMember = (role?: MemberRole) => {
  if (role !== ROLES.NONE) return true;
  return false;
};
