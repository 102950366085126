import { GroupMeetupPoiPresentation } from '@community-group/api/lib/group/models';

import { Map } from '@/components/common/Map';
import { Marker } from '@/components/common/Map/components/Marker';
import { Position } from '@/hooks/useCurrentPosition';
import { calculateDistance } from '@/utils/geolocation/distance';

import { ClientOnly } from '../../../../../../packages/react-components/src/components/ClientOnly';
import * as s from './index.css';

type Props = {
  poi: GroupMeetupPoiPresentation;
  currentPosition?: Position;
  onCardClick: () => void;
};

export const PoiMapCard = ({ poi, currentPosition, onCardClick }: Props) => {
  const coordinates = [poi.coordinates.longitude, poi.coordinates.latitude] as [number, number];

  const calculatedDistance = currentPosition
    ? calculateDistance({
        userPosition: currentPosition,
        poiPosition: poi.coordinates,
      })
    : undefined;

  return (
    <div className={s.wrapper} onClick={onCardClick}>
      <ClientOnly
        fallback={<div className={s.SkeletonMap} />}
        render={() => (
          <Map
            mapId="poiMap"
            className={s.map}
            options={{
              interactive: false,
              zoom: 16,
              center: coordinates,
            }}
          >
            <Marker markerId={poi.name} coordinates={coordinates} />
          </Map>
        )}
      />

      <div className={s.body}>
        <div className={s.contents}>
          <div className={s.titleWrapper}>
            <h6 className={s.title}>{poi.name}</h6>
            <span className={s.label}>{poi?.categoryName}</span>
          </div>
          <p className={s.description}>
            {currentPosition && (
              <span>{`${calculatedDistance?.distance}${calculatedDistance?.unit}  ⸱ `}</span>
            )}
            {poi?.region?.name && `${poi.region.city} ${poi.region.town}`}
            {poi?.address && poi.reviewPostCount > 0 && ` ⸱ `}
            {poi.reviewPostCount > 0 && `후기 ${poi.reviewPostCount}`}
          </p>
        </div>
      </div>
    </div>
  );
};
