import { Image, Video } from '@community-group/api/lib/group/models';
import queryString from 'query-string';
import { useMemo } from 'react';

import { isVideo } from '../utils/medias';
/*
 * 이미지 load시 기기에 최적화 된 이미지를 load하도록 params를 붙여주는 함수
 * t : css object-fit 설정과 같음
 * s : size 속성으로 width x height 를 갖는다
 * f : format 속성으로 이미지 확장자를 갖는다
 * q : quality 속성으로 이미지 품질을 갖는다
 */
export const useOptimizedImageList = (images: (Image | Video)[], imageHeight: number) => {
  return useMemo(
    () =>
      images.map((image) => {
        const imageRenderWidth = window.innerWidth * 2; // 레티나 디스플레이를 고려하여 2배수로 설정
        const imageRenderHeight = imageHeight;

        const sizeRatio = imageRenderWidth / image.width;

        const originImageUrl = isVideo(image)
          ? (image as Video).thumbnailUrl
          : (image as Image).url;

        // 이미지 관련 CS 대응 (https://daangn.slack.com/archives/C0336FFHA8L/p1715938518284819?thread_ts=1715936410.762929&cid=C0336FFHA8L)
        // url에 쿼리파람즈에 image_id를 붙이는 케이스에 대한 대응
        const imagePath = originImageUrl.split('?')[0];
        const mediumImageUrl = new URL(originImageUrl);
        const parsedQuery = queryString.parse(mediumImageUrl.search);

        parsedQuery.s = `${imageRenderWidth}x${imageRenderHeight}`;
        parsedQuery.f = 'webp';
        parsedQuery.q = '100';
        parsedQuery.t = 'outside';

        const optimizeQueryString = '?' + queryString.stringify(parsedQuery);

        const imageSrc = imagePath + optimizeQueryString;

        return {
          id: image.id,
          src: imageSrc,
          width: image.width * sizeRatio,
          height: image.height * sizeRatio,
        };
      }),
    [images]
  );
};
