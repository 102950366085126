import {
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';

import ImgVerificationBanner from '@/assets/images/verification_banner.png';
import ImgVerificationProfileBanner from '@/assets/images/verification_profile_banner.png';
import ImgVerificationProfileList from '@/assets/images/verification_profile_list.png';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './GroupSettingFAQVerificationPage.css';

const GroupSettingFAQVerificationPage: ActivityComponentType = () => {
  const { groupIdForChangeVerification } = useQueryParams();

  useEnterTrackEvent({
    event: 'enter_verification_faq_page',
    params: {
      groupId: groupIdForChangeVerification ?? '',
    },
  });

  return (
    <AppScreen>
      <div className={s.Wrapper}>
        <img src={ImgVerificationBanner} width={'100%'} />
        <div className={s.ContentWrapper}>
          <section className={s.Content}>
            <Typography typography="title1Bold">본인인증 모임이 무엇인가요?</Typography>
            <Spacing height={8} />
            <Typography typography="bodyL1Regular">
              휴대폰으로 본인인증을 완료한 이웃만 가입할 수 있는 모임이에요.
            </Typography>
          </section>
          <section className={s.Content}>
            <Typography typography="title1Bold">무엇이 달라지나요?</Typography>
            <Spacing height={8} />
            <Typography typography="bodyL1Regular">
              이미 가입한 멤버에게도 인증 요청 메시지가 전달되며, 인증을 완료한 멤버는 프로필에 인증
              마크가 표시돼요. 인증을 완료하지 않은 멤버도 계속 활동할 수 있지만, 모임장님의 판단에
              따라 내보낼 수 있어요.
            </Typography>
            <Spacing height={16} />
            <img src={ImgVerificationProfileBanner} width={'100%'} />
          </section>
          <section className={s.Content}>
            <Typography typography="title1Bold">
              본인인증 모임은 나이대와 <br /> 성별 정보 받기 옵션을 사용할 수 있어요
            </Typography>
            <Spacing height={8} />
            <Typography typography="bodyL1Regular">
              본인인증 모임으로 변경하면 선택 사항으로 나이대와 성별 정보를 받을 수 있어요.
              <br />
              <span style={{ color: vars.$semantic.color.accent }}>
                *가입하는 이웃이 모임장님의 정보도 볼 수 있어요.
              </span>
            </Typography>
            <Spacing height={16} />
            <img src={ImgVerificationProfileList} width={'100%'} />
          </section>
          <section className={s.Content}>
            <Typography typography="title1Bold">어떻게 할 수 있나요?</Typography>
            <Spacing height={8} />
            <Typography typography="bodyL1Regular">
              본인인증 모임으로 변경하려면 먼저 모임장님의 본인인증이 필요해요. <br />
            </Typography>
          </section>
        </div>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingFAQVerificationPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
