import {
  Dialog,
  Spacing,
  Typography,
  useBottomSheet,
  useDialog,
} from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular, IconCloseRegular } from '@seed-design/icon';

import { useGetMyGroupList } from '@/api/hooks/useGetMyGroupList';
import { getPhotoContestEntriesPath } from '@/api/hooks/useGetPhotoContestEntries';
import { queryClient } from '@/api/instance';
import { useFlow } from '@/stackflow';

import * as s from './photoContestNewGroupPost.css';

const EXERCISE_GROUP_CATEGORY_ID = 1;

export const useHandlePhotoContestNewGroupPost = () => {
  const { push } = useFlow();
  const { push: asyncPush } = useConnectedActions();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();

  const { data: myGroups } = useGetMyGroupList();

  const handleBottomSheetItemClick = async (groupId: string) => {
    await asyncPush('GroupPostNewPage', { groupId, from: 'groupPhotoContest' });

    queryClient.refetchQueries([getPhotoContestEntriesPath()]);
  };

  const handleNewGroupPost = () => {
    // 운동모임 리스트
    const myExerciseGroupList = (myGroups?.groups ?? []).filter(
      (el) => el?.category?.id === EXERCISE_GROUP_CATEGORY_ID
    );

    if (myExerciseGroupList.length === 1) {
      const group = myExerciseGroupList[0];
      handleBottomSheetItemClick(group.id.toString());
      return;
    }

    if (myExerciseGroupList.length > 0) {
      return openBottomSheet({
        element: (
          <div className={s.BottomSheetWrapper}>
            <div className={s.BottomSheetHeader}>
              <Typography as="h2" typography="title2Bold" color="gray900">
                모임 선택
              </Typography>
              <Spacing height={6} />
              <Typography typography="bodyM1Regular" color="gray700">
                &apos;운동&apos; 카테고리로 개설된 모임만 참여할 수 있어요.
              </Typography>
              <div className={s.CloseIconWrapper}>
                <IconCloseRegular
                  size={24}
                  color={vars.$scale.color.gray900}
                  onClick={closeBottomSheet}
                />
              </div>
            </div>
            <div className={s.BottomSheetContent}>
              <ul className={s.BottomSheetList}>
                {myExerciseGroupList.map(({ profileImage, id, name }) => (
                  <li
                    className={s.ItemWrapper}
                    key={id}
                    onClick={async () => {
                      await closeBottomSheet();
                      handleBottomSheetItemClick(id.toString());
                    }}
                  >
                    <img
                      src={profileImage.small}
                      width={48}
                      height={48}
                      style={{
                        objectFit: 'cover',
                        minWidth: '3rem',
                        minHeight: '3rem',
                        width: '3rem',
                        height: '3rem',
                        borderRadius: '0.375rem',
                      }}
                    />
                    <Typography
                      as="p"
                      typography="bodyM1Regular"
                      color="gray900"
                      ellipsisAfterLines={1}
                      style={{ width: '100%' }}
                    >
                      {name}
                    </Typography>
                    <IconChevronRightRegular size={16} color={vars.$scale.color.gray600} />
                  </li>
                ))}
                <p className={s.BottomSheetFooter} />
              </ul>
            </div>
          </div>
        ),
      });
    }

    // 다이얼로그
    openDialog({
      element: (
        <Dialog
          title="가입한 운동 모임이 없어요"
          description="운동 모임에 가입하고 게시글을 남겨보세요."
          primaryActionLabel="모임 둘러보기"
          onPrimaryAction={async () => {
            await closeDialog();
            push('HomePage', {});
          }}
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
          secondaryActionIntent="nonpreferred"
        />
      ),
    });
  };

  return handleNewGroupPost;
};
