import { Dialog, useDialog } from '@community-group/components';

import { useGetEventLogMyInfo } from '@/api/hooks/useGetEventLogMyInfo';
import { useGetMe } from '@/api/hooks/useGetMe';
import { useGetRunningRaceGroups } from '@/api/hooks/useGetRunningRaceGroups';
import { usePostApplication } from '@/api/hooks/usePostApplication';
import { usePostJoinGroup } from '@/api/hooks/usePostJoinGroup';
import {
  JoinGroupStateReturnType,
  useJoinGroupState,
} from '@/components/group/JoinGroupState/hooks/useJoinGroupState';
import { openVerifyNeighborhood } from '@/constants/path';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import useCheckGroupProfileCreated from '@/hooks/useCheckGroupProfileCreated';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { GroupEventParams } from '@/utils/analytics/type';
import { refetchCurrentUser } from '@/utils/refetch/currentUser';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { shortening } from '@/utils/text';

import useJoinGroupMarketingPromotion from '../../MarketingPromotion/hooks/useJoinGroupMarketingPromotion';
import usePreJoinGroupMarketingPromotion from '../../MarketingPromotion/hooks/usePreJoinGroupMarketingPromotion';
import { useHandleVerificationBottomSheet } from '../../new/Verification/hooks/useHandleVerificationBottomSheet';
import { JoinGroupStateProps } from './useJoinGroupState';

type Props = JoinGroupStateProps;
type HandleGroupOnlyParams = {
  isBlock?: boolean;
  blockGroupOnlyTitle?: string;
  blockGroupOnlyText?: (groupName: string) => string;
  onSettled?: (params: Pick<JoinGroupStateReturnType, 'userState'>) => void;
  onSuccess?: (params: Pick<JoinGroupStateReturnType, 'userState'>) => void;
  onFailure?: (params: Omit<JoinGroupStateReturnType, 'isGroupMember'>) => void;
};

export const useHandleGroupOnly = ({ groupId }: Props) => {
  const { push, pop } = useFlow();

  const joinGroupState = useJoinGroupState({ groupId });
  const {
    isGroupMember,
    userState,
    joinApprovalRequireReason,
    approvalConditions,
    groupInfo,
    ...states
  } = joinGroupState;

  const { handleVerificationBottomSheet } = useHandleVerificationBottomSheet();

  const { open: openDialog, close: closeDialog } = useDialog();

  const { setOpenWebviewModal, setRecommendGroup } = useStore();

  const { data: userMe } = useGetMe();

  const { state: profileState } = useCheckGroupProfileCreated({});

  const handleErrorWithToast = useHandleErrorWithToast();

  //TODO: 8~9월 운동모임 가입 사전신청 프로모션
  const { handleOpenPreJoinMarketingPromotionBottomSheet } = usePreJoinGroupMarketingPromotion({
    groupId,
  });
  //TODO: 8~9월 운동모임 가입 본 이벤트 프로모션
  const { handleOpenJoinMarketingPromotionBottomSheet } = useJoinGroupMarketingPromotion({
    groupId,
  });

  const hasQuestion =
    approvalConditions === 'optional_answer' || approvalConditions === 'require_answer';

  const { data: eventMyInfo } = useGetEventLogMyInfo();

  const { userConfig } = useUserConfig();
  const trackJoinGroupEvent = () => {
    const isFirstJoinGroup = !eventMyInfo?.data.isJoinedGroup;

    const eventParams: GroupEventParams = {
      joinType: groupInfo.joinType,
      groupId: groupInfo.id,
      groupName: groupInfo.name,
      groupCategory: groupInfo.categoryName,
      checkinReigionId: userConfig.regionId ?? '',
      checkinMostAccurate: userConfig.regionTownName ?? '',
      isInstantMeetupJoin: false,
      joinApprovalRequireReason,
      needVerification: groupInfo.needVerification,
      canViewVerifiedUserInfo: groupInfo.canViewVerifiedUserInfo,
      watched: groupInfo.watched,
      isFirstJoinGroup,
      isChatRequired: !groupInfo.isShowChatRoomSetting,
      isChatActivated: !groupInfo.isDeactivateChatRoom,
    };

    trackEvent({
      event: 'click_join_group',
      params: eventParams,
      loggerType: extendAppsflyerLoggerType,
    });

    if (isFirstJoinGroup) {
      trackEvent({
        event: 'click_first_join_group',
        params: eventParams,
        loggerType: ['APPSFLYER'],
      });
    }
  };
  const { bridge } = useBridge();
  const { mutate: joinMutate } = usePostJoinGroup({
    groupId,
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      trackJoinGroupEvent();

      refetchGroupDetail({ groupId });

      bridge.openToast({
        toast: {
          body: `'${shortening(groupInfo?.name, 14)}' 모임에 가입했어요.`,
        },
      });

      refetchCurrentUser({ groupId });

      handleOpenPreJoinMarketingPromotionBottomSheet('join_direct');
      handleOpenJoinMarketingPromotionBottomSheet();
    },
  });

  const { mutate: applicationMutate } = usePostApplication({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async (data) => {
      trackJoinGroupEvent();

      if (data.data.application.recommendedGroups) {
        await setRecommendGroup(data.data.application.recommendedGroups);
        setOpenWebviewModal(MODAL_KEY.RECOMMEND_3_OTHER_GROUPS, true);
      }
      refetchGroupDetail({ groupId });

      if (hasQuestion) {
        pop();
      }
      handleOpenPreJoinMarketingPromotionBottomSheet('approval');
      handleOpenJoinMarketingPromotionBottomSheet();
    },
  });

  const handleGroupJoinFlow = ({
    blockGroupOnlyTitle = '모임 가입 안내',
    blockGroupOnlyText,
    onSuccess,
  }: Pick<HandleGroupOnlyParams, 'blockGroupOnlyText' | 'blockGroupOnlyTitle' | 'onSuccess'>) => {
    const { joinApprovalRequireReason, groupInfo, approvalConditions } = joinGroupState;
    const contentText =
      blockGroupOnlyText?.(groupInfo.name) ?? BLOCK_GROUP_ONLY_TEXT.getDefault(groupInfo.name);

    const handleConfirm = async () => {
      trackEvent({
        event: 'click_join_group_dialog',
        params: {
          type: 'join_group',
        },
      });

      if (joinApprovalRequireReason === 'not_certificated_region') {
        await closeDialog();

        setTimeout(() => {
          openDialog({
            element: (
              <Dialog
                title="동네인증"
                description={`모임에 가입하려면 ${groupInfo.regionName} 동네인증이 필요해요.`}
                secondaryActionIntent="nonpreferred"
                primaryActionLabel="인증"
                secondaryActionLabel="취소"
                onPrimaryAction={async () => {
                  await closeDialog();
                  trackEvent({ event: 'click_verify_neighboorhood' });
                  openVerifyNeighborhood();
                }}
                onSecondaryAction={async () => {
                  trackEvent({ event: 'click_cancel_verify_neighboorhood' });
                  await closeDialog();
                }}
              />
            ),
          });
        });
        return;
      }

      await closeDialog();

      const getCertificationCandidateType = () => {
        if (
          !groupInfo.needVerification ||
          (userMe.verified && !groupInfo.canViewVerifiedUserInfo)
        ) {
          return 'pass_verification';
        }

        if (!groupInfo.canViewVerifiedUserInfo) {
          return 'join_group';
        }

        if (userMe.verified) {
          return 'join_group_with_user_info_and_verified';
        }

        return 'join_group_with_user_info_and_not_verified';
      };

      // 본인인증 결과 체크
      const verified = await handleVerificationBottomSheet({
        certificationCandidateType: getCertificationCandidateType(),
      });

      if (!verified) {
        return;
      }

      // 가입 승인이 필요없는 경우
      if (joinApprovalRequireReason === 'none') {
        setTimeout(() => {
          if (profileState.needToProfileSet) {
            push('GroupProfileCreatePage', {
              groupId,
            });
            return;
          }
          joinMutate(
            {
              id: Number(groupId),
              joinForm: {
                profile: undefined,
              },
            },
            {
              onSuccess() {
                onSuccess?.({ userState });
              },
            }
          );
        });
        return;
      }

      // 가입 범위에 해당하지 않거나, 가입 승인이 필요한 경우 (but 질문 없음)
      if (approvalConditions === 'none') {
        setTimeout(() => {
          if (profileState.needToProfileSet) {
            push('GroupProfileCreatePage', {
              groupId,
            });
            return;
          }
          applicationMutate(
            {
              id: Number(groupId),
              groupMemberApplicationForm: {
                answers: [],
                profile: undefined,
              },
            },
            {
              onSuccess() {
                onSuccess?.({ userState });
              },
            }
          );
        });
        return;
      }

      // 가입 승인이 필요한 경우 (and 질문 있음)
      if (approvalConditions === 'optional_answer' || approvalConditions === 'require_answer') {
        if (profileState.needToProfileSet) {
          push('GroupProfileCreatePage', {
            groupId,
          });
          return;
        }
        setTimeout(() => {
          push('GroupProfileApprovalPage', {
            groupId,
          });
        });
        return;
      }
    };
    openDialog({
      element: (
        <Dialog
          title={blockGroupOnlyTitle}
          description={contentText}
          primaryActionLabel="가입"
          secondaryActionLabel="취소"
          onPrimaryAction={handleConfirm}
          onSecondaryAction={async () => {
            trackEvent({
              event: 'click_cancel',
              params: {
                type: 'join_group',
              },
            });
            closeDialog();
          }}
        />
      ),
      onOutsideClick: closeDialog,
    });
    return;
  };

  // 성공시, 실패시 콜백을 params로 받아서 실행한다.
  const handleGroupOnly = ({
    isBlock,
    onSettled,
    onFailure,
    onSuccess,
    blockGroupOnlyTitle,
    blockGroupOnlyText,
  }: HandleGroupOnlyParams) => {
    if (isGroupMember || isBlock === false) {
      return onSettled?.({ userState });
    }

    // 실패 callback이 있으면 실패 callback을 실행한다.
    if (onFailure) {
      return onFailure?.({
        userState,
        joinApprovalRequireReason,
        approvalConditions,
        groupInfo,
        ...states,
      });
    }

    handleGroupJoinFlow({ blockGroupOnlyTitle, blockGroupOnlyText, onSuccess });
  };

  return { handleGroupOnly, ...joinGroupState };
};

export const BLOCK_GROUP_ONLY_TEXT = {
  getDefault: (groupName: string) =>
    `모임에만 공개된 게시글이에요. ${groupName} 모임에 가입할까요?`,
  getComment: (groupName: string) =>
    `모임에 가입해야 댓글을 달 수 있어요. ${groupName} 모임에 가입할까요?`,
  getWriteMeetup: (groupName: string) =>
    `모임에 가입해야 일정을 만들 수 있어요. ${groupName} 모임에 가입할까요?`,
  enterGroupMeetup: (groupName: string) =>
    `모임에만 공개된 일정이에요. ${groupName} 모임에 가입할까요?`,
  getPoll: (groupName: string) =>
    `모임에 가입해야 투표할 수 있어요. ${groupName} 모임에 가입할까요?`,
  getCommentReaction: (groupName: string) =>
    `모임에 가입해야 댓글에 좋아요를 남길 수 있어요. ${groupName} 모임에 가입할까요?`,
  getMemberProfileImage: (groupName: string) =>
    `모임에 가입해야 프로필을 볼 수 있어요. ${groupName} 모임에 가입할까요?`,
  getReadAllAlbum: (groupName: string) =>
    `모임에 가입해야 전체 앨범을 확인할 수 있어요. ${groupName} 모임에 가입할까요?`,
  getReadAllMember: (groupName: string) =>
    `모임에 가입해야 전체 멤버를 확인할 수 있어요. ${groupName} 모임에 가입할까요?`,
  getReadAllFeed: (groupName: string) =>
    `모임에 가입해야 전체 게시글을 확인할 수 있어요. ${groupName} 모임에 가입할까요?`,
  getReadAllMeetup: (groupName: string) =>
    `모임에 가입해야 전체 일정을 확인할 수 있어요. ${groupName} 모임에 가입할까요?`,
  getReadAllNotice: (groupName: string) =>
    `모임에 가입해야 전체 공지를 확인할 수 있어요. ${groupName} 모임에 가입할까요?`,
  getReadAllMeetupMember: (groupName: string) =>
    `모임에 가입해야 전체 참여 중인 이웃을 확인할 수 있어요. ${groupName} 모임에 가입할까요?`,
  getReadAllComment: (groupName: string) =>
    `모임에 가입해야 전체 댓글을 확인할 수 있어요. ${groupName} 모임에 가입할까요?`,
};
