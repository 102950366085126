import { GroupPhotoContestEntryPresentation } from '@community-group/api/lib/group/models';
import {
  BoxButton,
  Spacing,
  Spinner,
  Typography,
  withAsyncBoundary,
} from '@community-group/components';
import { MouseEvent, useMemo } from 'react';
import Masonry from 'react-masonry-css';

import { useDeleteLikePhotoContestEntry } from '@/api/hooks/useDeleteLikePhotoContestEntry';
import { useGetPhotoContestEntries } from '@/api/hooks/useGetPhotoContestEntries';
import { usePatchLikePhotoContestEntry } from '@/api/hooks/usePatchLikePhotoContestEntry';
import { LoadMoreListContainer } from '@/components/common/LoadMoreContainer';
import { useBridge } from '@/contexts/Bridge';
import { trackEvent } from '@/utils/analytics';

import { useHandlePhotoContestNewGroupPost } from '../hooks/useHandlePhotoContestNewGroupPost';
import PhotoContestEntryItem from './PhotoContestEntryItem';
import * as s from './PhotoContestEntryList.css';

type Props = {
  orderByLatest?: boolean;
  localOnly?: boolean;
  isContestResult?: boolean;
};

const PhotoContestEntryList = ({ orderByLatest, localOnly, isContestResult }: Props) => {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetPhotoContestEntries({
    order: orderByLatest ? 'latest' : 'popularity',
    localOnly,
  });

  const entries = useMemo(
    () =>
      (data?.pages ?? [])
        .flatMap((page) => page.data.list)
        .filter((entry): entry is GroupPhotoContestEntryPresentation => entry !== undefined),
    [data?.pages]
  );

  if (entries === undefined || entries.length === 0) {
    return <PhotoContestEntryEmptyList />;
  }

  return (
    <>
      <PhotoContestEntryListCore entries={entries} isContestResult={isContestResult} />
      {hasNextPage && !isFetchingNextPage && <LoadMoreListContainer callback={fetchNextPage} />}
    </>
  );
};

const PhotoContestEntryEmptyList = () => {
  const handleNewGroupPost = useHandlePhotoContestNewGroupPost();
  const { bridge } = useBridge();

  const handleClick = () => {
    bridge.setHapticSelect({});
    handleNewGroupPost();
  };

  return (
    <div className={s.EntryEmptyList}>
      <Typography typography="bodyM1Regular" color="gray600">
        작성된 게시글이 없어요.
        <br />첫 게시글을 작성해보세요.
      </Typography>
      <Spacing height={38} />
      <BoxButton
        onClick={handleClick}
        variant="secondary"
        size="medium"
        UNSAFE_style={{ width: '7.5rem' }}
      >
        글쓰기
      </BoxButton>
    </div>
  );
};

type CoreProps = {
  entries: GroupPhotoContestEntryPresentation[];
  isContestResult?: boolean;
};

export const PhotoContestEntryListCore = ({ entries, isContestResult }: CoreProps) => {
  const { bridge } = useBridge();
  const { mutateAsync: patchVote } = usePatchLikePhotoContestEntry({});
  const { mutateAsync: deleteVote } = useDeleteLikePhotoContestEntry({});

  const handleEntryItemVote = (entry: GroupPhotoContestEntryPresentation) => (e: MouseEvent) => {
    e.stopPropagation();
    bridge.setHapticLightFeedback({});

    if (entry.hasUserVoted) {
      deleteVote(entry.entryId);
      return;
    }
    patchVote(entry.entryId);
    trackEvent({
      event: 'click_like',
      params: {
        contentType: 'post',
        section: 'photoEvent',
        isContestResult,
      },
      sample: true,
    });
  };

  return (
    <ul className={s.EntryList}>
      <Masonry
        className={s.MasonryWrapper}
        breakpointCols={{
          default: 2,
        }}
      >
        {entries.map((entry) => {
          return (
            <PhotoContestEntryItem
              key={entry.entryId}
              onVote={handleEntryItemVote(entry)}
              isContestResult={isContestResult}
              {...entry}
            />
          );
        })}
      </Masonry>
      <Spacing height={30} />
    </ul>
  );
};

export default withAsyncBoundary(PhotoContestEntryList, {
  pendingFallback: (
    <div className={s.Loading}>
      <Spinner />
    </div>
  ),
});
