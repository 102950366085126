import { GroupMeetupPoiPresentation } from '@community-group/api/lib/group/models';
import { Spacing, Typography, withClientOnly } from '@community-group/components';

import IconExpand from '@/assets/images/icon_expand.svg';
import { Map } from '@/components/common/Map';
import { Marker } from '@/components/common/Map/components/Marker';
import { trackEvent } from '@/utils/analytics';
import { openPoiDetailPage } from '@/utils/link';

import * as s from './MeetupPlace.css';

type Props = {
  place: GroupMeetupPoiPresentation;
};

const MeetupPlace = ({ place }: Props) => {
  const coordinates = [place.coordinates.longitude, place.coordinates.latitude] as [number, number];
  const handleMapClick = () => {
    trackEvent({ event: 'click_poi_detail', sample: true });

    openPoiDetailPage({
      id: place.id,
      type: place.type,
    });
  };

  return (
    <div>
      <Typography typography="subtitle1Bold" color="gray900">
        자주 모이는 장소
      </Typography>
      <Spacing height={12} />
      <div className={s.MapContainer} onClick={handleMapClick}>
        <Map
          mapId="poiMap"
          className={s.Map}
          options={{
            interactive: false,
            zoom: 16,
            center: coordinates,
          }}
        >
          <Marker markerId={place.name} coordinates={coordinates} label={place.name} />
        </Map>
        <div className={s.ExpendButton}>
          <img src={IconExpand} alt="지도 펼쳐보기 버튼" />
        </div>
      </div>
    </div>
  );
};

export default withClientOnly(MeetupPlace);
