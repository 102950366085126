export const getTemperature = (temperature: number) => {
  const displayText = `${temperature.toFixed(1)}°C`;
  if (temperature < 30) {
    return {
      color: '#777F87',
      background: '119, 127, 135, 0.12',
      displayText,
    };
  }
  if (temperature < 36.2) {
    return {
      color: '#4D8FCE',
      background: '77, 143, 206, 0.12',
      displayText,
    };
  }
  if (temperature < 37.5) {
    return {
      color: '#58B9ED',
      background: '88, 185, 237, 0.12',
      displayText,
    };
  }
  if (temperature < 41.9) {
    return {
      color: '#66D06B',
      background: '102, 208, 107, 0.12',
      displayText,
    };
  }
  if (temperature >= 42 && temperature < 52) {
    return {
      color: '#FFB938',
      background: '255, 185, 56, 0.12',
      displayText,
    };
  }
  return {
    color: '#FF7E36',
    background: '255, 114, 54, 0.12',
    displayText,
  };
};
