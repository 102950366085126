import {
  AsyncBoundary,
  MultilineTextField,
  Spacing,
  TextField,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';
import { $Keys } from 'utility-types';

import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { FormContainer, FormItem } from '@/components/common/Form';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useUpdateStyleCurrentRouter } from '@/hooks/useUpdateStyleCurrentRouter';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import useSetFixedLayoutSize from '@/stackflow/hooks/useSetFixedLayoutSize';
import { PageParams } from '@/stackflow/types/params';
import { isForbiddenWord } from '@/utils/check';
import { groupFieldSchema } from '@/utils/validate/formSchema/group';
import { validateSchemaWithHandleToast, validateSchemaWithMessage } from '@/utils/validate/util';

import AppBarCloseCreateGroupButton from '../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../Common/GroupCreateAppScreen';
import GroupDescriptionCallout from '../Common/GroupDescriptionCallout';
import useCreateForm from '../hooks/useCreateForm';
import useCreateGroupFunnel from '../hooks/useCreateGroupFunnel';
import { indicatorCountHandler } from '../utils/indicatorCountHandler';
import { GuardForbiddenWordPopSendEnum } from './bottomSheet/GuardForbiddenWordGroupBottomSheet';

export type GroupNewSetGroupNamePageParams = Pick<
  PageParams,
  'categoryId' | 'createType' | 'from' | 'createGroupFlow' | 'joinGrowth'
>;

const GroupNewSetGroupNamePage: ActivityComponentType<GroupNewSetGroupNamePageParams> = () => {
  const { push, pop } = useFlow();
  const { push: connectedPush } = useConnectedActions();

  const { from, ...postNewGroupPayload }: GroupNewSetGroupNamePageParams = useQueryParams();

  const { handleSubmit, watch, setValue } = useCreateForm({});

  useSetFixedLayoutSize();
  useUpdateStyleCurrentRouter({ IOS_ONLY_hideKeyboardAccessoryView: false }, true);

  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: { step: 'name' },
  });
  useEnterTrackEvent({
    event: 'enter_create_group_step_name',
    params: { step: 'name' },
    loggerType: ['APPSFLYER'],
  });

  const nameFieldValue = watch('name') ?? '';
  const descriptionFieldValue = watch('description') ?? undefined;

  const validateForm = () => {
    if (!validateSchemaWithHandleToast(groupFieldSchema.name, nameFieldValue)) return false;
    if (
      !!descriptionFieldValue &&
      !validateSchemaWithHandleToast(groupFieldSchema.description, descriptionFieldValue)
    ) {
      return false;
    }
    return true;
  };

  const validateForbiddenWord = () => {
    if (isForbiddenWord(nameFieldValue)) {
      return false;
    }

    if (isForbiddenWord(descriptionFieldValue)) {
      return false;
    }

    return true;
  };

  const { nextPageName } = useCreateGroupFunnel();

  const onSubmit = handleSubmit(async () => {
    if (!validateForm()) return;

    if (!validateForbiddenWord()) {
      const popData = (await connectedPush(
        'BottomSheet/GuardForbiddenWordGroupBottomSheet',
        {}
      )) as {
        type: $Keys<typeof GuardForbiddenWordPopSendEnum>;
      };

      if (popData?.type === 'needToChangeGroupInfo') return;
    }

    push(nextPageName, {
      ...postNewGroupPayload,
      name: nameFieldValue,
      description: descriptionFieldValue,
      from,
    });
  });

  const nameFieldErrorMessage = useMemo(() => {
    const result = groupFieldSchema.name.safeParse(nameFieldValue);
    if (result.success) return '';
    const parsedError = JSON.parse(result.error.message)[0];
    return parsedError.message ?? '';
  }, [nameFieldValue]);

  const descriptionFieldErrorMessage = useMemo(() => {
    if (!descriptionFieldValue) return '';

    const result = validateSchemaWithMessage(groupFieldSchema.description, descriptionFieldValue);
    if (result.success) return '';
    return result.message ?? '';
  }, [descriptionFieldValue]);

  const isDisable = useMemo(() => {
    if (!nameFieldValue) return true;
    if (!descriptionFieldValue) return true;

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionFieldValue, groupFieldSchema.description]);

  const handleBack = () => {
    pop();
  };

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetGroupNamePage')}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup
            onBack={handleBack}
            onSubmit={onSubmit}
            buttonDisabled={isDisable}
          />
        </>
      )}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <LayoutWrapper padding="0 1rem">
          <div>
            <Typography
              as="p"
              typography="title1Bold"
              color="gray900"
              style={{
                marginBottom: '1.5rem',
                marginTop: '1rem',
                width: '100%',
              }}
            >
              모임을 소개해주세요
            </Typography>
            <Typography as="div" typography="label3Bold" color="gray900">
              모임명
            </Typography>
            <Spacing height={12} />
            <FormContainer>
              <input type="text" style={{ display: 'none' }} />
              <FormItem marginBottom={0}>
                <TextField
                  variant={'outlined'}
                  onChange={(value) => {
                    setValue('name', value);
                  }}
                  placeholder="모임명이 짧을수록 이해하기 쉬워요."
                  isInvalid={groupFieldSchema.name.safeParse(nameFieldValue).success === false}
                  maxLength={groupFieldSchema.name.maxLength ?? 0}
                  value={nameFieldValue?.toString() ?? ''}
                  errorMessage={nameFieldErrorMessage}
                  invalidAfterBlurred={true}
                />
              </FormItem>
            </FormContainer>

            <Spacing height={32} />

            <Typography as="div" typography="label3Bold" color="gray900">
              모임 소개
            </Typography>
            <Spacing height={12} />
            <FormItem>
              <div id="description-textarea">
                <MultilineTextField
                  value={descriptionFieldValue ?? ''}
                  onChange={(value) => {
                    setValue('description', value);
                  }}
                  placeholder="활동 중심으로 모임을 소개해주세요. 모임 설정에서 언제든지 바꿀 수 있어요."
                  maxLength={groupFieldSchema.description.maxLength ?? 0}
                  minLength={groupFieldSchema.description?.minLength ?? 0}
                  isInvalid={!!descriptionFieldErrorMessage}
                  errorMessage={descriptionFieldErrorMessage}
                  minHeight="12.5rem"
                />
              </div>
              <Spacing height={24} />
              <GroupDescriptionCallout />
            </FormItem>
            <Spacing height={32} />
          </div>
        </LayoutWrapper>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetGroupNamePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
