import { vars } from '@seed-design/design-token';

import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';

import { HomeBanner } from '../Banner';

export const JoinExerciseGroupMarketingBanner = () => {
  const handleClick = () => {
    trackEvent({
      event: 'click_home_banner_notice',
      params: {
        type: 'during_running_race_promotion',
      },
      sample: true,
    });

    openLink(
      'karrot://minikarrot/router?remote=https%3A%2F%2Flandkit.krrt.io%2Fpages%2Feguds3%2F&navbar=true&scrollable=true'
    );
  };

  return (
    <HomeBanner
      description="불꽃 튀는 참여현황을 구경해 보세요!"
      title="2024 당근 레이스 진행 중"
      image="https://assetstorage.krrt.io/1025317940251365954/d0733078-e40d-45ce-bbf9-e9917ee1458a/width=216,height=216.png"
      onClick={handleClick}
      style={{
        background: vars.$scale.color.green100,
      }}
    />
  );
};
