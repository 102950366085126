import { GroupCurrentUser, PostDetail } from '@community-group/api/lib/group/models';
import { isHigherManager, Spacing, Typography, useBottomSheet } from '@community-group/components';
import { IconMoreVertRegular } from '@seed-design/icon';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { MouseEvent, PropsWithChildren, useMemo } from 'react';

import FeedItemContents from '@/components/group/Detail/components/Feed/FeedItem/FeedItemContents';
import FeedItemProfile from '@/components/group/Detail/components/Feed/FeedItem/FeedItemProfile';
import HigherManagerRoleOptionsBottomSheet from '@/components/group/Detail/components/Feed/Modal/HigherManagerRoleOptionsBottomSheet';
import NormalUserRoleOptionsList from '@/components/group/Detail/components/Feed/Modal/NormalUserRoleOptionsList';
import WriterRoleOptionsList from '@/components/group/Detail/components/Feed/Modal/WriterRoleOptionsList';
import { useHandleGroupOnly } from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useJoinGroupState } from '@/components/group/JoinGroupState/hooks/useJoinGroupState';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { GroupRoutes } from '@/utils/analytics/type';

import * as s from './NoticeSummarySection.css';

type Props = PropsWithChildren<{
  groupId: string;
  notice: PostDetail;
  currentUser: GroupCurrentUser;
}>;

const NoticeSummarySection = ({ groupId, notice, currentUser, children }: Props) => {
  const activity = useFlow();
  const bottomSheet = useBottomSheet();

  const { handleGroupOnly, isGroupMember } = useHandleGroupOnly({ groupId });
  const { groupInfo } = useJoinGroupState({ groupId });

  const blockedGroupOnly = useMemo(
    () => !isGroupMember && notice.publishType === 'groupOnly',
    [isGroupMember, notice.publishType]
  );

  const handleNoticeItemClick = () => {
    handleGroupOnly({
      isBlock: blockedGroupOnly,
      onSettled: () => {
        activity.push('GroupPostDetailPage', {
          groupId,
          postId: notice.id.toString(),
        });
      },
      onSuccess() {
        if (groupInfo.isSettingSubNickname) {
          activity.push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
      },
    });
  };

  const handleProfileClick = (e: MouseEvent) => {
    e.stopPropagation();

    trackEvent({
      event: 'click_profile',
      params: {
        referrer: GroupRoutes.GROUP_DETAIL,
        clickedUserId: notice.author?.id,
        clickedUserRole: notice.author?.role,
        clickedUserState: notice.author?.state,
        type: 'feed',
      },
    });
    activity.push('GroupUserProfileDetailPage', {
      groupId,
      userId: notice.author.id.toString(),
    });
  };

  const handleProfileMoreButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    handleGroupOnly({
      isBlock: blockedGroupOnly,
      onSettled() {
        handleOnClickSheetList();
      },
      onSuccess() {
        if (groupInfo.isSettingSubNickname) {
          activity.push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
      },
    });
  };

  const handleEditPostClick = async () => {
    await bottomSheet.closeAsync();

    activity.push('GroupPostEditPage', {
      groupId,
      postId: notice.id.toString(),
      postType: notice.postType?.type,
    });
    return;
  };

  const handleEditPostBoardCategoryClick = async () => {
    await bottomSheet.closeAsync();

    activity.push('BottomSheet/GroupMoveBoardCategoryBottomSheet', {
      groupId,
      postId: notice.id.toString(),
    });
  };

  const handleOnClickSheetList = () => {
    if (isHigherManager(currentUser.role)) {
      bottomSheet.open({
        element: (
          <HigherManagerRoleOptionsBottomSheet
            post={notice}
            groupId={groupId}
            onEditPost={handleEditPostClick}
            onEditPostBoardCategory={handleEditPostBoardCategoryClick}
            currentUserPermissions={currentUser.permissions}
          />
        ),
        onDimClose: bottomSheet.close,
      });
      return;
    }

    const isNoticeAuthor = currentUser.id === notice.author.id;
    if (isNoticeAuthor) {
      bottomSheet.open({
        element: (
          <WriterRoleOptionsList
            post={notice}
            groupId={groupId}
            onEditPost={() => {
              handleEditPostClick();
            }}
            onEditPostBoardCategory={handleEditPostBoardCategoryClick}
          />
        ),
      });
      return;
    }

    bottomSheet.open({
      element: <NormalUserRoleOptionsList post={notice} groupId={groupId} />,
    });
  };
  const handlePollBannerClick = (e: MouseEvent) => {
    e.stopPropagation();
    activity.push('GroupPostDetailPage', {
      groupId,
      postId: notice.id.toString(),
    });
  };

  return (
    <section className={s.Container}>
      <Typography typography="title3Bold" color="gray900" className={s.Title}>
        공지
      </Typography>
      <Spacing height={20} />
      <motion.div className={clsx([s.FeedItemContainer, 'feed-item-wrapper'])}>
        <div className={s.NoticeItemContainer} onClick={handleNoticeItemClick}>
          <div className={s.ProfileHeader}>
            <FeedItemProfile post={notice} currentUser={currentUser} onClick={handleProfileClick} />
            <button onClick={handleProfileMoreButtonClick}>
              <IconMoreVertRegular size={24} />
            </button>
          </div>
          <Spacing height={12} />
          <div className={s.ContentWrapper}>
            <FeedItemContents
              post={notice}
              currentUserRole={currentUser.role}
              onPollBannerClick={handlePollBannerClick}
            />
          </div>
          <Spacing height={12} />
          <div className={s.Footer}>
            <Typography className={s.FooterText} typography="caption1Regular" color="gray600">
              조회 {notice.readCount} ⸱ 좋아요 {notice.emotion.count}
            </Typography>
          </div>
        </div>
        {children}
      </motion.div>
    </section>
  );
};

export default NoticeSummarySection;
