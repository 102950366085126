import { AsyncBoundary, ViewError, ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';

import { useGetMainProfile } from '@/api/hooks/useGetMainProfile';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import { ProfileMeetupMemberReviewMessageItem } from '../components/MeetupMemberReviewMessageItem';

export type ProfileMeetupMemberReviewMessagesPageParams = Pick<PageParams, 'userId' | 'groupId'>;

const ProfileMeetupMemberReviewMessagesPage: ActivityComponentType<
  ProfileMeetupMemberReviewMessagesPageParams
> = () => {
  return (
    <AppScreen
      appBar={{
        title: '받은 후기',
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <ProfileMeetupMemberReviewMessagesView />
      </AsyncBoundary>
    </AppScreen>
  );
};

const ProfileMeetupMemberReviewMessagesView = () => {
  const { userId } = usePathParams();

  const { data: mainProfile } = useGetMainProfile({
    userId: Number(userId),
  });
  const isMyProfile = !!mainProfile?.isMyProfile;

  const currentMeetupMemberReviewMessages = useMemo(
    () => mainProfile?.meetupMemberReviewMessages ?? [],
    [mainProfile]
  );

  const filteredMeetupMemberReviewMessages = useMemo(() => {
    if (isMyProfile) return currentMeetupMemberReviewMessages;

    return currentMeetupMemberReviewMessages.filter(
      ({ reviewExposureRange }) => reviewExposureRange === 'ALL'
    );
  }, [currentMeetupMemberReviewMessages, isMyProfile]);

  return (
    <ul>
      {filteredMeetupMemberReviewMessages.map((memberReviewMessage) => (
        <ProfileMeetupMemberReviewMessageItem
          key={memberReviewMessage.id}
          isMyProfile={isMyProfile}
          meetupMemberReviewMessage={memberReviewMessage}
        />
      ))}
    </ul>
  );
};

export default ProfileMeetupMemberReviewMessagesPage;
