import {
  Container,
  ImageLoader,
  MultilineTextField,
  Spacing,
  TextField,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCameraFill } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';
import { groupFieldSchema } from '@/utils/validate/formSchema/group';
import {
  validateSchemaWithBoolean,
  validateSchemaWithHandleToast,
  validateSchemaWithMessage,
} from '@/utils/validate/util';

import useGetIsExistHeroSlider from '../../Detail/hooks/useGetIsExistHeroSlider';
import GroupSettingEditInfoHeroSlider from '../../ImageViewerPage/components/GroupSettingEditInfoHeroSlider';
import EmptyHeroImageBox from '../components/EmptyHeroImageBox';
import * as s from './GroupSettingEditGroupInfoPage.css';

export type GroupSettingEditGroupInfoPageParams = Pick<PageParams, 'groupId'>;

const GroupSettingEditGroupInfoPage: ActivityComponentType<
  GroupSettingEditGroupInfoPageParams
> = () => {
  const { groupId } = usePathParams();
  const { group: groupDetail } = useGetGroupDetail(groupId);
  const { bridge } = useBridge();
  const { pop } = useFlow();

  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId, onlyPublic: true });
  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      name: groupDetail?.name ?? '',
      description: groupDetail?.description ?? '',
      profileImage: groupDetail?.profileImage.id ?? '',
      backgroundImage: groupDetail?.backgroundImage.id ?? '',
    },
  });

  const {
    originImage: profileImageOriginImage,
    isLoading: isProfileImageLoading,
    handleSetGroupProfile,
  } = useHandleSetGroupProfile({
    onUpload: (id) => {
      if (id) setValue('profileImage', id);
    },
  });

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isLoading } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });
      bridge.openToast({
        toast: {
          body: '모임 정보를 수정했어요.',
        },
      });
      pop();
    },
  });

  const nameFieldValue = watch('name') ?? '';
  const descriptionFieldValue = watch('description') ?? '';

  const validateForm = useCallback(() => {
    if (!validateSchemaWithHandleToast(groupFieldSchema.name, nameFieldValue)) return false;
    if (
      !!descriptionFieldValue &&
      !validateSchemaWithHandleToast(groupFieldSchema.description, descriptionFieldValue)
    ) {
      return false;
    }
    return true;
  }, [nameFieldValue, descriptionFieldValue]);

  const isDisable = useMemo(() => {
    if (!validateSchemaWithBoolean(groupFieldSchema.name, nameFieldValue)) return true;
    if (
      !!descriptionFieldValue &&
      !validateSchemaWithBoolean(groupFieldSchema.description, descriptionFieldValue)
    ) {
      return true;
    }
    return false;
  }, [descriptionFieldValue, nameFieldValue]);

  const onSubmit = handleSubmit(async (data) => {
    if (!validateForm()) return;

    mutate(
      {
        id: groupId,
        groupModifyForm: {
          name: data?.name,
          description: data?.description,
          backgroundImageId: data?.backgroundImage,
          profileImageId: data?.profileImage,
        },
      },
      {
        onSuccess: () => {
          refetchGroupSetting({ groupId });
          refetchGroupDetail({ groupId });
          trackEvent({
            event: 'click_modify_group_info',
            params: {
              groupId,
            },
          });
        },
      }
    );
  });

  return (
    <AppScreen
      appBar={{
        title: '모임 정보 수정',
        renderRight: () => (
          <p
            onClick={() => !isDisable && !isLoading && onSubmit()}
            className={clsx(s.SubmitButton, isDisable ? 'disabled' : '')}
          >
            완료
          </p>
        ),
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      {isExistHeroSlider ? (
        <GroupSettingEditInfoHeroSlider groupId={groupId} initialIndex={0} role={'superHost'} />
      ) : (
        <EmptyHeroImageBox groupId={groupId} />
      )}
      <Container paddingY={24}>
        <Typography typography={'label3Bold'} color={'gray900'}>
          대표사진
        </Typography>
        <Spacing height={4} />
        <Typography typography={'bodyM2Regular'} color={'gray600'}>
          전체 모임 목록에서 보이는 이미지에요.
        </Typography>
        <Spacing height={12} />
        <div className={s.ProfileImageSection}>
          <div
            className={s.ProfileImageWrapper}
            onClick={() => {
              trackEvent({
                event: 'click_change_group_info_image',
                params: {
                  type: 'setting_profile',
                  groupId,
                },
              });
              handleSetGroupProfile();
            }}
          >
            <ImageLoader
              className={s.GroupProfileImage}
              src={
                profileImageOriginImage
                  ? URL.createObjectURL(profileImageOriginImage)
                  : groupDetail?.profileImage.medium
              }
              isLoading={isProfileImageLoading}
              alt="모임 프로필 이미지"
            />
            <div className={s.ProfileCameraWrapper}>
              <IconCameraFill size={26} color={vars.$scale.color.gray600} />
            </div>
          </div>
        </div>
      </Container>
      <Spacing height={16} />
      <div className={s.Wrapper}>
        <TextField
          label="모임명"
          value={watch('name') ?? ''}
          onChange={(textValue) => setValue('name', textValue)}
          placeholder="모임명을 입력해주세요"
          maxLength={24}
          variant={'outlined'}
          isInvalid={!validateSchemaWithBoolean(groupFieldSchema.name, nameFieldValue)}
          errorMessage={
            validateSchemaWithMessage(groupFieldSchema.name, nameFieldValue)?.message ?? ''
          }
          invalidAfterBlurred={false}
        />

        <Spacing height={24} />
        <MultilineTextField
          label="모임 소개"
          value={watch('description') ?? ''}
          onChange={(textValue) => setValue('description', textValue)}
          placeholder="모임 소개를 입력해주세요"
          maxLength={500}
          isInvalid={
            !validateSchemaWithBoolean(groupFieldSchema.description, descriptionFieldValue)
          }
          errorMessage={
            validateSchemaWithMessage(groupFieldSchema.description, descriptionFieldValue)
              ?.message ?? ''
          }
          invalidAfterBlurred={false}
        />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingEditGroupInfoPage, {
  pendingFallback: (
    <>
      <AppScreen>
        <ViewLoader />
      </AppScreen>
    </>
  ),
  rejectedFallback: (
    <>
      <AppScreen>
        <ViewError />
      </AppScreen>
    </>
  ),
});
