import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';

import { openGroupMeetupReport } from '@/utils/link';

type Props = {
  hostId: string;
  meetupId: string;
  onClone: () => void;
  onDelete: () => void;
};

export const GroupHigherManagerMoreMenuAppBarBottomSheet = ({
  hostId,
  meetupId,
  onClone,
  onDelete,
}: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        <ActionListItem
          onClick={async () => {
            await closeBottomSheet();

            onClone();
          }}
        >
          복제
        </ActionListItem>
        <ActionListItem
          color={vars.$semantic.color.danger}
          onClick={async () => {
            await closeBottomSheet();

            onDelete();
          }}
        >
          삭제
        </ActionListItem>
        <ActionListItem
          onClick={async () => {
            await closeBottomSheet();
            openGroupMeetupReport({ meetupId, hostId });
          }}
        >
          신고
        </ActionListItem>
      </ActionList>
    </ActionSheet>
  );
};
