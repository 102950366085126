import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics';
import { basicUIPlugin } from '@stackflow/plugin-basic-ui';
import { historySyncPlugin } from '@stackflow/plugin-history-sync';
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic';
import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change';
import { stackflow } from '@stackflow/react';
import { useActions } from '@stackflow/react';
import { useStepActions } from '@stackflow/react';

import { bridge } from '@/contexts/Bridge';

import { activityPages } from './activity/pages';
import { routes } from './activity/routes';
import { checkVisitPlugin } from './plugins/checkVisitPlugin';
import { prevHistoryPlugin } from './plugins/prevHistoryPlugin';

const initStackflow = () => {
  const handleDepthChange = (depth: number) => {
    if (import.meta.env.SSR) return;

    bridge.styleCurrentRouter({
      router: {
        backSwipable: depth === 1,
        navbar: false,
        scrollable: false,
        enableSafeAreaInsets: false,
        IOS_ONLY_hideKeyboardAccessoryView: true,
      },
    });
  };

  return stackflow({
    transitionDuration: 270,
    activities: activityPages,
    plugins: [
      historySyncPlugin({
        routes,
        fallbackActivity: () => 'NotFoundPage',
      }),
      basicRendererPlugin(),
      basicUIPlugin(({ initialContext }) => {
        return {
          bridge,
          theme: 'android',
          rootClassName: 'root',
          minSafeAreaInsetTop: initialContext.minSafeAreaInsetTop,
        };
      }),
      stackDepthChangePlugin({
        onInit: ({ depth }) => handleDepthChange(depth),
        onDepthChanged: ({ depth }) => handleDepthChange(depth),
      }),
      checkVisitPlugin(),
      prevHistoryPlugin(),
      karrotAnalyticsPlugin({
        bridge,
        serviceName: 'community_group',
        itemId: {
          GroupDetailPage: (activityParams) => {
            return activityParams.groupId;
          },
          GroupPostDetailPage(activityParams) {
            return activityParams.groupId;
          },
          GroupMeetupDetailPage(activityParams) {
            return activityParams.meetupId;
          },
        },
      }),
    ],
  });
};

const {
  Stack,
  activities,
  useFlow: useOriginFlow,
  useStepFlow: useOriginStepFlow,
} = initStackflow();

export type TypeActivities = typeof activities;
export type TypeActivityNames = keyof TypeActivities;
export type TypeUseFlow = typeof useOriginFlow;
export type TypeUseStepFlow = typeof useOriginStepFlow;

// NOTE: GroupV2 실험을 위한 코드, 이후 실험 종료되면 원복 필요
const useFlow: TypeUseFlow = () => {
  const actions = useActions<TypeActivities>();

  const action =
    (method: 'push' | 'replace') =>
    (name: TypeActivityNames, params, ...rest) => {
      if (name === 'GroupDetailPage') {
        return actions[method]('GroupDetailPage', params, ...rest);
      }

      return actions[method](name, params, ...rest);
    };

  return {
    ...actions,
    push: action('push'),
    replace: action('replace'),
  };
};
const useStepFlow: TypeUseStepFlow = (activityName: TypeActivityNames) =>
  useStepActions(activityName as never);
const useConnectedFlow = () => useConnectedActions<TypeActivities>();

export { activities, Stack, useConnectedFlow, useFlow, useStepFlow };
