import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { shareGroupMeetupPermalink } from '@/utils/link';
import { isMember } from '@/utils/role';

type Params = {
  groupId: string;
  meetupId: string;
  permalinkId: string;
  currentUserRole: GroupMemberRoleEnum;
  from: 'meetupDetailAppBar' | 'meetupDetailFeedback' | 'feedItem';
};

export const useShareMeetupContents = () => {
  const { push } = useFlow();

  return ({ groupId, meetupId, permalinkId, currentUserRole, from }: Params) => {
    const isJoinedUser = isMember(currentUserRole);

    trackEvent({
      event: 'click_share',
      params: {
        groupId,
        meetupId,
        type: 'group_meetup',
        isJoined: isJoinedUser,
        role: currentUserRole,
        from,
      },
    });

    if (isJoinedUser) {
      push('BottomSheet/SharePostBottomSheet', {
        groupId: groupId,
        permalinkId: permalinkId,
        contentId: meetupId,
        contentType: 'groupMeetup',
      });
      return;
    }

    shareGroupMeetupPermalink(permalinkId);
  };
};
