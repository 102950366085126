import { groupClient } from '@community-group/api';
import { CoveredLazyLoadImage, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconLocationFill, IconProfileFill } from '@seed-design/icon';
import clsx from 'clsx';

import { VerticalSpacing } from '../Spacing';
import * as s from './ListView.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  group: groupClient.model.GroupSummaryPresentation;
  config?: {
    showDescription?: boolean;
    showMemberList?: boolean;
    enableLazyLoadImage?: boolean;
  };
  titleChildren?: React.ReactNode;
  descriptionChildren?: React.ReactNode;
}

const GroupInfoListViewItem = ({
  group: { profileImage, name, description, memberCount, isJustActivated, category, region },
  config,
  descriptionChildren,
  titleChildren,
  ...props
}: Props) => {
  const { showDescription = true, enableLazyLoadImage = true } = config ?? {};

  const renderTitle = () => {
    if (titleChildren) {
      return titleChildren;
    }

    return <h3 className={s.title}>{name}</h3>;
  };

  const renderDescription = () => {
    if (descriptionChildren) {
      return descriptionChildren;
    }

    return showDescription && description && <p className={s.description}>{description}</p>;
  };

  return (
    <div {...props} className={clsx([props.className, s.wrapper])}>
      <div className={s.groupImage}>
        <CoveredLazyLoadImage
          insideStroke
          isLazy={enableLazyLoadImage}
          src={profileImage.small}
          width="4.5rem"
          height="4.5rem"
          style={{ borderRadius: '1rem' }}
        />
      </div>
      <div className={s.contentsWrapper}>
        {renderTitle()}
        {renderDescription()}
        <div className={s.BadgeWrapper}>
          {region?.town && (
            <span className={s.Badge}>
              <IconLocationFill size={12} color={vars.$scale.color.gray500} />
              <VerticalSpacing height={2} />
              <Typography typography="caption1Regular" color="gray600" wordBreak="keep-all">
                {region?.town}
              </Typography>
              <div className={s.Space}>⸱</div>
            </span>
          )}
          <span className={s.Badge}>
            <IconProfileFill size={12} color={vars.$scale.color.gray500} />
            <VerticalSpacing height={2} />
            <Typography typography="caption1Regular" color="gray600" wordBreak="keep-all">
              {memberCount}명
            </Typography>
            <div className={s.Space}>⸱</div>
          </span>
          <span className={s.Badge}>
            <Typography typography="caption1Regular" color="gray600" wordBreak="keep-all">
              {category?.name}
            </Typography>
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupInfoListViewItem;
