import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconReplyRegular, IconThumbUpFill, IconThumbUpRegular } from '@seed-design/icon';
import { MouseEventHandler, useMemo } from 'react';

import { VerticalSpacing } from '@/components/common/Spacing';

import * as s from './CommentReaction.css';

interface Props {
  emotionCount: number;
  myEmotion?: string;
  parentId?: number;
  subCommentCount?: number;
  onLikeReactionClick?: MouseEventHandler;
  onDislikeReactionClick?: MouseEventHandler;
  onReplyClick?: MouseEventHandler;
}

const CommentReaction = ({
  emotionCount,
  myEmotion,
  parentId,
  subCommentCount,
  onLikeReactionClick,
  onDislikeReactionClick,
  onReplyClick,
}: Props) => {
  const isSubComment = Boolean(parentId);

  const emotionCountText = useMemo(() => {
    if (emotionCount > 0) return emotionCount;
    if (myEmotion) return 1;
    return '';
  }, [emotionCount, myEmotion]);

  const subCommentCountText = useMemo(() => {
    if (!subCommentCount || subCommentCount === 0) return '';
    return subCommentCount;
  }, [subCommentCount]);

  const handleEmotionClick = (e) => {
    if (myEmotion) {
      onDislikeReactionClick?.(e);
      return;
    }
    onLikeReactionClick?.(e);
  };

  return (
    <div className={s.ReactionWrapper}>
      <div className={s.FeedbackLeftBox}>
        <div className={s.FeedbackIcon} onClick={handleEmotionClick}>
          {myEmotion ? (
            <IconThumbUpFill width={18} height={18} color={vars.$semantic.color.primary} />
          ) : (
            <IconThumbUpRegular width={18} height={18} color={vars.$scale.color.gray600} />
          )}
          {emotionCountText !== '' && (
            <>
              <VerticalSpacing height={4} />
              <Typography typography="caption1Regular" color={myEmotion ? 'primary' : 'gray600'}>
                {emotionCountText}
              </Typography>
            </>
          )}
        </div>
        {!isSubComment && (
          <>
            <VerticalSpacing height={14} />
            <div className={s.FeedbackIcon} onClick={onReplyClick}>
              <IconReplyRegular
                width={18}
                height={18}
                color={vars.$scale.color.gray600}
                onClick={onReplyClick}
              />
              <VerticalSpacing height={4} />
              <Typography typography="caption1Regular" color="gray600">
                {subCommentCountText}
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CommentReaction;
