import { PostDetail } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import React from 'react';

import { VerticalSpacing } from '@/components/common/Spacing';
import { convertNicknameDisplay } from '@/utils/nickname';
import { getFeedPostInfoText } from '@/utils/postInfoText';

import * as s from './MyFeedItemProfile.css';
import MyFeedProfileImage from './MyFeedItemProfileImage';

interface Props {
  post: PostDetail;
}

const MyFeedItemProfile = ({ post }: Props) => {
  return (
    <div className={s.wrapper}>
      <MyFeedProfileImage
        groupImage={post.groupInfo?.profileImage.small || ''}
        userProfileImage={post.author.profileImage || ''}
      />
      <VerticalSpacing height={12} />
      <div className={s.container}>
        <Typography typography="caption1Bold" color="gray900" ellipsisAfterLines={1}>
          {post.groupInfo?.name}
        </Typography>
        <Spacing height={2} />
        <Typography typography="caption2Regular" color="gray600" ellipsisAfterLines={1}>
          {convertNicknameDisplay({
            nickname: post.author.nickname,
            subNickname: post.author.subNickname,
          })}
          {' ∙ '}
          {getFeedPostInfoText({
            createdAt: post.createdAt,
            isPublished: post.isPublished,
            isGroupOnly: post.publishType === 'groupOnly',
            isNoticed: post.isNoticed,
            boardCategory: post.boardCategories[post.boardCategories.length - 1]?.category,
          })}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(MyFeedItemProfile);
