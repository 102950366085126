import { useGetRunningRaceGroups } from '@/api/hooks/useGetRunningRaceGroups';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';

import { isDuringRealJoinGroupMarketingPromotion, isShowJoinGroupMarketingBanner } from '../utils';

//8~9월 운동 마케팅 본 프로모션 관련 분기용 유틸
function useJoinGroupMarketingPromotion({ groupId }: { groupId: string }) {
  const [marketingPromotionBottomSheet] = useStorage('promotion_group_2408_exercise_join', false);
  const { push } = useFlow();

  const { isEventGroup } = useGetRunningRaceGroups({ groupId });

  const isOngoingExerciseGroupJoinMarketingPromotion = useFeatureFlag(
    'exerciseGroupJoinMarketingPromotion'
  );

  const validIsOnGoingExerciseGroupJoinMarketingPromotion = () => {
    //피쳐플래그 오픈 여부 확인
    if (!isOngoingExerciseGroupJoinMarketingPromotion) return false;

    // 이미 오픈되었는지 여부 확인
    if (marketingPromotionBottomSheet) return false;

    // 이벤트 기간인지 여부 확인
    if (!isDuringRealJoinGroupMarketingPromotion()) return false;

    // 이벤트 모임 여부 체크 - 아니라면 리턴
    if (!isEventGroup) return false;

    return true;
  };

  // 모임 홈 배너, 모임 상세 띠배너, 게시글 하단 배너 노출 분기
  const validIsShowExerciseGroupJoinMarketingBanner = () => {
    //피쳐플래그 오픈 여부 확인
    if (!isOngoingExerciseGroupJoinMarketingPromotion) return false;

    // 이벤트 기간인지 여부 확인
    if (!isShowJoinGroupMarketingBanner()) return false;

    // 이벤트 모임 여부 체크 - 아니라면 리턴
    if (!isEventGroup) return false;

    return true;
  };

  const handleOpenJoinMarketingPromotionBottomSheet = () => {
    const isValid = validIsOnGoingExerciseGroupJoinMarketingPromotion();

    if (!isValid) return false;

    setTimeout(() => {
      push('BottomSheet/DoneJoinGroupMarketingPromotionBottomSheet', {
        groupId,
      });
    }, 1000);
    return true;
  };

  return {
    handleOpenJoinMarketingPromotionBottomSheet,
    validIsOnGoingExerciseGroupJoinMarketingPromotion,
    validIsShowExerciseGroupJoinMarketingBanner,
  };
}

export default useJoinGroupMarketingPromotion;
