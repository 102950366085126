import OnboardingBanner from '@/components/common/Banner/OnboardingBanner';

import useMemberOnboardingQuest from '../../hooks/useMemberOnboardingQuest';

const MemberOnboardingQuestBanner = () => {
  const questList = useMemberOnboardingQuest();
  if (questList.length === 0) return null;

  return (
    <OnboardingBanner
      title="모임 가입 미션"
      description="설레는 첫만남! 멤버들에게 나를 소개해보세요."
      localStorageKey="closedMemberOnboardingBanner"
      questList={questList}
    />
  );
};

export default MemberOnboardingQuestBanner;
