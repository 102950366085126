import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useFetchInstance } from './instance/useFetchInstance';

const getRunningRaceGroupsPath =
  groupClient.api.GroupOpenEventApi.getapiV1GroupsDaangnRaceGroupsGetPath;

export const useGetRunningRaceGroups = ({ groupId }: { groupId: string }) => {
  const fetchInstance = useFetchInstance();
  const getRunningRaceGroups = groupClient.api.GroupOpenEventApi.apiV1GroupsDaangnRaceGroupsGet({
    axios: fetchInstance,
  });

  const { data } = useQuery([getRunningRaceGroupsPath()], () => getRunningRaceGroups());

  const isEventGroup = useMemo(
    () => data?.data.groupIds.includes(Number(groupId)),
    [data?.data.groupIds, groupId]
  );

  return { data, isEventGroup };
};
