import { CommentResponse } from '@community-group/api/lib/group/models';
import { AxiosResponse } from 'axios';
import { RefObject } from 'react';

import { getMeetupCommentPath } from '@/api/hooks/useGetComment';
import { getMeetupCommentsPath } from '@/api/hooks/useGetComments';
import { getCommentTotalCountPath } from '@/api/hooks/useGetCommnetTotalCount';
import {
  getGroupMeetupDetailPath,
  useGetGroupMeetupDetail,
} from '@/api/hooks/useGetGroupMeetupDetail';
import { usePostComment } from '@/api/hooks/usePostComment';
import { queryClient } from '@/api/instance';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';

import { scrollCommentListBottom } from '../../PostDetail/utils/scrollCommentListBottom';

type Params = {
  ref: RefObject<HTMLDivElement>;
  groupId: string;
  meetupId: string;
  commentId: string;
};

export const useHandlePostMeetupComment = ({ ref, groupId, meetupId, commentId }: Params) => {
  const { refetch: refetchMeetupDetail } = useGetGroupMeetupDetail({ groupId, meetupId });

  const handleErrorWithToast = useHandleErrorWithToast();
  return usePostComment({
    relatedContentType: 'meetup',
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      // IOS에서는 refetch만으로 update 되지 않아서, queryClient를 이용하여 강제로 update
      const meetupDetail = await refetchMeetupDetail();
      queryClient.setQueryData([getGroupMeetupDetailPath(groupId, meetupId)], (prev: any) => {
        return meetupDetail.data ?? prev;
      });

      await queryClient.refetchQueries([getMeetupCommentsPath(groupId, meetupId)]);
      await queryClient.refetchQueries([getMeetupCommentPath(groupId, meetupId, commentId)]);
      await queryClient.refetchQueries([getCommentTotalCountPath(groupId, meetupId, 'meetup')]);

      // 댓글 상세
      if (commentId) {
        const commentDetail = await queryClient.getQueryData<AxiosResponse<CommentResponse>>([
          getMeetupCommentPath(groupId, meetupId, commentId),
        ]);
        scrollCommentListBottom(ref, commentDetail?.data?.comment.subCommentCount ?? 0);
        return;
      }

      // 게시글 상세
      scrollCommentListBottom(ref, meetupDetail.data?.data.groupMeetup?.commentCount ?? 0);
    },
  });
};
