import { SubNicknameSettingForm } from '@community-group/api/lib/group/models';
import {
  Callout,
  CalloutDescription,
  CalloutTitle,
  Spacing,
  Switch,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightRegular } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useConnectedFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchCurrentUser } from '@/utils/refetch/currentUser';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

import SubNicknamePreview from '../../new/SubNickname/components/SubNicknamePreview';
import * as s from './groupSettingEditSubNickname.css';

export type GroupSettingPageParams = Pick<PageParams, 'groupId'>;

const GroupSettingEditSubNicknamePage: ActivityComponentType<GroupSettingPageParams> = () => {
  const { groupId } = usePathParams();
  const { from } = useQueryParams();

  const { push, pop } = useConnectedFlow();

  const { group } = useGetGroupDetail(groupId);

  const { watch, control, setValue } = useForm({
    defaultValues: {
      hasSubNickname: group?.subNicknameSetting?.isSettingOn ?? false,
    } as FieldValues,
  });

  const [subNicknameSetting, setSubNicknameSetting] = useState<SubNicknameSettingForm | undefined>(
    group?.subNicknameSetting ? group.subNicknameSetting : undefined
  );

  const hasSubNicknameFieldValue = watch('hasSubNickname');

  const handleErrorWithToast = useHandleErrorWithToast();
  const { bridge } = useBridge();
  const { mutate } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      bridge.openToast({
        toast: {
          body: '별명이 잠시 후 반영돼요.',
        },
      });
      trackEvent({
        event: 'click_setting_on_subnickname',
        params: {
          groupId: groupId,
          groupName: group?.name,
          groupCategory: group?.category.name,
          isSettingOnSubnickname: false,
          subnicknameRequestText: '',
          from,
        },
      });
      refetchGroupDetail({ groupId });
      refetchCurrentUser({ groupId });
      refetchGroupProfile();
      refetchGroupSetting({ groupId });
    },
  });

  const handleSubNicknameSetting = async () => {
    const responseData = (await push('BottomSheet/GroupSettingEditSubNicknameQuestionBottomSheet', {
      groupId,
    })) as { subNicknameSetting: SubNicknameSettingForm };

    if (responseData?.subNicknameSetting) {
      setSubNicknameSetting(responseData.subNicknameSetting);
    } else {
      setValue('hasSubNickname', false);
    }
  };

  const handleSubmit = () => {
    mutate(
      {
        id: groupId.toString() ?? '',
        groupModifyForm: {
          subNicknameSetting: {
            isSettingOn: hasSubNicknameFieldValue,
            requestText: subNicknameSetting?.requestText,
          },
        },
      },
      {
        onSuccess: () => {
          pop();
        },
      }
    );
  };

  const renderRightSide = () => {
    if (subNicknameSetting?.isSettingOn) {
      return (
        <IconChevronRightRegular
          size={20}
          onClick={() => {
            handleSubNicknameSetting();
          }}
        />
      );
    }
    return (
      <Typography
        typography="label2Regular"
        color="carrot500"
        onClick={() => {
          handleSubNicknameSetting();
        }}
      >
        입력하기
      </Typography>
    );
  };

  return (
    <AppScreen
      appBar={{
        title: '별명',
        border: true,
        borderColor: vars.$semantic.color.divider3,
        borderSize: '0.5px',
        renderRight: () => (
          <Typography
            typography="label2Regular"
            onClick={handleSubmit}
            color={'gray900'}
            style={{
              padding: '8px',
            }}
          >
            완료
          </Typography>
        ),
      }}
    >
      <div style={{ padding: '1rem' }}>
        <Callout>
          <CalloutTitle>안내</CalloutTitle>
          <CalloutDescription>별명은 이 모임에서만 닉네임 옆에 표시돼요.</CalloutDescription>
        </Callout>
        <Spacing height={16} />
        <SubNicknamePreview subNicknameText="별명" />
        <div className={s.groupSettingEditSubNicknameSwitchBox}>
          <Typography typography="bodyL1Regular" color="gray900">
            별명 사용
          </Typography>
          <Controller
            control={control}
            name="hasSubNickname"
            render={({ field: { onChange, name, value } }) => (
              <Switch
                onChange={(isSeleted) => {
                  onChange(isSeleted);
                  isSeleted && handleSubNicknameSetting();
                }}
                name={name}
                isSelected={value}
              />
            )}
          />
        </div>
        <Spacing height={8} />
        <AnimatePresence>
          {hasSubNicknameFieldValue && (
            <motion.div
              className={s.groupSettingEditSubNicknameWrapper}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.1,
              }}
              exit={{ opacity: 0 }}
            >
              <div className={s.groupSettingEditSubNicknameRequestMessageBox}>
                <Typography typography="bodyM1Regular" color="gray900">
                  별명 입력 요청 문구
                </Typography>
                {subNicknameSetting?.requestText && (
                  <Typography typography="caption1Regular" color="gray600">
                    {subNicknameSetting?.requestText}
                  </Typography>
                )}
              </div>
              {renderRightSide()}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingEditSubNicknamePage, {
  pendingFallback: (
    <>
      <AppScreen>
        <ViewLoader />
      </AppScreen>
    </>
  ),
  rejectedFallback: (
    <>
      <AppScreen>
        <ViewError />
      </AppScreen>
    </>
  ),
});
