import {
  AsyncBoundary,
  Typography,
  ViewError,
  withAsyncBoundary,
} from '@community-group/components';
import { ViewLoader } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { usePostCreateRoom } from '@/api/hooks/apartment/usePostCreateRoom';
import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { FormInput } from '@/components/common/base/Input/Text';
import { Spacing } from '@/components/common/Spacing';
import { FormRadioForApproval } from '@/components/group/new/Approval/components/FormRadioForApproval';
import useBackToActivity from '@/hooks/useBackToActivity';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import useVisualViewPortFocusInput from '@/hooks/useVisualViewPortFocusInput';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';

import AppBarCloseTalkRoomButton from '../../AppBarCloseTalkRoomButton';
import * as s from './style.css';

const ADMISSION_TYPE = {
  PRIVATE: 'private',
  PUBLIC: 'public',
} as const;

export type TalkRoomNewApprovalPageParams = Pick<
  PageParams,
  'groupId' | 'name' | 'description' | 'categoryId' | 'profileImageId'
>;

const TalkRoomNewApprovalPage: ActivityComponentType<TalkRoomNewApprovalPageParams> = () => {
  const { name, description, profileImageId } = useQueryParams();

  const { groupId = '0' } = usePathParams();

  const { register, watch } = useForm();
  const { pop } = useFlow();

  const joinTypeFieldValue = watch('joinType');
  const passwordFieldValue = watch('password');

  const handleErrorWithToast = useHandleErrorWithToast();
  const handleBack = useBackToActivity();

  const { mutate, isLoading } = usePostCreateRoom({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: ({ data }) => {
      handleBack('GroupDetailPage');

      setTimeout(() => {
        openLink(data.talkRoom.channelInfo?.targetUri);
      }, 30);
    },
  });

  const handleCreateTalkRoom = () => {
    if (isLoading) return;

    mutate(
      {
        id: Number(groupId),
        talkRoomCreateFrom: {
          relateId: Number(groupId),
          roomType: 'default',
          admissionType: joinTypeFieldValue,
          name,
          description: description !== undefined ? description : '',
          profileImageId: profileImageId ?? undefined,
          password: passwordFieldValue,
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_create_talk_room',
            params: {
              talkRoomAdmissionType: joinTypeFieldValue,
            },
          });
        },
      }
    );
  };

  const able = useMemo(() => {
    if (joinTypeFieldValue === ADMISSION_TYPE.PUBLIC) return true;
    if (joinTypeFieldValue === ADMISSION_TYPE.PRIVATE && passwordFieldValue) return true;
    return false;
  }, [passwordFieldValue, joinTypeFieldValue]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useVisualViewPortFocusInput({
    wrapperRef,
  });

  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => <AppBarCloseTalkRoomButton />,
        },
        closeButton: {
          render: () => <AppBarCloseTalkRoomButton />,
        },
      }}
      accessoryBar={
        <AccessoryBarButtonGroup
          buttonDisabled={!able}
          onBack={pop}
          onSubmit={handleCreateTalkRoom}
          submitText={'채팅방 만들기'}
        />
      }
    >
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <div className={s.contentWrapper} ref={wrapperRef}>
          <div className={s.createWrapper}>
            <p className={s.title}>어떻게 들어올 수 있나요?</p>

            <FormRadioForApproval
              register={register}
              value={ADMISSION_TYPE.PUBLIC}
              name="joinType"
              title="공개"
              subtitle="모임 멤버 누구나 들어올 수 있어요."
              checked={joinTypeFieldValue === 'public'}
            />
            <Spacing height={12} />
            <FormRadioForApproval
              register={register}
              value={ADMISSION_TYPE.PRIVATE}
              name="joinType"
              title="비공개"
              subtitle="링크 공유 또는 초대로만 들어올 수 있어요."
              checked={joinTypeFieldValue === 'private'}
            />

            <AnimatePresence>
              {joinTypeFieldValue === 'private' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.1,
                  }}
                  exit={{ opacity: 0 }}
                >
                  <Spacing height={8} />
                  <div>
                    <div className={s.passwordHeader}>
                      <Typography as="div" typography="label3Bold">
                        초대 코드
                      </Typography>
                      <Spacing height={4} />
                      <Typography as="div" typography="label3Regular" color="gray600">
                        초대 링크로 가입할 때 초대 코드를 입력해요.
                      </Typography>
                    </div>
                    <div>
                      <FormInput
                        register={register}
                        value={passwordFieldValue}
                        name="password"
                        placeholder="초대 코드를 입력해주세요."
                        maxLength={24}
                      />
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(TalkRoomNewApprovalPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
