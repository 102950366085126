import queryString from 'query-string';

import { getWebviewSchemePrefix } from '@/utils/appLinkStore';

const { isAppEnvProduction } = globalThis.appConfig;

/**
 * @description
 * 당근마켓 앱 딥링크 프로토콜
 * (towneers는 당근 초창기 서비스 이름 후보중 하나로 아직 모바일 앱 등에서 사용되고 있는 곳들이 있어요. towneers가 보이면 karrot으로 바꿔야 해요.)
 */
export const APP_DEEP_LINK_PROTOCOL = isAppEnvProduction ? 'karrot://' : 'karrot.alpha://';

/**
 * @description
 * 모임 스킴
 */
export const GROUP_APP_SCHEME = `${APP_DEEP_LINK_PROTOCOL}${getWebviewSchemePrefix()}/router?remote=`;

export const GROUP_APP_PLUGIN_SCHEME = `${APP_DEEP_LINK_PROTOCOL}${getWebviewSchemePrefix()}/plugin?remote=`;

// DOMAIN_URL 분기 방식 변경
// import.meta.env.VITE_APP_BASE_URL을 사용해 분기처리 했으나, LNB를 통한 모임 상세 직접 접근 시에는 import.meta.env.VITE_APP_BASE_URL이 없어서 에러 발생하고 있었음 그래서 isAppEnvProduction을 사용해 분기처리
export const DOMAIN_URL = isAppEnvProduction
  ? 'https://community-web-group.kr.karrotmarket.com'
  : 'https://community-web-group.alpha.kr.karrotmarket.com';

// 이벤트용 페이지 도메인
export const COMMUNITY_LINK_DOMAIN_URL = isAppEnvProduction
  ? 'https://community-link.karrotwebview.com'
  : 'https://community-link.alpha.karrotwebview.com';

export const openVerifyNeighborhood = () => {
  window.location.href = `${APP_DEEP_LINK_PROTOCOL}checkin`;
};

export const openChangeRegion = () => {
  window.location.href = `${APP_DEEP_LINK_PROTOCOL}settings/change_region`;
};

export const openNotification = () => {
  window.location.href = `${APP_DEEP_LINK_PROTOCOL}notifications`;
};

export const openPluginWebview = (remoteUrl: string, queryParams: object) => {
  const encodeUrl = encodeURIComponent(remoteUrl);
  window.location.href = `${GROUP_APP_PLUGIN_SCHEME}${encodeUrl}&${queryString.stringify({
    ...queryParams,
  })}` as string;
};

export const FROM_QUERY_PARAMS = {
  HOME: 'home',
  HOME_FEED: 'community_home_feed',
  TALK_SUMMARY: 'talkSummary',
  POST_DETAIL: 'postDetail',
  FIRST_WEBVIEW: 'firstWebview', // 네이티브로 부터 처음 웹뷰를 열었을 때
  CREATE_GROUP_MEETUP: 'create_group_meetup', // 그룹 일정 생성 페이지에서 넘어왔을 때
} as const;

export type FROM_QUERY_PARAMS_TYPE = (typeof FROM_QUERY_PARAMS)[keyof typeof FROM_QUERY_PARAMS];
