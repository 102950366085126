import { GroupMeetupExposureRangeEnum } from '@community-group/api/lib/group/models';
import { Radio, RadioGroup, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconViewCountRegular } from '@seed-design/icon';
import { Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { GroupMeetupFormListItem, GroupMeetupFormListItemProps } from '../ListItem';
import * as s from './ExposureRange.css';

interface Props<T extends object> extends Pick<GroupMeetupFormListItemProps, 'children'> {
  name: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}

export const GroupMeetupFormExposureRangeField = <T extends object>({
  name,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  const exposureRange = watch(name) as GroupMeetupExposureRangeEnum;

  return (
    <GroupMeetupFormListItem
      {...props}
      fieldIcon={<IconViewCountRegular size={24} color={vars.$scale.color.gray900} />}
      fieldLabel="공개범위"
      fieldName={name}
      fieldValue={
        <div className={s.Value}>{GROUP_MEETUP_EXPOSURE_RANGE_ENUM_TEXT[exposureRange].label}</div>
      }
    >
      <Spacing height={16} />
      <RadioGroup
        size="large"
        value={exposureRange}
        onChange={(value) => {
          setValue(name, value);
        }}
      >
        {Object.entries(GROUP_MEETUP_EXPOSURE_RANGE_ENUM_TEXT).map(
          ([key, { label, description }]) => (
            <div className={s.RadioItem} key={key}>
              <Radio value={key}>
                <div className={s.RadioItemTextWrapper}>
                  <Typography as="p" typography="label2Regular" color="gray900">
                    {label}
                  </Typography>
                  <Typography as="p" typography="caption1Regular" color="gray600">
                    {description}
                  </Typography>
                </div>
              </Radio>
            </div>
          )
        )}
      </RadioGroup>
      <Spacing height={8} />
    </GroupMeetupFormListItem>
  );
};

const GROUP_MEETUP_EXPOSURE_RANGE_ENUM_TEXT = {
  ALL: {
    label: '누구나',
    description: '동네생활에 방문하는 누구나 볼 수 있어요.',
  },
  GROUP_MEMBER: {
    label: '멤버만',
    description: '모임에 가입한 멤버만 글을 볼 수 있어요.',
  },
};
