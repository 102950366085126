import { BottomSheetProvider, DialogProvider, SnackbarProvider } from '@community-group/components';

import Initialize from './components/group/Initialize';
import { BridgeProvider } from './contexts/Bridge';
import { DeviceConfig, DeviceConfigProvider } from './contexts/DeviceConfig';
import { GroupUIConfig, GroupUIConfigProvider } from './contexts/GroupUIConfig';
import { UserConfig, UserConfigProvider } from './contexts/UserConfig';
import { FixedLayoutProvider } from './providers/FixedLayout';
import { VirtualKeyboardProvider } from './providers/VirtualKeyboard';
import { Stack } from './stackflow';
type AppProps = {
  url: string;
  safeAreaTop?: string;
  userConfig: UserConfig;
  deviceConfig: DeviceConfig;
  groupUIConfig: GroupUIConfig;
};

const App = ({ url, safeAreaTop, userConfig, deviceConfig, groupUIConfig }: AppProps) => {
  if (/token-setting/.test(url)) {
    return (
      <UserConfigProvider {...userConfig}>
        <BridgeProvider>
          <Stack
            initialContext={{
              req: { path: url },
              theme: deviceConfig.theme,
              minSafeAreaInsetTop: safeAreaTop,
            }}
          />
        </BridgeProvider>
      </UserConfigProvider>
    );
  }

  return (
    <UserConfigProvider {...userConfig}>
      <DeviceConfigProvider {...deviceConfig}>
        <GroupUIConfigProvider {...groupUIConfig}>
          <BridgeProvider>
            <VirtualKeyboardProvider>
              <FixedLayoutProvider>
                <SnackbarProvider offset="auto">
                  <DialogProvider>
                    <BottomSheetProvider>
                      <Initialize />
                      <Stack
                        initialContext={{
                          req: { path: url },
                          theme: deviceConfig.theme,
                          minSafeAreaInsetTop: safeAreaTop,
                        }}
                      />
                    </BottomSheetProvider>
                  </DialogProvider>
                </SnackbarProvider>
              </FixedLayoutProvider>
            </VirtualKeyboardProvider>
          </BridgeProvider>
        </GroupUIConfigProvider>
      </DeviceConfigProvider>
    </UserConfigProvider>
  );
};

export default App;
