import { forwardRef } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  height?: number;
  background?: string;
}

export const Spacing = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: React.Ref<HTMLDivElement>) => (
    <div
      {...props}
      ref={ref}
      style={{
        width: '100%',
        height: `${props.height ?? 16}px`,
        background: props.background ?? 'transparent',
        ...props.style,
      }}
    />
  )
);

const DEFAULT_SIZE = 16;

export const VerticalSpacing = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: React.Ref<HTMLDivElement>) => (
    <span
      {...props}
      ref={ref}
      style={{
        height: '100%',
        minWidth: `${(props.height ?? 16) / DEFAULT_SIZE}rem`,
        background: props.background ?? 'transparent',
        ...props.style,
      }}
    />
  )
);
