import { ViewLoader } from '@community-group/components';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import Feed from '../../Detail/components/Feed';

function GroupDetailNotMemberFeedListPage() {
  const { groupId } = usePathParams();
  const { group } = useGetGroupDetail(groupId);
  const { data: me } = useGetGroupMe(groupId);

  if (!me?.currentUser || !group) return <ViewLoader />;
  return (
    <AppScreen appBar={{ title: '게시글' }} disabledClientOnly>
      <Feed enableCategoryFilterAllTime />
    </AppScreen>
  );
}

export default GroupDetailNotMemberFeedListPage;
