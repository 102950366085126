import { BoxButton } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import IMG_TADA from '@/assets/images/img_tada.svg';
import { Spacing } from '@/components/common/Spacing';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { openRunningGrowthRewardSurvey } from '@/utils/link';

import * as s from './RunningGrowthCompleteBottomSheet.css';

export type RunningGrowthMissionCompleteBottomSheetParams = Pick<PageParams, 'groupId'>;

const RunningGrowthMissionCompleteBottomSheet: ActivityComponentType = () => {
  const { step = '' } = useQueryParams();

  const handleQuestCompletedBottomSheetClick = () => {
    openRunningGrowthRewardSurvey(parseInt(step, 10));
  };

  return (
    <BottomSheet style={{ padding: '0' }}>
      <div className={s.HeroWrapper} />
      <div className={s.ContentsWrapper}>
        <img src={IMG_TADA} alt="축하 아이콘" />
        <h5 className={s.Title}>
          축하해요!
          <br />
          {step}단계 미션을 완료했어요
        </h5>
        <Spacing />
      </div>
      <div className={s.BottomWrapper}>
        <BoxButton
          width="calc(100% - 2rem)"
          UNSAFE_style={{
            position: 'fixed',
            left: '1rem',
          }}
          size="xlarge"
          onClick={handleQuestCompletedBottomSheetClick}
        >
          리워드 신청하기
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default RunningGrowthMissionCompleteBottomSheet;
