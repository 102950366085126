import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  AsyncBoundary,
  useBottomSheet,
} from '@community-group/components';
import { IconChattingRegular, IconMenuRegular, IconMoreVertFill } from '@seed-design/icon';
import { useCallback, useMemo } from 'react';

import { useGetMyRoomList } from '@/api/hooks/apartment/useGetMyRoomList';
import { IconShareRegular } from '@/components/common/Icons/shareRegular';
import { useBridge } from '@/contexts/Bridge';
import { useHandleIsNotViewGroup } from '@/hooks/NotViewGroup/useHandleIsNotViewGroup';
import { useFlow, useStepFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { getPermalink, openGroupReport } from '@/utils/link';

import * as s from './GroupDetailAppBarMenu.css';

type Props = {
  group: GroupDetailPresentation;
  currentUserRole: GroupMemberRoleEnum;
  iconColor: string;
};

const GroupDetailAppBarMenu = ({ group, currentUserRole, iconColor }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);
  const isMember = useMemo(() => currentUserRole !== 'none', [currentUserRole]);

  const { bridge } = useBridge();
  const handleShareMenuClick = () => {
    trackEvent({
      event: 'click_share',
      params: {
        groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
    });
    if (!group.permalink) return;

    bridge.share({
      share: {
        url: getPermalink(group.permalink.id),
        text: `${group.name} 모임 공유하기`,
      },
    });
  };

  const bottomSheet = useBottomSheet();
  const { shownHandleIsNotViewGroupButton, isNotViewGroup, toggleIsNotViewGroup } =
    useHandleIsNotViewGroup({
      groupId,
      groupName: group.name,
    });
  const handleMoreMenuClick = () => {
    trackEvent({
      event: 'click_more',
      params: {
        groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
      sample: true,
    });
    bottomSheet.open({
      element: (
        <ActionSheet
          bottomButton={{
            label: '닫기',
          }}
        >
          <ActionList>
            {shownHandleIsNotViewGroupButton && (
              <ActionListItem
                onClick={async () => {
                  await bottomSheet.closeAsync();
                  await toggleIsNotViewGroup();
                }}
              >
                {isNotViewGroup ? '이 모임의 글 다시 보기' : '이 모임의 글 보지 않기'}
              </ActionListItem>
            )}
            <ActionListItem
              onClick={() => {
                trackEvent({
                  event: 'click_report',
                  params: {
                    type: 'group',
                  },
                });
                bottomSheet.closeAsync();
                openGroupReport({
                  groupId: String(groupId),
                });
              }}
            >
              신고
            </ActionListItem>
          </ActionList>
        </ActionSheet>
      ),
    });
  };

  const activity = useFlow();
  const handleChatRoomMenuClick = () => {
    trackEvent({
      event: 'click_chat',
      params: {
        groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
      sample: true,
    });
    activity.push('GroupDetailChatRoomPage', { groupId });
  };

  const step = useStepFlow('GroupDetailPage');
  const handleSidebarMenuClick = () => {
    trackEvent({
      event: 'click_sidebar',
      params: {
        groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
    });
    step.stepPush({
      groupId,
      openSidebar: 'true',
    });
  };

  const handleSettingButton = () => {
    trackEvent({
      event: 'click_group_detail_setting_menu',
      params: {
        groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUserRole,
        version: '2',
      },
      sample: true,
    });
    activity.push('GroupSettingPage', { groupId });
  };

  const ChattingDefaultButton = useCallback(() => {
    return (
      <button className={s.MenuButton} onClick={handleChatRoomMenuClick}>
        <IconChattingRegular size={24} color={iconColor} />
      </button>
    );
  }, [handleChatRoomMenuClick]);

  if (!isMember) {
    return (
      <div className={s.ButtonContainer}>
        <button className={s.MenuButton} onClick={handleShareMenuClick}>
          <IconShareRegular size={24} color={iconColor} />
        </button>
        <button className={s.MenuButton} onClick={handleMoreMenuClick}>
          <IconMoreVertFill size={24} color={iconColor} />
        </button>
      </div>
    );
  }

  return (
    <div className={s.ButtonContainer}>
      <AsyncBoundary
        pendingFallback={<ChattingDefaultButton />}
        rejectedFallback={<ChattingDefaultButton />}
      >
        <ChattingButton groupId={groupId} onClick={handleChatRoomMenuClick} iconColor={iconColor} />
      </AsyncBoundary>

      <button className={s.MenuButton} onClick={handleSidebarMenuClick}>
        <IconMenuRegular size={24} color={iconColor} />
      </button>
    </div>
  );
};

export default GroupDetailAppBarMenu;

type ChattingButtonProps = {
  groupId: string;
  onClick: () => void;
  iconColor: string;
};
const ChattingButton = ({ groupId, onClick, iconColor }: ChattingButtonProps) => {
  const { data: roomList } = useGetMyRoomList(Number(groupId));
  const hasNewTalk = useMemo(
    () =>
      roomList?.pages?.some(({ data }) =>
        data.talkRooms.some((room) => {
          if (!room.lastTalkInfo) return false;
          return room.lastTalkInfo?.isNew;
        })
      ),
    [roomList?.pages]
  );

  return (
    <button className={s.MenuButton} onClick={onClick}>
      <span className={hasNewTalk ? s.RedDot : undefined}>
        <IconChattingRegular size={24} color={iconColor} />
      </span>
    </button>
  );
};
