import clsx from 'clsx';
import { Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import * as s from './Description.css';

interface Props<T extends object> extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}

export const GroupMeetupFormDescriptionField = <T extends object>({
  name,
  watch,
  setValue,
  ...props
}: Props<T>) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(name, event.target.value);
  };

  return (
    <textarea
      data-field-name={name}
      {...props}
      className={clsx([props.className, s.textarea])}
      defaultValue={watch(name)}
      onChange={handleChange}
    />
  );
};
