import { model } from '@community-group/api/lib/group';
import { isSuperHost } from '@community-group/components';
import { BoxButton } from '@community-group/components';

import { Container } from '@/components/common/Container';
import { ProfileListItemProps } from '@/components/common/Profile/ListItem';
import { Spacing } from '@/components/common/Spacing';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { formatDate } from '@/utils/date';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';

import { useHandleApplication } from '../hooks/useHandleApplication';
import MemberProfileSection from './MemberProfileSection';
import * as s from './MemberProfileSectionForHost.css';

interface Props extends ProfileListItemProps {
  applicationAnswers?: Array<model.ApplicationAnswer>;
  isJoinableRegionRange?: boolean;
  onClickActionButton?: () => void;
  createdAt?: string;
  currentUserRole?: model.GroupMemberRoleEnum;
  canViewVerifiedUserInfo?: boolean;
}

const MemberProfileSectionForHost = ({
  user,
  applicationAnswers,
  isJoinableRegionRange,
  onClickActionButton: handleClickActionButton,
  createdAt,
  currentUserRole,
  canViewVerifiedUserInfo,
}: Props) => {
  const { groupId } = usePathParams();
  const { handleAccept, handleReject } = useHandleApplication({
    userId: user.id as number,
    groupId,
    currentUserRole,
    isJoinable: isJoinableRegionRange ?? false,
    onPatchSuccess: () => {
      refetchGroupMemberList({ groupId });
      handleClickActionButton?.();
      refetchGroupDetail({ groupId });
    },
    onAccept: () => {
      trackEvent({
        event: 'click_group_member_request_approve_item',
        params: {
          groupId: groupId,
          hasVerifiedInfo: canViewVerifiedUserInfo,
          verifiedInfo: user?.verifiedUserInfoText,
        },
        sample: true,
      });
    },
    onReject: () => {
      trackEvent({
        event: 'click_group_member_request_reject_item',
        params: {
          groupId: groupId,
          hasVerifiedInfo: canViewVerifiedUserInfo,
          verifiedInfo: user?.verifiedUserInfoText,
        },
        sample: true,
      });
    },
  });

  const getAppendDescription = () => {
    if (!isSuperHost(currentUserRole) || !user.verifiedUserInfoText || !canViewVerifiedUserInfo) {
      return formatDate(new Date(Date.parse(String(createdAt))), 'yy.MM.dd 요청');
    }
    if (user?.verifiedUserInfoText) {
      return `${formatDate(new Date(Date.parse(String(createdAt))), 'yy.MM.dd 요청')} ⸱ ${
        user?.verifiedUserInfoText
      }`;
    }
  };

  const { push } = useFlow();
  const getMoveGroupUserProfileDetailPage = (userId: number) => () => {
    push('GroupUserProfileDetailPage', {
      groupId,
      userId: userId.toString(),
    });
  };

  return (
    <>
      <Spacing height={4} />
      <MemberProfileSection
        user={user}
        currentUserRole={currentUserRole}
        appendDescription={getAppendDescription()}
        approvePage
        moveGroupUserProfileDetailPage={getMoveGroupUserProfileDetailPage(user.id)}
      />
      <div className={s.wrapper}>
        <Container paddingY={0} paddingX={16}>
          {applicationAnswers &&
            applicationAnswers.length > 0 &&
            applicationAnswers.map((answer) => {
              return (
                <div className={s.questionBox} key={answer.question}>
                  <p className={s.question}>Q. {answer.question}</p>
                  <p className={s.answer}>{answer.answer}</p>
                </div>
              );
            })}

          <div className={s.buttonGroup}>
            <BoxButton variant="secondary" size="medium" width="100%" onClick={handleReject}>
              거절
            </BoxButton>
            <BoxButton variant="primaryLow" size="medium" width="100%" onClick={handleAccept}>
              승인
            </BoxButton>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MemberProfileSectionForHost;
