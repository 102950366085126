import {
  AccessStatusServiceEnum,
  GroupGrowthEnum,
  JoinableRegionRangeEnum,
  PostTypePresentationType,
} from '@community-group/api/lib/group/models';
import { ContentType } from '@community-group/api/lib/group/models/content-type';
import { useActivityParams } from '@stackflow/react';
import { $Values } from 'utility-types';

import { CertificationCandidateType } from '@/components/group/new/Verification/hooks/useHandleVerificationBottomSheet';
import { PostFormCalloutType } from '@/components/group/PostForm/components/Callout';
import { FROM_QUERY_PARAMS_TYPE } from '@/constants/path';
import { ReferParams } from '@/utils/analytics/type';

/*
 * 서비스에서 사용하는 QueryParams를 정의합니다.
 */

export interface ActivityQueryParams {
  from?: string | FROM_QUERY_PARAMS_TYPE | GROUP_DETAIL_FROM | MEETUP_DETAIL_FROM;
  activeHomeTab?: $Values<typeof HOME_ACTIVE_TAB>;
  referrer?: 'community_group.client';
  queryId?: string;
  categoryId?: string;
  meetupId?: string;
  postId?: string;
  instantJoinMeetupId?: string;
  requestMeetupReviewId?: string;
  activeTab?: GROUP_DETAIL_ACTIVE_TAB;
  keyboardUp?: string;
  joinPrivateRoomId?: string;
  isPrivate?: string;
  joinType?: string;
  question?: string;
  requiredQuestion?: string;
  haveQuestion?: string;
  scrollTo?: string;
  tab?: 'joint' | 'pending';
  name?: string;
  maximumMemberCount?: string;
  minAgeTag?: string;
  maxAgeTag?: string;
  postType?: PostTypePresentationType;
  title?: string;
  content?: string;
  answer?: string;
  description?: string;
  isNew?: string;
  fromTalkRoomId?: string;
  initialJoinType?: 'approval' | 'free';
  initialPublishPost?: 'true' | 'false';
  initialQuestionValue?: string;
  initialQuestionRequired?: 'true' | 'false';
  initialMaximumMemberCount?: string;
  currentMemberCount?: string;
  initialGroupName?: string;
  initialGroupDescription?: string;
  initialGroupMinAge?: string;
  initialGroupMaxAge?: string;
  joinPublicRoomId?: string;
  profileImageId?: string;
  joinableRegionRange?: JoinableRegionRangeEnum;
  regionId?: string;
  pollOptionValueJSONString?: string;
  meetupCreateForm?: string;
  meetupModifyForm?: string;
  backgroundImageId?: string;
  groupCategory?: string;
  isOnboarding?: string;
  //포인트 그로스 이벤트 모임 생성용 params
  createType?: string; // CREATE_PRE_OPEN_GROUP_FROM_PARAMS value의 유무로 판단
  groupCreateForm?: string;
  pointEventRegion?: string;
  groupJoined?: string;
  settedProfileImage?: string;
  keyword?: string;
  boardCategoryKey?: string;
  cloneGroupMeetupFormData?: string;
  poiName?: string;
  showEntryPointGuide?: string;
  meetupCalendarDay?: string;
  block1on1Chat?: 'true' | 'false';
  postIndex?: string;
  targetPermission?: string;
  groupId?: string;
  userId?: string;
  initialBoardCategoryName?: string;
  scrollToBottom?: 'true' | 'false';
  hasSubNickname?: 'true' | 'false';
  subNicknameQuestion?: string;
  needVerification?: 'true' | 'false';
  certificationCandidateType: CertificationCandidateType;
  defaultValue?: string;
  canViewVerifiedUserInfo?: 'true' | 'false';

  createGroupFlow?: 'DEFAULT' | 'GROUP_V2';
  calloutType?: PostFormCalloutType;
  applicationStatus?: 'not_applied' | 'cancelled' | 'applied' | 'accepted' | 'rejected';

  searchCurrentTab?: 'all' | 'group' | 'meetup' | 'onlyGroup';
  openSidebar?: 'true' | 'false';
  userName?: string;

  //TODO: 8~9월 운동 모임 가입 마케팅 프로모션 바텀시트, 종료 후 제거 가능
  promotion_type?: 'pay_promotion' | string;
  promotion_code?: string;

  //TODO: 러닝그로스 이벤트를 위한 임시 파라미터 이벤트 종료 이후 삭제 필요
  joinRunningGrowth?: string;
  step?: string;

  optionId?: string; // 모임 일정 후기 작성시 선택한 옵션 아이디

  groupIdForChangeVerification?: string;

  initialPollOptionValueJSONString?: string;
  refer_root?: ReferParams['refer_root'];
  refer_section?: ReferParams['refer_section'];
  refer_content?: ReferParams['refer_content'];
  refer_content_index?: ReferParams['refer_content_index'];
  refer_action?: ReferParams['refer_action'];
  utm_medium?: string;
  utm_campaign?: string; //그로스 캠피언용 utm

  serviceType?: AccessStatusServiceEnum;

  contentType?: ContentType;
  contentId?: string;

  // 카테고리/키워드 마이그레이션 후 카테고리 설정 플로우 진입시 사용
  open?: string;

  // 비슷한 모임 추천 클릭시 이전에 보던 모임을 제외하고 보여주기 위한 파라미터
  prevViewedGroupId?: string;

  // GroupDetailV2를 비롯한 V2 버전에서 사용하는 파라미터
  isGroupV2?: 'true' | 'false';
  joinGrowth?: GroupGrowthEnum;

  // SNS 태그 세팅할때 사용하는 파라미터
  snsSetBottomSheetType?: 'new' | 'edit';
  snsSetBottomInitialToggle?: 'facebook' | 'instagram' | 'x';

  // 댓글 상세에서 사용하는 파라미터
  commentCount?: string;
  groupName?: string;
  disabledCommentInput?: 'true' | 'false';
  directChatType?: 'profile_direct_chat' | 'profile_pay_chat';

  // 게시글 공유용
  permalinkId?: string;
  // 이미지 앨범 뷰어 index
  initialIndex?: string;
  imageUrls?: string;
  photoContestEntryId?: string;

  version?: string;

  videoId?: string; //video POC
  videoIds?: string; //video POC
  imageIds?: string; //video POC

  isEqualCurrentCommentAuthor?: 'true' | 'false';
  hasEmotionCount?: 'true' | 'false';
  hasPermission?: 'true' | 'false';
}

export const useQueryParams = (): ActivityQueryParams => {
  return useActivityParams<ActivityQueryParams>();
};

export const HOME_ACTIVE_TAB = {
  MT_GROUP_TAB: 'myGroupTab',
  EXPLORER_GROUP_TAB: 'explorerGroupTab',
  MEETUP_TAB: 'meetupTab',
} as const;

type GROUP_DETAIL_FROM =
  | 'CHANGE_ROLE_TO_HOST_NOTIFICATION'
  | 'CHANGE_MEETUP_V2_TO_GROUP_NOTIFICATION'
  | 'COMPLETE_MEETUP_V2_TO_GROUP';

export type GROUP_DETAIL_ACTIVE_TAB = 'home' | 'feed' | 'meetups' | 'photos' | 'talks';

type MEETUP_DETAIL_FROM = 'meetup_member';
