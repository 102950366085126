import { z } from 'zod';

const name = z
  .string()
  .trim()
  .min(3, '모임명을 3자 이상 입력해주세요.')
  .max(24, '24자 이하로 입력해주세요.');
const description = z
  .string()
  .trim()
  .min(8, '모임 소개를 8자 이상 입력해주세요.')
  .max(500, '500자 이하로 입력해주세요.');

export const groupFieldSchema = {
  name,
  description,
};

export const groupFormSchema = z.object(groupFieldSchema);
