import { useCallback } from 'react';

import { FileUploadState, UploadVideoResponse, VideoUploadErrorType } from '../types/Video';

interface UseUploadFileProps {
  uploadVideo: (params: { file: File }) => Promise<UploadVideoResponse>;
  updateUploadStatus: (fileId: string, updates: Partial<FileUploadState>) => void;
  onUploadStart?: (file: File) => void;
  onUploadSuccess?: (file: File, response: UploadVideoResponse) => void;
  onUploadError?: (type: VideoUploadErrorType) => (file: File, error: Error) => void;
}

export const useUploadVideoFile = ({
  uploadVideo,
  updateUploadStatus,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
}: UseUploadFileProps) => {
  // 파일 업로드 함수
  const startUpload = useCallback(
    async (fileState: FileUploadState) => {
      // 업로드 상태를 'uploading'으로 업데이트
      updateUploadStatus(fileState.id, { status: 'uploading' });
      onUploadStart?.(fileState.file);

      try {
        // 비디오 파일 업로드 실행
        const response = await uploadVideo({ file: fileState.file });

        // 업로드 성공 시 상태 업데이트 및 콜백 실행
        updateUploadStatus(fileState.id, {
          status: 'completed',
          uploadedVideoInfo: {
            id: response.videoId,
            duration: response.payload.commit_result.Data.SourceInfo.Duration,
          },
        });

        onUploadSuccess?.(fileState.file, response);
      } catch (error) {
        // 업로드 실패 시 상태 업데이트 및 콜백 실행
        updateUploadStatus(fileState.id, { status: 'failed', error: (error as Error).message });

        onUploadError?.('uploadError')(fileState.file, error as Error);
      }
    },
    [updateUploadStatus, onUploadStart, uploadVideo, onUploadSuccess, onUploadError]
  );

  return { startUpload };
};
