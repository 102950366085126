import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Drawer, Spacing } from '@community-group/components';
import { memo } from 'react';

import useGroupDetailSidebar from '../../hooks/useGroupDetailSidebar';
import * as s from './index.css';
import SidebarMenuSection from './SidebarMenuSection';
import SidebarProfile from './SidebarProfile';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupDetailSidebar = ({ group, currentUser }: Props) => {
  const {
    isOpened,
    onClickClose,
    onProfileClick,
    onAlbumMenuClick,
    onMemberMenuClick,
    onSettingMenuClick,
    onApplicationBannerClick,
    onHomeMenuClick,
    onMeetupMenuClick,
    onNoticeMenuClick,
    onEditBoardButtonClick,
    onBoardItemClick,
    onCreateBoardButtonClick,
    onReportButtonClick,
    onGuideBookButtonClick,
    onShareButtonClick,
  } = useGroupDetailSidebar({ group, currentUser });

  return (
    <Drawer isOpened={isOpened} onClickClose={onClickClose}>
      <div className={s.Container}>
        <Spacing height={18} />
        <SidebarProfile
          title={currentUser.nickname}
          imageUrl={currentUser.profileImage}
          description={currentUser.subNickname}
          role={currentUser.role}
          onProfileClick={onProfileClick}
        />
        <Spacing height={24} />
        <SidebarMenuSection
          groupId={currentUser.groupId}
          currentUserRole={currentUser.role}
          onAlbumMenuClick={onAlbumMenuClick}
          onMemberMenuClick={onMemberMenuClick}
          onSettingMenuClick={onSettingMenuClick}
          onApplicationBannerClick={onApplicationBannerClick}
          onHomeMenuClick={onHomeMenuClick}
          onMeetupMenuClick={onMeetupMenuClick}
          onNoticeMenuClick={onNoticeMenuClick}
          onEditBoardButtonClick={onEditBoardButtonClick}
          onBoardItemClick={onBoardItemClick}
          onCreateBoardButtonClick={onCreateBoardButtonClick}
          onReportButtonClick={onReportButtonClick}
          onGuideBookButtonClick={onGuideBookButtonClick}
          onShareButtonClick={onShareButtonClick}
        />
      </div>
    </Drawer>
  );
};

export default memo(GroupDetailSidebar);
