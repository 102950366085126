import {
  AsyncBoundary,
  BottomBasicButton,
  MultilineTextField,
  useStickInputStore,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { useEffect, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useGetCheckHasGroupProfile } from '@/api/hooks/useGetCheckHasGroupProfile';
import { usePostGroupProfile } from '@/api/hooks/usePostGroupProfile';
import { FormInput } from '@/components/common/base/Input/Text';
import { FormContainer, FormTitle } from '@/components/common/Form';
import { Spacing } from '@/components/common/Spacing';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useHandleSetGroupProfile } from '@/hooks/useHandleSetGroupProfile';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import {
  LayoutRefProps,
  LayoutWrapper,
} from '@/stackflow/components/Layout/components/ContentsLayout';
import { useBack } from '@/stackflow/hooks/useBack';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import { GroupCreateProfileHeader } from './GroupCreateProfileHeader';
import { GroupCreateProfileImageUploader } from './GroupProfileImageUploader';

const GroupProfileCreateComponent = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { setFocused } = useStickInputStore();

  const { refetch: refetchProfileCheck } = useGetCheckHasGroupProfile();

  const { userConfig } = useUserConfig();
  const { register, watch, setValue } = useForm<FieldValues>({
    defaultValues: {
      name: userConfig.userNickname ?? '',
    },
  });

  const { groupId } = useQueryParams();

  const nameFieldValue = watch('name');
  const descriptionFieldValue = watch('description');

  const { replace } = useFlow();
  const back = useBack();

  const {
    profileImage,
    handleSetGroupProfile,
    isLoading: isProfileImageLoading,
  } = useHandleSetGroupProfile({});
  const { bridge } = useBridge();
  const { mutate, isLoading: isSubmitProfileLoading } = usePostGroupProfile({
    onError: (error) => {
      handleErrorWithToast(error);
    },
    onSuccess: async () => {
      await trackCreateGroupProfileEvent();
      refetchProfileCheck();
      bridge.openToast({
        toast: {
          body: '모임 프로필을 등록했어요.',
        },
      });
      // 그룹 상세에서 온 경우 (내가 가입한 모임에서 프로필을 세팅하는 경우)
      if (groupId) {
        replace(
          'GroupDetailPage',
          {
            groupId,
            from: 'CreateGroupProfile',
          },
          {
            animate: false,
          }
        );
        return;
      }
      back();
    },
  });

  const trackCreateGroupProfileEvent = () => {
    trackEvent({
      event: 'click_integrated_group_profile_complete',
      params: {
        groupId: groupId?.toString() ?? '',
      },
      sample: true,
    });
  };

  const handleErrorWithToast = useHandleErrorWithToast();

  const handleSubmit = () => {
    mutate({
      groupMainProfileCreateForm: {
        nickname: nameFieldValue,
        description: descriptionFieldValue,
        profileImageId: profileImage?.id,
      },
    });
  };

  const ref = useRef<LayoutRefProps>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.setViweportInput({
        wrapperRef,
        callbackHandler: [
          {
            elementId: 'description-textarea',
            callback: (e) => {
              const el = e.target as HTMLTextAreaElement;
              el?.parentElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            },
          },
        ],
      });
    }
  }, []);

  const renderAccessoryBarButton = () => {
    return (
      <BottomBasicButton
        disabled={!nameFieldValue || isProfileImageLoading || isSubmitProfileLoading}
        loading={isSubmitProfileLoading}
        onClick={handleSubmit}
      >
        완료
      </BottomBasicButton>
    );
  };

  return (
    <AppScreen layoutRef={ref} accessoryBar={renderAccessoryBarButton()}>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <LayoutWrapper ref={wrapperRef} padding="0 1rem 1rem 1rem">
          <GroupCreateProfileHeader />
          <Spacing height={24} />
          <GroupCreateProfileImageUploader
            profileImage={profileImage}
            handleSetGroupProfile={handleSetGroupProfile}
            isLoading={isProfileImageLoading}
          />
          <Spacing height={24} />
          <FormContainer>
            <FormTitle>닉네임</FormTitle>
            <FormInput
              register={register}
              name="name"
              placeholder="닉네임을 입력해주세요."
              maxLength={12}
              value={nameFieldValue}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <Spacing height={24} />
            <FormTitle>자기소개</FormTitle>

            <div id="description-textarea">
              <MultilineTextField
                value={descriptionFieldValue ?? ''}
                onChange={(value) => {
                  setValue('description', value);
                }}
                placeholder="자기소개를 입력해주세요."
                maxLength={200}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </div>
          </FormContainer>
        </LayoutWrapper>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupProfileCreateComponent, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
