import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import {
  IconCategoryTogether,
  Typography,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconUserGroupFill } from '@seed-design/icon';
import { useMemo } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useGetApplications } from '@/api/hooks/useGetApplications';
import { useGetGroupFeedReviewWriteableMeetup } from '@/api/hooks/useGetGroupFeedReviewWriteableMeetup';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import ActionBannerListItem from './ActionBannerListItem';
import * as s from './index.css';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

type Banner = Parameters<typeof ActionBannerListItem>[0] & { key: string; isShow?: boolean };

export const GroupDetailActionBanner = ({ group, currentUser }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);

  const { data: reviewWriteable } = useGetGroupFeedReviewWriteableMeetup(groupId);
  const { data: applications } = useGetApplications(groupId);

  const isShowMeetupReviewBanner = useMemo(
    () => Boolean(reviewWriteable?.meetupId),
    [reviewWriteable]
  );
  const isShowWaitingMemberBanner = useMemo(() => {
    if (!currentUser.permissions.applyApplicationMember) return false;
    if (group.management?.applicationCount === undefined) return false;
    if (group.management?.applicationCount <= 0) return false;

    return true;
  }, [group, currentUser]);

  const { push } = useFlow();
  const banners: Banner[] = useMemo(() => {
    const meetupId = reviewWriteable?.meetupId?.toString() ?? '';
    const handleMeetupReviewBannerClick = () => {
      trackEvent({
        event: 'click_write_group_meetup_review_callout',
        params: {
          groupId: groupId,
          meetupId: meetupId,
          groupName: group.name,
          groupCategoryName: group.category.name,
          isGroupCategoryOn: group.isBoardManaged,
          role: currentUser.role,
          version: '2',
        },
      });
      push('GroupMeetupCreateReviewPage', {
        groupId: groupId,
        meetupId: meetupId,
      });
    };
    const waitingMemberCount = applications?.pages[0].data.applicationCount;
    const handleWaitingMemberBannerClick = () => {
      trackEvent({
        event: 'click_waiting_member_callout',
        params: {
          groupId: groupId,
          groupName: group.name,
          groupCategoryName: group.category.name,
          isGroupCategoryOn: group.isBoardManaged,
          role: currentUser.role,
          version: '2',
        },
        sample: true,
      });
      push('GroupMemberListForHostPage', {
        groupId,
        tab: 'pending',
      });
    };

    return [
      {
        key: 'meetupReview',
        icon: (
          <div className={s.IconWrapper({ color: 'yellow50' })}>
            <IconCategoryTogether size={16} color={vars.$scale.color.yellow400} />
          </div>
        ),
        isShow: isShowMeetupReviewBanner,
        children: (
          <Typography typography="bodyM2Regular" color="gray800">
            일정은 어떠셨나요? <b>후기</b>를 남겨주세요
          </Typography>
        ),
        onClick: handleMeetupReviewBannerClick,
      },
      {
        key: 'waitingMember',
        icon: (
          <div className={s.IconWrapper({ color: 'carrot50' })}>
            <IconUserGroupFill size={16} color={vars.$scale.color.carrot500} />
          </div>
        ),
        isShow: isShowWaitingMemberBanner,
        children: (
          <Typography typography="bodyM2Regular" color="gray800">
            <b>{waitingMemberCount}</b>명의 이웃이 참여 승인을 기다려요
          </Typography>
        ),
        onClick: handleWaitingMemberBannerClick,
      },
    ].filter((banner) => banner.isShow);
  }, [
    reviewWriteable?.meetupId,
    applications?.pages,
    isShowMeetupReviewBanner,
    isShowWaitingMemberBanner,
    groupId,
    group.name,
    group.category.name,
    group.isBoardManaged,
    currentUser.role,
    push,
  ]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const shuffledBanners = useMemo(() => shuffleArray([...banners]), []);

  if (shuffledBanners.length === 0) return null;

  return (
    <div className={s.Wrapper}>
      <Swiper
        pagination={{ dynamicBullets: true, clickable: true }}
        slidesPerView="auto"
        spaceBetween={8}
        modules={[Pagination]}
        style={
          {
            '--swiper-pagination-bullet-opacity': 0,
            '--swiper-pagination-bullet-inactive-opacity': 0,
          } as { [key: string]: string | number }
        }
      >
        {shuffledBanners.map((banner, index) => (
          <SwiperSlide
            key={index}
            style={{
              width: shuffledBanners.length > 1 ? 'calc(100% - 20%)' : '100%',
              paddingLeft: index === 0 ? '1rem' : '0',
              paddingRight: index === shuffledBanners.length - 1 ? '1rem' : '0',
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ActionBannerListItem key={index} icon={banner.icon} onClick={banner.onClick}>
              {banner.children}
            </ActionBannerListItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default withAsyncBoundary(GroupDetailActionBanner, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
