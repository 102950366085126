import { StoreSlice } from '..';

export type GlobalStateSlice = {
  fixedLayout: boolean;
  profileCheckPending: boolean;
  setFixedLayout: (value: boolean) => void;
  setProfileCheckPending: (value: boolean) => void;

  isExerciseMarketingPreJoinable: boolean; // 8~9월 운동모임 가입 사전신청 이벤트 참가 가능 여부, 프로모션 종료 후 제거 가능
  setExerciseMarketingPreJoinable: (value: boolean) => void;
};

export const createGlobalStateSlice: StoreSlice<GlobalStateSlice> = (set) => ({
  fixedLayout: true,
  profileCheckPending: false,
  isExerciseMarketingPreJoinable: false,
  setExerciseMarketingPreJoinable: (value) => {
    set((state) => ({ ...state, isExerciseMarketingPreJoinable: value }));
  },
  setFixedLayout: (value) => {
    set((state) => ({ ...state, fixedLayout: value }));
  },
  setProfileCheckPending: (value) => {
    set((state) => ({ ...state, profileCheckPending: value }));
  },
});
