import { model } from '@community-group/api/lib/group';
import dayjs from 'dayjs';

import { Spacing } from '@/components/common/Spacing';
import DefaultTalkRoomProfileImages from '@/components/group/TalkRoom/DetailSetting/components/DefaultTalkRoomProfileImages';

import * as s from './PreAttendInformation.css';

type Props = {
  roomInfo?: model.TalkRoomPresentation;
};

const PreAttendInformation = ({ roomInfo }: Props) => {
  return (
    <div className={s.infoBox}>
      <DefaultTalkRoomProfileImages
        userProfileImages={roomInfo?.someOfMemberProfileImages ?? []}
        size={64}
        borderSize={2}
        groupProfileImage={roomInfo?.profileImage?.medium}
      />
      <Spacing height={16} />
      <p className={s.title}>{roomInfo?.name}</p>
      <p className={s.subTitle}>
        {dayjs(roomInfo?.createdAt).format('YYYY.MM.DD')} 개설 ∙ {roomInfo?.memberCount}명
      </p>
      {roomInfo?.description && <p className={s.description}>{roomInfo?.description}</p>}
    </div>
  );
};

export default PreAttendInformation;
