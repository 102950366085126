import { Category, CategoryItemType } from '@community-group/api/lib/group/models';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import FilterBadgeRadio from '@/components/common/base/Input/Radio/FilterBadge';
import { useBridge } from '@/contexts/Bridge';
import { smoothScrollTo } from '@/hooks/useScrollPicker';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import * as s from './CategoryFilterList.css';

interface Props {
  categories: Category[] | CategoryItemType[];
  onClickItem: (item: Category | CategoryItemType) => void;
}

function CategoryFilterList({ categories, onClickItem: handleCategoryItem }: Props) {
  const { groupCategory, keyword } = useQueryParams();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(categories[0].id);
  const { register } = useForm();
  const [slider, setSlider] = useState<{
    left: number;
    width: number;
  }>({ left: 0, width: 0 });

  const tabListRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<Map<number, HTMLDivElement>>(new Map());
  const { bridge } = useBridge();
  const handleSelectedCategory = useCallback(
    (item: Category | CategoryItemType, initialize?: boolean) => {
      // 유저의 액션에 의해서만 haptic 동작하도록 분기처리
      if (!initialize) {
        bridge.setHapticSelect({});
        trackEvent({
          event: 'click_group_filter',
          params: {
            category: item.name,
            categoryId: item.id,
          },
          sample: true,
        });
      }
      setSelectedCategoryId(item.id);
      handleCategoryItem(item);

      const container = tabListRef.current;
      const target = buttonRef.current.get(item.id);

      if (target && container) {
        if (container.scrollWidth === 0) return;
        const left = target.offsetLeft;
        const itemWidth = target.offsetWidth;
        smoothScrollTo({
          duration: 300,
          element: container,
          point: left - container.offsetWidth / 2 + itemWidth / 2,
          direction: 'horizontal',
        });
        setSlider({
          left: left,
          width: itemWidth,
        });
      }
    },
    [handleCategoryItem]
  );

  // 카테고리 초기 선택 init
  useEffect(() => {
    if (groupCategory || keyword) {
      const selectedCategory =
        (categories.find(
          (item) => item.id === Number(groupCategory) || item.name === keyword
        ) as Category) ?? categories[0];

      handleSelectedCategory(selectedCategory, true);
    } else {
      handleSelectedCategory(categories[0], true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.badgeWrapper} ref={tabListRef}>
      {categories.map((item) => (
        <FilterBadgeRadio
          key={item.id}
          value={item.id}
          name="category"
          text={item.name}
          register={register}
          onClick={() => handleSelectedCategory(item)}
          checked={item.id === selectedCategoryId}
          ref={(el: HTMLDivElement) => {
            buttonRef.current.set(item.id, el);
          }}
        />
      ))}
      <motion.div
        style={{
          height: '2px',
          bottom: 0,
          left: `${slider.left}px`,
          width: `${slider.width}px`,
          position: 'absolute',
          background: vars.$scale.color.gray900,
          transition: 'left 0.3s ease-in-out, width 0.3s ease-in-out',
        }}
      />
    </div>
  );
}

export default CategoryFilterList;
