import { UserMeetupMemberReviewMessage } from '@community-group/api/lib/group/models';
import { GroupAvatar, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconViewCountOffFill } from '@seed-design/icon';

import { getDateDiffFromNow } from '@/utils/date';

import * as s from './index.css';
import { ProfileMeetupMemberReviewMessageItemMoreButton } from './MoreButton';

type Props = {
  meetupMemberReviewMessage: UserMeetupMemberReviewMessage;
  isMyProfile: boolean;
};

export const ProfileMeetupMemberReviewMessageItem = ({
  meetupMemberReviewMessage,
  isMyProfile,
}: Props) => {
  const { author, reviewExposureRange, createdAt, reviewMessage, id } = meetupMemberReviewMessage;
  const isReadOnlyMe = reviewExposureRange === 'ONLY_ME';

  return (
    <li className={s.Wrapper}>
      <div className={s.Header}>
        <div className={s.ProfileWrapper}>
          <GroupAvatar size="small" src={author.profileImage} />
          <div style={{ width: '100%' }}>
            <Typography typography="label3Bold" ellipsisAfterLines={1}>
              {author.nickname}
            </Typography>
            <Spacing height={2} />
            <div style={{ display: 'flex', gap: '4px' }}>
              <Typography typography="caption1Regular" color="gray600">
                {getDateDiffFromNow(new Date(createdAt)).text} 전
              </Typography>
              {isReadOnlyMe && (
                <p style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                  <Typography typography="caption1Regular" color="gray600">
                    ⸱
                  </Typography>
                  <IconViewCountOffFill size={15} color={vars.$scale.color.gray600} />
                  <Typography
                    typography="caption1Regular"
                    color="gray600"
                    style={{
                      marginLeft: '-2px',
                    }}
                  >
                    숨긴 후기
                  </Typography>
                </p>
              )}
            </div>
          </div>
        </div>
        {isMyProfile && (
          <ProfileMeetupMemberReviewMessageItemMoreButton
            reviewMessageId={id}
            reviewMessageExposureRange={reviewExposureRange}
            reviewMessage={reviewMessage}
          />
        )}
      </div>
      <div className={s.Body}>
        <Typography
          typography="bodyM1Regular"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {reviewMessage}
        </Typography>
      </div>
    </li>
  );
};
