import { Category, CategoryItemType } from '@community-group/api/lib/group/models';
import { vars } from '@seed-design/design-token';
import { $Values } from 'utility-types';

import { ORDER_HOME } from '@/api/base/group';
import { useGetCurationCategories } from '@/api/hooks/useGetCurationCategories';
import { Container } from '@/components/common/Container';
import { Spacing } from '@/components/common/Spacing';
import CategoryFilterList from '@/components/group/Home/components/common/Filter/CategoryFilterList';
import { trackEvent } from '@/utils/analytics';

type FilterType = {
  order: $Values<typeof ORDER_HOME>;
  keywordName?: string;
};

type Props = {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
};

const GroupCurationWithKeywordListFilter = ({ filter, setFilter }: Props) => {
  const { data: categoryList } = useGetCurationCategories();

  return (
    <div style={{ backgroundColor: vars.$semantic.color.paperDefault }}>
      <Container paddingY={0} paddingX={0}>
        <CategoryFilterList
          categories={categoryList}
          onClickItem={(item: Category | CategoryItemType) => {
            trackEvent({
              event: 'click_curation_group_filter',
              params: {
                category: item.name,
                categoryId: item.id,
              },
              sample: true,
            });
            setFilter({ ...filter, keywordName: item.name !== '' ? item.name : undefined });
          }}
        />

        <Spacing height={8} />
      </Container>
    </div>
  );
};

export default GroupCurationWithKeywordListFilter;
