import dayjs from 'dayjs';

import OnboardingBanner from '@/components/common/Banner/OnboardingBanner';

import useSuperHostOnboardingQuest from '../../hooks/useSuperHostOnboardingQuest';

// 이전에 만들었던 모임에서는 해당 배너가 노출되지 않아야함.
// 참고: https://daangn.slack.com/archives/C03RY6TNUBD/p1660200152190189?thread_ts=1660113974.238549&cid=C03RY6TNUBD
export const GROUP_DESCRIPTION_WRITE_BANNER_EXPOSURE_START_DATE = '2022-08-11';

export const isBeforeGroupDescriptionWriteBannerExposureStartDate = (createdAt: string) =>
  dayjs(createdAt).diff(dayjs(GROUP_DESCRIPTION_WRITE_BANNER_EXPOSURE_START_DATE), 'day') >= 0;

export const SuperHostOnboardingQuestBanner = () => {
  const questList = useSuperHostOnboardingQuest();
  if (questList.length === 0) return null;

  return (
    <OnboardingBanner
      title="모임 홍보하기"
      description="사진과 소개를 채우고 모임을 상단에 홍보해보세요"
      questList={questList}
      localStorageKey="closedSuperHostOnboardingBanner"
    />
  );
};
