import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import BoardCategorySection from './BoardCategorySection';
import SettingMenuItem from './SettingMenuItem';
import SettingSectionWrapper from './Wrapper';

const GroupManagementSettingSection = () => {
  const { push } = useFlow();
  const { groupId } = usePathParams();
  const currentUser = useCurrentGroupMe();

  return (
    <SettingSectionWrapper title="모임 운영">
      <SettingMenuItem
        title="멤버 관리"
        onClick={() => {
          push('GroupMemberListForHostPage', {
            groupId,
          });
        }}
      />
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <SettingMenuItem
          title="멤버 권한 관리"
          onClick={() => {
            push('GroupSettingPermissionPage', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <BoardCategorySection />
      </CheckAccessibleRole>
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <SettingMenuItem
          title="별명"
          description="닉네임 옆에 모임에서만 사용하는 별명을 표시해요."
          onClick={() => {
            push('GroupSettingEditSubNicknamePage', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>

      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <SettingMenuItem
          title="본인인증 설정"
          description="멤버의 본인인증, 성별과 나이대 공개 여부를 설정해요."
          onClick={() => {
            push('GroupSettingEditVerification', {
              groupId,
            });
          }}
        />
      </CheckAccessibleRole>
    </SettingSectionWrapper>
  );
};

export default GroupManagementSettingSection;
