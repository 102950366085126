import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill, IconExpandLessRegular } from '@seed-design/icon';
import { motion } from 'framer-motion';

import icon_checked_control_circle from '@/assets/images/icon_checked_control_circle.png';
import icon_control_circle from '@/assets/images/icon_control_circle.png';
import { useStorage } from '@/hooks/useStorage';
import { StorageData } from '@/hooks/useStorage/type';
import { TypeActivityNames } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { ProgressBar } from '../ProgressBar';
import * as s from './Onboarding.css';

type Props<T> = {
  questList: T[];
  title: string;
  description: string;
  localStorageKey: keyof StorageData;
};

type QuestionListType = {
  title: string;
  description: string;
  onButtonClick: () => void;
  isCompleted: boolean;
  activityName?: TypeActivityNames;
};

const OnboardingBanner = ({
  questList,
  title,
  description,
  localStorageKey,
}: Props<QuestionListType>) => {
  const [closedOnboardingBanner, setClosedOnboardingBanner] = useStorage(localStorageKey, false);
  const completedQuestCount = questList.filter(({ isCompleted }) => isCompleted).length;
  const totalQuestCount = questList.length;

  const animateConfig = closedOnboardingBanner
    ? { opacity: 0, height: 0, transitionEnd: { display: 'none' } }
    : {
        opacity: 1,
        height: 'auto',
        transitionEnd: {
          display: 'block',
        },
      };

  return (
    <motion.div className={s.Container}>
      <div className={s.Header}>
        <div>
          <h5 className={s.HeaderContentsTitle}>{title}</h5>
          <p className={s.HeaderContentsSubTitle}>{description}</p>
        </div>
        <div>
          <button
            style={{
              transform: !closedOnboardingBanner ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 200ms ease-in-out',
            }}
            onClick={() => {
              setClosedOnboardingBanner(!closedOnboardingBanner);
            }}
          >
            <IconExpandLessRegular size={24} />
          </button>
        </div>
      </div>
      <Spacing height={8} />
      <div className={s.ProgressBarWrapper}>
        <ProgressBar
          value={completedQuestCount}
          max={totalQuestCount}
          width="100%"
          height="0.25rem"
          borderRadius="0.5rem"
          fillColor={vars.$scale.color.gray700}
          barColor={vars.$scale.color.gray300}
          transitionTimingFunction="ease-in"
          animateOnRender
          transitionDuration="0.3s"
        />
        <p className={s.ProgressBarLabel}>
          <span>{completedQuestCount}</span>/{totalQuestCount}
        </p>
      </div>
      <motion.div
        style={{ width: '100%' }}
        animate={animateConfig}
        initial={false}
        transition={{
          opacity: {
            duration: !closedOnboardingBanner ? 0.5 : 0.2,
            type: 'spring',
            bounce: 0.26,
          },
          height: {
            duration: 0.5,
            type: 'spring',
            bounce: 0.26,
          },
        }}
      >
        <ul className={s.QuestListWrapper}>
          {questList.map(
            ({
              title,
              description,
              onButtonClick: handleButtonClick,
              isCompleted,
              activityName,
            }) => (
              <li
                className={s.QuestItem}
                key={title}
                onClick={() => {
                  if (localStorageKey === 'closedRunningGrowthBanner') {
                    handleButtonClick();
                    return;
                  }
                  if (!isCompleted) {
                    trackEvent({
                      event: 'click_onboarding_mission_button',
                      params: {
                        activityName,
                      },
                      sample: true,
                    });
                    handleButtonClick();
                  }
                }}
              >
                <img
                  className={s.QuestItemIcon}
                  src={isCompleted ? icon_checked_control_circle : icon_control_circle}
                  alt={`${title} 아이콘`}
                />
                <div className={s.QuestInfoWrapper}>
                  <Typography
                    className={s.QuestItemTitle}
                    as="h4"
                    typography="bodyM2Regular"
                    color="gray900"
                  >
                    {title}
                  </Typography>
                  <Typography
                    className={s.QuestItemDescription}
                    as="p"
                    typography="caption1Regular"
                    color="gray600"
                  >
                    {description}
                  </Typography>
                </div>

                {!isCompleted && (
                  <IconChevronRightFill size={20} color={vars.$scale.color.gray500} />
                )}
              </li>
            )
          )}
        </ul>
      </motion.div>
    </motion.div>
  );
};

export default OnboardingBanner;
