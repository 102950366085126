import { GroupMeetupModifyForm } from '@community-group/api/lib/group/models';
import { BoxButton } from '@community-group/components';
import { Divider, Typography } from '@community-group/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import isEmpty from 'lodash-es/isEmpty';
import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { usePutGroupMeetup } from '@/api/hooks/usePutGroupMeetup';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { refetchGroupMeetupDetail } from '@/utils/refetch/groupMeetupDetail';
import { meetupModifyFormSchema } from '@/utils/validate/formSchema/meetup';
import {
  convertHookFormErrorObjectToArray,
  validateSchemaWithBoolean,
} from '@/utils/validate/util';

import { GroupMeetupFormExposureRangeField } from '../../components/Field/ExposureRange';
import { openListItemFormFieldQuerySelector } from '../../utils/selector';
import * as s from './Options.css';

export type GroupMeetupEditOptionsPageParams = Pick<
  PageParams,
  'groupId' | 'meetupId' | 'meetupModifyForm'
>;

const GroupMeetupEditOptionsPage: ActivityComponentType<GroupMeetupEditOptionsPageParams> = () => {
  const { replace, pop } = useFlow();
  const { bridge } = useBridge();
  const { groupId, meetupId } = usePathParams();
  const { meetupModifyForm } = useQueryParams();

  const hookForm = useForm<GroupMeetupModifyForm>({
    resolver: zodResolver(meetupModifyFormSchema),
  });
  const { handleSubmit: handleFormSubmit } = hookForm;

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isLoading } = usePutGroupMeetup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_group_meetup_edit',
        params: {
          groupId,
          meetupId,
        },
      });
      refetchGroupDetail({ groupId });
      refetchGroupMeetupDetail({ groupId, meetupId });
    },
  });

  const handleSubmit = () => {
    handleFormSubmit(
      (data) => {
        mutate(
          {
            groupId: parseInt(groupId),
            meetupId: parseInt(meetupId),
            meetupModifyForm: data,
          },
          {
            onSuccess: () => {
              pop(2);
            },
          }
        );
      },
      (errors) => {
        const errorList = convertHookFormErrorObjectToArray(errors);

        const firstError = errorList[0];

        bridge.openToast({
          toast: {
            body: firstError.value.message ?? '',
          },
        });
      }
    )();
  };

  useEffect(() => {
    try {
      if (meetupModifyForm) {
        const parsedMeetupModifyForm = JSON.parse(meetupModifyForm);

        if (validateSchemaWithBoolean(meetupModifyFormSchema, parsedMeetupModifyForm)) {
          return hookForm.reset(parsedMeetupModifyForm);
        }
      }

      replace('GroupMeetupEditPage', { groupId, meetupId }, { animate: false });
    } catch {
      replace('GroupMeetupEditPage', { groupId, meetupId }, { animate: false });
    }
  }, [meetupModifyForm]);

  return (
    <AppScreen
      accessoryBar={
        <div className={s.accessoryBarWrapper}>
          <BoxButton
            isLoading={isLoading}
            size="xlarge"
            width="100%"
            onClick={handleSubmit}
            isDisabled={isLoading}
          >
            수정하기
          </BoxButton>
        </div>
      }
    >
      <form>
        {isEmpty(hookForm.getValues()) ? <></> : <GroupMeetupNewOptionsView hookForm={hookForm} />}
      </form>
    </AppScreen>
  );
};

export default GroupMeetupEditOptionsPage;

type Props = {
  hookForm: UseFormReturn<GroupMeetupModifyForm>;
};
const GroupMeetupNewOptionsView = ({ hookForm: { watch, setValue } }: Props) => {
  useEffect(() => {
    openListItemFormFieldQuerySelector('exposureRange');
  }, []);

  return (
    <div className={s.wrapper}>
      <Typography as="h2" typography="title1Bold" color="gray900" style={{ padding: '1rem 0' }}>
        옵션을 선택해주세요
      </Typography>
      <GroupMeetupFormExposureRangeField name="exposureRange" watch={watch} setValue={setValue} />
      <Divider padding={0} />
    </div>
  );
};
