import { vars } from '@seed-design/design-token';
import { IconCheckFill } from '@seed-design/icon';
import { IconChevronRightRegular } from '@seed-design/icon';
import { motion } from 'framer-motion';

import { VerticalSpacing } from '@/components/common/Spacing';

import * as s from './pollResultRow.css';

type Props = {
  title: string;
  totalVoteCount: number;
  voteCount: number;
  isWinner: boolean;
  isSelected: boolean;
  isLoading?: boolean;
};

const PollResultRow = ({
  title,
  totalVoteCount,
  voteCount,
  isWinner,
  isSelected,
  isLoading = false,
}: Props) => {
  const percent = voteCount > 0 ? (100 / totalVoteCount) * voteCount : 0;

  return (
    <div className={s.wrapper}>
      {!isLoading && (
        <>
          <motion.div
            className={isWinner ? s.winnerPollOptionWrapper : s.pollOptionWrapper}
            initial={{
              width: 0,
            }}
            transition={{
              delay: 0.3,
              duration: 0.3,
            }}
            whileInView={{
              width: `${percent}%`,
            }}
            viewport={{ once: true }}
          />
          {isSelected ? (
            <div className={s.selectedTitle}>
              <div className={s.iconWrapper}>
                <IconCheckFill size={12} />
              </div>
              <VerticalSpacing height={8} />
              <div>{title}</div>
            </div>
          ) : (
            <div className={s.title}>
              <div>{title}</div>
            </div>
          )}

          <div className={s.pollOptionRightItem}>
            <p className={s.percentText}>{voteCount}명</p>
            <IconChevronRightRegular
              size={14}
              color={vars.$scale.color.gray900}
              style={{ zIndex: 1, flexShrink: 0 }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PollResultRow;
