import { GroupCurrentUser } from '@community-group/api/lib/group/models';
import {
  Dialog,
  GroupAvatar,
  HelpBubbleTrigger,
  isMember,
  isNotMember,
  isSuperHost,
  Typography,
  useBottomSheet,
  useDialog,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import {
  IconConfirmationProfileFill,
  IconInfoRegular,
  IconLocationFill,
  IconProfileFill,
} from '@seed-design/icon';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';

import { GroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';
import ColoredKing from '@/components/common/Icons/coloredKing';
import { Spacing, VerticalSpacing } from '@/components/common/Spacing';
import { BLOCK_GROUP_ONLY_TEXT } from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';
import { getUserProfileUrlInduceExperiment } from '@/utils/analytics/experiment/experiments/userProfileInduceExperiment';
import { isHigherManager } from '@/utils/role';
import { getTemperatureColor } from '@/utils/temperature';

import { useHandleGroupOnlyForProfile } from '../hooks/useHandleGroupOnlyForProfile';
import * as s from './Information.css';

type Props = {
  profile?: Pick<
    GroupMainProfile,
    | 'id'
    | 'profileImage'
    | 'nickname'
    | 'subNickname'
    | 'temperature'
    | 'description'
    | 'role'
    | 'joinedAt'
    | 'userRegion'
    | 'userRegionCheckInCount'
    | 'verifiedUserInfoText'
    | 'state'
  > & {
    verified?: boolean;
  };
  canViewVerifiedUserInfo?: boolean;
  isModal?: boolean;
  moreInformationRender?: ReactNode;
  currentUser?: Pick<GroupCurrentUser, 'id' | 'role' | 'state'> & { verified?: boolean };
  groupCreated?: string;
  onClickProfileImage?: () => void;
};

const Information = ({
  profile,
  isModal,
  moreInformationRender,
  canViewVerifiedUserInfo,
  currentUser,
  groupCreated,
  onClickProfileImage,
}: Props) => {
  const [shownTooltip, setShownTooltip] = useState(true);
  const { push } = useFlow();

  const handleShownTooltip = () => {
    setShownTooltip(!shownTooltip);
  };

  const renderVerifiedInfo = () => {
    if (!profile?.verified) return;

    if (!profile.verifiedUserInfoText || !canViewVerifiedUserInfo) {
      return '본인인증 완료';
    }

    if (!currentUser?.verified) {
      return '본인인증 완료';
    }

    if (isSuperHost(currentUser?.role)) {
      return `본인인증 완료 ∙ ${profile.verifiedUserInfoText}`;
    }

    if (
      (currentUser?.state === 'applied' ||
        isMember(currentUser?.role) ||
        profile.id === currentUser?.id) &&
      profile.role === 'superHost'
    ) {
      return `본인인증 완료 ∙ ${profile.verifiedUserInfoText}`;
    }

    return `본인인증 완료`;
  };

  const { groupId } = usePathParams();
  const profileImage = getUserProfileUrlInduceExperiment({
    groupId: groupId ? parseInt(groupId) : 0,
    userId: currentUser?.id ?? 0,
    defaultUrl: profile?.profileImage ?? '',
  });
  const { open: openBottomSheet } = useBottomSheet();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { handleGroupOnly } = useHandleGroupOnlyForProfile({
    groupId,
  });

  const handleImageClick = () => {
    if (onClickProfileImage) {
      onClickProfileImage();
      return;
    }
    const isBlock = currentUser && isNotMember(currentUser?.role);
    trackEvent({
      event: 'click_user_profile_image',
      params: { groupId: groupId, isJoined: !isBlock, userState: currentUser?.state },
      sample: true,
    });
    if (currentUser?.state === 'applied') {
      openDialog({
        element: (
          <Dialog
            title="모임 가입 안내"
            description="가입 승인 후 프로필을 볼 수 있어요. 승인 후 다시 확인해주세요."
            secondaryActionIntent="nonpreferred"
            primaryActionLabel="확인"
            onPrimaryAction={async () => {
              await closeDialog();
            }}
          />
        ),
        onOutsideClick: closeDialog,
      });
      return;
    }

    handleGroupOnly({
      isBlock,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getMemberProfileImage,
      onSettled: () => {
        push('ImageViewerPage', {
          content: 'profile',
          groupId,
          userId: profile?.id.toString() ?? '0',
          initialIndex: '0',
        });
      },
      onSuccess: () => {
        push('ImageViewerPage', {
          content: 'profile',
          groupId,
          userId: profile?.id.toString() ?? '0',
          initialIndex: '0',
        });
      },
    });
  };

  return (
    <div>
      <div className={s.top}>
        <div className={s.leftBox} onClick={handleImageClick}>
          <GroupAvatar size="large" src={profileImage}></GroupAvatar>
        </div>
        <div className={s.rightBox}>
          <div className={s.topContentWrapper}>
            <div
              className={s.titleWrapper}
              style={{ marginBottom: moreInformationRender ? '0.125rem' : '0' }}
            >
              <Typography className={s.name} typography="title3Bold" color="gray900">
                {profile?.nickname}
              </Typography>
              <span
                className={s.temperature}
                style={{
                  color: getTemperatureColor(profile?.temperature)?.color,
                  background: `rgba(${getTemperatureColor(profile?.temperature)?.background})`,
                }}
              >
                {profile?.temperature.toFixed(1)}°C
              </span>
            </div>
            <Typography color="gray900" typography="subtitle1Regular">
              {profile?.subNickname}
            </Typography>
          </div>
          {moreInformationRender}
        </div>
      </div>
      <div>
        {profile?.verified && (
          <>
            <div className={s.iconWrapper}>
              <IconConfirmationProfileFill size={16} color={vars.$scale.color.green500} />
              <VerticalSpacing height={8} />
              <Typography color="gray900" typography="label3Regular">
                {renderVerifiedInfo()}
              </Typography>
              <VerticalSpacing height={4} />
              <HelpBubbleTrigger
                title={`휴대폰 본인인증 절차를 통과한\n이웃이에요.`}
                isOpen={!shownTooltip}
                UNSAFE_style={{
                  whiteSpace: 'pre-wrap',
                }}
                positioning={{
                  placement: 'bottom-start',
                }}
                marginLeft={-14}
              >
                <IconInfoRegular
                  data-tooltip-id="info-mode"
                  width={16}
                  height={16}
                  color={vars.$scale.color.gray600}
                  onClick={handleShownTooltip}
                />
              </HelpBubbleTrigger>
            </div>
            <Spacing height={8} />
          </>
        )}
        {profile?.role !== 'none' && (
          <>
            <p className={s.detailInfoTextWrapper}>
              {isHigherManager(profile?.role ?? 'member') && (
                <>
                  <div className={s.iconWrapper}>
                    <ColoredKing
                      fill={profile?.role === 'superHost' ? 'staticYellow300' : 'staticGreen400'}
                      size={15}
                    />
                    <VerticalSpacing height={8} />
                    <Typography color="gray900" typography="label3Regular">
                      {profile?.role === 'superHost' ? '모임장' : '운영진'}
                    </Typography>
                    <Typography
                      color="gray900"
                      typography="label3Regular"
                      style={{ padding: '0 0.25rem' }}
                    >
                      ∙
                    </Typography>
                  </div>
                </>
              )}
              {!isHigherManager(profile?.role ?? 'member') &&
                isMember(profile?.role ?? 'member') && (
                  <>
                    <div className={s.iconWrapper}>
                      <IconProfileFill width={16} height={16} color={vars.$scale.color.gray600} />
                      <VerticalSpacing height={8} />
                    </div>
                  </>
                )}
              <Typography color="gray900" typography="label3Regular">
                {profile?.joinedAt && (
                  <>{dayjs(profile?.joinedAt).format('YYYY년 MM월 DD일')} 가입</>
                )}
              </Typography>
            </p>
            <Spacing height={8} />
          </>
        )}
        {groupCreated && (
          <>
            <div className={s.iconWrapper}>
              <IconProfileFill size={16} color={vars.$scale.color.gray600} />
              <VerticalSpacing height={8} />
              <Typography color="gray800" typography="label3Regular">
                {dayjs(groupCreated).format('YYYY년 MM월 DD일')} 모임 개설
              </Typography>
            </div>
            <Spacing height={8} />
          </>
        )}
        <div className={s.iconWrapper}>
          <IconLocationFill size={16} color={vars.$scale.color.gray600} />
          <VerticalSpacing height={8} />
          <Typography color="gray900" typography="label3Regular">
            {profile?.userRegion.town}{' '}
            {profile?.userRegionCheckInCount !== 0 && `인증 ${profile?.userRegionCheckInCount}회`}
          </Typography>
        </div>
        {profile?.description && (
          <>
            <Spacing height={16} />
            <Typography
              color="gray900"
              typography="bodyM1Regular"
              whiteSpace="pre-wrap"
              style={{
                lineHeight: '1.625rem',
                maxHeight: isModal ? '150px' : 'auto',
              }}
            >
              {profile?.description.trim()}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default Information;
