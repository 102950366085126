import { useMemo } from 'react';

import useActiveActivities from '@/hooks/useActiveActivities';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { isDevelopmentAppStage } from '@/utils/check';

import {
  CREATE_GROUP_DEFAULT_FLOW,
  CREATE_GROUP_DEV_FLOW,
  CREATE_GROUP_V2,
} from '../utils/createGroupControl';

const useCreateGroupFunnel = () => {
  const { activeActivities } = useActiveActivities();
  const { createGroupFlow } = useQueryParams();
  const currentActivity = activeActivities[activeActivities.length - 1];

  // 플로우에 따라서 페이지 순서를 변경할 수 있도록
  const currentCreateGroupFlow = useMemo(() => {
    if (isDevelopmentAppStage()) {
      return CREATE_GROUP_DEV_FLOW;
    }

    switch (createGroupFlow) {
      case 'DEFAULT':
        return CREATE_GROUP_DEFAULT_FLOW;
      case 'GROUP_V2':
        return CREATE_GROUP_V2;
      default:
        return CREATE_GROUP_DEFAULT_FLOW;
    }
  }, [createGroupFlow]);

  // 다음 페이지 이름을 가져온다.
  const nextPageName = useMemo(() => {
    const nextPageIndex =
      currentCreateGroupFlow.findIndex((page) => page === currentActivity.name) + 1;
    return currentCreateGroupFlow[nextPageIndex];
  }, [currentActivity.name, currentCreateGroupFlow]);

  return { nextPageName };
};

export default useCreateGroupFunnel;
