import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';

import checkIcon from '@/assets/images/poll/checkcircle-enabled.png';
import checkedIcon from '@/assets/images/poll/checkcircle-selected.png';
import { VerticalSpacing } from '@/components/common/Spacing';

import * as s from './pollOptionRow.css';

type Props = {
  optionId: string;
  title: string;
  isSelected: boolean;
  onSelect: (optionId: string) => void;
};

const PollOptionRow = ({ optionId, title, isSelected, onSelect }: Props) => {
  return (
    <motion.div
      className={s.pollOptionRowWrapper}
      style={{
        backgroundColor: isSelected ? vars.$scale.color.gray200 : vars.$scale.color.gray100,
      }}
      animate={{
        backgroundColor: isSelected ? vars.$scale.color.gray200 : vars.$scale.color.gray100,
      }}
      transition={{
        duration: 0.3,
      }}
      onClick={() => onSelect(optionId)}
    >
      <img width={14} src={isSelected ? checkedIcon : checkIcon} alt="투표하기" />
      <VerticalSpacing height={8} />
      <Typography typography="label3Regular" color="gray800">
        {title}
      </Typography>
    </motion.div>
  );
};

export default PollOptionRow;
