import { vars } from '@seed-design/design-token';

import * as s from './index.css';

type Props = {
  height?: number;
  background?: string;
  padding?: number;
};

export const Divider = ({
  height = 1,
  background = vars.$semantic.color.divider1,
  padding = 16,
}: Props) => (
  <div
    className={s.wrapper}
    style={{
      height: `${height}px`,
      padding: `0 ${padding}px`,
    }}
  >
    <div
      className={s.line}
      style={{
        height: `${height}px`,
        background,
      }}
    />
  </div>
);
