import { GroupPhotoPostPresentation, Media, Video } from '@community-group/api/lib/group/models';
import {
  HiddenVideoPlayerWithVideoPlayInfoButton,
  Image,
  ImageType,
  videoDurationText,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import clsx from 'clsx';
import React, { ImgHTMLAttributes, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useCallback } from 'react';
import { Pagination, Virtual, Zoom } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { isVideo } from '@/components/common/base/slider/ImageSlider/utils/medias';

import * as s from './ImageViewerSlider.css';

export type MediaListType = {
  id: string;
  isPinned?: boolean;
  isPinnable?: boolean;
  postId?: number;
  post?: GroupPhotoPostPresentation;
} & Media;

export type MediaViewerType = {
  mediaList: MediaListType[];
  initialIndex: number;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
};

export type MediaViewerSliderOptions = {
  isVirtual?: boolean;
  isLazyLoad?: boolean;
};

// Image 타입이 아닌 Media(Image | Video) 타입을 사용하는 Slider
// 로직은 ImageViewerSlider와 거의 동일
const MediaViewerSlider = ({
  mediaData,
  objectFit = 'contain',
  setCurrentMedia,
  onClickMedia,
  renderBottom,
  slideStyle,
  zoomable = false,
  children,
  firstSliderChildren,
  lastSliderChildren,
  options = {
    isVirtual: true,
    isLazyLoad: true,
  },
}: {
  mediaData: MediaViewerType;
  objectFit?: 'cover' | 'contain';
  setCurrentMedia?: React.Dispatch<React.SetStateAction<MediaListType | undefined>>;
  onClickMedia?: (media: MediaListType, index: number) => void;
  renderBottom?: (media: MediaListType) => React.ReactNode;
  slideStyle?: React.CSSProperties;
  zoomable?: boolean;
  children?: React.ReactNode;
  firstSliderChildren?: React.ReactNode;
  lastSliderChildren?: React.ReactNode;
  options?: MediaViewerSliderOptions;
}) => {
  const {
    mediaList: medias,
    initialIndex,
    fetchNextPage: fetchNextPhotoList,
    isFetchingNextPage,
    hasNextPage: hasNextPhotoList,
  } = mediaData;

  const prevImageRef = useRef<MediaListType[] | undefined>();
  const swiperRef = useRef<SwiperClass>();

  const handleSlideChange = useCallback(
    (swiper) => {
      setCurrentMedia?.(medias[swiper.activeIndex]);
      if (
        medias?.length - swiper.activeIndex < 5 &&
        !isFetchingNextPage &&
        hasNextPhotoList &&
        fetchNextPhotoList
      ) {
        fetchNextPhotoList();
        return;
      }
    },
    [fetchNextPhotoList, hasNextPhotoList, medias, isFetchingNextPage, setCurrentMedia]
  );

  const [showSlider, setShowSlider] = useState(false);

  useLayoutEffect(() => {
    setShowSlider(true);
    if (medias[0]?.id !== prevImageRef.current?.[0]?.id) {
      prevImageRef.current = medias;
      swiperRef.current?.slideTo(0);
    }

    return () => setShowSlider(false);
  }, [medias]);

  const module = useMemo(() => {
    const modulesArr = [Pagination];
    if (zoomable) modulesArr.push(Zoom);
    if (options.isVirtual) modulesArr.push(Virtual);
    return modulesArr;
  }, [zoomable, options.isVirtual]);

  const backgroundColor = useMemo(
    () => (objectFit === 'cover' ? vars.$scale.color.gray200 : vars.$static.color.staticBlack),
    [objectFit]
  );

  const ImageComponents = useCallback(
    (props: ImgHTMLAttributes<HTMLImageElement> & ImageType) => {
      return options.isLazyLoad ? <Image {...props} /> : <img {...props} />;
    },
    [options.isLazyLoad]
  );

  if (!showSlider) return null;

  return (
    <Swiper
      className={clsx(s.MainSwiperStyle, 'imageViewerSlider')}
      onSwiper={(swiper: SwiperClass) => (swiperRef.current = swiper)}
      virtual={
        options.isVirtual && {
          enabled: true,
          addSlidesBefore: 2,
          addSlidesAfter: 2,
        }
      }
      modules={module}
      zoom={zoomable}
      pagination={{
        dynamicBullets: true,
        dynamicMainBullets: 1,
      }}
      centeredSlides={true}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      onInit={(swiper) => {
        handleSlideChange(swiper);
      }}
      onSlideChange={handleSlideChange}
      initialSlide={Number(initialIndex) ?? 0}
    >
      {firstSliderChildren}
      {medias.map((slideContent, index) => {
        const imageIdKey = slideContent?.id ? encodeURIComponent(slideContent?.id) : index;
        const isVideoType = isVideo(slideContent.media);
        const imageUrl = isVideo(slideContent.media)
          ? slideContent.media?.thumbnailUrl
          : slideContent.media?.url;

        const duration = isVideoType
          ? videoDurationText((slideContent.media as Video)?.duration)
          : '';

        return (
          <SwiperSlide key={imageIdKey} virtualIndex={index} zoom={false}>
            <div
              className={zoomable ? 'swiper-zoom-container' : ''}
              style={{
                width: '100%',
                height: '100%',
                background: backgroundColor,
                willChange: 'all',
                position: 'relative',
                ...slideStyle,
              }}
              onClick={() => onClickMedia?.(slideContent, index)}
            >
              {isVideoType && (
                <div className={s.VideoInfoWrapper}>
                  <HiddenVideoPlayerWithVideoPlayInfoButton
                    videoUrl={(slideContent.media as Video)?.videoUrl}
                    durationText={duration}
                  />
                </div>
              )}
              <ImageComponents
                style={{
                  objectFit,
                  objectPosition: 'center',
                }}
                key={slideContent.id}
                src={imageUrl}
                alt={isVideoType ? '비디오' : '이미지'}
                aria-hidden="true"
                height={'100%'}
                width={'100%'}
                id={imageIdKey.toString()}
              />
            </div>
            {renderBottom?.(slideContent)}
          </SwiperSlide>
        );
      })}
      {children}
      {lastSliderChildren}
    </Swiper>
  );
};

export default MediaViewerSlider;
