export const INTERNAL_USER_ID_LIST = globalThis.appConfig.isAppEnvProduction
  ? [
      '10735023', // Root
      '1181966', // Dave
      '9402271', // Kaia
      '5178673', // Corbin
      '23006225', // Kevin
      '23232559', // Ivan
      '3891569', // Promm
      '9951675', // Aio
      '19644414', // ina
      '16913997', // Heidi
      '60684712', // Heidi
      '42685337', // 유진
      '3330515', // 조셉
      '15859', // Aster
      '1425071', // Jeremy.kim
      '3590024', // Leo.kim
      '52513202', // Mars
      '14339456', // June.jung
      '1154374', // Hodu
    ]
  : [
      '0',
      '5652', // Root
      '5696', // Dave
      '5964', // Dave
      '10002338', // Dave
      '9651', // Kaia
      '8137', // Lychee
      '8139', // Reddy
      '7141', // Ivan
      '74943', // Root (동그래9999)
      '10000013', // Promm
      '9059', // Tony.kim
      '9290', // Tony.kim
      '9879', // Joeun
      '6497', // Heidi
      '10003278', // Mars
    ];
