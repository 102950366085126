import { GroupCurrentUser, PostDetail } from '@community-group/api/lib/group/models';
import { GroupAvatar, isMember } from '@community-group/components';
import { Spacing, Typography } from '@community-group/components';
import { MouseEvent } from 'react';

import { VerticalSpacing } from '@/components/common/Spacing';
import { getUserProfileUrlInduceExperiment } from '@/utils/analytics/experiment/experiments/userProfileInduceExperiment';
import { convertNicknameDisplay } from '@/utils/nickname';
import { getFeedPostInfoText } from '@/utils/postInfoText';

import * as s from './FeedItemProfile.css';

interface Props {
  post: PostDetail;
  currentUser: GroupCurrentUser;
  onClick?: (e: MouseEvent) => void;
}

const FeedItemProfile = ({ post, currentUser, onClick }: Props) => {
  const handleProfileClick = (e: MouseEvent) => {
    post.author.isAccountDeleted ? e.preventDefault() : onClick?.(e);
  };

  const profileImage = getUserProfileUrlInduceExperiment({
    groupId: post.groupInfo?.id ? post.groupInfo.id : 0,
    userId: currentUser.id ?? 0,
    defaultUrl: post.author.profileImage ?? '',
  });

  return (
    <div className={s.wrapper} onClick={handleProfileClick} aria-hidden="true">
      <GroupAvatar src={profileImage} role={post.author.role} />
      <VerticalSpacing height={12} />
      <div className={s.container}>
        <Typography
          typography="caption1Bold"
          color={post.author.isAccountDeleted ? 'gray500' : 'gray900'}
        >
          {convertNicknameDisplay({
            nickname: post.author.nickname,
            subNickname: post.author.subNickname,
          })}
        </Typography>
        <Spacing height={2} />
        <Typography typography="caption2Regular" color="gray600">
          {getFeedPostInfoText({
            createdAt: post.createdAt,
            isPublished: post.isPublished,
            isGroupOnly: post.publishType === 'groupOnly' && isMember(currentUser.role),
            isNoticed: post.isNoticed,
            boardCategory: post.boardCategories[post.boardCategories.length - 1]?.category,
          })}
        </Typography>
      </div>
    </div>
  );
};

export default FeedItemProfile;
