import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import { Divider, ViewLoader, withAsyncBoundary } from '@community-group/components';

import { useGetGroupFeedSummaryMeetup } from '@/api/hooks/useGetGroupFeedSummaryMeetup';

import GroupDetailSummaryMoreButton from '../GroupDetailSummaryMoreButton';
import MeetupSummarySection from './MeetupSummarySection';

type Props = {
  group: GroupDetailPresentation;
  currentUserRole: GroupMemberRoleEnum;
  shouldSetSubNickname: boolean;
};

const GroupMeetupSummary = ({ group, currentUserRole, shouldSetSubNickname }: Props) => {
  const { data } = useGetGroupFeedSummaryMeetup(group.id);
  if (!data || data.meetups.length === 0) return null;

  return (
    <>
      <Divider padding={0} height={8} />
      <MeetupSummarySection
        group={group}
        currentUserRole={currentUserRole}
        shouldSetSubNickname={shouldSetSubNickname}
        totalCount={data.totalCount}
        meetups={data.meetups}
      >
        <GroupDetailSummaryMoreButton
          group={group}
          summaryType="meetups"
          currentUserRole={currentUserRole}
        />
      </MeetupSummarySection>
    </>
  );
};

export default withAsyncBoundary(GroupMeetupSummary, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
