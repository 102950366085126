import { Divider, Skeleton, Typography } from '@community-group/components';
import { Tabs } from '@karrotframe/tabs';

import { Spacing } from '@/components/common/Spacing';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { curationHomeSectionKeywords } from '../../Curation/Page/GroupCurationWithKeyword/curationKeywords';
import * as style from './searchSkeleton.css';

const GroupHomeSkeleton = () => {
  const { keyword } = useQueryParams();

  const titleCuration =
    curationHomeSectionKeywords.find((item) => item.name === keyword)?.description ??
    '또래 친구는 여기서 만나요!';

  return (
    <div className={style.wrapper}>
      <Tabs
        className="custom_tab"
        activeTabKey="a"
        disableSwipe
        onTabChange={() => {}}
        tabs={[
          {
            key: 'a',
            buttonLabel: '전체 모임',
            render: () => (
              <>
                <div
                  className={style.wrapper}
                  style={{
                    height: '384px',
                  }}
                >
                  <div className={style.header}>
                    <Typography typography="title3Bold" color="gray900">
                      {titleCuration}
                    </Typography>
                  </div>
                  <Spacing height={8} />
                  <ListSkeleton />
                </div>
                <Divider height={8} padding={-16} />
                <div className={style.wrapper}>
                  <div className={style.header}>
                    <Typography typography="title3Bold" color="gray900">
                      새로 생겼어요
                    </Typography>
                  </div>
                  <Spacing height={8} />
                  <ListSkeleton />
                </div>
              </>
            ),
          },
          {
            key: Math.random().toString(),
            buttonLabel: '모임 일정',
            render: () => <div></div>,
          },
        ]}
      />
    </div>
  );
};

export default GroupHomeSkeleton;

const ListSkeleton = () => (
  <div className={style.newListWrapper}>
    <div className={style.newListContentsWrapper}>
      {Array.from({ length: 3 }).map((item, index) => (
        <div className={style.newListItem} key={`talk-skeleton-${index}`}>
          <div className={style.profileWrapper}>
            <Skeleton width="4.5rem" height="4.5rem" borderRadius="1rem" />
          </div>
          <div className={style.newListContentsWrapper}>
            <Skeleton width="100%" height="21px" enableAnimation />
            <Spacing height={4} />
            <Skeleton width="4.875rem" height="14px" style={{ marginRight: '.375rem' }} />
            <div className={style.categoryListWrapper}>
              <Skeleton width="3rem" height="1rem" style={{ marginRight: '.375rem' }} />
              <Skeleton width="2rem" height="1rem" style={{ marginRight: '.375rem' }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
