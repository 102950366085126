import { forwardRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Mention, MentionsInput as OriginMentionsInput } from '../../../../mention';
import {
  StickyInputDefaultMentionStyle,
  StickyInputDefaultStyle,
} from '../../../../mention/customStyle';
import * as s from './MentionInputField.css';

type Mention = {
  id: number;
  display: string;
  imageSrc?: string; // 유저 맨션에서 사용
  subDisplay?: string; // 챌린지 맨션에서 사용
};

export type MentionConfig = {
  trigger: string;
  name: string;
  mentionList: Mention[];
  setMentionList?: (mentionList: string[]) => void;
};

export interface MentionInputFieldProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  mentionConfig?: MentionConfig[];
  suggestionsPortalHost?: HTMLDivElement | null;
  allowSuggestionsAboveCursor?: boolean;
}

export const MentionInputField = forwardRef(
  (
    { mentionConfig, onChange, suggestionsPortalHost = null, ...props }: MentionInputFieldProps,
    ref
  ) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;

      const mentionFormatRegex = /[^[}]+(?=})/g;
      const mentions = value.match(mentionFormatRegex);

      mentionConfig?.forEach(({ trigger, setMentionList }) => {
        const filteredMentions = mentions?.filter((mention) => mention[0] === trigger) ?? [];

        if (filteredMentions?.length <= 0) {
          return setMentionList?.([]);
        }

        const mentionIdRegex = /[^{}]+(?=})/g;
        const mentionIds =
          filteredMentions
            .map((value) => value + '}')
            .join(', ')
            .match(mentionIdRegex) ?? [];

        setMentionList?.(mentionIds);
      });

      onChange?.(event);
    };

    return (
      <div className={s.wrapper}>
        <OriginMentionsInput
          data-name="MentionsInput"
          className={undefined}
          display={undefined}
          classNames={undefined}
          style={StickyInputDefaultStyle}
          onChange={handleInputChange}
          suggestionsPortalHost={suggestionsPortalHost}
          {...props}
        >
          {mentionConfig ? (
            mentionConfig?.map(({ trigger, name, mentionList }) => (
              <Mention
                key={name}
                markup="[__display__]{__id__}"
                data={mentionList}
                trigger={trigger}
                style={StickyInputDefaultMentionStyle}
                className={undefined}
                display={undefined}
                classNames={undefined}
              />
            ))
          ) : (
            <Mention
              markup="[__display__]{__id__}"
              data={[]}
              trigger={'UNDEFINED_MENTION_TRIGGER'}
              style={StickyInputDefaultMentionStyle}
              className={undefined}
              display={undefined}
              classNames={undefined}
            />
          )}
        </OriginMentionsInput>
        <input className={s.hiddenInput} data-name="MentionsHiddenInput" />
      </div>
    );
  }
);
