import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { useDialog } from '@community-group/components';
import { MutateOptions, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import FullMemberAlertDialog from '@/components/group/MarketingPromotion/components/FullMemberAlertDialog';
import { useFlow } from '@/stackflow';
import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { getMyGroupListPath } from '../base/group';
import { queryClient } from './../instance/index';
import { useFetchInstance } from './instance/useFetchInstance';
import { useGetGroupDetail } from './useGetGroupDetail';
import { getGroupMePath } from './useGetGroupMe';
import { useGetRunningRaceGroups } from './useGetRunningRaceGroups';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type Props = UseMutationOptions<
  AxiosResponse<model.GroupMemberJoinResponse>,
  Error,
  { id: number; joinForm?: model.GroupJoinForm }
> & {
  groupId: string;
};

const MAX_GROUP_MEMBER_COUNT = 2000;

export const usePostJoinGroup = ({ onError, onSuccess, groupId, onMutate, onSettled }: Props) => {
  const fetchInstance = useFetchInstance();
  const { group } = useGetGroupDetail(groupId);

  const { open: openDialog, close: closeDialog } = useDialog();
  const { push } = useFlow();

  const { isEventGroup } = useGetRunningRaceGroups({ groupId });
  const postGroupJoin = groupClient.api.GroupApi.apiV1GroupsIdJoinPost({ axios: fetchInstance });

  const postGroupJoinWrapperFp = ({
    id,
    joinForm,
  }: {
    id: number;
    joinForm: model.GroupJoinForm;
  }) => {
    return postGroupJoin(id, joinForm);
  };

  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  const { mutate: postGroupJoinMutate, ...rest } = useThrottleMutation(postGroupJoinWrapperFp, {
    onError,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [getMyGroupListPath()] });
      queryClient.invalidateQueries({ queryKey: [getGroupMePath(groupId)] });

      onSuccess?.(data, variables, context);

      mutateEventLogLocation({
        eventName: 'join_group_detail',
        groupId: variables.id,
      });
    },
    onMutate,
    onSettled,
  });

  // TODO: 8~9월 운동 마케팅 본 프로모션 관련 분기용 추가
  const mutate = async (
    {
      id,
      joinForm,
    }: {
      id: number;
      joinForm: model.GroupJoinForm;
    },
    options?:
      | MutateOptions<
          AxiosResponse<groupClient.model.GroupMemberJoinResponse, any>,
          Error,
          { id: number; joinForm: groupClient.model.GroupJoinForm },
          unknown
        >
      | undefined
  ) => {
    const isOverMaxMemberCount = group?.memberCount && group.memberCount >= MAX_GROUP_MEMBER_COUNT;
    if (isEventGroup && isOverMaxMemberCount) {
      openDialog({
        element: <FullMemberAlertDialog closeDialog={closeDialog} push={push} />,
      });
      return;
    }
    return await postGroupJoinMutate({ id, joinForm }, options);
  };

  return { mutate, ...rest };
};
