import { GroupSidebarPresentationFeatureRedDots } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconPhotoRegular, IconSettingRegular, IconUserGroupRegular } from '@seed-design/icon';
import { memo, MouseEventHandler, useMemo } from 'react';

import * as s from './SidebarFeatureList.css';

type Props = {
  redDots: GroupSidebarPresentationFeatureRedDots[];
  onAlbumMenuClick: MouseEventHandler;
  onMemberMenuClick: MouseEventHandler;
  onSettingMenuClick: MouseEventHandler;
};

const SidebarFeatureList = ({
  redDots,
  onAlbumMenuClick,
  onMemberMenuClick,
  onSettingMenuClick,
}: Props) => {
  const featureMenuList = useMemo(
    () => [
      {
        onClick: onAlbumMenuClick,
        icon: <IconPhotoRegular color={vars.$scale.color.gray900} />,
        text: '앨범',
        type: 'album',
      },
      {
        onClick: onMemberMenuClick,
        icon: <IconUserGroupRegular color={vars.$scale.color.gray900} />,
        text: '멤버',
        type: 'member',
      },
      {
        onClick: onSettingMenuClick,
        icon: <IconSettingRegular color={vars.$scale.color.gray900} />,
        text: '설정',
        type: 'setting',
      },
    ],
    [onAlbumMenuClick, onMemberMenuClick, onSettingMenuClick]
  );

  return (
    <ul className={s.FeatureList}>
      {featureMenuList.map((item, index) => {
        const featureType = item.type as GroupSidebarPresentationFeatureRedDots;
        const hasRedDot = redDots.includes(featureType);

        return (
          <li
            key={`${item.text}-${index}`}
            className={s.FeatureItem}
            onClick={item.onClick}
            role="link"
          >
            <div className={s.IconContainer}>
              {item.icon}
              {hasRedDot && <div className={s.RedDot({ type: featureType })} />}
            </div>
            <Spacing height={6} />
            <Typography typography="caption2Regular" color="gray900">
              {item.text}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};

export default memo(SidebarFeatureList);
