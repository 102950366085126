import { useBottomSheet } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import React, { useState } from 'react';

import { formatDate, KARROT_DATE_FORMAT } from '@/utils/date';

import { TimePicker } from '../TimePicker';
import * as s from './index.css';

interface Props {
  meetupTimeAt: string;
  setMeetupTimeAt: (value: string) => void;
}

export const TimePickerButtonSheet = ({
  meetupTimeAt,
  setMeetupTimeAt: handleMeetupTimeAt,
}: Props) => {
  const { close } = useBottomSheet();

  const [selectedDate, setSelectedDate] = useState(meetupTimeAt);

  const handleConfirmClick = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();

    handleMeetupTimeAt(formatDate(new Date(selectedDate), KARROT_DATE_FORMAT));
    close();
  };

  return (
    <>
      <div className={s.timePickerWrapper}>
        <TimePicker timeAt={selectedDate} onChange={setSelectedDate} />
      </div>
      <div className={s.buttonWrapper}>
        <BoxButton width="100%" onClick={handleConfirmClick} size="xlarge">
          확인
        </BoxButton>
      </div>
    </>
  );
};
