import { TypeActivityNames } from '@/stackflow';

type RUNNING_GROWTH_FLOW_TYPE = {
  step: number;
  title: string;
  description: string;
  pageName: TypeActivityNames;
};

export const RUNNING_GROWTH_FLOW: RUNNING_GROWTH_FLOW_TYPE[] = [
  {
    step: 1,
    title: '1단계: 모임을 시작해요',
    description: '모임을 만들고 소개를 작성해보세요.',
    pageName: 'GroupSettingEditGroupDescription',
  },
  {
    step: 2,
    title: '2단계: 모임 규칙을 설정해요',
    description: '규칙을 설명하는 글을 작성해보세요.',
    pageName: 'GroupPostNewPage',
  },
  {
    step: 3,
    title: '3단계: 러닝 코스를 짜 봐요',
    description: '함께 달릴 코스를 글로 작성해보세요.',
    pageName: 'GroupPostNewPage',
  },
  {
    step: 4,
    title: '4단계: 두근두근 첫 일정을 진행해요',
    description: '일정을 진행하고 후기를 업로드해보세요.',
    pageName: 'GroupMeetupNewPage',
  },
  {
    step: 5,
    title: '5단계: 꾸준히 만나요',
    description: '일정을 3개 더 진행하고 후기를 업로드해보세요.',
    pageName: 'GroupMeetupNewPage',
  },
];
