import './styles';

import { ViewError } from '@community-group/components';
import * as Sentry from '@sentry/react';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import cookie from 'cookie';
import ReactDOM from 'react-dom/client';

import { queryClient } from './api/instance';
import App from './App';
import { COOKIE } from './constants/keys';
import { GroupUIConfig } from './contexts/GroupUIConfig';
import datadogRum from './utils/datadogRum';
import sentry from './utils/sentry';

try {
  const { appEnv } = globalThis.appConfig;

  if (appEnv === 'production' || appEnv === 'alpha') {
    datadogRum.init();
    sentry.init();
  }
} catch (error: any) {
  console.log(`[Sentry | Datadog] initialize failed. ${error.message}`);
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!;

const dehydratedState = window.__REACT_QUERY_STATE__;
const parsedCookie = cookie.parse(document.cookie);

ReactDOM.hydrateRoot(
  rootElement,
  <Sentry.ErrorBoundary
    fallback={() => {
      return <ViewError />;
    }}
  >
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <App
          url={`${window.location.pathname}${window.location.search ? window.location.search : ''}`}
          safeAreaTop={parsedCookie[COOKIE.SAFE_AREA_TOP]}
          userConfig={{
            regionId: parsedCookie[COOKIE.REGION_ID]
              ? parseInt(parsedCookie[COOKIE.REGION_ID])
              : null,
            regionProvinceName: parsedCookie[COOKIE.REGION_PROVINCE_NAME],
            regionCityName: parsedCookie[COOKIE.REGION_CITY_NAME],
            regionTownName: parsedCookie[COOKIE.REGION_TOWN_NAME],
            userId: parsedCookie[COOKIE.USER_ID] ? parseInt(parsedCookie[COOKIE.USER_ID]) : null,
            userNickname: parsedCookie[COOKIE.USER_NICKNAME],
            userProfileImageUrl: parsedCookie[COOKIE.USER_PROFILE_IMAGE_URL],
            userAuthToken: parsedCookie[COOKIE.AUTH_TOKEN],
            userHashId: parsedCookie[COOKIE.HASH_ID],
          }}
          deviceConfig={{
            theme: document.documentElement.dataset.stackflowTheme as 'cupertino' | 'android',
            userAgent: navigator.userAgent,
            xUserAgent: parsedCookie[COOKIE.USER_AGENT],
            isLegacyIOS: parsedCookie[COOKIE.IS_LEGACY_IOS] === 'true',
          }}
          groupUIConfig={{
            heroImageBannerShownCount: parsedCookie[
              COOKIE.HERO_IMAGE_BANNER_SHOWN_COUNT
            ] as unknown as GroupUIConfig['heroImageBannerShownCount'],
          }}
        />
      </Hydrate>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);

// yarn dev 시점에 Layout Shifting 이슈 해결을 위한 hack
rootElement.style.display = '';
