import {
  GroupMemberRoleEnum,
  PostDetail,
  PostDetailMeetupInfoPresentation,
} from '@community-group/api/lib/group/models';
import { ContainerWithGap, isNotMember, Typography } from '@community-group/components';
import { MouseEvent, useMemo } from 'react';

import { MeetupBanner } from '@/components/common/Banner/MeetupBanner';
import { PoiBanner } from '@/components/common/Banner/PoiBanner';
import { PollBanner } from '@/components/common/Banner/PollBanner';
import Warning from '@/components/common/Icons/warning';
import LinkifyContainer from '@/components/common/Linkify';
import { VerticalSpacing } from '@/components/common/Spacing';
import { isMember } from '@/utils/role';

import { getMeetupStatusTitleColor } from '../../../utils/getMeetupStatusTitleColor';
import * as s from './FeedItemContents.css';
import FeedItemContentText from './FeedItemContentText';
import FeedItemMediaSlider from './FeedItemMediaSlider';

interface Props {
  post: PostDetail;
  currentUserRole: GroupMemberRoleEnum;
  meetup?: PostDetailMeetupInfoPresentation;
  onMeetupBannerClick?: (e: MouseEvent) => void;
  onPollBannerClick?: (e: MouseEvent) => void;
}

const FeedItemPublicContents = ({
  post,
  currentUserRole,
  meetup,
  onMeetupBannerClick,
  onPollBannerClick,
}: Props) => {
  const hasMedias = useMemo(() => post.medias.length > 0, [post.medias.length]);

  const isReview = post.postType?.type === 'meetupReview';

  const canReadMeetup = useMemo(() => {
    const isGroupMember = isMember(currentUserRole);

    return meetup?.exposureRange === 'ALL' ? true : isGroupMember;
  }, [currentUserRole, meetup]);

  const renderMeetupPrefix = useMemo(() => {
    if (!meetup || isReview) return null;

    return (
      <>
        <FeedItemContentText>
          <span
            style={{
              color: getMeetupStatusTitleColor(meetup.progressStatus.status),
              marginRight: 4,
            }}
          >
            {meetup.progressStatus.description}
          </span>
          {meetup.title}
        </FeedItemContentText>
        <br />
      </>
    );
  }, [isReview, meetup]);

  // 본문 존재 여부에 따라 제목의 폰트가 다름
  // 관련 이슈 : https://daangn.slack.com/archives/C0336FFHA8L/p1710816075026709
  const renderPostTitleAndContent = useMemo(() => {
    return (
      <>
        {renderMeetupPrefix}
        {post?.content && (
          <FeedItemContentText>
            <LinkifyContainer text={post.content} />
          </FeedItemContentText>
        )}
      </>
    );
  }, [post.content, renderMeetupPrefix]);

  return (
    <ContainerWithGap name="FeedItemContents" gap={12}>
      {renderPostTitleAndContent}
      {hasMedias && <FeedItemMediaSlider medias={post.medias} />}
      {canReadMeetup && meetup && (
        <MeetupBanner meetup={meetup} isReview={isReview} onClick={onMeetupBannerClick} />
      )}
      {post.pois?.[0] && <PoiBanner poi={post.pois[0]} onClick={() => {}} />}
      {post.poll && <PollBanner poll={post.poll} onClick={onPollBannerClick} />}
    </ContainerWithGap>
  );
};

const FeedItemPrivateContents = () => {
  return (
    <div className={s.PrivateContentsWrapper}>
      <Warning />
      <VerticalSpacing height={4} />
      <Typography typography="bodyM1Regular" color="gray600">
        모임에만 공개된 게시글이에요.
      </Typography>
    </div>
  );
};

const FeedItemContents = (props: Props) => {
  const isPrivateContents = props.post.publishType === 'groupOnly';

  if (isPrivateContents && isNotMember(props.currentUserRole)) {
    return <FeedItemPrivateContents />;
  }

  return <FeedItemPublicContents {...props} />;
};

export default FeedItemContents;
