import { Skeleton } from '@community-group/components';

import { Spacing } from '@/components/common/Spacing';

import * as s from './Skeleton.css';

export const PostDetailSkeleton = () => (
  <div className={s.wrapper}>
    <Skeleton
      width="180px"
      height="31.5px"
      borderRadius="16px"
      style={{ margin: '12px 0 0 16px' }}
    />
    <Spacing height={16} />
    <div className={s.profileWrapper}>
      <Skeleton width="32px" height="32px" borderRadius="16px" />
      <div className={s.profileContentsWrapper}>
        <Skeleton enableAnimation width="80px" height="13px" style={{ margin: '3px 0' }} />
        <Skeleton enableAnimation width="140px" height="12px" style={{ margin: '3px 0' }} />
      </div>
    </div>
    <div className={s.infoWrapper}>
      <Skeleton enableAnimation width="128px" height="14px" />
      <Spacing height={8} />
      <Skeleton enableAnimation width="166px" height="14px" />
    </div>
    <Spacing height={12} />
    <Skeleton width="100%" height="272px" />
  </div>
);
