import {
  AsyncBoundary,
  isMember,
  Spacing,
  ViewError,
  ViewLoader,
} from '@community-group/components';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import FeedMeetupList from '@/components/group/DetailV2/components/GroupDetailFeed/FeedMeetupList';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import * as s from './index.css';

const GroupMeetupList = () => {
  return (
    <AsyncBoundary pendingFallback={<></>} rejectedFallback={<ViewError />}>
      <GroupMeetupListView />
    </AsyncBoundary>
  );
};

const GroupMeetupListView = () => {
  const { groupId = '' } = usePathParams();

  const { group } = useGetGroupDetail(groupId);

  const { data: myInfo } = useGetGroupMe(groupId, {
    refetchOnWindowFocus: true,
  });

  const isNotGroupMember = !isMember(myInfo?.currentUser.role);

  return (
    <section className={s.wrapper}>
      {!isNotGroupMember && <Spacing height={8} />}

      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <FeedMeetupList
          groupId={groupId}
          shouldSetSubNickname={group?.subNicknameSetting.isSettingOn ?? false}
        />
      </AsyncBoundary>
    </section>
  );
};

export default GroupMeetupList;
