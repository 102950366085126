import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconReplyMissionRegular, IconThumbUpRegular } from '@seed-design/icon';

import ReplyMisson from '@/components/common/Icons/replyMission';
import ThumpUp from '@/components/common/Icons/thumpUp';
import Warning from '@/components/common/Icons/warning';
import { getElementFromMention } from '@/components/common/Mention';
import { VerticalSpacing } from '@/components/common/Spacing';

import * as s from './ListItem.css';

type Props = {
  id?: number;
  content?: string;
  emotionCount?: number;
  commentCount?: number;
  imageUrl?: string;
  onClick?: () => void;
  isComment?: boolean;
  isMember?: boolean;
  publishType?: string;
};

const ListItem = ({
  content,
  isMember,
  publishType,
  emotionCount,
  commentCount,
  imageUrl,
  onClick,
  isComment,
}: Props) => {
  if (!isMember && publishType === 'groupOnly') {
    return (
      <li
        className={s.wrapper}
        style={{
          flexDirection: 'column',
        }}
        onClick={onClick}
      >
        <div className={s.PrivateContentsWrapper}>
          <Warning />
          <VerticalSpacing height={4} />
          <Typography typography="bodyM1Regular" color="gray600">
            모임에만 공개된 게시글이에요.
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={s.iconBox}>
            <IconThumbUpRegular color={vars.$scale.color.gray600} size={18} />
            <span>{emotionCount}</span>
          </div>
          {!isComment && (
            <div className={s.iconBox}>
              <IconReplyMissionRegular color={vars.$scale.color.gray600} size={18} />
              <span>{commentCount}</span>
            </div>
          )}
        </div>
      </li>
    );
  }

  return (
    <li className={s.wrapper} onClick={onClick}>
      <div className={s.left}>
        <p> {getElementFromMention(content || '', 'string')}</p>
        <div style={{ display: 'flex' }}>
          <div className={s.iconBox}>
            <IconThumbUpRegular color={vars.$scale.color.gray600} size={18} />
            <span>{emotionCount}</span>
          </div>
          {!isComment && (
            <div className={s.iconBox}>
              <IconReplyMissionRegular color={vars.$scale.color.gray600} size={18} />
              <span>{commentCount}</span>
            </div>
          )}
        </div>
      </div>
      {imageUrl && <img className={s.image} src={imageUrl} />}
    </li>
  );
};

export default ListItem;
