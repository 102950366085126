import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';

import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import { useGetRunningRaceGroups } from '@/api/hooks/useGetRunningRaceGroups';
import { isSuperHost } from '@/utils/role';

import RunningGrowthMissionBanner from '../../../Detail/components/RunningGrowth/RunningGrowthMissionBanner';
import MemberOnboardingQuestBanner from './MemberOnboardingQuestBanner';
import {
  isBeforeGroupDescriptionWriteBannerExposureStartDate,
  SuperHostOnboardingQuestBanner,
} from './SuperHostOnboardingQuestBanner';

const GroupDetailOnboardingBanner = ({
  currentUser,
  group,
}: {
  currentUser: GroupCurrentUser;
  group?: GroupDetailPresentation;
}) => {
  const { runningGrowthData } = useGetRunningGrowthMission(String(group?.id));
  const { isEventGroup } = useGetRunningRaceGroups({ groupId: String(group?.id) });

  // * 일반 멤버인 경우
  //  TODO: 8~9월 당근 레이스 마케팅 이벤트 끝나면 삭제
  if (!isSuperHost(currentUser.role)) {
    // ** 당근 레이스 마케팅 이벤트인 경우
    if (isEventGroup) return <></>;
    // ** 2주 이내 가입한 경우에만 온보딩 섹션 & 바텀시트 노출
    const isJoinedIn2Weeks =
      new Date(currentUser.joinedAt) > new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14);
    if (isJoinedIn2Weeks) return <MemberOnboardingQuestBanner />;
    return <></>;
  }

  // * 모임장인 경우 +

  // ** 러닝 그로스 데이터가 있는 경우
  if (runningGrowthData && runningGrowthData.length > 0) return <RunningGrowthMissionBanner />;

  // ** 특정 일자 이후에 만들어진 모임만 온보딩 노출
  const groupCreationDate = group?.createdAt ?? '';
  if (isBeforeGroupDescriptionWriteBannerExposureStartDate(groupCreationDate))
    return <SuperHostOnboardingQuestBanner />;

  return <></>;
};

export default GroupDetailOnboardingBanner;
