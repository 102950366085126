import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import SettingMenuItem from './SettingMenuItem';
import SettingSectionWrapper from './Wrapper';

const MyGroupProfileSettingSection = () => {
  const { push } = useFlow();

  const { groupId } = usePathParams();
  const currentUser = useCurrentGroupMe();
  const { group } = useGetGroupDetail(groupId);

  return (
    <SettingSectionWrapper title="프로필">
      <SettingMenuItem
        onClick={() => {
          push('GroupUserProfileDetailPage', {
            groupId,
            userId: currentUser.id.toString(),
          });
        }}
        title="내 프로필"
      />
      {group?.subNicknameSetting?.isSettingOn && (
        <SettingMenuItem
          onClick={() => {
            push('GroupEditMemberSubNicknamePage', {
              groupId,
              userId: currentUser.id.toString(),
            });
          }}
          title="별명 설정"
        />
      )}
    </SettingSectionWrapper>
  );
};

export default MyGroupProfileSettingSection;
