import { vars } from '@seed-design/design-token';
import { IconVideoRegular } from '@seed-design/icon';

import useHandleUploadVideo from '../../hooks/useHandleUploadVideo';
import { GroupPostFormProps } from '../GroupPostForm';
import * as s from './index.css';

type Props = {
  formHandler: GroupPostFormProps['formHandler'];
  closeBottomSheet: () => Promise<void>;
};

function StartBottomSheetVideoUploadButton({ formHandler, closeBottomSheet }: Props) {
  const { handleVideoFileSelection } = useHandleUploadVideo({
    formHandler,
  });

  return (
    <button
      className={s.Item}
      onClick={() => {
        handleVideoFileSelection();
        closeBottomSheet();
      }}
    >
      <IconVideoRegular size={24} color={vars.$scale.color.gray900} />
      <p>동영상</p>
    </button>
  );
}

export default StartBottomSheetVideoUploadButton;
