import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { ContainerWithGap, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { MouseEventHandler } from 'react';

import { useGetGroupSideBar } from '@/api/hooks/useGetGroupSidebar';

import { useGroupDetailContext } from '../GroupDetailContext';
import SidebarApplicationBanner from './SidebarApplicationBanner';
import SidebarBoardList from './SidebarBoardList';
import SidebarCustomBoardSection from './SidebarCustomBoardSection';
import SidebarFeatureList from './SidebarFeatureList';
import SidebarFooter from './SidebarFooter';
import * as s from './SidebarMenuSection.css';

type Props = {
  groupId: number;
  currentUserRole: GroupMemberRoleEnum;
  onAlbumMenuClick: MouseEventHandler;
  onMemberMenuClick: MouseEventHandler;
  onSettingMenuClick: MouseEventHandler;
  onApplicationBannerClick: MouseEventHandler;
  onHomeMenuClick: MouseEventHandler;
  onMeetupMenuClick: MouseEventHandler;
  onNoticeMenuClick: MouseEventHandler;
  onEditBoardButtonClick: MouseEventHandler;
  onBoardItemClick: (id: number) => MouseEventHandler;
  onCreateBoardButtonClick: MouseEventHandler;
  onReportButtonClick: MouseEventHandler;
  onGuideBookButtonClick: MouseEventHandler;
  onShareButtonClick: (permalinkId: string) => MouseEventHandler;
};

const SidebarMenuSection = ({
  groupId,
  currentUserRole,
  onAlbumMenuClick,
  onMemberMenuClick,
  onSettingMenuClick,
  onApplicationBannerClick,
  onHomeMenuClick,
  onMeetupMenuClick,
  onNoticeMenuClick,
  onEditBoardButtonClick,
  onBoardItemClick,
  onCreateBoardButtonClick,
  onReportButtonClick,
  onGuideBookButtonClick,
  onShareButtonClick,
}: Props) => {
  const { data } = useGetGroupSideBar(groupId);
  const { selectedBoardKey } = useGroupDetailContext();

  if (!data) return <ViewLoader />;

  const renderSideBarBanner = () => {
    if (data.applications) {
      return (
        <SidebarApplicationBanner
          appliedApplicationCount={data.applications.appliedApplicationCount}
          onClick={onApplicationBannerClick}
        />
      );
    }

    return null;
  };

  return (
    <div className={s.Container}>
      <ContainerWithGap name="SidebarMenuSection" gap={20}>
        <SidebarFeatureList
          redDots={data.featureRedDots ?? []}
          onAlbumMenuClick={onAlbumMenuClick}
          onMemberMenuClick={onMemberMenuClick}
          onSettingMenuClick={onSettingMenuClick}
        />
        {renderSideBarBanner()}
        <>
          <SidebarBoardList
            boardList={data.defaultBoards ?? []}
            upcomingMeetupCount={data.upcomingMeetupCount}
            onHomeMenuClick={onHomeMenuClick}
            onMeetupMenuClick={onMeetupMenuClick}
            onNoticeMenuClick={onNoticeMenuClick}
          />
          <SidebarCustomBoardSection
            isHost={currentUserRole === 'superHost'}
            boardList={data.customBoards ?? []}
            selectedBoardId={selectedBoardKey}
            onEditBoardButtonClick={onEditBoardButtonClick}
            onBoardItemClick={onBoardItemClick}
            onCreateBoardButtonClick={onCreateBoardButtonClick}
          />
        </>
      </ContainerWithGap>
      <SidebarFooter
        role={currentUserRole}
        onReportButtonClick={onReportButtonClick}
        onGuideBookButtonClick={onGuideBookButtonClick}
        onShareButtonClick={onShareButtonClick(data.groupPermalinkId)}
      />
    </div>
  );
};

export default withAsyncBoundary(SidebarMenuSection, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
