import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import { MouseEventHandler } from 'react';

import IconGroup from '@/assets/images/icon_category_group.png';
import { VerticalSpacing } from '@/components/common/Spacing';

import * as s from './SidebarApplicationBanner.css';

type Props = {
  appliedApplicationCount: number;
  onClick?: MouseEventHandler;
};

const SidebarApplicationBanner = ({ appliedApplicationCount, onClick }: Props) => {
  const count = appliedApplicationCount.toLocaleString('ko-KR');

  return (
    <div className={s.Wrapper}>
      <div className={s.Banner} onClick={onClick}>
        <div className={s.LeftSide}>
          <div className={s.ImageWrapper}>
            <img className={s.Image} src={IconGroup} />
          </div>
          <VerticalSpacing height={10} />
          <Typography typography="caption1Regular" color="gray900">
            <b>{`${count}명의 이웃`}</b>이<br />
            가입 승인을 기다려요
          </Typography>
        </div>
        <div>
          <VerticalSpacing height={10} />
          <IconChevronRightFill size={16} color={vars.$scale.color.gray900} />
        </div>
      </div>
    </div>
  );
};

export default SidebarApplicationBanner;
