import type { PropsWithChildren } from 'react';
import { createContext, useContext, useState } from 'react';
import Cookies from 'universal-cookie';

type Props = PropsWithChildren<GroupUIConfig>;

type ReturnValue = {
  groupUIConfig: GroupUIConfig;
  setGroupUIConfig: React.Dispatch<React.SetStateAction<GroupUIConfig>>;
  handleUpdateGroupUIConfig: (
    key: keyof GroupUIConfig,
    newConfig: GroupUIConfig[keyof GroupUIConfig]
  ) => void;
};

const GroupUIConfig = createContext<ReturnValue>({
  groupUIConfig: {
    heroImageBannerShownCount: {},
  },
  setGroupUIConfig: () => {},
  handleUpdateGroupUIConfig: () => {},
});
export type GroupUIConfig = {
  heroImageBannerShownCount: {
    [key: string]: number;
  };
};
export const GroupUIConfigProvider = ({ children, ...initialConfig }: Props) => {
  const parsedInitialConfig = Object.keys(initialConfig).reduce((acc, key) => {
    if (typeof initialConfig[key] === 'string') {
      acc[key] = JSON.parse(initialConfig[key]);
    }
    return acc;
  }, initialConfig);

  const [groupUIConfig, setGroupUIConfig] = useState<GroupUIConfig>(parsedInitialConfig);

  const handleUpdateGroupUIConfig = (
    key: keyof GroupUIConfig,
    newConfig: GroupUIConfig[keyof GroupUIConfig]
  ) => {
    const cookies = new Cookies(null, { path: '/' });
    const cookie = cookies.get(key);
    if (!cookie) {
      cookies.set(key, JSON.stringify(newConfig), { path: '/' });
    } else if (cookie) {
      const updatedConfig = {
        ...cookie,
        ...newConfig,
      };
      cookies.set(key, JSON.stringify(updatedConfig), { path: '/' });
    }

    setGroupUIConfig((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        ...newConfig,
      },
    }));
  };

  return (
    <GroupUIConfig.Provider
      value={{
        groupUIConfig,
        setGroupUIConfig,
        handleUpdateGroupUIConfig,
      }}
    >
      {children}
    </GroupUIConfig.Provider>
  );
};

export const useGroupUIConfig = () => useContext(GroupUIConfig);
