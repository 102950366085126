import { SVGProps } from 'react';

import { convertToStyleProp } from '../utils/convert';
import { DefaultMarkerVariation, pinMapper } from '../utils/pinMapper';

interface PinProps extends SVGProps<SVGSVGElement> {
  hasLabel?: boolean;
  variation: DefaultMarkerVariation;
}
export function Pin({ variation, hasLabel, ...props }: PinProps) {
  const { Component, size } = pinMapper[variation];

  const width = convertToStyleProp(props.width ?? size);
  const height = convertToStyleProp(props.height ?? size);

  return (
    <Component
      {...props}
      width={width}
      height={height}
      style={{
        marginTop: hasLabel ? '' : `-${size / 2}px`,
      }}
    />
  );
}
