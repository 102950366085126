import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';

import GroupDetailAlbumSummary from './GroupDetailAlbumSummary';
import GroupDetailFeedSummary from './GroupDetailFeedSummary';
import GroupDetailMemberSummary from './GroupDetailMemberSummary';
import GroupDetailSummary from './GroupDetailSummary';
import GroupMeetupSummary from './GroupMeetupSummary';
import GroupNoticeSummary from './GroupNoticeSummary';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupDetailForNotMember = ({ group, currentUser }: Props) => {
  return (
    <>
      <GroupDetailSummary
        description={group.description ?? ''}
        categoryName={group.category.name ?? ''}
        joinableAgeRangeText={group.joinableAgeRangeText}
        meetupPlaces={group.meetupPois}
        createdRegionTown={group.groupCreatedRegion.town}
        groupId={group.id}
      />
      <GroupDetailAlbumSummary group={group} currentUserRole={currentUser.role} />
      <GroupDetailMemberSummary
        group={group}
        currentUserId={currentUser.id}
        currentUserRole={currentUser.role}
      />
      <GroupMeetupSummary
        group={group}
        currentUserRole={currentUser.role}
        shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
      />
      <GroupNoticeSummary group={group} currentUser={currentUser} />
      <GroupDetailFeedSummary
        group={group}
        currentUser={currentUser}
        shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
      />
    </>
  );
};

export default GroupDetailForNotMember;
