import {
  MentionInputField,
  Spacing,
  useCheckOpenKakao,
  useSnackbarAdapter,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { ActivityComponentType } from '@stackflow/react';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import { usePutGroup } from '@/api/hooks/usePutGroup';
import OpenKakaoBanner from '@/components/common/OpenKakaoBanner';
import { useBridge } from '@/contexts/Bridge';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';
import { groupFieldSchema } from '@/utils/validate/formSchema/group';
import { validateSchemaWithBoolean } from '@/utils/validate/util';

import { useCheckRunningGrowthGroupStep } from '../../Detail/components/RunningGrowth/hooks/useCheckRunningGrowthGroupStep';
import RunningGrowthCallout from '../../Detail/components/RunningGrowth/RunningGrowthCallout';
import PopularGroupDescriptionList from '../components/SettingEditGroupDescription/PopularGroupDescriptionList';
import * as s from './GroupSettingEditGroupDescription.css';

const GroupSettingEditGroupDescription: ActivityComponentType = () => {
  const { bridge } = useBridge();
  const { groupId } = usePathParams();
  const { initialGroupDescription } = useQueryParams();
  const { checkCurrentRunningGrowthStep } = useCheckRunningGrowthGroupStep(groupId);
  const { group } = useGetGroupDetail(groupId);
  const { pop } = useFlow();
  const { refetch } = useGetRunningGrowthMission(groupId);
  const snackbarAdapter = useSnackbarAdapter();

  const { watch, setValue } = useForm<FieldValues>({
    defaultValues: {
      description: initialGroupDescription,
    },
  });

  useEffect(() => {
    if (!group) return;
    setValue('description', group.description);
  }, [group, initialGroupDescription, setValue]);

  useEnterTrackEvent({
    event: 'enter_edit_group_description',
    params: { groupId },
  });

  const descriptionFieldValue = watch('description');

  const { showOpenKakaoBanner } = useCheckOpenKakao(undefined, descriptionFieldValue, false);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isLoading } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });
      snackbarAdapter.create({
        message: '수정되었어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
    },
  });

  const isDisable = useMemo(() => {
    if (checkCurrentRunningGrowthStep()) {
      if ((descriptionFieldValue as string)?.length <= 200) {
        return true;
      }
    }
    const isSuccess = validateSchemaWithBoolean(
      groupFieldSchema.description,
      descriptionFieldValue
    );
    return !isSuccess;
  }, [descriptionFieldValue]);

  const handleSubmit = () => {
    mutate(
      {
        id: groupId,
        groupModifyForm: {
          description: descriptionFieldValue,
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'finish_modify_group_description',
            params: {
              groupId,
            },
            loggerType: ['AMPLITUDE', 'FIREBASE'],
          });
          trackEvent({
            event: 'click_finish_modify_group_description',
            params: {
              groupId,
            },
            loggerType: ['KARROT'],
          });
          refetch();
          pop();
        },
      }
    );
  };

  return (
    <AppScreen
      appBar={{
        title: '모임 소개 수정',
        renderRight: () => (
          <p
            className={clsx(s.SubmitButton, isDisable ? 'disabled' : '')}
            onClick={() => {
              if (checkCurrentRunningGrowthStep() && isDisable) {
                snackbarAdapter.create({
                  message: '200자 이상 작성해야 미션이 완료돼요.',
                  type: 'default',
                  timeout: 3000,
                  onClick: () => {
                    snackbarAdapter.dismiss();
                  },
                });
              }
              !isDisable && !isLoading && handleSubmit();
            }}
          >
            완료
          </p>
        ),
        borderSize: '0.5px',
        borderColor: vars.$semantic.color.divider3,
      }}
    >
      <div className={s.Wrapper}>
        <div className={s.ContentWrapper}>
          <MentionInputField
            value={descriptionFieldValue ?? ''}
            onChange={(event) => {
              const text = event.target.value;
              // 글자가 삭제되었을 때는 validation 체크를 하지 않는다.
              if (text.length < descriptionFieldValue.length) {
                setValue('description', event.target.value);
                return;
              }
              if ((groupFieldSchema.description.maxLength ?? 500) <= text.length) {
                const errorMessage = groupFieldSchema.description._def.checks?.find(
                  (check) => check.kind === 'max'
                )?.message;
                bridge.openToast({
                  toast: {
                    body: errorMessage ?? '모임 소개는 500자 이하로 입력해주세요.',
                  },
                });
                return;
              }

              setValue('description', event.target.value);
            }}
            placeholder="글을 입력해주세요."
          />
        </div>
        <Spacing height={16} />
        {showOpenKakaoBanner && (
          <div className={s.KakaoCalloutWrapper}>
            <OpenKakaoBanner marginX={false} />
          </div>
        )}

        <RunningGrowthCallout />
        <PopularGroupDescriptionList />
      </div>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupSettingEditGroupDescription, {
  rejectedFallback: <ViewError />,
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
});
