import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import { MouseEventHandler, PropsWithChildren, ReactElement } from 'react';

import { VerticalSpacing } from '@/components/common/Spacing';

import * as s from './ActionBannerListItem.css';

type Props = PropsWithChildren<{
  icon: ReactElement;
  onClick: MouseEventHandler;
}>;

const ActionBannerListItem = ({ icon, onClick, children }: Props) => {
  return (
    <li className={s.ListItem} onClick={onClick}>
      <div className={s.ListItemBody}>
        {icon}
        <VerticalSpacing height={10} />
        {children}
      </div>
      <IconChevronRightFill size={16} color={vars.$scale.color.gray600} />
    </li>
  );
};

export default ActionBannerListItem;
