import { Category, CategoryItemType } from '@community-group/api/lib/group/models';
import { ChipFilter, ChipToggleButton, useBottomSheet } from '@community-group/components';
import { useMemo } from 'react';

import { ORDER_HOME } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import GroupIcon from '@/assets/images/group_icon.png';
import { Container } from '@/components/common/Container';
import SortingGroupFilterBottomSheet, {
  SortingListValue,
} from '@/components/common/SortingFilterBottomSheet/SortingGroupFilterBottomSheet';
import { Spacing } from '@/components/common/Spacing';
import { useBridge } from '@/contexts/Bridge';
import { trackEvent } from '@/utils/analytics';

import CategoryFilterList from '../../common/Filter/CategoryFilterList';
import { FilterType } from '.';
import * as s from './GroupListFilter.css';

type Props = {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
};

const GroupListFilter = ({ filter, setFilter }: Props) => {
  const { categories } = useGetCategories();

  const { open: openBottomSheet, closeAsync: closeBottomSheet } = useBottomSheet();

  const orderFilterSelectedText = () => {
    if (filter.order === ORDER_HOME.RECOMMEND_SCORE_DESC) return '추천순';
    if (filter.order === ORDER_HOME.POPULARITY_DESC) return '인기순';
    return '최신순';
  };

  const setSelectedSorting = (sorting: SortingListValue) => {
    setFilter({ ...filter, order: sorting });
  };

  const categoryList = useMemo(
    () => [{ id: 0, name: '전체', icon: GroupIcon, createdAt: '' }, ...categories],
    [categories]
  );
  const { bridge } = useBridge();
  return (
    <div className={s.wrapper}>
      <Container paddingY={0} paddingX={0}>
        <CategoryFilterList
          categories={categoryList}
          onClickItem={(item: Category | CategoryItemType) => {
            setFilter({ ...filter, category: item.id !== 0 ? item.id : undefined });
          }}
        />

        <Spacing height={8} />

        <div className={s.switchOrderWrapper}>
          <ChipFilter
            label={orderFilterSelectedText()}
            onClick={() => {
              openBottomSheet({
                element: (
                  <SortingGroupFilterBottomSheet
                    setSelectedSorting={setSelectedSorting}
                    selectedSorting={filter.order}
                  />
                ),
                onDimClose: closeBottomSheet,
              });
            }}
          />
          <ChipToggleButton
            size="small"
            isSelected={filter.viewRange === 'my'}
            onClick={() => {
              bridge.setHapticSelect({});
              const enabled = filter.viewRange === 'my';
              trackEvent({
                event: 'click_viewrange_filter',
                params: {
                  section: 'home',
                  enabled,
                },
                sample: true,
              });

              if (enabled) {
                setFilter({ ...filter, viewRange: undefined });
                return;
              }
              setFilter({ ...filter, viewRange: 'my' });
            }}
          >
            가까운 동네만
          </ChipToggleButton>
        </div>
      </Container>
    </div>
  );
};

export default GroupListFilter;
