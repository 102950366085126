import { send } from '@stackflow/compat-await-push';
import { ActivityComponentType, useActivity } from '@stackflow/react';

import { TimePickerButtonSheet } from '@/components/common/TimePickerBottomSheet';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

export type TimePickerBottomSheetParams = Pick<PageParams, 'defaultValue'>;

// defaultValue = meetupDateTime.toISOString()
export const TimePickerBottomSheet: ActivityComponentType<TimePickerBottomSheetParams> = () => {
  const { defaultValue = '' } = useQueryParams();
  const { id } = useActivity();

  return (
    <BottomSheet
      style={{ padding: 0 }}
      onOutsideClick={() => {
        send({
          activityId: id,
          data: {
            time: undefined,
          },
        });
      }}
    >
      <TimePickerButtonSheet
        meetupTimeAt={defaultValue}
        setMeetupTimeAt={function (value: string): void {
          send({
            activityId: id,
            data: {
              time: value,
            },
          });
        }}
      />
    </BottomSheet>
  );
};

export default TimePickerBottomSheet;
