import { GroupMeetupCreateForm } from '@community-group/api/lib/group/models';
import { BoxButton } from '@community-group/components';
import {
  AsyncBoundary,
  Dialog,
  Divider,
  useDialog,
  useEffectOnce,
  useKeyboardSize,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconCloseRegular } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import RunningGrowthCallout from '@/components/group/Detail/components/RunningGrowth/RunningGrowthCallout';
import { useBridge } from '@/contexts/Bridge';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useBack } from '@/stackflow/hooks/useBack';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { meetupCreateFormSchema, meetupFieldSchema } from '@/utils/validate/formSchema/meetup';
import { convertHookFormErrorObjectToArray } from '@/utils/validate/util';

import { GroupMeetupCreationCycleField } from '../../components/Field/CreationCycleField';
import { GroupMeetupFormDescriptionField } from '../../components/Field/Description';
import { GroupMeetupFormImagesField } from '../../components/Field/Images';
import { GroupMeetupFormMaximumParticipantsNumberField } from '../../components/Field/MaximumParticipantsNumber';
import { GroupMeetupFormMeetupDateTimeField } from '../../components/Field/MeetupDateTime';
import { GroupMeetupFormPoiField } from '../../components/Field/Poi';
import { GroupMeetupFormTitleField } from '../../components/Field/Title';
import {
  CloneGroupMeetupFormDataQueryParams,
  useInitGroupMeetupFormData,
} from '../../hooks/useInitGroupMeetupFormData';
import {
  closeListItemFormFieldQuerySelector,
  getFormFieldQuerySelector,
  openListItemFormFieldQuerySelector,
} from '../../utils/selector';
import * as s from './index.css';

export type GroupMeetupNewPageParams = Pick<
  PageParams,
  'groupId' | keyof CloneGroupMeetupFormDataQueryParams
>;

const GroupMeetupNewPage: ActivityComponentType<GroupMeetupNewPageParams> = () => {
  const { groupId } = usePathParams();
  const { cloneGroupMeetupFormData, from } = useQueryParams();
  const { push } = useFlow();
  const back = useBack();
  const { open: openDialog } = useDialog();

  const { bridge } = useBridge();
  const { isKeyboardOn } = useKeyboardSize(bridge);

  const initGroupMeetupFormData = useInitGroupMeetupFormData();

  const hookForm = useForm<GroupMeetupCreateForm>({
    defaultValues: initGroupMeetupFormData,
    resolver: zodResolver(meetupCreateFormSchema),
  });

  useEnterTrackEvent({
    event: 'enter_create_meetup',
    params: {
      groupId,
    },
    loggerType: ['APPSFLYER', 'AMPLITUDE', 'KARROT'],
  });

  useEffect(() => {
    if (cloneGroupMeetupFormData) {
      openListItemFormFieldQuerySelector('meetupTimeAt');
    }
  }, []);

  useEffectOnce(() => {
    if (from === 'individual_chat') {
      openDialog({
        element: (
          <Dialog
            description="개별 채팅방에서는 일정을 사용할 수 없어요."
            primaryActionLabel="닫기"
            onPrimaryAction={async () => {
              bridge.closeRouter({});
            }}
          />
        ),
      });
    }
  });

  const { handleSubmit: handleFormSubmit, watch } = hookForm;

  const isDisabledButton = useMemo(() => {
    if (isKeyboardOn) return false;

    if (watch('title').length < (meetupFieldSchema.title.minLength ?? 1)) return true;

    if (
      (watch('images') ?? [])?.filter(
        (imageId) => imageId.includes('temp') || imageId.includes('error')
      ).length > 0
    )
      return true;

    return false;
  }, [isKeyboardOn, watch('title'), watch('images')]);

  const handleSubmit = () => {
    if (isKeyboardOn) return;

    handleFormSubmit(
      (data) => {
        push('GroupMeetupNewOptionsPage', {
          groupId,
          meetupCreateForm: JSON.stringify(data),
          from,
        });
      },
      (errors) => {
        const errorList = convertHookFormErrorObjectToArray(errors);

        const firstError = errorList[0];

        bridge.openToast({
          toast: {
            body: firstError.value.message ?? '',
          },
        });
      }
    )();
  };

  return (
    <AppScreen
      appBar={{
        backButton: {
          render: () => (
            <div className={s.closeButtonWrapper} onClick={back}>
              <IconCloseRegular />
            </div>
          ),
        },
        closeButton: {
          render: () => (
            <div className={s.closeButtonWrapper} onClick={back}>
              <IconCloseRegular />
            </div>
          ),
        },
      }}
      accessoryBar={
        <div
          className={s.accessoryBarWrapper}
          style={{
            padding: isKeyboardOn
              ? '0'
              : '0.75rem 1rem calc(0.5rem + env(safe-area-inset-bottom)) 1rem',
          }}
        >
          <RunningGrowthCallout />
          <BoxButton
            width="100%"
            variant="primary"
            size="xlarge"
            isDisabled={isDisabledButton}
            onClick={handleSubmit}
            UNSAFE_style={isKeyboardOn ? { borderRadius: 0 } : undefined}
          >
            다음
          </BoxButton>
        </div>
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <form>
          <GroupMeetupNewView hookForm={hookForm} />
        </form>
      </AsyncBoundary>
    </AppScreen>
  );
};

export default GroupMeetupNewPage;

type Props = {
  hookForm: UseFormReturn<GroupMeetupCreateForm>;
};
const GroupMeetupNewView = ({ hookForm: { watch, setValue } }: Props) => {
  const { poiName } = useQueryParams();

  return (
    <div className={s.wrapper}>
      <GroupMeetupFormTitleField
        name="title"
        watch={watch}
        setValue={setValue}
        placeholder="멤버들과 어떤 활동을 할까요?"
        maxLength={meetupFieldSchema.title.maxLength ?? 100}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <GroupMeetupFormMeetupDateTimeField
        dateName="meetupTimeAt"
        onlyDateName="hasMeetupTimeAtOnlyDate"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider padding={0} />
      <GroupMeetupCreationCycleField
        creationCycleName="creationCycle"
        dateName="meetupTimeAt"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider padding={0} />
      <GroupMeetupFormPoiField
        name="poiItems.0"
        setValue={setValue}
        defaultValue={poiName}
        placeholder="미정"
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider padding={0} />
      <GroupMeetupFormMaximumParticipantsNumberField
        name="maximumParticipantsNumber"
        watch={watch}
        setValue={setValue}
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider padding={0} />
      <GroupMeetupFormDescriptionField
        name="description"
        watch={watch}
        setValue={setValue}
        onFocus={() => {
          setTimeout(() => {
            const descriptionFieldDom = getFormFieldQuerySelector('description');

            descriptionFieldDom?.scrollIntoView({
              behavior: 'smooth',
            });
          }, 350);
        }}
        placeholder="활동에 대한 설명을 추가해주세요."
        onClick={() => {
          closeListItemFormFieldQuerySelector([
            'meetupTimeAt',
            'hasMeetupTimeAtOnlyDate',
            'creationCycle',
            'maximumParticipantsNumber',
          ]);
        }}
      />
      <Divider padding={0} />
      <GroupMeetupFormImagesField name="images" setValue={setValue} />
    </div>
  );
};
