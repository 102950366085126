import {
  GroupDetailPresentation,
  GroupMemberRoleEnum,
} from '@community-group/api/lib/group/models';
import {
  ActionList,
  ActionListItem,
  ActionSheet,
  Divider,
  useBottomSheet,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { useCallback, useMemo } from 'react';

import { useGetGroupFeedSummaryMember } from '@/api/hooks/useGetGroupFeedSummaryMember';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { openGroupProfileReport } from '@/utils/link';

import GroupDetailSummaryMoreButton from '../GroupDetailSummaryMoreButton';
import MemberSummarySection from './MemberSummarySection';

type Props = {
  group: GroupDetailPresentation;
  currentUserId: number;
  currentUserRole: GroupMemberRoleEnum;
};

const GroupDetailMemberSummary = ({ group, currentUserId, currentUserRole }: Props) => {
  const { data } = useGetGroupFeedSummaryMember({ groupId: group.id });
  const groupId = useMemo(() => group.id.toString(), [group.id]);

  const activity = useFlow();
  const bottomSheet = useBottomSheet();

  const handleMemberListItemClick = useCallback(
    (memberId: number, memberRole: GroupMemberRoleEnum) => (e) => {
      e.stopPropagation();
      e.preventDefault();

      trackEvent({
        event: 'click_member_profile',
        params: {
          groupId: groupId,
          groupName: group.name,
          clickedUserId: memberId,
          clickedUserRole: memberRole,
          groupCategoryName: group.category.name,
          isGroupCategoryOn: group.isBoardManaged,
          role: currentUserRole,
          version: '2',
        },
        sample: true,
      });

      activity.push('GroupUserProfileDetailPage', {
        groupId,
        userId: memberId.toString(),
      });
    },
    [activity, currentUserRole, group.category.name, group.isBoardManaged, group.name, groupId]
  );
  const handleMemberActionButtonClick = useCallback(
    (memberId) => (e) => {
      e.stopPropagation();
      e.preventDefault();

      const handleReportMenuClick = async () => {
        await bottomSheet.closeAsync();
        openGroupProfileReport({
          groupId,
          userId: memberId.toString() ?? '',
        });
      };
      bottomSheet.open({
        element: (
          <ActionSheet
            bottomButton={{
              label: '닫기',
            }}
          >
            <ActionList>
              <ActionListItem onClick={handleReportMenuClick}>신고</ActionListItem>
            </ActionList>
          </ActionSheet>
        ),
      });
    },
    [bottomSheet, groupId]
  );

  if (!data) return null;

  return (
    <>
      <Divider padding={0} height={8} />
      <MemberSummarySection
        totalCount={data.totalCount}
        members={data.members}
        currentUserId={currentUserId}
        currentUserRole={currentUserRole}
        onMemberListItemClick={handleMemberListItemClick}
        onMemberActionButtonClick={handleMemberActionButtonClick}
      >
        <GroupDetailSummaryMoreButton
          group={group}
          summaryType="members"
          currentUserRole={currentUserRole}
        />
      </MemberSummarySection>
    </>
  );
};

export default withAsyncBoundary(GroupDetailMemberSummary, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
