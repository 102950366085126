import { BoxButton, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './index.css';

const AGORA_TAB_IMG =
  'https://assetstorage.krrt.io/1025317940251365954/721043bf-f393-454e-b9e8-aa3940341834/width=1029,height=480.png';

//TODO: 8~9월 운동 모임 가입 본 마케팅 프로모션 바텀시트, 종료 후 제거 가능
//당근레이스 참여 완료 바텀시트
const DoneJoinGroupMarketingPromotionBottomSheet: ActivityComponentType = () => {
  const { groupId } = useQueryParams();

  useEnterTrackEvent({
    event: 'enter_join_group_marketing_promotion_bottom_sheet',
    params: {
      groupId,
    },
  });

  const [_, setMarketingPromotionBottomSheet] = useStorage(
    'promotion_group_2408_exercise_join',
    false
  );
  const { pop } = useFlow();

  return (
    <BottomSheet
      onOutsideClick={() => {
        setMarketingPromotionBottomSheet(true);
        pop();
      }}
      style={{
        padding: '1.5rem 1rem .5rem 1rem',
      }}
    >
      <div className={s.BottomSheetWrapper}>
        <Typography typography="title2Bold">참가 완료되었어요!</Typography>
        <Spacing height={6} />
        <Typography
          typography="subtitle1Regular"
          textAlign="center"
          color="gray700"
          style={{
            whiteSpace: 'pre-wrap !important',
          }}
        >
          내가 참가한 모임은
          <br />
          &apos;동네생활&apos; 탭 상단에서 다시 확인할 수 있어요.
        </Typography>
        <Spacing height={16} />
        <img src={AGORA_TAB_IMG} className={s.Image} />
        <Spacing height={28} />
        <BoxButton
          variant="primary"
          width="100%"
          size="xlarge"
          onClick={() => {
            setMarketingPromotionBottomSheet(true);
            pop();
          }}
        >
          확인
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default DoneJoinGroupMarketingPromotionBottomSheet;
