import { groupClient } from '@community-group/api';
import { PostCreateForm } from '@community-group/api/lib/group/models';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { getPostPath } from '../base/post';
import { queryClient } from './../instance/index';
import { useFetchInstance } from './instance/useFetchInstance';
import { getGroupMainProfileQueryKey } from './useGetGroupMainProfile';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type Props = UseMutationOptions<groupClient.model.PostResponse, Error, PostCreateForm>;

export const usePostArticle = ({ onError, onSuccess: handleSuccess }: Props) => {
  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  const fetchInstance = useFetchInstance();

  const postArticle = async ({
    groupId,
    ...requestData
  }: groupClient.model.PostCreateForm & { groupId: string }) => {
    const { data } = await fetchInstance.post<
      groupClient.model.PostCreateForm,
      AxiosResponse<groupClient.model.PostResponse>
    >(getPostPath(groupId), {
      ...requestData,
    });

    return data;
  };

  return useThrottleMutation(postArticle, {
    onError,
    onSuccess: (data, variables, context) => {
      // 참고: https://daangn.slack.com/archives/C0324MBCA9Z/p1661771563885839
      queryClient.setQueryData([getPostPath(variables.groupId)], (prevData) => {
        const { post } = data;

        if (prevData) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { pageParams, pages } = prevData;

          return {
            pages: [
              {
                data: {
                  ...pages[0].data,
                  posts: [
                    {
                      ...post,
                    },
                    ...pages[0].data.posts,
                  ],
                },
              },
            ],
            pageParams,
          };
        }
      });

      queryClient.invalidateQueries({
        queryKey: getGroupMainProfileQueryKey(data.post.author.id, parseInt(variables.groupId)),
      });

      handleSuccess?.(data, variables, context);

      mutateEventLogLocation({
        eventName: 'create_group_post',
        groupId: parseInt(variables.groupId),
      });
    },
  });
};
