import { Spacing, ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import FixedBottomSection from '../../Detail/components/FixedBottomSection';
import useGetIsExistHeroSlider from '../../Detail/hooks/useGetIsExistHeroSlider';
import GroupDetailAppBarMenu from '../components/GroupDetailAppBarMenu';
import GroupDetailAppScreen from '../components/GroupDetailAppScreen';
import GroupDetailForNotMember from '../components/GroupDetailForNotMember';
import GroupDetailHeader from '../components/GroupDetailHeader';
import GroupDetailPreviewPageButton from '../components/GroupDetailPreviewPageButton';
import PreviewPageGuideBanner from '../components/PreviewPageGuideBanner';

type GroupDetailPageParams = Pick<
  PageParams,
  | 'groupId'
  | 'activeTab'
  | 'isNew'
  | 'from'
  | 'requestMeetupReviewId'
  | 'boardCategoryKey'
  | 'applicationStatus'
  | 'openSidebar'
>;

const GroupDetailPreviewPage: ActivityComponentType<GroupDetailPageParams> = () => {
  const { groupId } = usePathParams();
  const { data: groupMe } = useGetGroupMe(groupId);
  const isExistHeroSlider = useGetIsExistHeroSlider({ groupId, onlyPublic: true });
  const { group } = useGetGroupDetail(groupId);
  const currentUserRole = useMemo(() => groupMe?.currentUser?.role ?? 'none', [groupMe]);
  const hideHeroImage = useMemo(() => Boolean(group?.backgroundImage.isDefault), [group]);

  if (!group) {
    return <ViewLoader />;
  }

  return (
    <GroupDetailAppScreen
      isExistHeroSlider={isExistHeroSlider}
      title={group.name}
      transparent={isExistHeroSlider}
      appendRight={({ iconColor }) => (
        <GroupDetailAppBarMenu
          group={group}
          currentUserRole={currentUserRole}
          iconColor={iconColor}
        />
      )}
    >
      <GroupDetailPreviewPageButton />
      <GroupDetailHeader
        onlyPublic
        groupId={group.id}
        title={group.name}
        postCount={group.postCount}
        memberCount={group.memberCount}
        profileImageUrl={group.profileImage.medium}
        lastActivatedAt={group.lastActivatedAt}
        onClickTitleText={() => {}}
        isExistHeroSlider={isExistHeroSlider}
        role={currentUserRole}
        heroImageUrl={hideHeroImage ? undefined : group.backgroundImage.medium}
      >
        <PreviewPageGuideBanner />
      </GroupDetailHeader>
      {groupMe?.currentUser && (
        <GroupDetailForNotMember
          group={group}
          currentUser={{ ...groupMe.currentUser, role: 'none' }}
        />
      )}
      <Spacing height={80} />
      <FixedBottomSection />
    </GroupDetailAppScreen>
  );
};

export default withAsyncBoundary(GroupDetailPreviewPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
