import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';

import { isDuringPreJoinGroupMarketingPromotion } from '../utils';

//8~9월 운동 마케팅 사전신청 프로모션 관련 분기용 유틸
function usePreJoinGroupMarketingPromotion({ groupId }: { groupId: string }) {
  const { group } = useGetGroupDetail(groupId);
  const [marketingPromotionBottomSheet] = useStorage(
    'promotion_group_2408_exercise_pre_join',
    false
  );
  const { isExerciseMarketingPreJoinable } = useStore();
  const { push } = useFlow();

  const isOngoingPreExerciseGroupJoinMarketingPromotion = useFeatureFlag(
    'preExerciseGroupJoinMarketingPromotion'
  );

  const handleOpenPreJoinMarketingPromotionBottomSheet = (
    promotionType: 'approval' | 'join_direct'
  ) => {
    //피쳐플래그 오픈 여부 확인
    if (!isOngoingPreExerciseGroupJoinMarketingPromotion) return;

    // 사전신청 프로모션 페이지에서 넘어왔는지 여부 체크
    if (!isExerciseMarketingPreJoinable) return;

    // 이미 오픈되었는지 여부 확인
    if (marketingPromotionBottomSheet) return;

    // 사전신청 기간인지 여부 확인
    if (!isDuringPreJoinGroupMarketingPromotion()) return;

    // 운동 모임인지 여부 확인
    if (group?.category.id !== 1) return;

    setTimeout(() => {
      push('BottomSheet/PreJoinMarketingPromotionBottomSheet', {
        promotion_type: promotionType,
        groupId,
        groupName: group.name,
      });
    }, 1000);
    return true;
  };

  return { handleOpenPreJoinMarketingPromotionBottomSheet };
}

export default usePreJoinGroupMarketingPromotion;
