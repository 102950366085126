import { CommentRelatedContentTypeEnum } from '@community-group/api/lib/group/models';
import { AsyncBoundary, Spacing, Typography, ViewLoader } from '@community-group/components';
import { IconChevronLeftRegular } from '@seed-design/icon';
import { ActivityComponentType, useActivity } from '@stackflow/react';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { useGetComments } from '@/api/hooks/useGetComments';
import { useGetCommentTotalCount } from '@/api/hooks/useGetCommnetTotalCount';
import EmptySection from '@/components/common/Empty';
import { LoadMoreListContainer } from '@/components/common/LoadMoreContainer';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import CommentFormAccessoryBar from '../../CommentFormAccessoryBar';
import CommentList from '../components/CommentList';
import * as s from './index.css';

type Params = Pick<
  PageParams,
  'groupId' | 'relatedId' | 'relatedContentType' | 'commentCount' | 'groupName'
>;

const CommentListPage: ActivityComponentType<Params> = () => {
  const { replace, pop } = useFlow();
  const { isRoot } = useActivity();
  const { groupId = '', relatedId, relatedContentType } = usePathParams();
  const { commentCount, groupName } = useQueryParams();
  const [commentTotalCount, setCommentTotalCount] = useState(
    commentCount ? Number(commentCount) : 0
  );
  const bottomRef = useRef<HTMLDivElement>(null);

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (isRoot) {
      if (relatedContentType === 'meetup') {
        replace('GroupMeetupDetailPage', {
          groupId,
          meetupId: relatedId,
        });
      } else {
        replace('GroupPostDetailPage', {
          groupId,
          postId: relatedId,
        });
      }

      return;
    } else {
      pop();
    }
  };

  return (
    <AppScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconChevronLeftRegular />,
          onClick: handleBackButtonClick,
        },
        backButton: {
          renderIcon: () => <IconChevronLeftRegular />,
          onClick: handleBackButtonClick,
        },
        title: (
          <div className={s.Title}>
            <Typography typography="subtitle1Bold" color="gray900">
              댓글{commentTotalCount > 0 ? ` ${commentTotalCount}` : ''}
            </Typography>
            <Typography typography="caption2Regular" color="gray600" ellipsisAfterLines={1}>
              {groupName}
            </Typography>
          </div>
        ),
      }}
      accessoryBar={
        <CommentFormAccessoryBar
          listRef={bottomRef}
          groupId={groupId}
          relatedId={relatedId}
          relatedContentType={relatedContentType}
        />
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <CommentListPageCore
          groupId={groupId}
          relatedId={relatedId}
          relatedContentType={relatedContentType}
          setTotalCommentCount={setCommentTotalCount}
        />
        <div ref={bottomRef} className={s.Bottom} />
      </AsyncBoundary>
    </AppScreen>
  );
};

type Props = {
  groupId: string;
  relatedId: string;
  relatedContentType: CommentRelatedContentTypeEnum;
  setTotalCommentCount: Dispatch<SetStateAction<number>>;
};

const CommentListPageCore = ({
  groupId,
  relatedId,
  relatedContentType,
  setTotalCommentCount,
}: Props) => {
  const {
    data: commentsData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetComments({ groupId, relatedId, relatedContentType });
  const { data: totalCountData } = useGetCommentTotalCount({
    groupId,
    relatedId,
    relatedContentType,
  });
  const comments = commentsData?.pages.flatMap((page) => page.data.comments) ?? [];

  useEffect(() => {
    setTotalCommentCount(totalCountData?.totalCount ?? 0);
  }, [totalCountData, setTotalCommentCount]);

  if (comments.length === 0) {
    return (
      <>
        <Spacing height={38} />
        <EmptySection>
          아직 댓글이 없어요.
          <br />
          가장 먼저 댓글을 남겨보세요.
        </EmptySection>
      </>
    );
  }

  return (
    <CommentList
      groupId={groupId}
      relatedId={relatedId}
      relatedContentType={relatedContentType}
      comments={comments}
    >
      {hasNextPage && !isFetchingNextPage && <LoadMoreListContainer callback={fetchNextPage} />}
    </CommentList>
  );
};

export default CommentListPage;
