import {
  AsyncBoundary,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo } from 'react';

import { useGetMe } from '@/api/hooks/useGetMe';
import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';
import { Container } from '@/components/common/Container';
import { MemberProfileMeetupMemberReviews } from '@/components/common/MemberProfileMeetupMemberReviews';
import { Spacing } from '@/components/common/Spacing';
import { MEETUP_FEEDBACK_TYPES } from '@/components/group/Meetup/Review/constant/reviewOptions';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { LayoutWrapper } from '@/stackflow/components/Layout';

import MyRequestDirectChatPageAppBarRightButton from '../../../MainProfile/Components/MyRequestDirectChatPageAppBarRightButton';
import Information from '../../../MemberProfileDetail/components/Information';
import { SnsTagSection } from '../components/SnsTagSection';
import * as s from './index.css';

// 본인 프로필 페이지 (홈 > 우측 상단 프로필)
const GroupProfileDetailPage: ActivityComponentType = () => {
  return (
    <AppScreen
      appBar={{
        title: '모임 프로필',
        renderRight: () => {
          return <MyRequestDirectChatPageAppBarRightButton />;
        },
        borderSize: '0.5px',
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<></>}>
        <ProfileWrapper />
      </AsyncBoundary>
    </AppScreen>
  );
};

const ProfileWrapper = () => {
  const { data: profile } = useGetMyMainProfile({});
  const { data: me } = useGetMe();

  const { push } = useFlow();

  useEnterTrackEvent({
    event: 'enter_integreated_group_profile_page',
    params: {
      userId: profile?.id,
      nickname: profile?.nickname,
    },
    sample: true,
  });

  const isRenderMeetupMemberReviews = useMemo(() => {
    const filteredPositiveMeetupMemberReviews = (profile?.meetupMemberReviews ?? []).filter(
      ({ feedbackTypeId }) => MEETUP_FEEDBACK_TYPES.LIKE.get(feedbackTypeId)
    );

    return filteredPositiveMeetupMemberReviews.length > 0;
  }, [profile]);

  return (
    <LayoutWrapper>
      <Spacing height={24} />
      <Container paddingY={0}>
        <Information
          profile={{
            id: profile?.id ?? 0,
            state: 'notMember',
            profileImage: profile?.profileImage ?? '',
            nickname: profile?.nickname ?? '',
            temperature: profile?.temperature ?? 0,
            description: profile?.description ?? '',
            role: 'none',
            joinedAt: '',
            userRegion: profile?.userRegion ?? {
              id: 0,
              name: '',
              province: '',
              city: '',
              town: '',
            },
            verified: profile?.verified ?? false,
            userRegionCheckInCount: profile?.userRegionCheckInCount ?? 0,
          }}
        />
      </Container>
      <Spacing height={16} />
      <SnsTagSection snsTags={profile?.snsTags} isMyProfile={true} />
      {me.id === profile?.id && (
        <>
          <div className={s.buttonWrapper}>
            <BoxButton
              size="medium"
              variant="secondary"
              UNSAFE_style={{ width: '100%' }}
              onClick={() => {
                push('GroupProfileEditPage', {});
              }}
            >
              모임 프로필 수정
            </BoxButton>
          </div>
        </>
      )}
      {isRenderMeetupMemberReviews && (
        <MemberProfileMeetupMemberReviews
          userId={(profile?.id ?? '').toString()}
          isMyProfile={true}
          meetupMemberReviews={profile?.meetupMemberReviews ?? []}
          meetupMemberReviewMessages={profile?.meetupMemberReviewMessages ?? []}
        />
      )}
    </LayoutWrapper>
  );
};

export default withAsyncBoundary(GroupProfileDetailPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: <ViewError />,
});
