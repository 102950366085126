import { AsyncBoundary, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { usePutGroup } from '@/api/hooks/usePutGroup';
import Chip from '@/components/common/base/Chip';
import FormBadgeRadio, { BadgeRadioWrapper } from '@/components/common/base/Input/Radio/Badge';
import { FormContainer } from '@/components/common/Form';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { Spacing } from '@/components/common/Spacing';
import { AgeRangeHandlerProps } from '@/components/group/new/RecruitmentCondition/ageRange';
import AgeRangeInputWrapper from '@/components/group/new/RecruitmentCondition/components/age-range-input-wrapper';
import { AGE_RANGE_OPTIONS } from '@/components/group/new/RecruitmentCondition/constants/age-range-constants';
import useValidAgeRangeInput from '@/components/group/new/RecruitmentCondition/hooks/use-validate-age-range-input';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchGroupSetting } from '@/utils/refetch/groupSetting';

export type GroupSettingEditGroupAgeRangeBottomSheetParams = Pick<
  PageParams,
  'groupId' | 'initialGroupMinAge' | 'initialGroupMaxAge'
>;

const GroupSettingEditGroupAgeRangeBottomSheet: ActivityComponentType<
  GroupSettingEditGroupAgeRangeBottomSheetParams
> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <GroupSettingEditGroupAgeRangeBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const GroupSettingEditGroupAgeRangeBottomSheetWrapper = () => {
  const { groupId } = usePathParams();
  const { initialGroupMinAge, initialGroupMaxAge } = useQueryParams();

  const { pop } = useFlow();

  const initAgeRangeValue = {
    minAge: initialGroupMinAge ? Number(initialGroupMinAge) : undefined,
    maxAge: initialGroupMaxAge ? Number(initialGroupMaxAge) : undefined,
  };

  const initSelectedChipId = () => {
    const selectedIndex = AGE_RANGE_OPTIONS.map((option, index) => {
      if (initAgeRangeValue.minAge === undefined || initAgeRangeValue.maxAge === undefined)
        return 0;

      if (
        option.value?.minAge === initAgeRangeValue.minAge &&
        option.value?.maxAge === initAgeRangeValue.maxAge
      )
        return index;

      return;
    }).filter((element) => element !== undefined);

    if (selectedIndex.length === 0) return AGE_RANGE_OPTIONS.length - 1;

    return selectedIndex[0];
  };

  const [selectedChip, setSelectedChip] = useState(initSelectedChipId());
  const { register, watch, setValue } = useForm<FieldValues>({
    defaultValues: {
      ageRange: initAgeRangeValue,
    },
  });

  const ageRangeFieldValue = watch('ageRange');
  const { validateMinValue, validateMaxValue } = useValidAgeRangeInput({ watch });

  const handleSelectChip = ({ id, value }: AgeRangeHandlerProps) => {
    setSelectedChip(id);
    setValue('ageRange', value);
  };
  const { bridge } = useBridge();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isLoading } = usePutGroup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      refetchGroupSetting({ groupId });

      bridge.openToast({
        toast: {
          body: '수정되었어요.',
        },
      });
    },
  });

  const handleSubmit = () => {
    const payload = {
      groupTag: {
        tags:
          ageRangeFieldValue.minAge && ageRangeFieldValue.maxAge
            ? [
                {
                  type: 'AGE',
                  min: ageRangeFieldValue.minAge,
                  max: ageRangeFieldValue.maxAge,
                },
              ]
            : [],
      },
    };

    mutate(
      {
        id: groupId,
        groupModifyForm: payload,
      },
      {
        onSuccess: () => {
          pop();
        },
      }
    );
  };

  const isDisableButton = useMemo(() => {
    const ALL_AGES_CHIP_ID = 0;

    if (selectedChip !== ALL_AGES_CHIP_ID) {
      if (!validateMinValue().isValid) return true;
      if (!validateMaxValue().isValid) return true;
    }

    if (selectedChip === initSelectedChipId()) {
      if (selectedChip === ALL_AGES_CHIP_ID) return true;

      if (
        ageRangeFieldValue.minAge === initAgeRangeValue.minAge &&
        ageRangeFieldValue.maxAge === initAgeRangeValue.maxAge
      )
        return true;
    }

    return false;
  }, [selectedChip, validateMinValue()]);

  return (
    <>
      <Spacing height={16} />
      <Typography typography="title3Bold" color="gray900">
        나이대
      </Typography>
      <Spacing height={16} />
      <FormContainer>
        <BadgeRadioWrapper>
          {AGE_RANGE_OPTIONS.map((item) => (
            <FormBadgeRadio
              key={item.id}
              value={item.id}
              register={register}
              name="category"
              onClick={() => handleSelectChip({ id: item.id, value: item.value })}
              checked={item.id === selectedChip}
            >
              <Chip text={item.name} />
            </FormBadgeRadio>
          ))}
        </BadgeRadioWrapper>

        <AgeRangeInputWrapper
          watch={watch}
          setValue={setValue}
          disable={selectedChip !== AGE_RANGE_OPTIONS.length - 1}
        />
      </FormContainer>
      <Spacing height={16} />
      <FixedButton disabled={isDisableButton} onClick={handleSubmit} isLoading={isLoading}>
        수정
      </FixedButton>
    </>
  );
};

export default GroupSettingEditGroupAgeRangeBottomSheet;
