import React, { useRef, useState } from 'react';

import * as s from './HiddenVideoPlayerWithVideoPlayInfoButton.css';
import VideoPlayer from './VideoPlayer';
import VideoPlayInfoButton from './VideoPlayInfoButton';

type Props = {
  videoUrl: string;
  durationText: string;
};

function HiddenVideoPlayerWithVideoPlayInfoButton({ videoUrl, durationText }: Props) {
  const [playerState, setPlayerState] = useState<'loading' | 'ready' | 'playing' | 'paused'>(
    'loading'
  );
  const [isFullscreen, setIsFullscreen] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      videoRef.current.requestFullscreen();
      setIsFullscreen(true);

      const fullscreenchangeHandler = () => {
        if (document.fullscreenElement === null) {
          setIsFullscreen(false);
          videoRef.current?.pause();
        }
      };

      videoRef.current.addEventListener('fullscreenchange', fullscreenchangeHandler);

      return () =>
        videoRef.current?.removeEventListener('fullscreenchange', fullscreenchangeHandler);
    }
  };

  return (
    <div className={s.HiddenVideoPlayerWrapper} onClick={handlePlayVideo}>
      <div className={s.VideoWrapper}>
        <VideoPlayer
          controls
          src={videoUrl}
          ref={videoRef}
          onCanPlay={() => {
            if (playerState === 'loading') {
              setPlayerState('ready');
            }
          }}
          style={{
            display: isFullscreen ? 'block' : 'none',
          }}
          onPlay={() => {
            setPlayerState('playing');
          }}
          onPause={() => {
            setPlayerState('paused');
          }}
        />
      </div>
      <VideoPlayInfoButton durationText={durationText} />
    </div>
  );
}

export default HiddenVideoPlayerWithVideoPlayInfoButton;
