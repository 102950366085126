import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { BoxButton, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { memo, MouseEventHandler, useCallback, useMemo, useRef } from 'react';

import { IconShareRegular } from '@/components/common/Icons/shareRegular';
import { VerticalSpacing } from '@/components/common/Spacing';

import * as s from './SidebarFooter.css';

type Props = {
  role: GroupMemberRoleEnum;
  onReportButtonClick: MouseEventHandler;
  onGuideBookButtonClick: MouseEventHandler;
  onShareButtonClick: MouseEventHandler;
};

const SidebarFooter = ({ onShareButtonClick, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const footerSafeAreaHeight = useMemo(() => {
    const defaultFooterHeight = 90;
    return ref.current?.clientHeight ?? defaultFooterHeight;
  }, []);

  const LeftButton = useCallback(
    ({ role, onReportButtonClick, onGuideBookButtonClick }: Omit<Props, 'onShareButtonClick'>) => {
      switch (role) {
        case 'none':
          return (
            <>
              <BoxButton
                variant="secondary"
                size="medium"
                minWidth={65}
                onClick={onReportButtonClick}
              >
                <Typography typography="label3Regular" color="gray800">
                  신고
                </Typography>
              </BoxButton>
              <VerticalSpacing height={8} />
            </>
          );
        case 'superHost':
          return (
            <>
              <BoxButton
                variant="secondary"
                size="medium"
                width="100%"
                onClick={onGuideBookButtonClick}
              >
                <Typography typography="label3Regular" color="gray800">
                  가이드북
                </Typography>
              </BoxButton>
              <VerticalSpacing height={8} />
            </>
          );
        default:
          return null;
      }
    },
    []
  );

  return (
    <>
      <div
        style={{
          height: footerSafeAreaHeight,
          minHeight: footerSafeAreaHeight,
          marginBottom: '-calc(env(safe-area-inset-bottom))',
        }}
      />
      <footer ref={ref} className={s.Footer}>
        <Spacing
          height={24}
          background={`linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, ${vars.$semantic.color.paperDefault} 100%)`}
        />
        <div className={s.ButtonContainer}>
          <LeftButton {...props} />
          <BoxButton
            variant="secondary"
            size="medium"
            width="100%"
            onClick={onShareButtonClick}
            prefix={<IconShareRegular size={16} color={vars.$scale.color.gray500} />}
          >
            <Typography typography="label3Regular" color="gray800">
              공유
            </Typography>
          </BoxButton>
        </div>
      </footer>
    </>
  );
};

export default memo(SidebarFooter);
