import { TypeActivities } from '@/stackflow';

import {
  CREATE_GROUP_DEFAULT_FLOW,
  CREATE_GROUP_FLOW,
  CREATE_GROUP_V2,
} from './createGroupControl';

export const indicatorCountHandler = (
  pageName: keyof TypeActivities,
  flow: CREATE_GROUP_FLOW = 'DEFAULT'
) => {
  const currentFlow = () => {
    switch (flow) {
      case 'DEFAULT':
        return CREATE_GROUP_DEFAULT_FLOW;
      case 'GROUP_V2':
        return CREATE_GROUP_V2;
      default:
        return CREATE_GROUP_DEFAULT_FLOW;
    }
  };

  const maxCount = currentFlow().length - 1;
  const currentCount = currentFlow().findIndex((page) => page.includes(pageName)) + 1;

  return {
    count: currentCount,
    maxCount,
  };
};
