import { Checkbox, Spacing, Typography } from '@community-group/components';
import { useState } from 'react';

import { VerticalSpacing } from '@/components/common/Spacing';
import { trackEvent } from '@/utils/analytics';

import PhotoContestEntryList from './PhotoContestEntryList';
import * as s from './PhotoContestEntrySection.css';

const PhotoContestEntrySection = () => {
  const [localOnly, setLocalOnly] = useState(false);
  const [orderByLatest, setOrderByLatest] = useState(true);

  const handleLocalOnlyChange = () => {
    setLocalOnly((prev) => {
      trackEvent({
        event: 'click_viewrange_filter',
        params: {
          section: 'photoEvent',
          enabled: !prev,
        },
        sample: true,
      });
      return !prev;
    });
  };
  const handleOrderChange = (byLatest: boolean) => () => {
    trackEvent({
      event: 'click_group_photo_event_page_sorting_button',
      params: {
        order: byLatest ? 'latest' : 'popularity',
      },
    });
    setOrderByLatest(byLatest);
  };

  return (
    <>
      <div className={s.TitleContainer}>
        <Typography typography="title3Bold" color="gray900">
          이번주 모임 인증샷
        </Typography>
        <Spacing height={2} />
        <Typography typography="caption1Regular" color="gray600">
          마음에 드는 사진에 공감👍을 눌러 투표해보세요!
        </Typography>
      </div>
      <div className={s.FilterContainer}>
        <div>
          <Checkbox size="medium" isSelected={localOnly} onChange={handleLocalOnlyChange}>
            우리 동네만 보기
          </Checkbox>
        </div>
        <div className={s.SortContainer}>
          <Typography
            typography="subtitle2Bold"
            color={orderByLatest ? 'gray600' : 'gray700'}
            onClick={handleOrderChange(false)}
          >
            인기순
          </Typography>
          <VerticalSpacing height={12} />
          <Typography
            typography="subtitle2Bold"
            color={orderByLatest ? 'gray700' : 'gray600'}
            onClick={handleOrderChange(true)}
          >
            최신순
          </Typography>
        </div>
      </div>
      <PhotoContestEntryList localOnly={localOnly} orderByLatest={orderByLatest} />
    </>
  );
};

export default PhotoContestEntrySection;
