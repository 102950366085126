import { vars } from '@seed-design/design-token';
import React from 'react';

import { trackEvent } from '@/utils/analytics';
import { openLink } from '@/utils/link';

import { HomeBanner } from '../Banner';

function PreJoinExerciseGroupMarketingBanner() {
  const handleClick = () => {
    trackEvent({
      event: 'click_home_banner_notice',
      params: {
        type: 'prejoin_exercise_group_marketing_banner',
      },
      sample: true,
    });

    openLink(
      'karrot://minikarrot/router?remote=https%3A%2F%2Flandkit.krrt.io%2Fpages%2Fstgbws%2F&navbar=true&scrollable=true'
    );
  };

  return (
    <HomeBanner
      description="내 동네에서 달리는 러닝 이벤트"
      title="당근 레이스 COMING SOON"
      image="https://assetstorage.krrt.io/1025317940251365954/e59c7a3e-3789-4ab0-8737-9ea9043aaade/width=216,height=216.png"
      onClick={handleClick}
      style={{
        background: vars.$scale.color.green100,
      }}
    />
  );
}

export default PreJoinExerciseGroupMarketingBanner;
