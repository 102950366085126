import {
  BoardCategoryPresentation,
  GroupCurrentUser,
  GroupDetailPresentation,
} from '@community-group/api/lib/group/models';
import { BoxButton, Spacing, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { IconChevronRightFill } from '@seed-design/icon';
import { useMemo, useState } from 'react';

import { useGetGroupFeedSummary } from '@/api/hooks/useGetGroupFeedSummary';
import { mapGroupFeedToPostDetail } from '@/components/group/Detail/utils/mapGroupFeedToPostDetail';
import FeedSummaryList from '@/components/group/DetailV2/components/GroupDetailFeedSummary/FeedSummaryList';
import GroupDetailSummaryMoreButton from '@/components/group/DetailV2/components/GroupDetailSummaryMoreButton';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import FeedSummarySection from './FeedSummarySection';

type Props = {
  group: GroupDetailPresentation;
  currentUser?: GroupCurrentUser;
};

type BoardCategory = {
  key: number;
  value: string;
};

const DetailNotMemberFeedSummary = ({ group, currentUser }: Props) => {
  const { push } = useFlow();
  const groupId = useMemo(() => group.id.toString(), [group.id]);
  const defaultBoards = useMemo(() => [{ key: 0, value: '전체' }], []);
  const [selectedBoard, setSelectedBoard] = useState<BoardCategory>(defaultBoards[0]);
  const handleBoardChange = (category: BoardCategory) => {
    trackEvent({
      event: 'click_board_category_chip',
      params: {
        groupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUser?.role,
        boardName: category.value,
      },
      sample: true,
    });

    setSelectedBoard(category);
  };

  const { data: feedSummary } = useGetGroupFeedSummary(Number(groupId));
  const boards = useMemo(() => {
    if (!feedSummary) return defaultBoards;

    const customBoards = feedSummary.categoryFeeds
      .filter((item) => {
        return (
          item.boardCategory !== undefined &&
          item.boardCategory.id !== undefined &&
          item.boardCategory.category !== undefined
        );
      })
      .map((feed) => feed.boardCategory)
      .filter((category): category is BoardCategoryPresentation => category !== undefined)
      .map((category) => ({
        key: category.id,
        value: category.category,
      }));

    return [...defaultBoards, ...customBoards];
  }, [feedSummary, defaultBoards]);

  const feeds = useMemo(() => {
    if (!feedSummary) return [];
    switch (selectedBoard.key) {
      case 0: {
        // feedSummary.allFeeds 내에는 모임 일정도 포함되어 있어 전체 게시글 목록은 카테고리 목록을 조합해 사용
        // 배포 이후 인기 게시글 섹션으로 대체할 예정이라 우선 조합해 사용하는 것으로 진행.
        const allPosts = feedSummary.categoryFeeds
          .flatMap((feed) => feed.feeds || [])
          .filter((el) => el !== undefined)
          .sort((prevPost, nextPost) => (prevPost.createdAt < nextPost.createdAt ? 1 : -1));
        return allPosts;
      }
      default: {
        const categoryFeeds = feedSummary.categoryFeeds.find(
          (feed) => feed.boardCategory?.category === selectedBoard.value
        );
        return categoryFeeds?.feeds || [];
      }
    }
  }, [feedSummary, selectedBoard]);

  if (!currentUser) return null;

  return (
    <FeedSummarySection
      boardOptions={boards}
      selectedBoardKey={selectedBoard.key}
      onBoardChange={handleBoardChange}
      totalCount={group.postCount ?? 0}
    >
      <FeedSummaryList
        totalCount={feedSummary?.totalCount ?? 0}
        feeds={feeds.map(mapGroupFeedToPostDetail(group, currentUser)).slice(0, 3)}
        currentUser={currentUser}
      >
        <>
          <Spacing height={8} />
          <GroupDetailSummaryMoreButton
            group={group}
            summaryType="feeds"
            currentUserRole={currentUser.role}
          >
            더보기
          </GroupDetailSummaryMoreButton>
        </>
      </FeedSummaryList>
    </FeedSummarySection>
  );
};

export default withAsyncBoundary(DetailNotMemberFeedSummary, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
