import { useIsomorphicLayoutEffect } from '@community-group/components';
import uniqueId from 'lodash-es/uniqueId';
import React, { useCallback, useMemo, useState } from 'react';

import { Map as MapType, MapOptions } from './maplibre-gl';

export const KarrotMapContext = React.createContext<MapType>(undefined as unknown as MapType);

export type MapProps = {
  mapId: string;
  options?: Partial<MapOptions>;
  config?: {
    renderDelay?: number;
  };
} & React.HTMLAttributes<HTMLDivElement>;

export const Map = React.memo(({ mapId, options, children, config, className }: MapProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [map, setMap] = useState<MapType>(undefined as unknown as MapType);
  const currentMapId = useMemo(() => uniqueId(mapId), [mapId]);

  const loadKarrotMaplibreGl = useCallback(async () => {
    try {
      const currentElement = document.getElementById(currentMapId);
      if (currentElement?.children.length ?? 0 > 0) return;

      const initMap = new window.maplibregl.Map({
        serviceName: 'karrotMap',
        container: currentMapId,
        style: 'https://maps.karroter.net/static/styles/2024-01-24.json',
        zoom: 15,
        maxBounds: [
          [123.3731463104218733, 32.5075706009476022], // Southwest coordinates
          [133.0698818487595645, 39.2083044744925999], // Northeast coordinates
        ],
        pitchWithRotate: false,
        dragRotate: false,
        daangnLogo: false,
        localIdeographFontFamily: false,
        interactive: true,
        minZoom: 7,
        minPitch: 0,
        maxPitch: 0,
        useWebGL2: true,
        ...options,
      });

      setIsLoaded(true);

      setMap(initMap);
    } catch (error) {
      console.log('mapId_f', currentMapId);
      setIsLoaded(false);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (map) return;

    if (config?.renderDelay) {
      setTimeout(() => {
        loadKarrotMaplibreGl();
      }, config.renderDelay);
    } else {
      loadKarrotMaplibreGl();
    }
  }, []);

  return (
    <div>
      <div id={currentMapId} className={className} />
      {isLoaded && map && (
        <KarrotMapContext.Provider value={map}>{children}</KarrotMapContext.Provider>
      )}
    </div>
  );
});

export const useMap = () => {
  const map = React.useContext(KarrotMapContext);

  if (map === undefined) {
    throw new Error('useMap must be used within a Map Components');
  }

  return map;
};

let mapStyle: { default: string } | null = null;
const loadMapStyle = async () => {
  if (!mapStyle) {
    const response = await fetch('https://map.kr.karrotmarket.com/props/style.json');
    mapStyle = (await response.json()) as { default: string };
  }

  return mapStyle;
};
