import { BoxButton, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconConfirmationProfileFill } from '@seed-design/icon';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';
import { VerticalSpacing } from '@/components/common/Spacing';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './GroupInfoSection.css';

const GroupInfoSection = () => {
  const { groupId } = usePathParams();
  const { group: groupDetail } = useGetGroupDetail(groupId);
  const currentUser = useCurrentGroupMe();
  const { push } = useFlow();
  const { isGroupV2 = 'false' } = useQueryParams();

  const needVerifiedGroup = useMemo(() => {
    return groupDetail?.needVerification;
  }, [groupDetail?.needVerification]);

  const verifiedInfoText = useMemo(() => {
    if (!needVerifiedGroup) return '';

    let renderText = '본인인증 모임';

    if (groupDetail?.canViewVerifiedUserInfo) renderText += ' ∙ 성별 및 나이대 공개';

    return renderText;
  }, [groupDetail?.canViewVerifiedUserInfo, needVerifiedGroup]);

  return (
    <div className={s.SectionWrapper}>
      <img
        className={s.GroupProfileImage}
        src={groupDetail?.profileImage.medium}
        alt="모임 프로필 이미지"
      />
      <Spacing height={16} />
      <Typography typography="title3Bold" color="gray900" className={s.TextWrapper}>
        {groupDetail?.name}
      </Typography>
      <Spacing height={2} />
      <Typography typography="caption1Regular" color="gray600" className={s.TextWrapper}>
        {groupDetail?.groupCreatedRegion.name}
        {' ∙ '}
        {dayjs(groupDetail?.createdAt).format('YYYY.MM.DD')} 개설
      </Typography>

      {needVerifiedGroup && (
        <div className={s.TextBox}>
          <IconConfirmationProfileFill size={16} color={vars.$scale.color.green500} />
          <Typography typography="label3Regular" color="gray800">
            {verifiedInfoText}
          </Typography>
        </div>
      )}
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <Spacing height={16} />
        <div className={s.ButtonContainer}>
          {isGroupV2 === 'true' && (
            <>
              <BoxButton
                width="100%"
                variant="secondary"
                size="medium"
                onClick={() => {
                  push('GroupDetailPreviewPage', { groupId });
                }}
              >
                미리보기
              </BoxButton>
              <VerticalSpacing height={8} />
            </>
          )}
          <BoxButton
            width="100%"
            variant="secondary"
            size="medium"
            onClick={() => {
              push('GroupSettingEditGroupInfoPage', { groupId });
            }}
          >
            모임 정보 수정
          </BoxButton>
        </div>
      </CheckAccessibleRole>
    </div>
  );
};

export default GroupInfoSection;
