import { memo } from 'react';

import pxToRem from '../../utils/pxToRem';

type Props = {
  size: number;
  fill?: string;
  direction?: 'horizontal' | 'vertical';
};

const Spacer = ({ size, fill = 'transparent', direction = 'horizontal' }: Props) => {
  switch (direction) {
    case 'vertical':
      return (
        <div
          aria-hidden
          style={{
            minWidth: `${pxToRem(size)}rem`,
            minHeight: '100%',
            backgroundColor: fill,
          }}
        />
      );
    default:
    case 'horizontal':
      return (
        <div
          aria-hidden
          style={{
            minWidth: '100%',
            minHeight: `${pxToRem(size)}rem`,
            backgroundColor: fill,
          }}
        />
      );
  }
};

export const VerticalSpacer = memo((props: Omit<Props, 'direction'>) => (
  <Spacer direction="vertical" {...props} />
));

export const HorizontalSpacer = memo((props: Omit<Props, 'direction'>) => (
  <Spacer direction="horizontal" {...props} />
));

export default Spacer;
