import { Spacing, Typography } from '@community-group/components';

export const GroupCreateProfileHeader = () => {
  return (
    <>
      <Spacing height={16} />
      <Typography typography="title1Bold" color="gray900">
        먼저 모임 프로필을 <br />
        만들어주세요
      </Typography>
      <Spacing height={12} />
      <Typography typography="bodyL1Regular" color="gray900">
        모든 모임에서 동일하게 사용하는 프로필이에요.
      </Typography>
    </>
  );
};
