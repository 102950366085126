import { Category } from '@community-group/api/lib/group/models';
import { useBottomSheet } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { Dispatch, SetStateAction } from 'react';
import { $Values } from 'utility-types';

import { ORDER_SEARCH, ORDER_SEARCH_KR_TYPO } from '@/api/base/group';
import { useGetCategories } from '@/api/hooks/useGetCategories';
import ChevronDown from '@/components/common/Icons/chevronDown';
import Retry from '@/components/common/Icons/retry';
import { VerticalSpacing } from '@/components/common/Spacing';

import SortingFilterBottomSheet from '../../bottomSheets/SortingFilterBottomSheet';
import CategoryFilterListBottomSheet from './CategoryFilterListBottomSheet';
import * as s from './searchResultFilter.css';

export interface SearchResultGroupFilterProps {
  selectedCategoryList: Category[];
  setSelectedCategoryList: Dispatch<SetStateAction<Category[]>>;
  selectedSorting: $Values<typeof ORDER_SEARCH>;
  setSelectedSorting: Dispatch<SetStateAction<$Values<typeof ORDER_SEARCH>>>;
}

const SearchResultGroupFilter = ({
  selectedCategoryList,
  setSelectedCategoryList,
  selectedSorting,
  setSelectedSorting,
}: SearchResultGroupFilterProps) => {
  const { open: openBottomSheet } = useBottomSheet();

  const { categories } = useGetCategories();

  const hasSelectedCategory = selectedCategoryList.length > 0;

  const categoryButtonText = () => {
    if (selectedCategoryList.length === 0) {
      return '카테고리 선택';
    }

    if (selectedCategoryList.length === 1) {
      return selectedCategoryList[0].name;
    }

    return `${selectedCategoryList[0].name} 외 ${selectedCategoryList.length - 1}개`;
  };

  const handleCategoryClick = () => {
    openBottomSheet({
      element: (
        <CategoryFilterListBottomSheet
          categoryList={categories}
          selectedCategoryList={selectedCategoryList}
          setSelectedCategoryList={setSelectedCategoryList}
        />
      ),
    });
  };

  const handleSortingClick = () => {
    openBottomSheet({
      element: (
        <SortingFilterBottomSheet
          selectedSorting={selectedSorting}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setSelectedSorting={setSelectedSorting}
        />
      ),
    });
  };

  return (
    <div className={s.Wrapper}>
      {hasSelectedCategory && (
        <button
          className={s.ResetButton}
          onClick={() => {
            setSelectedCategoryList([]);
          }}
        >
          <Retry />
        </button>
      )}
      <button
        className={s.FilterButtonRecipe({
          active: hasSelectedCategory,
        })}
        onClick={handleCategoryClick}
      >
        {categoryButtonText()}
        <div className={s.IconWrapper}>
          <ChevronDown
            strokeColor={hasSelectedCategory ? vars.$scale.color.gray00 : vars.$scale.color.gray900}
          />
        </div>
      </button>
      <VerticalSpacing height={8} />
      <button className={s.FilterButtonRecipe({})} onClick={handleSortingClick}>
        {ORDER_SEARCH_KR_TYPO[selectedSorting]}
        <div className={s.IconWrapper}>
          <ChevronDown />
        </div>
      </button>
    </div>
  );
};

export default SearchResultGroupFilter;
