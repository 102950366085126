import { groupClient } from '@community-group/api';
import { useQuery } from '@tanstack/react-query';

import { useFetchInstance } from './instance/useFetchInstance';

export const getGroupFeedSummaryMember = (groupId: number) =>
  groupClient.api.GroupFeedApi.getapiV1GroupsIdFeedSummaryMemberGetPath(groupId);

export const useGetGroupFeedSummaryMember = ({
  groupId,
  limit,
}: {
  groupId: number;
  limit?: number;
}) => {
  const fetchInstance = useFetchInstance();
  const get = groupClient.api.GroupFeedApi.apiV1GroupsIdFeedSummaryMemberGet({
    axios: fetchInstance,
  });

  const { data, refetch } = useQuery(
    [getGroupFeedSummaryMember(groupId)],
    () => get(groupId, limit),
    {
      suspense: true,
    }
  );
  const response = data?.data;

  return { data: response, refetch };
};
