import { GroupAgeRangeTagPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCalendarFill, IconConfirmationFill, IconProfileFill } from '@seed-design/icon';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { getAgeDisplayText } from '@/components/common/TagLabel/AgeLabel';

import * as s from './GroupDetailInfo.css';

type Props = {
  createdAt: string;
  ageRangeTag?: GroupAgeRangeTagPresentation;
  canViewVerifiedUserInfo: boolean;
  needVerification: boolean;
};

const GroupDetailInfo = ({
  createdAt,
  ageRangeTag,
  needVerification,
  canViewVerifiedUserInfo,
}: Props) => {
  const ageRangeDisplayText = useMemo(() => {
    if (!ageRangeTag) return null;
    const { minAge, maxAge } = ageRangeTag;
    const displayText = getAgeDisplayText({ min: minAge, max: maxAge });
    return displayText;
  }, [ageRangeTag]);

  const verifyAgeRangeDisplayText = useMemo(() => {
    const text: string[] = [];
    if (needVerification) text.push('본인인증 필요');
    if (canViewVerifiedUserInfo) text.push('나이대 공개');

    if (text.length === 0) return null;
    return text.join(', ');
  }, [canViewVerifiedUserInfo, needVerification]);

  return (
    <div className={s.ListWrapper}>
      {createdAt && (
        <div className={s.RowWrapper}>
          <div className={s.RowIconWrapper}>
            <IconCalendarFill size={16} color={vars.$scale.color.gray600} />
          </div>
          <Typography typography="label2Regular" color="gray800">
            {dayjs(createdAt).format('YYYY년 M월 D일')} 개설
          </Typography>
        </div>
      )}
      {ageRangeDisplayText && (
        <div className={s.RowWrapper}>
          <div className={s.RowIconWrapper}>
            <IconProfileFill size={16} color={vars.$scale.color.gray600} />
          </div>
          <Typography typography="label2Regular" color="gray800">
            {ageRangeDisplayText} 모집
          </Typography>
        </div>
      )}
      {verifyAgeRangeDisplayText && (
        <div className={s.RowWrapper}>
          <div className={s.RowIconWrapper}>
            <IconConfirmationFill size={16} color={vars.$scale.color.gray600} />
          </div>
          <Typography typography="label2Regular" color="gray800">
            {verifyAgeRangeDisplayText}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default GroupDetailInfo;
