import { Container, Dialog, Spacing, useDialog } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { UseFormReturn } from 'react-hook-form';

import { usePathParams } from '@/stackflow/hooks/usePathParams';

import { PollFormActivityParamsJSON } from '../../Poll/pages';
import PollPreview from '../../Poll/PollPreview';
import { GroupPostFormHandlerProps } from './GroupPostForm';

export interface GroupPostFormPollProps {
  formHandler: UseFormReturn<GroupPostFormHandlerProps>;
}

export const GroupPostFormPoll = ({ formHandler }: GroupPostFormPollProps) => {
  const { open: openDialog, close: closeDialog } = useDialog();
  const { push } = useConnectedActions();
  const { watch, setValue, getValues } = formHandler;
  const { groupId } = usePathParams();

  return (
    <Container paddingX={16} paddingY={0}>
      <PollPreview
        readOnly={false}
        title={watch('poll')?.title ?? ''}
        isMultiple={watch('poll')?.isMultiple ?? false}
        pollOptionTitles={watch('poll')?.options.map((pollOption) => pollOption.title) ?? []}
        onEditClick={async () => {
          const poll = getValues('poll');

          const pollValues = (await push('GroupPollFormPage', {
            groupId,
            pollOptionValueJSONString: JSON.stringify({
              title: poll?.title ?? '',
              pollOptionTitles: poll?.options?.map((option) => option.title) ?? [],
              isMultiple: poll?.isMultiple ?? false,
            }),
          })) as PollFormActivityParamsJSON;

          setValue('poll', {
            title: pollValues.title,
            options: pollValues.pollOptionTitles.map((title) => ({ title })),
            isMultiple: pollValues.isMultiple,
          });
        }}
        onRemoveClick={() => {
          openDialog({
            element: (
              <Dialog
                description="투표를 삭제할까요?"
                primaryActionLabel="삭제"
                secondaryActionLabel="취소"
                onSecondaryAction={closeDialog}
                onPrimaryAction={() => {
                  setValue('poll', undefined);
                  closeDialog();
                }}
              />
            ),
          });
        }}
        onClick={() => {}}
      />
      <Spacing height={16} />
    </Container>
  );
};
