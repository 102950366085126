import { Spacing, Typography } from '@community-group/components';
import { IconChevronRightRegular } from '@seed-design/icon';

import { useGetMeetupReviewsForNotMember } from '@/api/hooks/useGetMeetupReviewsForNotMember';
import { VerticalSpacing } from '@/components/common/Spacing';
import { useFlow } from '@/stackflow';

import * as s from './index.css';
import MeetupReviewsMoreButton from './MeetupReviewsMoreButton';
import ReviewItem from './ReviewItem';

type Props = {
  groupId: string;
};

const MAX_REVIEW_COUNT = 6;

const MeetupReviewSection = ({ groupId }: Props) => {
  const { data } = useGetMeetupReviewsForNotMember(groupId);
  const { push } = useFlow();

  const showMoreButton = data && data.reviews.length >= 3;

  const reviewList = data?.reviews.slice(0, MAX_REVIEW_COUNT);

  const handleMoveToReviewList = () => {
    push('GroupDetailNotMemberMeetupReviewListPage', { groupId });
  };

  return (
    <section className={s.Wrapper}>
      <div className={s.TitleContainer}>
        <Typography typography="title3Bold">일정 후기</Typography>
        {showMoreButton && (
          <div className={s.More} onClick={handleMoveToReviewList}>
            <Typography typography="label3Regular" color="gray900">
              더보기
            </Typography>
            <VerticalSpacing height={2} />
            <IconChevronRightRegular size={14} />
          </div>
        )}
      </div>
      <Spacing height={24} />
      <div className={s.ListWrapper}>
        {reviewList?.map((review, index) => (
          <>
            <ReviewItem key={review.id} item={review} groupId={groupId} />
            {index !== reviewList.length - 1 && <VerticalSpacing height={10} />}
          </>
        ))}
        {showMoreButton && (
          <>
            <VerticalSpacing height={10} />
            <MeetupReviewsMoreButton groupId={groupId} />
          </>
        )}
      </div>
    </section>
  );
};

export default MeetupReviewSection;
