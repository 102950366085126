import { Skeleton } from '@community-group/components';

import { Spacing } from '@/components/common/Spacing';

import * as s from './Skeleton.css';

const GroupDetailSkeleton = () => (
  <div>
    <Skeleton height="13.575rem" />
    <div className={s.headerWrapper}>
      <div className={s.profileWrapper}>
        <Skeleton width="4rem" height="4rem" borderRadius="0.75rem" />
      </div>
      <div className={s.memberListWrapper}>
        <Skeleton width="2.25rem" height="2.25rem" borderRadius="1.125rem" />
        <Skeleton
          width="2.25rem"
          height="2.25rem"
          borderRadius="1.125rem"
          style={{ marginLeft: '0.25rem' }}
        />
      </div>
    </div>

    <Skeleton
      enableAnimation
      width="5rem"
      height="1rem"
      style={{ marginLeft: '1rem' }}
      borderRadius="0.3rem"
    />
    <Spacing height={8} />
    <Skeleton
      enableAnimation
      width="12rem"
      height="1rem"
      style={{ marginLeft: '1rem' }}
      borderRadius="0.3rem"
    />
  </div>
);

export default GroupDetailSkeleton;
