import {
  AsyncBoundary,
  Spacing,
  Typography,
  useSnackbarAdapter,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useGetMyMainProfile } from '@/api/hooks/useGetMyMainProfile';
import { usePutGroupProfile } from '@/api/hooks/usePutGroupProfile';
import { FormInput } from '@/components/common/base/Input/Text';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { useUserConfig } from '@/contexts/UserConfig';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupProfile } from '@/utils/refetch/groupGroupProfile';

import { SnsTagItem, SnsTagItemProps } from '../components/SnsTagItem';

export const SnsTagSetBottomSheet: ActivityComponentType = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<ViewLoader />}>
        <SnsTagSetSection />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const snsTagList = [
  {
    name: 'Instagram',
    value: 'instagram',
    iconType: 'instagram',
  },
  {
    name: 'Facebook',
    value: 'facebook',
    iconType: 'facebook',
  },
  {
    name: 'X',
    value: 'x',
    iconType: 'x',
  },
] as Pick<SnsTagItemProps, 'name' | 'value' | 'iconType'>[];

const SnsTagSetSection = () => {
  const { snsSetBottomSheetType = 'new', snsSetBottomInitialToggle = 'instagram' } =
    useQueryParams();
  const { data: profile } = useGetMyMainProfile({});
  const currentUserMe = useUserConfig();
  const { setValue, watch } = useForm({});
  const snsTagFieldValue = watch('snsTag');
  const [toggle, setToggle] = useState<'facebook' | 'instagram' | 'x'>(snsSetBottomInitialToggle);

  const { pop } = useFlow();

  const snackbarAdapter = useSnackbarAdapter();
  const handleErrorWithToast = useHandleErrorWithToast();

  const openSnackbar = (message: string) => {
    snackbarAdapter.create({
      message: message,
      type: 'default',
      timeout: 3000,
      onClick: () => {
        snackbarAdapter.dismiss();
      },
    });
  };

  const { mutate: putMutate, isLoading: isSubmitEditProfileLoading } = usePutGroupProfile({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      pop();
      refetchGroupProfile();
      if (snsSetBottomSheetType === 'new') {
        trackEvent({
          event: 'click_create_sns_tag',
          params: {
            snsTagId: snsTagFieldValue,
            snsTagType: toggle,
          },
        });
        openSnackbar('소셜 계정을 추가했어요.');
        return;
      }
      trackEvent({
        event: 'click_edit_sns_tag',
        params: {
          snsTagId: snsTagFieldValue,
          snsTagType: toggle,
        },
      });
      openSnackbar('소셜 계정을 수정했어요.');
    },
  });

  const getPlaceholder = () => {
    if (toggle === 'facebook') return 'facebook.com/';
    return '@사용자이름';
  };

  const handleOnChangeToggle = (value: 'facebook' | 'instagram' | 'x') => {
    setToggle(value as 'facebook' | 'instagram' | 'x');
    setValue('snsTag', '');
  };

  const handleSubmit = () => {
    if (snsSetBottomSheetType === 'new') {
      const isAlreadyExistSns = profile?.snsTags?.find((tag) => tag.snsType === toggle);
      if (isAlreadyExistSns) {
        openSnackbar(`${isAlreadyExistSns.snsType}계정은 하나만 추가할 수 있어요.`);
        return;
      }
    }

    const fieldValueWithoutFacebookUrlAndAt = snsTagFieldValue
      .replace(/^@/, '') // 문자열 시작 부분의 "@" 제거
      .replace('facebook.com/', ''); // "facebook.com/" 제거

    const profileTags = profile?.snsTags ?? [];
    const snsTags = profileTags.filter((tag) => tag.snsType !== toggle);
    const formTags = [...snsTags, { snsType: toggle, snsId: fieldValueWithoutFacebookUrlAndAt }];

    putMutate({
      groupMainProfileModifyForm: {
        nickname: profile?.nickname ?? '',
        snsTags: formTags,
      },
    });
  };

  useEffect(() => {
    if (!snsTagFieldValue) return;
    if (snsTagFieldValue.length !== 1) return;

    if (toggle === 'facebook') {
      snsTagFieldValue && setValue('snsTag', `facebook.com/${snsTagFieldValue}`);
      return;
    }
    if (snsTagFieldValue !== '@') {
      snsTagFieldValue && setValue('snsTag', `@${snsTagFieldValue}`);
    }
  }, [setValue, toggle, snsTagFieldValue]);

  const handleOnChangeInput = (event) => {
    const { value } = event.target;
    let formattedValue = value;

    // @를 포함하여 facebook과 @를 처리하는 로직
    if (toggle === 'facebook') {
      if (value === 'facebook.com' || value === 'facebook.com/') {
        formattedValue = ''; // 전체를 지웁니다.
      } else if (!value.startsWith('facebook.com/')) {
        formattedValue = `facebook.com/${value}`;
      }
    }

    // 이미 @가 포함되어 있거나, 입력값이 @일 때 처리
    if (value.includes('@')) {
      if (!snsTagFieldValue.includes('@')) {
        // 처음 입력된 @ 처리
        formattedValue = `@${value.replace(/@/g, '')}`;
      }
    }

    // React Hook Form의 setValue 함수를 사용하여 값을 업데이트
    setValue('snsTag', formattedValue, { shouldValidate: true });
  };

  const disabled = !snsTagFieldValue || isSubmitEditProfileLoading;

  const renderSnsTagList = () => {
    if (currentUserMe.userConfig.userId === profile?.id && snsSetBottomSheetType === 'edit') {
      return profile?.snsTags?.map((snsTag) => (
        <SnsTagItem
          key={snsTag.snsId}
          name={snsTag.snsId}
          iconType={snsTag.snsType}
          selected={snsTag.snsType === toggle}
          onClick={() => {
            handleOnChangeToggle(snsTag.snsType as 'facebook' | 'instagram' | 'x');
          }}
          {...snsTag}
        />
      ));
    }
    return snsTagList.map((snsTag) => (
      <SnsTagItem
        key={snsTag.name}
        selected={snsTag.value === toggle}
        onClick={() => {
          handleOnChangeToggle(snsTag.value as 'facebook' | 'instagram' | 'x');
        }}
        {...snsTag}
      />
    ));
  };

  return (
    <div>
      <Spacing height={8} />
      <Typography typography="title2Bold">소셜 계정</Typography>
      <Spacing height={16} />
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        {renderSnsTagList()}
      </div>
      <Spacing height={16} />
      <FormInput
        name="snsTag"
        placeholder={getPlaceholder()}
        value={snsTagFieldValue}
        onChange={handleOnChangeInput}
      />
      <Spacing height={24} />
      <FixedButton onClick={handleSubmit} disabled={disabled}>
        완료
      </FixedButton>
    </div>
  );
};
