import { Dialog } from '@community-group/components';

import { TypeUseFlow } from '@/stackflow';

type Props = {
  closeDialog: () => void;
  push: ReturnType<TypeUseFlow>['push'];
};
function FullMemberAlertDialog({ closeDialog, push }: Props) {
  return (
    <Dialog
      title="앗, 모집 인원이 꽉 찼어요!"
      description="아쉽지만 다음 기회를 노려 보세요. 대신 동네 러닝 모임, 운동 모임에 함께해 보세요!"
      secondaryActionIntent="nonpreferred"
      secondaryActionLabel="다음에 볼게요"
      onSecondaryAction={async () => {
        await closeDialog();
        return;
      }}
      primaryActionLabel="러닝 모임 보러가기"
      onPrimaryAction={async () => {
        await closeDialog();
        push('GroupCurationWithKeywordPage', {
          keyword: '당근 레이스',
        });
      }}
    />
  );
}

export default FullMemberAlertDialog;
