import { GroupMeetupCreateForm } from '@community-group/api/lib/group/models';
import { Divider, Typography } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityComponentType } from '@stackflow/react';
import isEmpty from 'lodash-es/isEmpty';
import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { usePostGroupMeetup } from '@/api/hooks/usePostGroupMeetup';
import { FROM_QUERY_PARAMS } from '@/constants/path';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { meetupCreateFormSchema } from '@/utils/validate/formSchema/meetup';
import {
  convertHookFormErrorObjectToArray,
  validateSchemaWithBoolean,
} from '@/utils/validate/util';

import { GroupMeetupFormChatCreateField } from '../../components/Field/ChatCreate';
import { GroupMeetupFormExposureRangeField } from '../../components/Field/ExposureRange';
import { GroupMeetupFormPublishedField } from '../../components/Field/Published';
import { openListItemFormFieldQuerySelector } from '../../utils/selector';
import * as s from './Options.css';

export type GroupMeetupNewOptionsPageParams = Pick<
  PageParams,
  'groupId' | 'meetupCreateForm' | 'from'
>;

const GroupMeetupNewOptionsPage: ActivityComponentType<GroupMeetupNewOptionsPageParams> = () => {
  const { replace, pop, push } = useFlow();

  const { groupId } = usePathParams();
  const { meetupCreateForm, from, refer_root } = useQueryParams();

  const hookForm = useForm<GroupMeetupCreateForm>({
    resolver: zodResolver(meetupCreateFormSchema),
  });

  const { group } = useGetGroupDetail(groupId.toString());

  const { handleSubmit: handleFormSubmit, watch } = hookForm;

  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate, isLoading } = usePostGroupMeetup({
    onError: handleErrorWithToast,
    onSuccess: () => {
      const eventParams = {
        groupId,
        groupMeetupCreationCycle: watch('creationCycle'),
        category: group?.category.name,
        // TODO: 일정채팅방 개선으로 인해 잠시 true로 변경
        // https://daangn.slack.com/archives/C06D8034RUK/p1708649282388759
        createMeetupChatRoom: true,
        hasPoi: !!watch('poi'),
        referrer: from,
        refer_root,
        from,
      };
      trackEvent({ event: 'click_group_meetup_post', params: eventParams });
      trackEvent({
        event: 'click_create_meetup_post',
        params: eventParams,
        loggerType: ['APPSFLYER'],
      });

      // 채팅방에서 일정 생성시 창 닫기
      if (from === 'chat') {
        bridge.closeRouter({});
        return;
      }

      // 500ms 딜레이를 주는 이유 참고: https://daangn.slack.com/archives/C05354CCEBA/p1690868586993619?thread_ts=1690789131.754829&cid=C05354CCEBA
      setTimeout(() => {
        refetchGroupDetail({ groupId });
      }, 500);
    },
  });
  const { bridge } = useBridge();
  const handleSubmit = () => {
    handleFormSubmit(
      (data) => {
        mutate(
          {
            groupId: parseInt(groupId),
            meetupCreateForm: {
              ...data,
              isPublished: data.exposureRange === 'GROUP_MEMBER' ? false : data.isPublished,
            },
          },
          {
            onSuccess: ({ data }) => {
              pop(2);
              setTimeout(() => {
                push('GroupMeetupDetailPage', {
                  groupId,
                  meetupId: data.meetupId.toString(),
                  from: FROM_QUERY_PARAMS.CREATE_GROUP_MEETUP,
                });
              }, 250);
            },
          }
        );
      },
      (errors) => {
        const errorList = convertHookFormErrorObjectToArray(errors);

        const firstError = errorList[0];

        bridge.openToast({
          toast: {
            body: firstError.value.message ?? '',
          },
        });
      }
    )();
  };

  useEffect(() => {
    try {
      if (meetupCreateForm) {
        const parsedMeetupCreateForm = JSON.parse(meetupCreateForm);

        if (validateSchemaWithBoolean(meetupCreateFormSchema, parsedMeetupCreateForm)) {
          return hookForm.reset(parsedMeetupCreateForm);
        }
      }

      replace('GroupMeetupNewPage', { groupId }, { animate: false });
    } catch {
      replace('GroupMeetupNewPage', { groupId }, { animate: false });
    }
  }, [meetupCreateForm]);

  return (
    <AppScreen
      accessoryBar={
        <div className={s.accessoryBarWrapper}>
          <BoxButton isLoading={isLoading} size="xlarge" width="100%" onClick={handleSubmit}>
            일정 만들기
          </BoxButton>
        </div>
      }
    >
      <form>
        {isEmpty(hookForm.getValues()) ? <></> : <GroupMeetupNewOptionsView hookForm={hookForm} />}
      </form>
    </AppScreen>
  );
};

export default GroupMeetupNewOptionsPage;

type Props = {
  hookForm: UseFormReturn<GroupMeetupCreateForm>;
};
const GroupMeetupNewOptionsView = ({ hookForm: { watch, setValue } }: Props) => {
  useEffect(() => {
    openListItemFormFieldQuerySelector('exposureRange');
  }, []);

  return (
    <section className={s.wrapper}>
      <Typography as="h2" typography="title1Bold" color="gray900" style={{ padding: '1rem 0' }}>
        옵션을 선택해주세요
      </Typography>
      <GroupMeetupFormExposureRangeField name="exposureRange" watch={watch} setValue={setValue} />
      <Divider padding={0} />
      <GroupMeetupFormChatCreateField name="withCreateChatRoom" watch={watch} setValue={setValue} />
      <Divider padding={0} />
      {watch('exposureRange') === 'ALL' && (
        <>
          <GroupMeetupFormPublishedField name="isPublished" watch={watch} setValue={setValue} />
          <Divider padding={0} />
        </>
      )}
    </section>
  );
};
