import { ReactNode, useMemo } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { isShowJoinGroupMarketingBanner } from '@/components/group/MarketingPromotion/utils';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import * as s from './index.css';
import { JoinExerciseGroupMarketingBanner } from './JoinExerciseGroupMarketingBanner';
import PreJoinExerciseGroupMarketingBanner from './PreJoinExerciseGroupMarketingBanner';

// 모임 홈 상단 공지
export const BannerSection = () => {
  const shownPhotoContest = useFeatureFlag('shownPhotoContest');
  const shownPreJoinExerciseGroupMarketingBanner = useFeatureFlag(
    'shownPreJoinExerciseGroupMarketingBanner'
  );

  // TODO: 8~9월 러닝 그로스 본 마케팅 프로모션용
  const isOngoingExerciseGroupJoinMarketingPromotion = useFeatureFlag(
    'exerciseGroupJoinMarketingPromotion'
  );

  const bannerList = useMemo(() => {
    const bannerList: ReactNode[] = [];

    if (shownPreJoinExerciseGroupMarketingBanner) {
      bannerList.push(<PreJoinExerciseGroupMarketingBanner />);
    }

    // TODO: 8~9월 러닝 그로스 본 마케팅 프로모션용
    if (isOngoingExerciseGroupJoinMarketingPromotion && isShowJoinGroupMarketingBanner()) {
      bannerList.push(<JoinExerciseGroupMarketingBanner />);
    }

    // TODO: 사진전 진행시 주석 해제
    // if (shownPhotoContest) {
    //   bannerList.push(<ExplorerGroupTabPhotoContestBanner />);
    // }

    // bannerList.push(<NoticeBanner />);
    return bannerList;
  }, [isOngoingExerciseGroupJoinMarketingPromotion, shownPreJoinExerciseGroupMarketingBanner]);

  const hasOnlyOne = useMemo(() => bannerList.length <= 1, [bannerList]);

  if (bannerList.length === 0) return null;

  return (
    <div className={s.SwiperOuter}>
      <Swiper
        pagination={{ dynamicBullets: false }}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className={s.SwiperWrapper({ hasOnlyOne })}
      >
        {bannerList.map((banner, index) => (
          <SwiperSlide className={s.ItemWrapper} key={'banner-' + index}>
            {banner}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
