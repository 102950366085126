import { GroupSection } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import React from 'react';

import IconExpand from '@/assets/images/icon_expand.svg';
import { Map } from '@/components/common/Map';
import { Marker } from '@/components/common/Map/components/Marker';
import { trackEvent } from '@/utils/analytics';
import { openPoiDetailPage } from '@/utils/link';

import * as s from './MeetupPoiMap.css';

interface Props {
  meetupPois: GroupSection['meetupPois'];
}

const MeetupPoiMap = ({ meetupPois }: Props) => {
  const hasMeetupPois =
    meetupPois && meetupPois.length > 0 && meetupPois[0].coordinates.latitude !== 0;

  if (!hasMeetupPois) return null;
  const poi = meetupPois[0];
  const coordinates = [poi.coordinates.longitude, poi.coordinates.latitude] as [number, number];

  return (
    <div>
      <Spacing height={4} />
      <Typography typography="subtitle1Bold" color="gray900">
        자주 모이는 장소
      </Typography>
      <Spacing height={12} />

      <div
        className={s.MapWrapper}
        onClick={() => {
          trackEvent({ event: 'click_poi_detail', sample: true });

          openPoiDetailPage({
            id: poi.id,
            type: poi.type,
          });
        }}
      >
        <Map
          mapId="poiMap"
          className={s.MapStyle}
          options={{
            interactive: false,
            zoom: 16,
            center: coordinates,
          }}
        >
          <Marker markerId={poi.name} coordinates={coordinates} label={poi.name} />
        </Map>
        <div className={s.ExpendButton}>
          <img src={IconExpand} alt="expand button" />
        </div>
      </div>
    </div>
  );
};

export default MeetupPoiMap;
