import { useMemo } from 'react';

import { useGetMemberOnBoardingMission } from '@/api/hooks/useGetMemberOnBoardingMission';
import ICON_CT_INVITE from '@/assets/images/icon_ct_invite.svg';
import ICON_CT_PROFILE from '@/assets/images/icon_ct_profile.svg';
import ICON_CT_SPEAKER from '@/assets/images/icon_ct_speaker.svg';
import { useUserConfig } from '@/contexts/UserConfig';
import { TypeActivityNames, useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

interface MemberOnboardingQuest {
  icon: string;
  title: string;
  description: string;
  onButtonClick: () => void;
  activityName: TypeActivityNames;
  isCompleted: boolean;
}

const useMemberOnboardingQuest = (): MemberOnboardingQuest[] => {
  const { push } = useFlow();

  const { groupId = '0' } = usePathParams();
  const { userConfig } = useUserConfig();
  const userId = userConfig.userId ?? 0;

  const { data } = useGetMemberOnBoardingMission(groupId);

  const quest = useMemo(() => {
    const {
      isFirstPostCreated = false,
      isSetProfileImage = false,
      isSetDescription = false,
      isOnboardingCompleted = false,
    } = data?.data.groupMemberOnboarding ?? {};

    if (isOnboardingCompleted) return [];

    return [
      {
        icon: ICON_CT_SPEAKER,
        title: '프로필 사진 등록하기',
        description: `나를 나타낼 수 있는 사진을 등록해주세요.`,
        onButtonClick: () => {
          push('GroupProfileEditPage', {
            groupId,
            userId,
          });
        },
        activityName: 'GroupProfileEditPage' as TypeActivityNames,
        isCompleted: isSetProfileImage,
      },

      {
        icon: ICON_CT_PROFILE,
        title: '자기소개 작성하기',
        description: '연령대, 성별, 관심사 등 소개를 작성해주세요.',
        onButtonClick: () => {
          push('GroupProfileEditPage', { groupId, userId });
        },
        activityName: 'GroupProfileEditPage' as TypeActivityNames,
        isCompleted: isSetDescription,
      },
      {
        icon: ICON_CT_INVITE,
        title: '가입 인사하기',
        description: '모임 이웃들에게 가입 인사를 남겨보세요.',
        onButtonClick: () => {
          push('GroupPostNewPage', {
            groupId,
          });
        },
        activityName: 'GroupPostNewPage' as TypeActivityNames,
        isCompleted: isFirstPostCreated,
      },
    ];
  }, [data?.data.groupMemberOnboarding, groupId, push, userId]);

  return quest.sort((prev, current) => {
    if (prev.isCompleted && !current.isCompleted) {
      return -1;
    }
    if (!prev.isCompleted && current.isCompleted) {
      return 1;
    }
    return 0;
  });
};

export default useMemberOnboardingQuest;
