import { ActionableCallout, CalloutDescription, CalloutTitle } from '@community-group/components';
import { useActivity } from '@stackflow/react';

import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { openRunningGrowthGuideBook } from '@/utils/link';

import { useCheckRunningGrowthGroupStep } from './hooks/useCheckRunningGrowthGroupStep';
import { RUNNING_GROWTH_FLOW } from './utils/runningGrowthFlow';

const RunningGrowthCallout = () => {
  const { groupId } = usePathParams();
  const { checkCurrentRunningGrowthStep } = useCheckRunningGrowthGroupStep(groupId);
  const step = checkCurrentRunningGrowthStep();
  const { name: activityName } = useActivity();

  return (
    <>
      {step && activityName === RUNNING_GROWTH_FLOW[step - 1].pageName && (
        <div style={{ width: '100%', padding: '1rem' }}>
          <ActionableCallout
            width="100%"
            variant="danger"
            onClick={() => {
              openRunningGrowthGuideBook(step as number);
            }}
          >
            <CalloutTitle>안내</CalloutTitle>
            <CalloutDescription>미션 달성 조건과 리워드를 확인해주세요.</CalloutDescription>
          </ActionableCallout>
        </div>
      )}
    </>
  );
};

export default RunningGrowthCallout;
