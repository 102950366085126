import { withAsyncBoundary } from '@community-group/components';
import { IconEditFill } from '@seed-design/icon';

import { useBridge } from '@/contexts/Bridge';
import { trackEvent } from '@/utils/analytics';

import { useHandlePhotoContestNewGroupPost } from '../hooks/useHandlePhotoContestNewGroupPost';
import * as s from './PhotoContestFloatingButton.css';

const PhotoContestFloatingButton = () => {
  const { bridge } = useBridge();
  const handleNewGroupPost = useHandlePhotoContestNewGroupPost();

  const handleClick = () => {
    bridge.setHapticSelect({});
    trackEvent({
      event: 'click_write_fab',
      params: {
        activeTabKey: 'photoEvent',
      },
    });
    handleNewGroupPost();
  };

  return (
    <button className={s.FloatingButton} onClick={handleClick}>
      <IconEditFill size={24} />
    </button>
  );
};

export default withAsyncBoundary(PhotoContestFloatingButton, {});
