import { vars } from '@seed-design/design-token';
import { IconCloseFill, IconRetryRegular } from '@seed-design/icon';
import clsx from 'clsx';
import React, { HTMLAttributes, useMemo } from 'react';

import { Spinner, Typography } from '../../../base';
import { VerticalSpacer } from '../../../Spacer';
import * as s from './ImageFieldItem.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  image: Blob | string; // string is url
  imageId: string;
  onRemoveClick: (imageId: string) => void;
  onRetryClick?: (imageId: string) => void;
}

const ImageFieldItem = ({ imageId, image, onRemoveClick, onRetryClick, ...props }: Props) => {
  const imageUrl = useMemo(() => {
    if (typeof image === 'string') {
      return image;
    }
    return URL.createObjectURL(image);
  }, [image]);

  return (
    <>
      <div {...props} className={clsx([props.className, s.wrapper])}>
        <div
          className={s.removeWrapper}
          onClick={(event) => {
            event.stopPropagation();

            onRemoveClick(imageId);
          }}
        >
          <IconCloseFill size={12} color={vars.$scale.color.gray00} />
        </div>
        <div
          className={s.statusWrapper({
            shown: !imageId.includes('error'),
          })}
          onClick={() => {
            onRetryClick?.(imageId);
          }}
        >
          <IconRetryRegular width={24} height={24} color={vars.$static.color.staticWhite} />
          <Typography color="staticWhite" typography="label4Bold">
            재시도
          </Typography>
        </div>
        <div
          className={s.statusWrapper({
            shown: !imageId.includes('temp'),
          })}
        >
          <Spinner variant="white" size="small" />
        </div>
        <img
          className={s.image}
          src={imageUrl}
          alt={imageId}
          width={64}
          height={64}
          loading="lazy"
        />
      </div>
      <VerticalSpacer size={8} />
    </>
  );
};

export default React.memo(ImageFieldItem);
