import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { motion } from 'framer-motion';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { MEETUP_PROGRESS_STATUS_FILTER } from '@/api/hooks/useGetMeetupList';
import { useGetMyGroupsMeetupLikes } from '@/api/hooks/useGetMyGroupsMeetupLikes';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import { MeetupHomeListCard } from '../../MeetupTab/ExplorerMeetupSection/MeetupHomeListCardItem';
import * as s from './index.css';

export const MyLikedMeetups = () => {
  const { push } = useFlow();

  const { data } = useGetMyGroupsMeetupLikes({
    progressStatuses: MEETUP_PROGRESS_STATUS_FILTER.OPENED,
  });

  const meetupList = data?.pages.map(({ data }) => data.meetups) ?? [];
  const flattenMeetupList = meetupList.flat(1);
  const slicedMeetupList = flattenMeetupList.slice(0, 10);

  if (flattenMeetupList.length <= 0) return <></>;

  return (
    <>
      <section className={s.wrapper}>
        <div className={s.header}>
          <Typography typography="title3Bold" color="gray900">
            관심 일정
          </Typography>
          <Typography
            className={s.textButton}
            as="button"
            typography="label3Regular"
            color="gray900"
            onClick={() => {
              push('MyInfoLikedGroupMeetupListPage', {});
            }}
          >
            전체보기
            <IconChevronRightFill size={14} color={vars.$scale.color.gray900} />
          </Typography>
        </div>
        <motion.ul
          className={s.list}
          style={{
            ...assignInlineVars({
              [s.listMinHeightVar]: flattenMeetupList.length <= 1 ? '9rem' : '11.375rem',
            }),
          }}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
        >
          <div
            className={s.swiperWrapper}
            style={{
              ...assignInlineVars({
                [s.listMinHeightVar]: flattenMeetupList.length <= 1 ? '9rem' : '11.375rem',
              }),
            }}
          >
            <Swiper
              pagination={{
                dynamicBullets: false,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {slicedMeetupList.map((meetup) => {
                if (!meetup) return <></>;

                return (
                  <SwiperSlide key={meetup.id}>
                    <MeetupHomeListCard
                      key={meetup.id}
                      title={meetup.title}
                      headerLabel={[meetup?.groupInfo?.groupCreatedRegion?.name ?? '']}
                      meetupAtDisplayText={meetup.meetupTimeAtDisplayText}
                      currentParticipantsCount={meetup.currentParticipantsNumber}
                      maximumParticipantsCount={meetup.maximumParticipantsNumber}
                      image={meetup.image?.medium ?? ''}
                      hostInfo={{
                        image: meetup.groupInfo?.profileImage?.small ?? '',
                        displayText: meetup.groupInfo?.name ?? '',
                        imageShape: 'square',
                      }}
                      onClick={() => {
                        push('GroupMeetupDetailPage', {
                          groupId: (meetup.groupInfo?.id ?? '').toString(),
                          meetupId: meetup.id.toString(),
                        });

                        trackEvent({
                          event: 'click_liked_group_meetup_list_item',
                          params: {
                            meetupId: meetup.id.toString(),
                            groupId: (meetup.groupInfo?.id ?? '').toString(),
                          },
                        });
                      }}
                      style={{
                        borderBottom: 0,
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </motion.ul>
      </section>
      <Spacing height={8} background={vars.$semantic.color.paperBackground} />
    </>
  );
};
