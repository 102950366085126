import { useCallback } from 'react';

import { useGetGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import {
  isCanceledGroupMeetup,
  isHostDeletedGroupMeetup,
  isOpenedGroupMeetup,
} from '../../utils/progressStatus';
import { GroupMeetupHostButton } from './GroupMeetupHostButton';
import { GroupMeetupParticipantButton } from './GroupMeetupParticipantButton';
import { NotGroupMemberJoinButton } from './NotGroupMemberJoinButton';
import { NotJoinedGroupMeetupJoinButton } from './NotJoinedGroupMeetupJoinButton';

export const GroupMeetupDetailAccessoryBar = () => {
  const { groupId, meetupId } = usePathParams();
  const { data } = useGetGroupMeetupDetail({ groupId, meetupId });

  const currentUserInfo = data?.currentUserInfo;
  const GroupMeetupButtonByStatus = useCallback(() => {
    if (!currentUserInfo?.groupJoined) {
      return <NotGroupMemberJoinButton />;
    }

    if (currentUserInfo?.role === 'HOST') {
      return <GroupMeetupHostButton />;
    }

    if (currentUserInfo?.role === 'PARTICIPANT') {
      return <GroupMeetupParticipantButton />;
    }

    return <NotJoinedGroupMeetupJoinButton />;
  }, [currentUserInfo]);

  const isWroteReviewWithoutPost =
    currentUserInfo?.reviewWritten && !currentUserInfo?.writtenReviewPostId;

  if (!data) return <></>;

  // 일정 후기는 제출했지만, 일정 후기 게시글은 작성하지 않은 경우
  if (!isOpenedGroupMeetup(data?.progressStatus.status) && isWroteReviewWithoutPost) {
    return <></>;
  }
  if (isCanceledGroupMeetup(data?.progressStatus.status) && data?.currentUserInfo.role !== 'HOST')
    return <></>;
  if (isHostDeletedGroupMeetup(data?.progressStatus.status)) return <></>;

  return <GroupMeetupButtonByStatus />;
};
