// 운동 마케팅 프로모션 기간인지 확인하는 함수
export const isDuringMarketingPromotion = () => {
  // 한국시 4월 14일 24시까지 마케팅 프로모션 기간
  const endPromotionUtcDate = new Date(Date.UTC(2024, 3, 14, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const now = new Date();
  return now < endPromotionUtcDate;
};

// 8~9월 운동모임 가입 마케팅 프로모션 사전신청 기간인지 확인하는 함수
export const isDuringPreJoinGroupMarketingPromotion = () => {
  // 한국시 9월 02일 24시까지 마케팅 프로모션 기간
  const endPromotionUtcDate = new Date(Date.UTC(2024, 8, 2, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const now = new Date();
  return now < endPromotionUtcDate;
};

// 8~9월 운동모임 가입 마케팅 프로모션 기간인지 확인하는 함수
export const isDuringRealJoinGroupMarketingPromotion = () => {
  // 한국시 9월 03일 00시 부터 9월 15일 24시까지 마케팅 프로모션 기간
  if (globalThis.appConfig.appEnv === 'alpha') {
    return true;
  }
  const startPromotionUtcDate = new Date(Date.UTC(2024, 7, 2, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const endPromotionUtcDate = new Date(Date.UTC(2024, 8, 15, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const now = new Date();
  return now > startPromotionUtcDate && now < endPromotionUtcDate;
};

// 8~9월 운동모임 가입 배너 노출 기간인지 확인하는 함수
export const isShowJoinGroupMarketingBanner = () => {
  // 한국시 9월 09일 00시 부터 9월 15일 24시까지 마케팅 프로모션 기간
  if (globalThis.appConfig.appEnv === 'alpha' || globalThis.appConfig.appEnv === 'dev') {
    return true;
  }
  const startPromotionUtcDate = new Date(Date.UTC(2024, 8, 8, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const endPromotionUtcDate = new Date(Date.UTC(2024, 8, 15, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const now = new Date();
  return now > startPromotionUtcDate && now < endPromotionUtcDate;
};
