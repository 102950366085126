import { GroupSidebarBoardCategoryPresentation } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconAddRegular } from '@seed-design/icon';
import { MouseEventHandler } from 'react';

import { VerticalSpacing } from '@/components/common/Spacing';

import * as s from './SidebarCustomBoardSection.css';
import SidebarMenuListItem from './SidebarMenuListItem';

type Props = {
  isHost: boolean;
  boardList: GroupSidebarBoardCategoryPresentation[];
  selectedBoardId?: number;
  onEditBoardButtonClick?: MouseEventHandler;
  onBoardItemClick?: (id: number) => MouseEventHandler;
  onCreateBoardButtonClick?: MouseEventHandler;
};

const SidebarCustomBoardSection = ({
  isHost,
  boardList,
  selectedBoardId,
  onEditBoardButtonClick,
  onBoardItemClick,
  onCreateBoardButtonClick,
}: Props) => {
  const hasCustomBoard = boardList.length > 0;

  if (!hasCustomBoard && !isHost) return null;

  return (
    <>
      {hasCustomBoard && (
        <>
          <Spacing height={8} />
          <div className={s.Title}>
            <Typography typography="subtitle2Regular" color="gray600">
              게시판
            </Typography>
            {isHost && (
              <Typography
                typography="subtitle2Regular"
                color="gray600"
                as="button"
                onClick={onEditBoardButtonClick}
              >
                편집
              </Typography>
            )}
          </div>
          <ul>
            {boardList.map((board) => (
              <SidebarMenuListItem
                key={board.id}
                text={board.name}
                bold={board.id === selectedBoardId}
                onClick={onBoardItemClick?.(board.id)}
              />
            ))}
          </ul>
        </>
      )}
      {isHost && (
        <div className={s.CreateBoardButton} role="button" onClick={onCreateBoardButtonClick}>
          <IconAddRegular size={16} color={vars.$scale.color.gray500} />
          <VerticalSpacing height={8} />
          <Typography typography="subtitle1Regular" color="gray600">
            게시판 만들기
          </Typography>
        </div>
      )}
    </>
  );
};

export default SidebarCustomBoardSection;
