import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import chunk from 'lodash-es/chunk';
import shuffle from 'lodash-es/shuffle';
import { useEffect, useState } from 'react';
import React from 'react';

import { useGetJustStartedGroupList } from '@/api/hooks/useGetJustStartedGroupList';
import GroupInfoListViewItem from '@/components/common/GroupInfoListItem/ListView';
import RefreshListSection from '@/components/common/RefreshListSection';
import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';

const RENDER_GROUP_MAX_COUNT = 5;

const RecommendNewGroupSection = () => {
  const { push } = useFlow();
  const { data, isLoading, isRefetching } = useGetJustStartedGroupList({});

  const groupList = data?.pages.map(({ data }) => data.groups) ?? [];
  const flattenGroupList = groupList.flat(1);

  const [chunkGroupList, setChunkGroupList] = useState(chunk(shuffle(flattenGroupList), 3));

  useEffect(() => {
    if (isRefetching) {
      setChunkGroupList(chunk(shuffle(flattenGroupList), 3));
    }
  }, [isRefetching]);

  const [currRefreshSectionPageCount, setCurrentRefreshSectionPageCount] = useState(0);

  if (isLoading || flattenGroupList.length <= 0) return null;

  return (
    <section className={s.wrapper}>
      <div className={s.header}>
        <Typography typography="title3Bold" color="gray900">
          새로 생겼어요
        </Typography>
        <Typography
          className={s.textButton}
          as="button"
          typography="label3Regular"
          color="gray900"
          onClick={() => {
            push('JustStartedGroupMorePage', {});
            // 2023-11 앰플리튜드 샘플링으로 주석처리
            // 2024-01-15 디자인 변경으로 임시 추가
            trackEvent({ event: 'click_just_started_group_more', sample: true });
          }}
        >
          전체보기
          <IconChevronRightFill size={14} color={vars.$scale.color.gray900} />
        </Typography>
      </div>
      <Spacing height={8} />
      <RefreshListSection
        maxCount={Math.min(chunkGroupList.length, RENDER_GROUP_MAX_COUNT)}
        currentCount={currRefreshSectionPageCount}
        onRefresh={(nextPageIndex) => {
          setCurrentRefreshSectionPageCount(nextPageIndex);
        }}
        buttonText="새로운 모임 더보기"
      >
        {chunkGroupList[currRefreshSectionPageCount].map((group, index) => {
          return (
            <GroupInfoListViewItem
              key={group?.id}
              group={group}
              onClick={(event) => {
                event.stopPropagation();

                push('GroupDetailPage', {
                  groupId: group?.id.toString(),
                });
                // 2023-11 앰플리튜드 샘플링으로 주석처리
                // 2024-01-15 디자인 변경으로 임시 추가
                trackEvent({
                  event: 'click_just_started_group',
                  params: {
                    groupId: group?.id,
                    groupName: group?.name,
                    groupCategoryName: group?.category?.name,
                    listItemIndex: index,
                  },
                  sample: true,
                });
              }}
              config={{
                showMemberList: false,
                enableLazyLoadImage: false,
              }}
            />
          );
        })}
      </RefreshListSection>
    </section>
  );
};

export default React.memo(RecommendNewGroupSection);
