import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import {
  isMember,
  useEffectOnce,
  withAsyncBoundary,
  withClientOnly,
} from '@community-group/components';

import { useCheckApplicationRejectedBottomSheet } from '@/hooks/useCheckApplicationRejectedBottomSheet';
import useCheckGroupProfileCreated from '@/hooks/useCheckGroupProfileCreated';
import useDidMountSequentialProcess from '@/hooks/useDidMountSequentialProcess';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';
import { extendAppsflyerLoggerType } from '@/utils/analytics';
import { getDocumentColorScheme } from '@/utils/color';

import useCheckFromParams from '../hooks/useCheckFromParams';
import useCheckIsNewParams from '../hooks/useCheckIsNewParams';
import useCheckNeedToSetSubNicknameBottomSheet from '../hooks/useCheckNeedToSetSubNicknameBottomSheet';
import useCheckNeedToVerificationRequiredAfterSuperHostUpdate from '../hooks/useCheckNeedToVerificationRequiredAfterSuperHostUpdate';
import useCheckPublishGroupNotificationBottomSheet from '../hooks/useCheckPublishGroupNotificationBottomSheet';
import useCheckRequestMeetupReviewIdParams from '../hooks/useCheckRequestMeetupReviewIdParams';
import useCheckTurnOnNotificationBottomSheet from '../hooks/useCheckTurnOnNotificationBottomSheet';
import useEnterPasswordRoomWithQR from '../hooks/useEnterPasswordRoomWithQR';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

// FIXME: 미가입자/가입자 뷰에 따라 로직 분리 필요
const GroupDetailHooks = ({ group, currentUser }: Props) => {
  const { groupId } = usePathParams();
  const { push } = useFlow();

  /** 채팅방 입장 관련 로직 시작 */
  const { joinPrivateRoomId, joinPublicRoomId } = useQueryParams();
  const { joinDirectly } = useEnterPasswordRoomWithQR(groupId, joinPublicRoomId);
  useEffectOnce(() => {
    const hasPublicRoomId = joinPublicRoomId && joinPublicRoomId.length !== 0;
    const hasPrivateRoomId = joinPrivateRoomId && joinPrivateRoomId.length !== 0;

    // private 채팅방에 링크를 통한 채팅방 입장시
    if (hasPrivateRoomId) {
      push('BottomSheet/GroupChatRoomPrivateBottomSheet', {
        groupId,
        roomId: joinPrivateRoomId,
      });
    }
    // public 채팅방에 링크를 통한 채팅방 입장시
    if (hasPublicRoomId) {
      joinDirectly();
    }
  });
  /* 채팅방 입장 관련 로직 끝 **/

  /** 프로필 생성 관련 로직 시작 */
  const { from } = useQueryParams();
  const { handleCheckGroupProfileCreated } = useCheckGroupProfileCreated({
    currentPage: 'GroupDetail',
  });
  const handleOpenGroupProfileCreate = () => {
    if (isMember(currentUser.role)) {
      handleCheckGroupProfileCreated();
    }
  };
  useEffectOnce(() => {
    // 프로필 있는지 체크 후 없으면 프로필 생성 페이지로 이동
    // 프로필 생성 후 돌아왔다면 함수 호출 X
    if (from !== 'CreateGroupProfile') {
      handleOpenGroupProfileCreate();
    }
  });
  /* 프로필 생성 관련 로직 끝 **/

  /** 바텀 시트 및 화면 전환 관련 로직 시작 */
  const hasIsNew = useCheckIsNewParams();
  const hasRequestMeetupReviewIdParams = useCheckRequestMeetupReviewIdParams({ groupId });
  const checkFromParams = useCheckFromParams();
  const hasTurnOnNotificationBottomSheet = useCheckTurnOnNotificationBottomSheet({ groupId });
  const hasNoticePublishGroupBottomSheet = useCheckPublishGroupNotificationBottomSheet({ groupId });
  const needToOpenSetSubNicknameBottomSheet = useCheckNeedToSetSubNicknameBottomSheet({ groupId });
  const hasApplicationRejectedBottomSheet = useCheckApplicationRejectedBottomSheet({
    groupId,
    userId: currentUser.id,
  });
  const needToVerificationRequiredAfterSuperHostUpdate =
    useCheckNeedToVerificationRequiredAfterSuperHostUpdate({
      groupId,
    });

  // 페이지 로드 후 순차적으로 확인해 화면 전환 or 바텀시트가 실행되어야 하지 확인하는 로직
  useDidMountSequentialProcess([
    hasApplicationRejectedBottomSheet,
    hasIsNew,
    hasRequestMeetupReviewIdParams,
    // 호스트 권한 받았을 때 알려주는 바텀시트 -> 노티로 갔으면 좋겠다
    checkFromParams({
      from: 'CHANGE_ROLE_TO_HOST_NOTIFICATION',
      additionalCondition: currentUser.role === 'manager',
      callback: () => {
        push('BottomSheet/CongratsManagerRoleBottomSheet', { groupId });
      },
    }),
    // !지우지 말 것 변경시에도 promm과 논의 필요
    // 모임장이 본인인증으로 모임을 전환했을때 멤버에게 본인인증을 요청하는 바텀시트
    needToVerificationRequiredAfterSuperHostUpdate,
    needToOpenSetSubNicknameBottomSheet,
    hasNoticePublishGroupBottomSheet,
    hasTurnOnNotificationBottomSheet,
  ]);
  /* 바텀 시트 및 화면 전환 관련 로직 끝 **/

  /** 모임 상세 진입 이벤트 로그 시작 */
  const referParams = useReferQueryParams();
  useEnterTrackEvent({
    event: 'enter_group_detail',
    params: {
      groupId,
      groupCategoryName: group.category.name,
      groupName: group.name,
      role: currentUser.role,
      isGroupCategoryOn: group.isBoardManaged,
      hasMeetupPois: (group.meetupPois ?? []).length > 0,
      isChatRequired: !group.chatRoomSetting.isShowChatRoomSetting,
      isChatActivated: !group.chatRoomSetting.isDeactivate,
      needVerification: group?.needVerification,
      version: '2',
      displayAppearance: getDocumentColorScheme(),
      ...referParams,
    },
    sample: true,
    loggerType: extendAppsflyerLoggerType,
  });
  /* 모임 상세 진입 이벤트 로그 끝 **/

  return null;
};

export default withAsyncBoundary(withClientOnly(GroupDetailHooks), {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
