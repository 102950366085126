import { ReactNode } from 'react';

import { useBottomSheet } from '../../../providers';
import { BoxButton } from '../Button';
import * as s from './index.css';

export type ActionItem = {
  label: string;
  color?: string;
  onClick: () => void;
};

type Props = {
  children: ReactNode;
  bottomButton: {
    label: string;
    onClick?: () => void;
  };
};

export const ActionSheet = ({ children, bottomButton }: Props) => {
  const { closeAsync: close } = useBottomSheet();

  const handleBottomButtonClick = () => {
    if (bottomButton.onClick) {
      return bottomButton.onClick();
    }

    close();
  };

  return (
    <div className={s.wrapper}>
      {children}
      <div className={s.bottom}>
        <BoxButton variant="secondary" onClick={handleBottomButtonClick} width="100%">
          {bottomButton.label}
        </BoxButton>
      </div>
    </div>
  );
};
