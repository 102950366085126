import { Callout, CalloutDescription, Spacing } from '@community-group/components';

import * as s from './GroupDescriptionCallout.css';

const GroupDescriptionCallout = () => {
  return (
    <>
      <div className={s.tipLabel}>
        <div className={s.tipBanner}>TIP</div>
        <label>이런 내용으로 모임을 소개해보세요.</label>
      </div>
      <Spacing height={12} />
      <Callout>
        <CalloutDescription>
          <ul>
            <li className={s.calloutContentsItem}>주로 어떤 활동을 하나요?</li>
            <li className={s.calloutContentsItem}>언제, 어디에서 활동하나요?</li>
            <li className={s.calloutContentsItem}>어떤 사람들과 함께하고 싶나요?</li>
            <li className={s.calloutContentsItem}>
              모임에서 지켜야 할 규칙이 있나요? (가입 조건, 출석 조건, 강퇴 조건 등)
            </li>
          </ul>
        </CalloutDescription>
      </Callout>
    </>
  );
};

export default GroupDescriptionCallout;
