import { Spacing, Typography } from '@community-group/components';
import { useMemo } from 'react';

import { useGetGroupMeetupMembers } from '@/api/hooks/useGetGroupMeetupMembers';
import { useUserConfig } from '@/contexts/UserConfig';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import { MemberReviewFieldItem } from '../common/MemberReviewFields/MemberReviewFieldItem';
import { MeetupMemberReviewFormHandler } from '../MeetupReviewForm/type';
import * as s from './style.css';

type Props = {
  formHandler: MeetupMemberReviewFormHandler;
};

export const MeetupMemberReviewForm = ({ formHandler }: Props) => {
  const { groupId, meetupId } = usePathParams();
  const { data: groupMeetupMemberList } = useGetGroupMeetupMembers({
    groupId,
    meetupId,
    meetupJoinStatus: 'join',
  });

  const { userConfig } = useUserConfig();
  const reviewerList = useMemo(() => {
    const memberList = groupMeetupMemberList?.pages.flatMap((page) => page.data.members) ?? [];
    return memberList.filter((member) => member.userId !== userConfig.userId);
  }, [groupMeetupMemberList?.pages, userConfig.userId]);

  return (
    <section className={s.Wrapper}>
      <div>
        <Typography typography="title2Bold" style={{ whiteSpace: 'pre-line' }} wordBreak="keep-all">
          멤버들에 대한 후기를 남겨주세요
        </Typography>
        <Spacing height={12} />
        <Typography typography="bodyM1Regular" wordBreak="break-all">
          누가 후기를 남겼는지 공개되지 않으니 안심하고 선택해주세요.
        </Typography>
      </div>
      <div className={s.MemberReviewFields}>
        {reviewerList.map((member, index) => (
          <MemberReviewFieldItem
            key={member.userId}
            groupId={groupId}
            memberData={member}
            index={index}
            formHandler={formHandler}
          />
        ))}
      </div>
    </section>
  );
};
