import { Dialog, useDialog } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useEffect } from 'react';

import { LottieURL } from '@/components/common/Lottie/LottieURL';
import { useBridge } from '@/contexts/Bridge';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useOpenGroupPayTransferDirectChat } from '@/hooks/useOpenGroupPayTransferDirectChat';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { openAppStore, openGroupPayTransferDirectChat } from '@/utils/link';
import { checkHigherTargetVersion } from '@/utils/version';

import * as s from './style.css';

type GroupPayTransferDirectChatPageParams = Pick<
  PageParams,
  'chatChannelId' | 'groupId' | 'userId'
>;

export const GroupPayTransferDirectChatPage: ActivityComponentType<
  GroupPayTransferDirectChatPageParams
> = () => {
  const { chatChannelId } = usePathParams();
  const { groupId, userId } = useQueryParams();
  const { bridge } = useBridge();

  useEnterTrackEvent({
    event: 'enter_group_pay_transfer_direct_chat',
    params: {
      groupId,
      userId,
    },
  });

  const { open: openDialog, close: closeDialog } = useDialog();

  const handleErrorWithToast = useHandleErrorWithToast();
  const { openAsyncPayTransferDirectChat } = useOpenGroupPayTransferDirectChat({
    onError: handleErrorWithToast,
  });

  useEffect(() => {
    setTimeout(() => {
      openAsyncPayTransferDirectChat(
        {
          chatChannelId,
          transferReferrer: 'GROUP_PROFILE',
        },
        {
          onSuccess: (data) => {
            const { ticketId } = data.data;

            // 참고: https://www.notion.so/daangn/72f46c103a6a4bdd92af3cbed48dc2c8?pvs=4#5a97441c0b904d3b9a394d03ba7921f4
            if (checkHigherTargetVersion(241600)) {
              openGroupPayTransferDirectChat(ticketId);
            } else {
              openDialog({
                element: (
                  <Dialog
                    description="송금을 위해 당근 앱을 업데이트해 주세요."
                    secondaryActionLabel="다음에 하기"
                    onSecondaryAction={() => {
                      closeDialog();
                      bridge.closeRouter({});
                    }}
                    primaryActionLabel="스토어 가기"
                    onPrimaryAction={async () => {
                      await closeDialog();
                      openAppStore();
                    }}
                  />
                ),
              });
            }
          },
        }
      );
    }, 500);
  }, []);

  useEffect(() => {
    const onFocus = () => {
      bridge.closeRouter({});
    };
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  return (
    <AppScreen>
      <div className={s.Wrapper}>
        <div className={s.LottieWrapper}>
          <LottieURL src="https://asset-town.krrt.io/production/motion/0c1f86d0-e90e-499b-8d7d-eb0158acf33f/37de0ec55b04885f4442fc8642c1a10f6c3d46c3.json" />
        </div>
      </div>
    </AppScreen>
  );
};
