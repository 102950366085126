import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { BoxButton, Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconConfirmationProfileFill } from '@seed-design/icon';
import dayjs from 'dayjs';
import { MouseEventHandler, useMemo } from 'react';

import CheckAccessibleRole from '@/components/common/CheckAccessibleRole';

import * as s from './index.css';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
  onGroupEditClick: MouseEventHandler;
};

const GroupDetailInformation = ({ group, currentUser, onGroupEditClick }: Props) => {
  const verifiedInfoText = useMemo(() => {
    if (!group.needVerification) return '';

    let renderText = '본인인증 모임';
    if (group.canViewVerifiedUserInfo) renderText += ' ∙ 성별 및 나이대 공개';

    return renderText;
  }, [group.needVerification, group.canViewVerifiedUserInfo]);

  return (
    <div className={s.SectionWrapper}>
      <img
        className={s.GroupProfileImage}
        src={group.profileImage.medium}
        alt="모임 프로필 이미지"
      />
      <Spacing height={16} />
      <Typography typography="title3Bold" color="gray900" className={s.TextWrapper}>
        {group.name}
      </Typography>
      <Spacing height={2} />
      <Typography typography="caption1Regular" color="gray600" className={s.TextWrapper}>
        {dayjs(group.createdAt).format('YYYY.MM.DD')} 개설
      </Typography>
      {group.needVerification && (
        <div className={s.TextBox}>
          <IconConfirmationProfileFill size={16} color={vars.$scale.color.green500} />
          <Typography typography="label3Regular" color="gray800">
            {verifiedInfoText}
          </Typography>
        </div>
      )}
      <CheckAccessibleRole currentRole={currentUser.role} accessibleRoles={['superHost']}>
        <Spacing height={16} />
        <BoxButton width="100%" variant="secondary" size="medium" onClick={onGroupEditClick}>
          모임 정보 수정
        </BoxButton>
      </CheckAccessibleRole>
    </div>
  );
};

export default GroupDetailInformation;
