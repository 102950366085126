import { Dialog, useDialog, useSnackbarAdapter } from '@community-group/components';
import { useMemo } from 'react';

import { useGetRunningGrowthMission } from '@/api/hooks/useGetRunningGrowthMission';
import { TypeActivityNames, useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import { RUNNING_GROWTH_FLOW } from '../utils/runningGrowthFlow';
import { useCheckRunningGrowthGroupStep } from './useCheckRunningGrowthGroupStep';

interface RunningGrowthMission {
  step: number;
  title: string;
  description: string;
  onButtonClick: () => void;
  isCompleted: boolean;
}

const useRunningGrowthMission = (): RunningGrowthMission[] => {
  const { push } = useFlow();
  const snackbarAdapter = useSnackbarAdapter();
  const { open: openDialog, close: closeDialog } = useDialog();
  const { groupId = '0' } = usePathParams();
  const { checkSelectedRunningGrowthStep } = useCheckRunningGrowthGroupStep(groupId);
  const { runningGrowthData = [] } = useGetRunningGrowthMission(groupId);

  const handleOpenDialog = () => {
    openDialog({
      element: (
        <Dialog
          description="이전 미션을 완료해야 다음 미션을 확인할 수 있어요."
          primaryActionLabel="확인했어요"
          onPrimaryAction={closeDialog}
        />
      ),
    });
  };

  const handleClickStep = (currentStep: number, missionPage: TypeActivityNames) => {
    const { previousStepComplete, isStepCompleted, isStepSuccess } =
      checkSelectedRunningGrowthStep(currentStep);
    if (!previousStepComplete) {
      handleOpenDialog();
      return;
    }

    if (isStepCompleted) {
      snackbarAdapter.create({
        message: '리워드 신청이 완료되었어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
      return;
    }

    if (isStepSuccess && !isStepCompleted) {
      return push('BottomSheet/RunningGrowthMissionCompleteBottomSheet', {
        groupId,
        step: currentStep,
      });
    }
    //미션 수행 페이지로 이동
    push(missionPage, {
      groupId,
    });
  };

  const quest = useMemo(() => {
    return RUNNING_GROWTH_FLOW.map((flow) => {
      const { isRewardSent } = checkSelectedRunningGrowthStep(flow.step);
      return {
        step: flow.step,
        title: flow.title,
        description: flow.description,
        onButtonClick: () => {
          handleClickStep(flow.step, flow.pageName);
        },
        isCompleted: isRewardSent,
      };
    });
  }, [groupId, push, runningGrowthData]);

  return quest.sort((prev, current) => {
    if (prev.isCompleted && !current.isCompleted) {
      return -1;
    }
    if (!prev.isCompleted && current.isCompleted) {
      return 1;
    }
    return 0;
  });
};

export default useRunningGrowthMission;
