import { AsyncBoundary, GroupAvatar, Typography } from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetApplicationAcceptance } from '@/api/hooks/useGetApplicationAcceptance';
import { Spacing, VerticalSpacing } from '@/components/common/Spacing';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';

import * as s from './ApplicationRejectedCommentBottomSheet.css';

export type ApplicationRejectedCommentBottomSheetParams = Pick<PageParams, 'groupId' | 'userId'>;

const ApplicationRejectedCommentBottomSheet: ActivityComponentType<
  ApplicationRejectedCommentBottomSheetParams
> = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <ApplicationRejectedCommentBottomSheetCore />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const ApplicationRejectedCommentBottomSheetCore = () => {
  const { pop } = useFlow();
  const { groupId, userId } = usePathParams();
  const { data: applicationAcceptance } = useGetApplicationAcceptance(groupId, Number(userId));

  useEnterTrackEvent({
    event: 'enter_application_rejected_bottom_sheet',
    params: { groupId },
  });

  if (!applicationAcceptance?.comment?.commenter?.profileImageUrl) {
    return null;
  }

  return (
    <div className={s.Wrapper}>
      <Typography as="h5" typography="title2Bold" color="gray900">
        아쉽지만 가입 신청이 거절되었어요
      </Typography>
      <Spacing height={6} />
      <Typography as="p" typography="subtitle1Regular" color="gray700">
        모임장이 보낸 의견을 확인해주세요.
      </Typography>
      <Spacing height={16} />
      <div className={s.CommentContainer}>
        <GroupAvatar
          src={applicationAcceptance.comment.commenter.profileImageUrl}
          size="small"
          role={applicationAcceptance.comment.commenter.role}
        />
        <VerticalSpacing height={8} />
        <div className={s.CommentBubble}>
          <Typography as="p" typography="bodyM1Regular" color="gray900">
            {applicationAcceptance.comment.commentFromManager}
          </Typography>
        </div>
      </div>
      <Spacing height={28} />
      <div className={s.ButtonContainer}>
        <BoxButton
          variant="primary"
          width="100%"
          size="xlarge"
          onClick={() => {
            trackEvent({
              event: 'click_application_rejected_bottom_sheet_close',
              params: { groupId },
            });
            pop();
          }}
        >
          확인
        </BoxButton>
      </div>
    </div>
  );
};

export default ApplicationRejectedCommentBottomSheet;
