import { GroupCurrentUser, PostDetail } from '@community-group/api/lib/group/models';
import { Spacing, Typography, withAsyncBoundary } from '@community-group/components';
import { PropsWithChildren } from 'react';

import { MemoizingFeedItem } from '@/components/group/Detail/components/Feed/FeedItem';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

import * as s from './FeedSummaryList.css';

type Props = PropsWithChildren<{
  totalCount: number;
  feeds: PostDetail[];
  currentUser: GroupCurrentUser;
}>;

const FeedSummaryList = ({ feeds, totalCount, currentUser, children }: Props) => {
  const shouldUseMeetupComment = useFeatureFlag('useMeetupComment');

  if (feeds.length === 0) {
    return (
      <Typography className={s.EmptyMessage} typography="bodyM1Regular" color="gray600">
        아직 게시글이 없어요.
        <br />
        가장 먼저 게시글을 남겨보세요.
      </Typography>
    );
  }

  return (
    <>
      <Spacing height={8} />
      <ul>
        {feeds.map((feed) => (
          <MemoizingFeedItem
            key={feed.id}
            post={feed}
            meetup={feed.meetupInfo}
            currentUser={currentUser}
            shouldSetSubNickname={feed.isSettingOnSubNickname}
            shouldUseMeetupComment={shouldUseMeetupComment}
          />
        ))}
      </ul>
      {totalCount > 3 && <div className={s.ButtonWrapper}>{children}</div>}
    </>
  );
};

export default withAsyncBoundary(FeedSummaryList, {});
