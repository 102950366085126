import { Spacing } from '@community-group/components';
import React, { Dispatch, SetStateAction } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import Chip from '@/components/common/base/Chip';
import FormBadgeRadio from '@/components/common/base/Input/Radio/Badge';
import { BadgeRadioWrapper } from '@/components/common/base/Input/Radio/FilterBadge';
import { FormContainer } from '@/components/common/Form';

import AgeRangeInputWrapper from './components/age-range-input-wrapper';
import { AGE_RANGE_OPTIONS } from './constants/age-range-constants';

type Props = {
  selectedAgeRangeChip: number;
  setSelectedAgeRangeChip: Dispatch<SetStateAction<number>>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
};

export type AgeRangeHandlerProps = {
  id: number;
  value:
    | {
        minAge: number | undefined;
        maxAge: number | undefined;
      }
    | undefined;
};

const AgeRange = ({
  selectedAgeRangeChip,
  setSelectedAgeRangeChip,
  watch,
  register,
  setValue,
}: Props) => {
  const ageRangeChipHandler = ({ id, value }: AgeRangeHandlerProps) => {
    setSelectedAgeRangeChip(id);
    setValue('ageRange', value);
  };

  return (
    <FormContainer>
      <div>
        <BadgeRadioWrapper>
          {AGE_RANGE_OPTIONS.map((item) => (
            <FormBadgeRadio
              key={item.id}
              value={item.id}
              name="ageRange"
              onClick={() => ageRangeChipHandler({ id: item.id, value: item.value })}
              checked={item.id === selectedAgeRangeChip}
            >
              <Chip text={item.name} />
            </FormBadgeRadio>
          ))}
        </BadgeRadioWrapper>
      </div>
      {AGE_RANGE_OPTIONS[selectedAgeRangeChip].showCustomInput && (
        <>
          <Spacing height={8} />
          <AgeRangeInputWrapper watch={watch} setValue={setValue} />
        </>
      )}
    </FormContainer>
  );
};

export default AgeRange;
