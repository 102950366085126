import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { Divider, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { useMemo } from 'react';

import { useGetNotices } from '@/api/hooks/useGetNotices';

import GroupDetailSummaryMoreButton from '../GroupDetailSummaryMoreButton';
import NoticeSummarySection from './NoticeSummarySection';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupNoticeSummary = ({ group, currentUser }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group]);
  const { data } = useGetNotices(groupId);
  if (data?.notices?.[0] === undefined) return null;

  return (
    <>
      <Divider padding={0} height={8} />
      <NoticeSummarySection groupId={groupId} notice={data.notices[0]} currentUser={currentUser}>
        <GroupDetailSummaryMoreButton
          group={group}
          summaryType="notices"
          currentUserRole={currentUser.role}
        />
      </NoticeSummarySection>
    </>
  );
};

export default withAsyncBoundary(GroupNoticeSummary, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
