import { vars } from '@seed-design/design-token';
import { IconVideoRegular } from '@seed-design/icon';

import { useUploadVideo } from '@/api/base/uploadVideo';
import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';

import useHandleUploadVideo from '../../hooks/useHandleUploadVideo';
import { GroupPostFormProps } from '../GroupPostForm';
import * as s from './index.css';

type Props = {
  formHandler: GroupPostFormProps['formHandler'];
};

function VideoButton({ formHandler }: Props) {
  const { getValues, setValue } = formHandler;

  const fetchInstance = useFetchInstance();

  const { mutateAsync: mutateUploadVideo } = useUploadVideo({
    fetchInstance,
  });

  const { handleVideoFileSelection } = useHandleUploadVideo({
    formHandler,
    uploadVideo: async ({ file }) => {
      return await mutateUploadVideo({
        file,
        onProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          const currentUploads = getValues('uploadVideos');
          setValue(
            'uploadVideos',
            currentUploads.map((upload) =>
              upload.file === file ? { ...upload, progress } : upload
            )
          );
        },
      });
    },
  });

  return (
    <button className={s.FeatureButton}>
      <IconVideoRegular
        width={24}
        height={24}
        color={vars.$scale.color.gray600}
        onClick={handleVideoFileSelection}
      />
    </button>
  );
}

export default VideoButton;
