import { useCallback, useMemo } from 'react';

import { VideoUploadErrorType } from '../types/Video';
import { DEFAULT_VALIDATION_CONFIG, ValidationConfig, validationVideoFile } from '../utils/video';

export const useVideoValidation = ({
  validationConfig = DEFAULT_VALIDATION_CONFIG,
  onUploadError,
}: {
  validationConfig: Partial<ValidationConfig>;
  onUploadError?: (type: VideoUploadErrorType) => (file: File, error: Error) => void;
}) => {
  const validation = useMemo(() => {
    return { ...DEFAULT_VALIDATION_CONFIG, ...validationConfig };
  }, [validationConfig]);

  // 개별 파일의 유효성 검사
  const validateVideoFile = useCallback(
    async (file: File) => {
      const validationMessage = await validationVideoFile(file, validation);

      //invalid file type
      if (validationMessage) return validationMessage;

      // valid file
      return undefined;
    },
    [validation]
  );

  // 파일 개수 제한 검사
  const validateFileCount = useCallback(
    (selectedFiles: File[], currentFileCount: number) => {
      const maxFileCount = validation.maxVideoCount;
      const availableSlots = maxFileCount - currentFileCount;

      // 파일 수 제한 로직: 초과하는 파일을 무효로 처리
      const validFiles = selectedFiles.slice(0, availableSlots);
      const invalidFiles = selectedFiles.slice(availableSlots);

      if (invalidFiles.length > 0) {
        onUploadError?.('fileCount')(
          invalidFiles[0],
          new Error(
            `최대 ${maxFileCount}개의 파일만 추가할 수 있어요. ${invalidFiles.length}개의 파일은 추가되지 않았어요.`
          )
        );
      }

      return { validFiles, invalidFiles };
    },
    [onUploadError, validation.maxVideoCount]
  );

  return { validateVideoFile, validateFileCount };
};
