import * as s from './wrapper.css';

interface Props {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  paddingX?: number;
  paddingY?: number;
}

/**
 * @deprecated "공통레포의 SettingList.Wrapper를 사용하세요." https://daangn.height.app/T-77506
 */
const SettingSectionWrapper = ({ title, children, paddingX = 0, paddingY = 8 }: Props) => (
  <div
    style={{
      padding: `${paddingY}px ${paddingX}px`,
    }}
    className={s.wrapperWrapper}
  >
    {title && <h4 className={s.wrapperTitle}>{title}</h4>}
    <ul>{children}</ul>
  </div>
);

export default SettingSectionWrapper;
