import { useEffect } from 'react';

import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useStore } from '@/store';

// 유저가 사전신청 마케팅 페이지에서 넘어왔는지 확인해서 저장하는 훅
const useCheckUserFromMarketingPromotion = () => {
  const { from = '' } = useQueryParams();

  // 해당 진입 세션에서만 사전신청 가능해야해서 store에 저장
  const { setExerciseMarketingPreJoinable } = useStore();

  useEffect(() => {
    if (from === 'pre_join_exercise_marketing') {
      setExerciseMarketingPreJoinable(true);
    }
  }, [from, setExerciseMarketingPreJoinable]);
};

export default useCheckUserFromMarketingPromotion;
