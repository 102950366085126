import {
  AsyncBoundary,
  TextButton,
  uploadedVideoFormatter,
  validateUploadingVideos,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

import { getPostPath, GROUP_URL } from '@/api/base/post';
import { useGetBoardTypeList } from '@/api/hooks/useGetBoardTypeList';
import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import { usePutEditPost } from '@/api/hooks/usePutEditPost';
import { queryClient } from '@/api/instance';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useOpenDialogValidateFormPhotoContest } from '@/hooks/useOpenDialogValidateFormPhotoContest';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { postFormSchema } from '@/utils/validate/formSchema/post';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

import { GroupPostFormAppBar } from '../components/AppBar';
import { GroupPostForm, GroupPostFormHandlerProps } from '../components/GroupPostForm';
import { typeSafeBoolean } from '../utils';

export type GroupPostEditPageParams = Pick<PageParams, 'groupId' | 'postId' | 'postType'>;

const GroupPostEditPage: ActivityComponentType<GroupPostEditPageParams> = () => {
  const { groupId = '', postId = '' } = usePathParams();
  const { group } = useGetGroupDetail(groupId);

  const { post } = useGetPostDetail(groupId, postId);

  const mentionSuggestionsRef = useRef<HTMLDivElement>(null);

  const { data: boardCateogries } = useGetBoardTypeList(Number(groupId));

  const formHandler = useForm({
    defaultValues: {
      isGroupOnly: post?.publishType === 'groupOnly',
      content: post?.content,
      isPublished: post?.isPublished,
      isNoticed: post?.isNoticed,
      images: [],
      originImages: [],
      prevImages: post?.images.map((image) => ({
        id: image.id,
        src: image.small,
      })),
      postType: {
        type: 'post',
      },
      isPhotoContestEntry: post.isPhotoContestEntry,
      isJoinedPhotoContest: post.isPhotoContestEntry,
      poiStreams: post?.pois?.[0] && [
        {
          poiId: post.pois[0].id,
          type: post.pois[0].type,
          poiName: post.pois[0].name,
        },
      ],
      boardCategoryIds: [
        post?.boardCategories?.[0]?.id ||
          boardCateogries?.boardCategories?.find((item) => item.type === 'default')?.id,
      ],
      uploadVideos: uploadedVideoFormatter(post.medias),
    } as GroupPostFormHandlerProps,
  });
  const { watch, getValues, setValue } = formHandler;
  const { bridge } = useBridge();

  const { pop } = useFlow();
  const handleErrorWithToast = useHandleErrorWithToast();
  const { mutate: editPost, isLoading } = usePutEditPost({
    onError: handleErrorWithToast,
    onSuccess: () => {
      queryClient.refetchQueries([`${getPostPath(groupId)}/${postId}`]);

      refetchGroupDetail({ groupId });
      queryClient.refetchQueries([`${GROUP_URL}/${groupId}/notices`]);

      pop();
    },
  });

  const handlePostSubmit = () => {
    // todo: ts-ignore 제거 (참고: https://github.com/daangn/community-web-group/pull/698#issue-1641248802)
    const images = [
      ...watch().prevImages.map(({ id }) => id),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...(watch().images?.map(({ id }) => id) ?? []),
    ];

    const mediasVideo = (watch('uploadVideos') ?? [])
      .filter(({ uploadedVideoInfo }) => uploadedVideoInfo?.id)
      .map(({ uploadedVideoInfo }) => {
        return {
          id: uploadedVideoInfo?.id,
          type: 'video',
        };
      });

    const mediasImage = images
      .filter((id) => !id.includes('temp') && !id.includes('error'))
      .map((id) => {
        return {
          id,
          type: 'image',
        };
      });

    const invalidUploadVideo = validateUploadingVideos(watch('uploadVideos') ?? []);
    if (invalidUploadVideo) {
      bridge.openToast({
        toast: {
          body: invalidUploadVideo.message,
        },
      });
      return false;
    }

    if (
      !validateSchemaWithHandleToast(postFormSchema, {
        content: watch('content'),
        medias: [...mediasVideo, ...mediasImage],
        images,
      })
    ) {
      return false;
    }
    editPost(
      {
        id: parseInt(groupId),
        postId: parseInt(postId),
        postModifyForm: {
          content: watch('content'),
          images,
          poiItems: [
            ...(watch('poiStreams')?.map(({ poiId, type }) => ({ id: poiId, type })) ?? []),
          ],
          isGroupOnly: typeSafeBoolean(watch('isGroupOnly')),
          isNoticed: typeSafeBoolean(watch('isNoticed')),
          isPublished: typeSafeBoolean(watch('isPublished')),
          boardCategoryIds: watch('boardCategoryIds'),
          medias: [...mediasVideo, ...mediasImage],
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            event: 'click_modify_post',
            params: {
              groupId,
              isChangedPublished: watch('isPublished') !== post?.isPublished,
              isChangedNotice: watch('isNoticed') !== post?.isNoticed,
              isChangedGroupOnly: watch('isGroupOnly') !== (post?.publishType === 'groupOnly'),
            },
          });
        },
      }
    );
  };

  const openDialogValidateFormPhotoContest = useOpenDialogValidateFormPhotoContest();
  const handleSubmitClick = () => {
    const isJoinedPhotoContest = !!post?.isPhotoContestEntry;
    const hasImage =
      (getValues('images') ?? []).length > 0 || (getValues('prevImages') ?? []).length > 0;

    if (isJoinedPhotoContest && !hasImage) {
      openDialogValidateFormPhotoContest({
        onPrimaryAction: () => {
          // 모임사진전: 백엔드에서 수정 시 사진이 없다면 자동으로 참여 취소
          handlePostSubmit();
        },
      });
      return;
    } else {
      handlePostSubmit();
    }
  };

  const isValidSubmit = () => {
    if (!watch('content')) {
      return true;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (watch('originImages').some(({ id }) => id.includes('temp') || id.includes('error'))) {
      return true;
    }
    if (watch('uploadVideos').some(({ status }) => status === 'failed' || status === 'uploading')) {
      return true;
    }

    return false;
  };

  const textareaParentRef = useRef<HTMLDivElement>(null);

  const handleFocusTextarea = (cursorEnd = true) => {
    setTimeout(() => {
      const textarea: HTMLInputElement | null | undefined =
        textareaParentRef?.current?.querySelector('[data-name="MentionsInput"]');
      const end = textarea?.textContent?.length;

      if (cursorEnd) {
        textarea?.setSelectionRange(end as number | null, end as number | null);
      }

      textarea?.focus();
    });
  };

  const handleBlurTextarea = () => {
    const textarea: HTMLInputElement | null | undefined = textareaParentRef?.current?.querySelector(
      '[data-name="MentionsInput"]'
    );
    textarea?.blur();
  };

  return (
    <AppScreen
      appBar={{
        title: group?.name ?? '',
        renderRight: () => (
          <TextButton
            isDisabled={isValidSubmit() || isLoading}
            variant="secondary"
            UNSAFE_style={{ padding: '0.5rem' }}
            onClick={handleSubmitClick}
          >
            완료
          </TextButton>
        ),
      }}
      accessoryBar={
        <GroupPostFormAppBar
          mode="edit"
          handleBlurTextarea={handleBlurTextarea}
          handleFocusTextarea={handleFocusTextarea}
          formHandler={formHandler}
          mentionSuggestionsRef={mentionSuggestionsRef}
        />
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <GroupPostForm
          mode="edit"
          formHandler={formHandler}
          textareaParentRef={textareaParentRef}
          mentionSuggestionsRef={mentionSuggestionsRef}
          isJoinedPhotoContest={post?.isPhotoContestEntry}
        />
      </AsyncBoundary>
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupPostEditPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
