import { GroupRunningGrowthResponseGrowthList } from '@community-group/api/lib/group/models';

import OnboardingBanner from '@/components/common/Banner/OnboardingBanner';

import useRunningGrowthMission from './hooks/useRunningGrowthMission';
export const isRunningGrowthGroup = (data: GroupRunningGrowthResponseGrowthList[] | undefined) => {
  return data?.every((step) => step.isStepCompleted);
};

const RunningGrowthMissionBanner = () => {
  const questList = useRunningGrowthMission();

  return (
    <OnboardingBanner
      questList={questList}
      title="'러닝 모임 프로그램' 미션 달성하기"
      description="각 미션을 달성하고 리워드를 신청해보세요."
      localStorageKey="closedRunningGrowthBanner"
    />
  );
};

export default RunningGrowthMissionBanner;
