import { IconPlayFill } from '@seed-design/icon';

import { Typography } from '../../base';
import { Spacing } from '../../Spacing';
import * as s from './VideoPlayInfoButton.css';

type Props = {
  durationText: string;
};

const VideoPlayInfoButton = ({ durationText }: Props) => {
  return (
    <>
      <div className={s.PlayButton}>
        <IconPlayFill size={26} color={'white'} />
      </div>
      <Spacing height={8} />
      <Typography typography={'caption2Regular'} color="onPrimary" className={s.DurationText}>
        {durationText}
      </Typography>
    </>
  );
};

export default VideoPlayInfoButton;
