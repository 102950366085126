import { ViewLoader, withAsyncBoundary } from '@community-group/components';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import FloatingIcon, { FLOATING_BUTTON_TYPE } from '@/components/common/FloatingIcon';
import { useBridge } from '@/contexts/Bridge';
import { useConnectedFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { GROUP_DETAIL_ACTIVE_TAB, useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';

import FixedBottomSection from '../../Detail/components/FixedBottomSection';

type Props = {
  activeTabKey: GROUP_DETAIL_ACTIVE_TAB;
};

const GroupDetailFloatingButton = ({ activeTabKey }: Props) => {
  const { groupId = '' } = usePathParams();

  const { data: currentUserData } = useGetGroupMe(groupId);
  const { group } = useGetGroupDetail(groupId);
  const { from } = useQueryParams();
  const { push: asyncPush } = useConnectedFlow();

  const { bridge } = useBridge();
  if (activeTabKey === 'meetups') return <></>;
  if (activeTabKey === 'talks') {
    if (!currentUserData?.currentUser?.role) {
      return <FixedBottomSection />;
    }
    return null;
  }

  return (
    <FloatingIcon
      onClick={async () => {
        bridge.setHapticSelect({});

        trackEvent({
          event: 'click_write_fab',
          params: {
            from,
            groupId: group?.id.toString(),
            groupCategoryName: group?.category?.name,
            activeTabKey,
          },
        });

        // 피드 & 앨범 탭일 경우 작성 페이지로 이동
        // 작성 페이지에서 챌린지 인증 글을 작성한 경우 챌린지 인증 화면을 띄운다.
        // 작성 페이지에서 챌린지 작성 버튼을 클릭 한 경우 챌린지 생성 페이지로 이동한다.
        const pageResponseData = await asyncPush('GroupPostNewPage', {
          groupId: group?.id.toString() ?? '',
        });
      }}
      type={FLOATING_BUTTON_TYPE.POST}
    />
  );
};

export default withAsyncBoundary(GroupDetailFloatingButton, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
