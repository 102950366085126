import { postUploadImageV2 } from '@community-group/api';
import { useBottomSheet, useHookFormImages } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import {
  IconCalendarRegular,
  IconLocationRegular,
  IconPhotoRegular,
  IconPollRegular,
} from '@seed-design/icon';
import { useState } from 'react';

import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';
import { useGetSuperHostOnBoardingMission } from '@/api/hooks/useGetSuperHostOnBoardingMission';
import { PollFormActivityParamsJSON } from '@/components/group/Poll/pages';
import { useBridge } from '@/contexts/Bridge';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePlaceSearchClient } from '@/hooks/usePlaceSearchClient';
import { trackEvent } from '@/utils/analytics';

import { GroupPostFormProps } from '../GroupPostForm';
import * as s from './index.css';
import StartBottomSheetVideoUploadButton from './StartBottomSheetVideoUploadButton';

export const GroupPostNewStartBottomSheet = ({
  formHandler,
  groupId,
  replacePage,
  pushPage,
}: GroupPostFormProps & {
  groupId: string;
  replacePage: any;
  popPage: any;
  hasMention: boolean;
  pushPage: any;
}) => {
  const fetchCommunityInstance = useFetchInstance();
  const { openSearchPoiPage } = usePlaceSearchClient();

  const { data: superHostOnBoardingData } = useGetSuperHostOnBoardingMission(groupId);

  const { closeAsync: closeBottomSheet } = useBottomSheet();
  const { bridge } = useBridge();
  const { setValue, watch, getValues } = formHandler;
  const { setImages } = useHookFormImages({
    handleHookFormContext: () => formHandler as unknown as any,
    plugins: {
      bridge,
      postUploadImageV2: (imageFile) =>
        postUploadImageV2({
          imageFile,
          fetchCommunityInstance,
        }),
    },
  });
  const [tempShownAddPhotoGuideHelpBubble, setTempShownAddPhotoGuideHelpBubble] = useState(false);

  const handleCalendarClick = async () => {
    await closeBottomSheet();

    setTimeout(() => {
      trackEvent({
        event: 'click_group_meetup_create',
        params: {
          name: 'bottomSheet',
        },
      });

      replacePage('GroupMeetupNewPage', { groupId });
    }, 50);
  };

  const handlePhotoClick = async () => {
    await closeBottomSheet();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const originImages = await bridge.driver.compat.pickImages();

    if (originImages) {
      setImages(originImages);
    }
  };

  const handleLocationClick = async () => {
    await closeBottomSheet();

    if ((watch('poiStreams') ?? []).length > 0) {
      return bridge.openToast({
        toast: {
          body: '게시글에는 하나의 장소만 추가할 수 있어요.',
        },
      });
    }

    openSearchPoiPage({});
  };

  const handlePollClick = async () => {
    await closeBottomSheet();

    const poll = getValues('poll');
    const pollValues = (await pushPage('GroupPollFormPage', {
      groupId,
      pollOptionValueJSONString: JSON.stringify({
        title: poll?.title ?? '',
        pollOptionTitles: poll?.options?.map((option) => option.title) ?? [],
        isMultiple: poll?.isMultiple ?? false,
      }),
    })) as PollFormActivityParamsJSON;

    setValue('poll', {
      title: pollValues.title,
      options: pollValues.pollOptionTitles.map((title) => ({ title })),
      isMultiple: pollValues.isMultiple,
    });
  };

  const useVideoUpload = useFeatureFlag('useVideoUpload');

  return (
    <div
      className={s.Wrapper}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <button className={s.Item} onClick={handlePhotoClick}>
        <IconPhotoRegular size={24} color={vars.$scale.color.gray900} />
        <p>사진</p>
      </button>

      {/* 동영상 업로드 아이템 */}
      {useVideoUpload && (
        <StartBottomSheetVideoUploadButton
          formHandler={formHandler}
          closeBottomSheet={closeBottomSheet}
        />
      )}
      <button className={s.Item} onClick={handleCalendarClick}>
        <IconCalendarRegular size={24} color={vars.$scale.color.gray900} />
        <p>일정</p>
      </button>
      <button className={s.Item} onClick={handlePollClick}>
        <IconPollRegular size={24} color={vars.$scale.color.gray900} />
        <p>투표</p>
      </button>
      <button className={s.Item} onClick={handleLocationClick}>
        <IconLocationRegular size={24} color={vars.$scale.color.gray900} />
        <p>장소</p>
      </button>
    </div>
  );
};
