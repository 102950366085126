import {
  installCommunityWebDriver,
  makeKarrotBridge as makeCommunityBridge,
} from '@daangn/community-web-bridge';
import type { CommunityWebBridgeDriver } from '@daangn/community-web-bridge/lib/installCommunityWebBridgeDriver';
import { installKarrotBridgeDriver, makeKarrotBridge } from '@daangn/karrotbridge';
import type { ReactNode } from 'react';
import { startTransition } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import { useUserConfig } from './UserConfig';

export type Bridge = ReturnType<typeof makeKarrotBridge>;
type CommunityBridge = ReturnType<typeof makeCommunityBridge<CommunityWebBridgeDriver>>;

// NOTE: communityBridge에서 karrotBridge를 덮어쓰는 부분이 있기 때문에 브릿지 초기화 순서를 유지해야 해요.
const { driver } = installKarrotBridgeDriver();
const { driver: communityDriver } = installCommunityWebDriver();
export const bridge: Bridge = makeKarrotBridge({ driver });
export const communityBridge: CommunityBridge = makeCommunityBridge({
  driver: communityDriver,
});

const BridgeContext = createContext<{
  bridge: Bridge;
  communityBridge: CommunityBridge;
  loading: boolean;
  isInitialized: boolean;
  setLoading: (loading: boolean) => void;
}>({
  bridge,
  communityBridge,
  loading: false,
  isInitialized: false,
  setLoading: (_) => {},
});

export const BridgeProvider = ({ children }: { children: ReactNode }) => {
  const { setUserConfig } = useUserConfig();
  const [loading, setLoading] = useState(false);
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (globalThis.appConfig.appEnv === 'dev' || !bridge) {
      return;
    }

    startTransition(() => {
      (async () => {
        const [userInfo, regionInfo] = await Promise.all([
          bridge.getUserInfo({}),
          bridge.getRegionInfo({}),
        ]);

        setUserConfig({
          regionId: regionInfo.info.region.id,
          regionProvinceName: regionInfo.info.region.name1,
          regionCityName: regionInfo.info.region.name2,
          regionTownName: regionInfo.info.region.name3,
          userId: userInfo.info.user.id,
          userNickname: userInfo.info.user.nickname,
          userProfileImageUrl: userInfo.info.user.profileImage,
          userAuthToken: userInfo.info.user.authToken,
          userHashId: userInfo.info.user.hashId,
        });

        setInitialized(true);
      })();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BridgeContext.Provider
      value={{
        bridge,
        communityBridge,
        loading,
        isInitialized,
        setLoading,
      }}
    >
      {children}
    </BridgeContext.Provider>
  );
};

export const useBridge = () => {
  return useContext(BridgeContext);
};
