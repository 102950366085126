import { ViewError, ViewLoader, withAsyncBoundary } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';

import GroupDetailAlbumSummary from '../components/GroupDetailAlbumSummary';
import GroupDetailFeedSummary from '../components/GroupDetailFeedSummary';
import GroupDetailInformation from '../components/GroupDetailInformation';
import GroupDetailMemberSummary from '../components/GroupDetailMemberSummary';
import GroupDetailSummary from '../components/GroupDetailSummary';
import GroupMeetupSummary from '../components/GroupMeetupSummary';

type GroupDetailSummaryPageParams = Pick<PageParams, 'groupId'>;

const GroupDetailSummaryPage: ActivityComponentType<GroupDetailSummaryPageParams> = () => {
  const { groupId } = usePathParams();
  const { data: groupMe } = useGetGroupMe(groupId);
  const { group } = useGetGroupDetail(groupId);

  const { push } = useFlow();
  const handleGroupEditClick = () => {
    push('GroupSettingEditGroupInfoPage', { groupId });
  };

  if (!group || !groupMe) {
    return <ViewLoader />;
  }

  return (
    <AppScreen>
      <GroupDetailInformation
        group={group}
        currentUser={groupMe.currentUser}
        onGroupEditClick={handleGroupEditClick}
      />
      <GroupDetailSummary
        description={group.description ?? ''}
        categoryName={group.category.name ?? ''}
        joinableAgeRangeText={group.joinableAgeRangeText}
        meetupPlaces={group.meetupPois}
        createdRegionTown={group.groupCreatedRegion.town}
        groupId={group.id}
      />
      <GroupDetailAlbumSummary group={group} currentUserRole={groupMe.currentUser.role} />
      <GroupDetailMemberSummary
        group={group}
        currentUserId={groupMe.currentUser.id}
        currentUserRole={groupMe.currentUser.role}
      />
      <GroupMeetupSummary
        group={group}
        currentUserRole={groupMe.currentUser.role}
        shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
      />
      <GroupDetailFeedSummary
        group={group}
        currentUser={groupMe.currentUser}
        shouldSetSubNickname={group.subNicknameSetting.isSettingOn}
      />
    </AppScreen>
  );
};

export default withAsyncBoundary(GroupDetailSummaryPage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
