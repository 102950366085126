import { useIsomorphicLayoutEffect } from '@community-group/components';
import { useActivity } from '@stackflow/react';
import type { FillLayer } from 'mapbox-gl';

import { useMap } from '..';
import { ensureLongitudeLatitudeOrder, getBoundsCoordinates } from '../utils/polygon';

type Props = {
  polygonId: string;
  coordinates: [number, number][];
  options?: {
    fitBounds?: boolean;
    fitBoundsPadding?: number;
  };
};

export const Polygon = ({ polygonId, coordinates, options }: Props) => {
  const map = useMap();
  const { transitionState } = useActivity();

  useIsomorphicLayoutEffect(() => {
    const updatePolygon = () => {
      const lnglatCoordinates = ensureLongitudeLatitudeOrder(coordinates);

      const geoJSONData: GeoJSON.Feature<GeoJSON.Geometry> = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [lnglatCoordinates],
        },
        properties: {},
      };

      if (map.getSource(polygonId)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map.getSource(polygonId).setData(geoJSONData);
      } else {
        map.addSource(polygonId, {
          type: 'geojson',
          data: geoJSONData,
        });

        map.addLayer(getPolygonTheme(polygonId));
      }

      if (options?.fitBounds) {
        map.fitBounds(getBoundsCoordinates(lnglatCoordinates), {
          padding: options.fitBoundsPadding ?? 60,
        });
      }
    };

    if (coordinates.length <= 0) return;
    if (transitionState !== 'enter-done') return;

    if (map.isStyleLoaded()) {
      updatePolygon();
    } else {
      map.on('load', updatePolygon);
    }

    return () => {
      if (map.getLayer(polygonId)) {
        map.removeLayer(polygonId);
      }

      if (map.getSource(polygonId)) {
        map.removeSource(polygonId);
      }
    };
  }, [transitionState, polygonId, coordinates]);

  return <></>;
};

const getPolygonTheme = (polygonId: string): FillLayer => ({
  id: polygonId,
  type: 'fill',
  source: polygonId,
  paint: {
    'fill-outline-color': '#FF7E36',
    'fill-color': '#FF7E36',
    'fill-opacity': 0.15,
  },
});
