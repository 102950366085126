import { GroupEmotionUserPresentation } from '@community-group/api/lib/group/models';
import { Typography } from '@community-group/components';

import { LoadMoreListContainer } from '@/components/common/LoadMoreContainer';
import { ProfileListItemUser } from '@/components/common/Profile/ListItem';

import MemberProfileSection from '../../memberList/components/MemberProfileSection';
import * as s from './EmotionUserList.css';

type Props = {
  users: GroupEmotionUserPresentation[];
  onClickUserProfile: (userId: number) => () => void;
  shouldFetchNextUsers: boolean;
  fetchNextUsers: () => void;
};

const EmotionUserList = ({
  users,
  onClickUserProfile,
  shouldFetchNextUsers,
  fetchNextUsers,
}: Props) => {
  const isJoinedUsers = users.every((user) => user.role !== 'none');

  return (
    <>
      {!isJoinedUsers && (
        <div className={s.UserListTitleWrapper}>
          <Typography typography="subtitle1Bold" color="gray900">
            가입하지 않은 이웃
          </Typography>
        </div>
      )}
      <ul>
        {users.map((user) => (
          <MemberProfileSection
            key={user.id}
            user={user as ProfileListItemUser}
            moveGroupUserProfileDetailPage={onClickUserProfile(user.id)}
          />
        ))}
      </ul>
      {shouldFetchNextUsers && <LoadMoreListContainer callback={fetchNextUsers} />}
    </>
  );
};

export default EmotionUserList;
