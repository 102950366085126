import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { GroupEventParams, SpecificEventType } from '@/utils/analytics/type';

import * as s from './CreateMeetupButton.css';
type Props = {
  eventName: SpecificEventType;
  eventParams: GroupEventParams;
};

const CreateMeetupButton = (props: Props) => {
  const { push } = useFlow();

  return (
    <button
      className={s.button}
      onClick={() => {
        trackEvent({ event: props.eventName, params: props.eventParams });
        push('GroupNewSetGroupCategoryPage', {});
      }}
    >
      모임 만들기
    </button>
  );
};

export default CreateMeetupButton;
