import { useBottomSheet } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconCheckRegular } from '@seed-design/icon';

import { ORDER_MEETUP, ORDER_MEETUP_KR_TYPO } from '@/api/base/group';
import { Spacing } from '@/components/common/Spacing';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { trackEvent } from '@/utils/analytics';

import * as s from './SortingGroupFilterBottomSheet.css';

export type SortingListValue = (typeof ORDER_MEETUP)[keyof typeof ORDER_MEETUP];

interface Props {
  selectedSorting: SortingListValue;
  setSelectedSorting: (sorting: SortingListValue) => void;
}

const SortingMeetupFilterBottomSheet = ({ selectedSorting, setSelectedSorting }: Props) => {
  const sortingList = Object.values(ORDER_MEETUP);
  const { closeAsync } = useBottomSheet();

  useEnterTrackEvent({
    event: 'enter_sorting_group_list_bottom_sheet',
    params: {
      currentValue: selectedSorting,
    },
  });

  const handleClick = (value: SortingListValue) => {
    trackEvent({
      event: 'click_sorting_group_list',
      params: { sorting: value, prevSorting: selectedSorting },
      sample: true,
    });
    setSelectedSorting(value);
    closeAsync();
  };

  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>정렬</h1>
      <Spacing />
      <ul className={s.sortingList}>
        {sortingList.map((sorting) => (
          <li
            className={s.sortingItem({
              selected: sorting === selectedSorting,
            })}
            key={sorting}
            onClick={() => {
              handleClick(sorting);
            }}
          >
            {ORDER_MEETUP_KR_TYPO[sorting]}
            {sorting === selectedSorting && (
              <div className={s.iconWrapper}>
                <IconCheckRegular size={24} color={vars.$scale.color.carrot500} />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SortingMeetupFilterBottomSheet;
