import { useIsomorphicLayoutEffect } from '@community-group/components';
import { useActivity } from '@stackflow/react';
import type { Marker as MapboxMarker } from 'mapbox-gl';
import { useRef } from 'react';
import ReactDOM from 'react-dom/client';

import { useMap } from '..';
import * as s from './Marker.css';
import { Pin } from './Pin';

type Props = {
  markerId: string;
  coordinates: [number, number];
  label?: string;
};

export const Marker = ({ markerId, label, coordinates }: Props) => {
  const map = useMap();
  const markerRef = useRef<MapboxMarker | null>(null);

  const { transitionState } = useActivity();

  useIsomorphicLayoutEffect(() => {
    const updateMarker = () => {
      const markerIcon = document.createElement('div');
      markerIcon.id = markerId;

      const marker = markerRef.current ?? new window.maplibregl.Marker(markerIcon);
      ReactDOM.createRoot(markerIcon).render(<PinIcon label={label} />);

      if (markerRef.current) {
        marker.setLngLat(coordinates);
      } else {
        marker.setLngLat(coordinates).addTo(map);

        markerRef.current = marker;
      }
    };

    if (coordinates.length <= 0) return;
    if (transitionState !== 'enter-done') return;

    if (map.isStyleLoaded()) {
      updateMarker();
    } else {
      map.on('load', updateMarker);
    }
  }, [markerId, coordinates]);

  return <></>;
};

const PinIcon = ({ label }: { label?: string }) => {
  return (
    <div className={s.pinIconWrapper}>
      {label && <div className={s.label}>{label}</div>}
      <Pin hasLabel={!!label} variation="pin-default-single" />
    </div>
  );
};
