import { axiosClient } from '@community-group/api';

import { wrapSSRErrorHandler } from '@/api/instance/axios';

import { useKarrotCustomHeader } from './useKarrotCustomHeader';

export const useFetchInstance = () => {
  const karrotCustomHeader = useKarrotCustomHeader();

  return wrapSSRErrorHandler(
    axiosClient.initFetchInstance(
      {
        baseURL: globalThis.appConfig.apiEndPoints.community,
      },
      karrotCustomHeader
    )
  );
};
