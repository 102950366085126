import { FeedbackBanner, Spacing, withAsyncBoundary } from '@community-group/components';
import { useMemo } from 'react';

import { useXproperty } from '@/hooks/useXproperty';
import { trackEvent } from '@/utils/analytics';

type Props = {
  surveyId: string;
  referrer?: string;
  padding?: string;
  topSpacing?: number;
  bottomSpacing?: number;
  eventLogParams?: Record<string, any>;
};

/**
 * xProperty에 저장된 설문조사 정보를 바탕으로 설문조사 배너를 렌더링합니다.
 * !!신규 구좌에 배너를 추가하려면 xProperty에 적당한 키값을 먼저 추가하고 호출해주세요.
 * !! 알파 / 프로덕션 xProperty가 분기되어져 있습니다.
 * 프로덕션 : https://xproperty.kr.wekarrot.net/admin/edit?pkey=community_group_survey_banners&country=KR
 * 알파 : https://xproperty.alpha.kr.karrotmarket.com/admin/edit?pkey=community_group_survey_banners&country=KR
 * @param {string} surveyId xProperty에 저장한 surveyId 값입니다.
 * @param {string} referrer 로깅에 남길 refferrer
 * @param {string} padding 배너의 padding 값입니다.
 * @param {number} topSpacing 상단 여백 값입니다.
 * @param {number} bottomSpacing 하단 여백 값입니다.
 * @param {Record<string, any>} eventLogParams 로깅에 남길 이벤트 로그 파라미터
 */
function SurveyFeedbackBanner({
  padding,
  surveyId,
  referrer,
  topSpacing,
  bottomSpacing,
  eventLogParams,
}: Props) {
  const xProperty = useXproperty();

  const surveyInfo = useMemo(
    () => xProperty.communityGroupSurveyBanners?.[surveyId],
    [xProperty.communityGroupSurveyBanners, surveyId]
  );

  const isHide = useMemo(() => surveyInfo && !isRenderSurveyInfo(surveyInfo), [surveyInfo]);

  if (!surveyInfo || isHide) return null;

  const openSurvey = () => {
    window.location.href = surveyInfo.targetUrl;
  };

  return (
    <>
      {topSpacing && <Spacing height={topSpacing} />}
      <div style={{ padding: padding }}>
        <FeedbackBanner
          titleText={surveyInfo.title}
          variant={surveyInfo?.variant ?? 'default'}
          onClick={() => {
            trackEvent({
              event: 'click_start_survey',
              params: {
                surveyId,
                targetUrl: surveyInfo.targetUrl,
                referrer,
                ...eventLogParams,
              },
            });
            openSurvey();
          }}
        />
      </div>
      {bottomSpacing && <Spacing height={bottomSpacing} />}
    </>
  );
}

export default withAsyncBoundary(SurveyFeedbackBanner, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});

const isRenderSurveyInfo = (surveyInfo: {
  title: string;
  targetUrl: string;
  variant?: 'outlined' | 'default';
  show?: boolean;
}) => {
  if (surveyInfo?.show === false) return false;

  if (!surveyInfo) return false;
  if (!surveyInfo.title) return false;
  if (!surveyInfo.targetUrl) return false;

  if (surveyInfo.title.length === 0 || surveyInfo.targetUrl.length === 0) return false;

  if (!surveyInfo.targetUrl.startsWith('karrot') && !surveyInfo.targetUrl.startsWith('http'))
    return false;

  if (surveyInfo.variant && surveyInfo.variant !== 'outlined' && surveyInfo.variant !== 'default')
    return false;

  return true;
};
