import {
  ActionList,
  ActionListItem,
  ActionSheet,
  useBottomSheet,
} from '@community-group/components';

import { openGroupMeetupReport } from '@/utils/link';

type Props = {
  hostId: string;
  meetupId: string;
  onClone: () => void;
};

export const GroupMemberMoreMenuAppBarBottomSheet = ({ hostId, meetupId, onClone }: Props) => {
  const { closeAsync: closeBottomSheet } = useBottomSheet();

  return (
    <ActionSheet
      bottomButton={{
        label: '닫기',
      }}
    >
      <ActionList>
        <ActionListItem
          onClick={async () => {
            await closeBottomSheet();

            onClone();
          }}
        >
          복제
        </ActionListItem>
        <ActionListItem
          onClick={async () => {
            await closeBottomSheet();
            openGroupMeetupReport({ meetupId, hostId });
          }}
        >
          신고
        </ActionListItem>
      </ActionList>
    </ActionSheet>
  );
};
