import { isSuperHost } from '@community-group/components';
import React from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';

import useSuperHostOnboardingQuest from './useSuperHostOnboardingQuest';

interface Props {
  groupId: string;
}

const useCheckPublishGroupNotificationBottomSheet = ({ groupId }: Props) => {
  const { push } = useFlow();
  const { data: myInfo } = useGetGroupMe(groupId);
  const { group: groupInfo } = useGetGroupDetail(groupId);
  const myRole = myInfo?.currentUser.role;
  const questList = useSuperHostOnboardingQuest();

  const completedQuestCount = questList.filter(({ isCompleted }) => isCompleted).length;
  const totalQuestCount = questList.length;

  const [closeSuperHostNoticePublishGroupBottomSheetList, _] = useStorage(
    'closeSuperHostNoticePublishGroupBottomSheetList',
    []
  );

  const checkTurnOnNotificationBottomSheet = React.useCallback(() => {
    if (!isSuperHost(myRole)) return false;
    if (questList.length === 0) return false;
    if (groupInfo?.publishedAt) return false;

    if (closeSuperHostNoticePublishGroupBottomSheetList?.includes(groupId)) return false;
    if (completedQuestCount < totalQuestCount) {
      push('BottomSheet/NoticePublishGroupBottomSheet', { groupId });
      return true;
    }
    return false;
  }, [
    myRole,
    questList.length,
    groupInfo?.publishedAt,
    closeSuperHostNoticePublishGroupBottomSheetList,
    groupId,
    completedQuestCount,
    totalQuestCount,
    push,
  ]);

  return checkTurnOnNotificationBottomSheet;
};

export default useCheckPublishGroupNotificationBottomSheet;
