import { BoxButton } from '@community-group/components';
import { isHigherManager, Spacing, Spinner, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconRetryRegular } from '@seed-design/icon';
import { IconChevronRightRegular } from '@seed-design/icon';
import dayjs from 'dayjs';
import orderBy from 'lodash-es/orderBy';
import React from 'react';

import { VerticalSpacing } from '@/components/common/Spacing';

import PollLayout from './PollLayout';
import PollResultRow from './PollResultRow';
import * as s from './PollStyle.css';
import { PollProps } from './type/poll';

dayjs.locale('ko');

type Props = Pick<
  PollProps,
  | 'selectedOptionIds'
  | 'isLoading'
  | 'hideVoteButton'
  | 'poll'
  | 'currentUserRole'
  | 'onModeChange'
  | 'onRevoteButtonClick'
  | 'onVoteEndButtonClick'
  | 'onVotedMembersClick'
>;

const PollResult = ({
  selectedOptionIds = [],
  isLoading = false,
  hideVoteButton = false,
  poll,
  currentUserRole = 'none',
  onModeChange,
  onRevoteButtonClick,
  onVoteEndButtonClick,
  onVotedMembersClick,
}: Props) => {
  const sortedPollOptions = orderBy(poll.options, ['voteCount', 'id'], ['desc', 'asc']);

  const maxVoteCount = Math.max(...sortedPollOptions.map((o) => o.voteCount));

  const hideVoteButtons = hideVoteButton || poll.isEnded;

  const hideVoteMembers = poll.totalVoteCount < 1;

  const handleVotedMembersClick = () => {
    if (hideVoteMembers) return;
    onVotedMembersClick?.();
  };

  return (
    <div onClick={(event) => event.stopPropagation()} style={{ width: '100%' }}>
      <PollLayout
        headRight={
          <div className={s.pollInfo} onClick={handleVotedMembersClick}>
            <div>{poll.totalVoterCount}명 참여</div>
            <VerticalSpacing height={2} />
            {!hideVoteMembers && (
              <IconChevronRightRegular size={15} color={vars.$scale.color.gray600} />
            )}
          </div>
        }
      >
        <form>
          <h1 className={s.pollTitle}>
            {poll.isEnded && <span>종료</span>}
            {poll.title}
          </h1>
          {(poll.isMultiple || poll.isEnded) && (
            <>
              <Spacing height={6} />
              <Typography typography="caption2Regular" color="gray600">
                {poll.isEnded && `${dayjs(poll.endedAt).format('MMMM DD일 (ddd) A h:mm')} 종료`}{' '}
                {poll.isEnded && poll.isMultiple && ' ⸱ '}
                {poll.isMultiple && `복수 선택`}
              </Typography>
            </>
          )}
          <Spacing height={12} />
          <div className={s.pollItemRows}>
            {sortedPollOptions.map((pollOption, index) => (
              <li
                key={index}
                onClick={() => {
                  handleVotedMembersClick();
                  return;
                }}
              >
                <PollResultRow
                  isLoading={isLoading}
                  totalVoteCount={poll.totalVoteCount}
                  isSelected={selectedOptionIds?.includes(pollOption.id) ?? false}
                  isWinner={index === 0 || maxVoteCount === pollOption.voteCount}
                  title={pollOption.title}
                  voteCount={pollOption.voteCount}
                />
                {index !== sortedPollOptions.length - 1 && <Spacing height={8} />}
              </li>
            ))}
          </div>
          {!hideVoteButtons && (
            <div className={s.pollActionWrapper}>
              <BoxButton
                size="large"
                width="100%"
                variant="secondary"
                isDisabled={isLoading}
                prefix={isLoading ? undefined : <IconRetryRegular size={16} />}
                onClick={() => {
                  onRevoteButtonClick?.();
                  onModeChange('poll');
                }}
              >
                {isLoading ? <Spinner variant="gray" size="small" /> : '다시 투표하기'}
              </BoxButton>
              {(poll.isHost || isHigherManager(currentUserRole)) && (
                <BoxButton
                  type="button"
                  size="large"
                  width="100%"
                  variant="secondary"
                  isDisabled={isLoading}
                  onClick={(event) => {
                    onModeChange('result');
                    onVoteEndButtonClick?.(event);
                    event.preventDefault();
                  }}
                >
                  {isLoading ? <Spinner variant="gray" size="small" /> : '투표 종료'}
                </BoxButton>
              )}
            </div>
          )}
        </form>
      </PollLayout>
    </div>
  );
};

export default React.memo(PollResult);
