import { Typography } from '@community-group/components';
import { IconChevronRightFill } from '@seed-design/icon';

import { openLink } from '@/utils/link';

import * as s from './style.css';

export const GroupDetailRunningGrowthMarketingBanner = () => {
  const handleClick = () => {
    openLink(
      'karrot://minikarrot/router?remote=https%3A%2F%2Flandkit.krrt.io%2Fpages%2Feguds3%2F&navbar=true&scrollable=true'
    );
  };

  return (
    <div className={s.Banner} onClick={handleClick}>
      <Typography typography="bodyM2Regular" color="gray800" ellipsisAfterLines={2}>
        당근 레이스🏃🏻‍♂️ 참여 현황을 확인해 보세요!
      </Typography>
      <IconChevronRightFill size={16} />
    </div>
  );
};
