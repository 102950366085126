import { GroupMemberRoleEnum } from '@community-group/api/lib/group/models';
import { AsyncBoundary, ViewError } from '@community-group/components';
import { Tabs } from '@karrotframe/tabs';
import { ActivityComponentType } from '@stackflow/react';
import { useMemo, useState } from 'react';

import { useGetApplications } from '@/api/hooks/useGetApplications';
import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import {
  MemberListOrderType,
  MemberListParams,
  useGetMemberList,
} from '@/api/hooks/useGetMemberList';
import { useGetRestrictedMembers } from '@/api/hooks/useGetRestrictedMembers';
import { Container } from '@/components/common/Container';
import EmptySection from '@/components/common/Empty';
import { LoadMoreApplications } from '@/components/common/LoadMoreContainer';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { refetchGroupMemberList } from '@/utils/refetch/memberList';

import { JoinableRegionRangeExpandBanner } from '../components/JoinableRegionRangeExpandBanner';
import { MemberListAppScreen } from '../components/MemberListAppScreen';
import { MemberProfileDetailList } from '../components/MemberProfileDetailList';
import MemberProfileDetailRestrictedList from '../components/MemberProfileDetailRestrictedList';
import MemberProfileSectionForHost from '../components/MemberProfileSectionForHost';
import * as s from './MemberListForHost.css';

export type GroupMemberListForHostPageParams = Pick<PageParams, 'groupId' | 'tab'>;

const GroupMemberListForHostPage: ActivityComponentType<GroupMemberListForHostPageParams> = () => {
  return (
    <MemberListAppScreen>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<ViewError />}>
        <MemberListWrapper />
      </AsyncBoundary>
    </MemberListAppScreen>
  );
};

export type MemberListFilter = Pick<
  MemberListParams,
  'shownMemberActivities' | 'shownMemberApplication' | 'shownOnlyHost'
>;

const MemberListWrapper = () => {
  const { groupId } = usePathParams();

  useEnterTrackEvent({
    event: 'enter_member_list',
    params: { groupId },
    sample: true,
  });

  const { tab = 'joint' } = useQueryParams();
  const [activeTabKey, setActiveTabKey] = useState<'joint' | 'pending'>(tab);

  const { group } = useGetGroupDetail(groupId);

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();
  const { data } = useGetMemberList({
    groupId,
    options: {
      onError: (error) =>
        handleErrorWithThrowErrorStatusPage({
          error,
          serviceType: 'group',
          groupId,
        }),
    },
  });
  const { data: applications, hasNextPage: hasApplicationsNextPage } = useGetApplications(groupId);
  const { data: restrictedMembers } = useGetRestrictedMembers(groupId);
  const currentUser = useCurrentGroupMe();

  const hasApprovedMemberPermission = currentUser.permissions.applyApplicationMember;
  const hasRestrictMemberPermission = currentUser.permissions.restrictMember;

  const [memberListFilter, setMemberListFilter] = useState<MemberListFilter>({
    shownMemberActivities: false,
    shownMemberApplication: false,
    shownOnlyHost: false,
  });

  const [selectedMemberOrderType, setSelectedMemberOrderType] =
    useState<MemberListOrderType>('joinedAtDesc');

  const handleRefetchMemberList = () => {
    refetchGroupMemberList({
      groupId,
      order: selectedMemberOrderType,
      ...memberListFilter,
    });
  };

  const shownJoinableRegionRangeExpandBanner = useMemo(() => {
    if (currentUser.role !== 'superHost') return false;

    if (group?.joinableRegionRange === 'unlimited') {
      return false;
    }

    const applicationList = applications?.pages[0].data.applications ?? [];

    if (applicationList.length < 1) {
      return false;
    }

    const hasNotJoinableRegionRangeMember = applicationList.find(
      ({ isJoinableRegionRange }) => isJoinableRegionRange === false
    );

    if (!hasNotJoinableRegionRangeMember) {
      return false;
    }

    return true;
  }, [currentUser.role, group?.joinableRegionRange, applications?.pages]);

  const tabs = useMemo(() => {
    const jointTab = {
      key: 'joint',
      buttonLabel: `가입한 이웃 ${data?.pages[0].data.memberCount}`,
      render: () => (
        <MemberProfileDetailList
          memberListFilter={memberListFilter}
          setMemberListFiler={setMemberListFilter}
          selectedMemberOrderType={selectedMemberOrderType}
          setSelectedMemberOrderType={setSelectedMemberOrderType}
        />
      ),
    };

    const restrictedTab = {
      key: 'restricted',
      buttonLabel: `내보낸 이웃 ${restrictedMembers?.pages[0].data.restrictedMembers.length ?? ''}`,
      render: () => <MemberProfileDetailRestrictedList />,
    };

    const pendingTab = {
      key: 'pending',
      buttonLabel: `가입 요청 ${applications?.pages[0].data.applicationCount}`,
      render: () => (
        <div className={s.wrapper}>
          {shownJoinableRegionRangeExpandBanner && (
            <div className={s.bannerWrapper}>
              <JoinableRegionRangeExpandBanner groupId={groupId} />
            </div>
          )}
          <Container paddingX={0} paddingY={0}>
            <ul className={s.list}>
              {applications &&
                applications.pages[0].data.applications &&
                applications.pages[0].data.applications.length < 1 && (
                  <EmptySection style={{ height: '80vh' }}>가입 요청한 이웃이 없어요.</EmptySection>
                )}
              {applications &&
                applications.pages.map((page) =>
                  page.data.applications?.map((application) => (
                    <MemberProfileSectionForHost
                      key={application.id}
                      user={{
                        id: application.id,
                        nickname: application.nickname,
                        subNickname: application.subNickname,
                        profileImage: application.profileImage,
                        userRegion: application.userRegion,
                        role: application.role as GroupMemberRoleEnum,
                        isAccountDeleted: application.isAccountDeleted,
                        verifiedUserInfoText: application.verifiedUserInfoText,
                      }}
                      currentUserRole={currentUser.role}
                      createdAt={application.createdAt}
                      applicationAnswers={application.applicationAnswers}
                      onClickActionButton={handleRefetchMemberList}
                      isJoinableRegionRange={application.isJoinableRegionRange}
                      canViewVerifiedUserInfo={group?.canViewVerifiedUserInfo}
                    />
                  ))
                )}
            </ul>
            {hasApplicationsNextPage && <LoadMoreApplications />}
          </Container>
        </div>
      ),
    };

    if (hasApprovedMemberPermission && hasRestrictMemberPermission) {
      return [jointTab, pendingTab, restrictedTab];
    }

    if (hasApprovedMemberPermission && !hasRestrictMemberPermission) {
      return [jointTab, pendingTab];
    }

    if (!hasApprovedMemberPermission && hasRestrictMemberPermission) {
      return [jointTab, restrictedTab];
    }

    return [jointTab];
  }, [
    data?.pages,
    restrictedMembers?.pages,
    applications,
    hasApprovedMemberPermission,
    hasRestrictMemberPermission,
    memberListFilter,
    selectedMemberOrderType,
    shownJoinableRegionRangeExpandBanner,
    groupId,
    handleRefetchMemberList,
  ]);

  if (!hasApprovedMemberPermission && !hasRestrictMemberPermission) {
    return (
      <MemberProfileDetailList
        memberListFilter={memberListFilter}
        setMemberListFiler={setMemberListFilter}
        selectedMemberOrderType={selectedMemberOrderType}
        setSelectedMemberOrderType={setSelectedMemberOrderType}
      />
    );
  }

  return (
    <>
      <Tabs
        className="custom_tab"
        activeTabKey={activeTabKey}
        disableSwipe
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTabKey(key as 'joint' | 'pending');
        }}
      />
    </>
  );
};

export default GroupMemberListForHostPage;
