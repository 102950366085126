import {
  AlertDialog as SproutAlertDialog,
  Dialog as SproutDialog,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@daangn/sprout-components-dialog';
import { vars } from '@seed-design/design-token';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import React from 'react';
import { ComponentProps, ReactNode } from 'react';
import { CSSProperties } from 'react';

import { isDarkTheme } from '../../utils/isDarkTheme';
import { BoxButton, TextButton } from '../base';
import * as s from './index.css';

interface Props {
  title?: string;
  description?: string;
  primaryActionLabel: ComponentProps<typeof SproutAlertDialog>['primaryActionLabel'];
  onPrimaryAction?: () => void;
  secondaryActionLabel?: ComponentProps<typeof SproutAlertDialog>['secondaryActionLabel'];
  secondaryActionIntent?: ComponentProps<typeof SproutAlertDialog>['secondaryActionIntent'];
  onSecondaryAction?: () => void;
  optionalElement?: ReactNode;
  zIndex?: number;
  style?: CSSProperties;
}

export const Dialog: React.FC<Props> = ({
  title,
  description,
  primaryActionLabel,
  onPrimaryAction,
  secondaryActionLabel,
  secondaryActionIntent = 'neutral',
  onSecondaryAction,
  optionalElement,
  zIndex = 1000,
  style,
}) => {
  const isSecondaryActionVisible = !!secondaryActionLabel;

  return (
    <SproutDialog zIndex={zIndex} UNSAFE_style={{ ...style }}>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        {description && <DialogDescription>{description}</DialogDescription>}
        {optionalElement && <div className={s.Wrapper}>{optionalElement}</div>}
      </DialogHeader>

      <div
        className={s.ButtonWrapper}
        style={{
          ...assignInlineVars({
            [s.ButtonDirectionVar]: secondaryActionIntent === 'nonpreferred' ? 'column' : 'row',
          }),
        }}
      >
        {isSecondaryActionVisible &&
          (secondaryActionIntent === 'nonpreferred' ? (
            <TextButton
              order={secondaryActionIntent === 'nonpreferred' ? 2 : 1}
              size="small"
              width="100%"
              variant="secondary"
              onClick={onSecondaryAction}
              bold={true}
            >
              {secondaryActionLabel}
            </TextButton>
          ) : (
            <BoxButton
              width="100%"
              flex={1}
              variant={secondaryActionIntent === 'alternative' ? 'primaryLow' : 'secondary'}
              UNSAFE_style={{
                backgroundColor:
                  secondaryActionIntent !== 'alternative'
                    ? isDarkTheme()
                      ? '#424348'
                      : vars.$scale.color.gray100
                    : undefined,
              }}
              onClick={onSecondaryAction}
              size="medium"
            >
              {secondaryActionLabel}
            </BoxButton>
          ))}
        <BoxButton
          order={secondaryActionIntent === 'nonpreferred' ? 1 : 2}
          flex={secondaryActionIntent === 'nonpreferred' ? undefined : 1}
          width="100%"
          onClick={onPrimaryAction}
          variant="primary"
          size="medium"
        >
          {primaryActionLabel}
        </BoxButton>
      </div>
    </SproutDialog>
  );
};
