import { JoinableRegionRangeEnum } from '@community-group/api/lib/group/models';
import {
  AsyncBoundary,
  Spacing,
  Typography,
  ViewError,
  ViewLoader,
  withAsyncBoundary,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import {
  initialGroupRegionRanges,
  REGION_RANGE_NAME,
  useGetGroupRegionRanges,
} from '@/api/hooks/useGetGroupRegionRanges';
import AccessoryBarButtonGroup from '@/components/common/AccessoryBarButtonGroup/AccessoryBarButtonGroup';
import { Map } from '@/components/common/Map';
import { Polygon } from '@/components/common/Map/components/Ploygon';
import {
  Coordinates,
  estimateZoomLevel,
  getPolygonCenter,
} from '@/components/common/Map/utils/polygon';
import { SelectBoxRadio } from '@/components/common/SelectBox/SelectBoxRadio';
import { useUserConfig } from '@/contexts/UserConfig';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { PageParams } from '@/stackflow/types/params';

import AppBarCloseCreateGroupButton from '../Common/AppBarCloseCreateGroupButton';
import GroupCreateAppScreen from '../Common/GroupCreateAppScreen';
import useCreateForm from '../hooks/useCreateForm';
import useCreateGroupFunnel from '../hooks/useCreateGroupFunnel';
import { indicatorCountHandler } from '../utils/indicatorCountHandler';
import * as s from './index.css';

export type GroupNewSetGroupRegionRangePageParams = Pick<
  PageParams,
  | 'name'
  | 'categoryId'
  | 'joinType'
  | 'question'
  | 'requiredQuestion'
  | 'haveQuestion'
  | 'maximumMemberCount'
  | 'minAgeTag'
  | 'maxAgeTag'
  | 'createType'
  | 'from'
  | 'description'
  | 'createGroupFlow'
  | 'joinGrowth'
>;

const GroupNewSetGroupRegionRangePage: ActivityComponentType<
  GroupNewSetGroupRegionRangePageParams
> = () => {
  const { from, ...postNewGroupPayload }: GroupNewSetGroupRegionRangePageParams = useQueryParams();
  const { push, pop } = useFlow();
  useEnterTrackEvent({
    event: 'enter_create_group_step',
    params: {
      step: 'regionRange',
    },
  });

  const { userConfig } = useUserConfig();
  const currentRegionId = userConfig.regionId ?? 0;
  const { data: regionData } = useGetGroupRegionRanges(currentRegionId);
  const { joinableRegionRanges, regionInfo } = regionData ?? initialGroupRegionRanges();

  const { handleSubmit, watch, setValue } = useCreateForm({});

  const selectedRegionRangeFieldValue = watch('joinableRegionRange');

  const { nextPageName } = useCreateGroupFunnel();

  const onSubmit = handleSubmit(async () => {
    push(nextPageName, {
      ...postNewGroupPayload,
      from,
      joinableRegionRange: selectedRegionRangeFieldValue,
    });
  });

  return (
    <GroupCreateAppScreen
      indicator={indicatorCountHandler('GroupNewSetGroupRegionRangePage')}
      accessoryBar={({ appendTop }) => (
        <>
          {appendTop}
          <AccessoryBarButtonGroup
            buttonDisabled={selectedRegionRangeFieldValue === undefined}
            onBack={pop}
            onSubmit={onSubmit}
          />
        </>
      )}
      appBar={{
        backButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
        closeButton: {
          render: () => <AppBarCloseCreateGroupButton />,
        },
      }}
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <div className={s.wrapper}>
          <Typography typography="title1Bold">
            이웃들을 모집할
            <br />
            동네를 설정해주세요
          </Typography>
          <Spacing height={16} />
          {joinableRegionRanges.map(({ key, regionPolygons, count }) => {
            if (key === 'unlimited') {
              return (
                <SelectBoxRadio
                  style={{
                    marginBottom: '0.75rem',
                  }}
                  key={key}
                  label={REGION_RANGE_NAME[key as JoinableRegionRangeEnum]}
                  description="어디서나 가입할 수 있어요."
                  selected={selectedRegionRangeFieldValue === key}
                  onClick={() => {
                    setValue('joinableRegionRange', key);
                  }}
                />
              );
            }

            // TODO: Region이 1개 이하일 경우에는 Map을 보여주지 않아요. Polygon 그리기가 애매한 이슈가 있어요. (참고: https://daangn.slack.com/archives/C017YMSBBDX/p1694585616574719)
            const hasRegionPolygon = regionPolygons.length > 1;
            const hasManyRegions = count > 1;

            const centerCoordinate = getPolygonCenter(regionPolygons as Coordinates);
            const zoomLevel = estimateZoomLevel(regionPolygons as Coordinates, {
              width: 324,
              height: 240,
            });

            const description = hasManyRegions
              ? `${regionInfo.name}과 근처 동네 ${count - 1}개`
              : `${regionInfo.name}`;

            return (
              <SelectBoxRadio
                style={{
                  marginBottom: '0.75rem',
                }}
                key={key}
                label={REGION_RANGE_NAME[key as JoinableRegionRangeEnum]}
                description={description}
                selected={selectedRegionRangeFieldValue === key}
                onClick={() => {
                  setValue('joinableRegionRange', key);
                }}
              >
                {hasRegionPolygon && (
                  <div className={s.mapWrapper}>
                    <Map
                      mapId={`group-region-range-map-${key}`}
                      className={s.mapStyle}
                      options={{
                        interactive: true,
                        zoom: zoomLevel,
                        center: centerCoordinate,
                      }}
                      config={{
                        renderDelay: 150,
                      }}
                    >
                      <Polygon
                        polygonId={`region-ploygons-${key}`}
                        coordinates={regionPolygons as Coordinates}
                      />
                    </Map>
                  </div>
                )}
              </SelectBoxRadio>
            );
          })}
        </div>
      </AsyncBoundary>
    </GroupCreateAppScreen>
  );
};

export default withAsyncBoundary(GroupNewSetGroupRegionRangePage, {
  pendingFallback: (
    <AppScreen>
      <ViewLoader />
    </AppScreen>
  ),
  rejectedFallback: (
    <AppScreen>
      <ViewError />
    </AppScreen>
  ),
});
