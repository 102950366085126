import {
  BoxButton,
  Dialog,
  Spacing,
  TextButton,
  Typography,
  useDialog,
  useEffectOnce,
} from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { ActivityComponentType } from '@stackflow/react';
import { useCallback } from 'react';

import { useGetMe } from '@/api/hooks/useGetMe';
import ImgIdentification from '@/assets/images/img_verification.png';
import { useBridge } from '@/contexts/Bridge';
import { useStorage } from '@/hooks/useStorage';
import { useVisibilityEvent, VisibilityState } from '@/hooks/useVisibilityEvent';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { trackEvent } from '@/utils/analytics';
import { openAppStore, openVerificationUser } from '@/utils/link';
import { checkVerifyIdentificationWorking } from '@/utils/version';

export type VerificationSuccessStatus = 'success' | 'fail' | 'pending';

export const VerificationInduceBottomSheet: ActivityComponentType = () => {
  const { certificationCandidateType, groupIdForChangeVerification = '' } = useQueryParams();
  const { open: openDialog, close: closeDialog } = useDialog();

  const { pop } = useConnectedActions();
  const { refetch: refetchUserMe } = useGetMe();
  const { bridge } = useBridge();

  useEffectOnce(() => {
    trackEvent({
      event: 'enter_verification_induce_bottomSheet',
      params: {
        candidateType: certificationCandidateType,
        groupId: groupIdForChangeVerification,
      },
      sample: true,
    });
  });

  const [isIdentityVerificationNoticeChecked, setIsIdentityVerificationNoticeChecked] = useStorage(
    'isIdentityVerificationNoticeChecked',
    {
      [groupIdForChangeVerification]: false,
    }
  );

  const handleSetIsIdentityVerificationNoticeChecked = (value: boolean) => {
    setIsIdentityVerificationNoticeChecked({
      ...isIdentityVerificationNoticeChecked,
      [groupIdForChangeVerification]: value,
    });
  };

  const visibleCallbackFunc = useCallback(
    async (visibilityState: VisibilityState) => {
      if (visibilityState === 'visible') {
        const { data: userInfo } = await refetchUserMe();

        if (userInfo?.data.verified) {
          bridge.openToast({
            toast: {
              body: '본인인증에 성공했어요.',
            },
          });

          if (
            certificationCandidateType ===
              'joined_group_with_user_info_and_not_verified_leader_update_state' &&
            groupIdForChangeVerification
          ) {
            handleSetIsIdentityVerificationNoticeChecked(true);
          }

          pop().send({
            verification: 'success' as VerificationSuccessStatus,
          });

          return;
        }
        bridge.openToast({
          toast: {
            body: '본인인증에 실패했어요. 다시 시도해주세요.',
          },
        });
        pop().send({
          verification: 'fail' as VerificationSuccessStatus,
        });
      }
    },
    [pop, refetchUserMe]
  );

  useVisibilityEvent({
    callback: visibleCallbackFunc,
  });

  const handlePendingClick = () => {
    pop().send({
      verification: 'fail' as VerificationSuccessStatus,
    });
  };

  const handleConfirmVerificationBottomSheetCheck = () => {
    handleSetIsIdentityVerificationNoticeChecked(true);
    if (!groupIdForChangeVerification) return;

    pop().send({
      verification: 'fail' as VerificationSuccessStatus,
    });
  };

  const handleOpenVerificationStep = () => {
    const isWorking = checkVerifyIdentificationWorking();

    if (!isWorking) {
      openDialog({
        element: (
          <Dialog
            description="본인인증 이용을 위해 스토어에서 버전을 업데이트해 주세요."
            secondaryActionLabel="다음에 하기"
            onSecondaryAction={closeDialog}
            primaryActionLabel="스토어 가기"
            onPrimaryAction={async () => {
              await closeDialog();
              openAppStore();
            }}
          />
        ),
      });
      return;
    }
    openVerificationUser();
  };

  const handleDoNotNeedVerification = () => {
    pop().send({
      verification: 'success' as VerificationSuccessStatus,
    });
  };

  const BOTTOM_SHEET_TEXT = {
    ['create_group_with_user_info']: {
      title: '나이대와 성별 정보를 받으려면\n 최초 1회 인증이 필요해요',
      description:
        '인증된 멤버만 모집하려면 먼저 모임장의 본인인증이 필요해요. 이웃과 따듯하고 안전한 모임 활동을 위해 인증해주세요.',
    },
    ['create_group']: {
      title: '본인인증이 필요한 모임을 만드려면\n 최초 1회 인증이 필요해요',
      description:
        '인증된 멤버만 모집하려면 먼저 모임장의 본인인증이 필요해요. 이웃과 따듯하고 안전한 모임 활동을 위해 인증해주세요.',
    },
    ['join_group_with_user_info_and_verified']: {
      title: '모임장에게 성별과 나이대가 공개되는 모임이에요',
      description:
        '안전한 모임 활동을 위해 본인인증을 필수로 설정한 모임이에요. 다른 정보는 공개 되지 않으니 안심해주세요.',
    },
    ['join_group_with_user_info_and_not_verified']: {
      title: '모임장에게 성별과 나이대가 공개되는 모임이에요',
      description:
        '안전한 모임 활동을 위해 본인인증을 필수로 설정한 모임이에요. 다른 정보는 공개 되지 않으니 안심하고 인증해주세요.',
    },
    ['join_group']: {
      title: '이 모임에 가입하려면\n 최초 1회 본인인증이 필요해요',
      description:
        '안전한 모임 활동을 위해 본인인증을 필수로 설정한 모임이에요. 개인정보는 공개 되지 않으니 안심하고 인증해주세요.',
    },
    ['joined_group_and_not_verified_leader_update_state']: {
      title: '모임에서 활동하려면\n 본인인증이 필요해요',
      description:
        '본인인증 모임으로 변경되었어요. 개인정보는 공개되지 않으니 안심하고 인증해주세요.',
    },
    ['joined_group_with_user_info_and_not_verified_leader_update_state']: {
      title: '본인인증이 필요한 모임으로\n  모임장에게 성별과 나이대가 공개돼요',
      description:
        '본인인증 모임으로 변경되었어요. 다른 정보는 공개 되지 않으니 안심하고 인증해주세요.',
    },
    ['joined_group_and_verified_leader_update_state']: {
      title: '본인인증 모임으로 변경되었어요',
      description:
        '본인인증 모임으로 변경되었어요. 개인정보는 공개 되지 않으니 안심하고 인증해주세요.',
    },
    ['joined_group_with_user_info_and_verified_leader_update_state']: {
      title: '본인인증이 필요한 모임으로\n 모임장에게 성별과 나이대가 공개돼요',
      description:
        '본인인증 모임으로 변경되었어요. 다른 정보는 공개 되지 않으니 안심하고 인증해주세요.',
    },
  };

  const renderText = () => {
    return (
      <>
        <Typography
          style={{ width: '100%', whiteSpace: 'pre-wrap' }}
          as="h4"
          typography="title2Bold"
          color="gray900"
          textAlign="center"
        >
          {BOTTOM_SHEET_TEXT[certificationCandidateType]?.title}
        </Typography>
        <Spacing height={6} />
        <Typography
          style={{ width: '100%' }}
          typography="bodyM1Regular"
          color="gray700"
          textAlign="center"
        >
          {BOTTOM_SHEET_TEXT[certificationCandidateType]?.description}
        </Typography>
      </>
    );
  };

  const renderButtons = () => {
    if (
      certificationCandidateType === 'join_group_with_user_info_and_not_verified' ||
      certificationCandidateType === 'join_group'
    ) {
      return (
        <>
          <BoxButton size="xlarge" width="100%" onClick={handleOpenVerificationStep}>
            인증하고 가입하기
          </BoxButton>
          <Spacing height={14} />
          <TextButton variant="secondary" bold onClick={handlePendingClick} width="100%">
            다음에 할게요
          </TextButton>
        </>
      );
    }

    if (certificationCandidateType === 'join_group_with_user_info_and_verified') {
      return (
        <>
          <BoxButton size="xlarge" width="100%" onClick={handleDoNotNeedVerification}>
            가입하기
          </BoxButton>
          <Spacing height={14} />
          <TextButton variant="secondary" bold onClick={handlePendingClick} width="100%">
            다음에 할게요
          </TextButton>
        </>
      );
    }

    if (
      certificationCandidateType === 'joined_group_and_verified_leader_update_state' ||
      certificationCandidateType === 'joined_group_with_user_info_and_verified_leader_update_state'
    ) {
      return (
        <>
          <BoxButton size="xlarge" width="100%" onClick={handleConfirmVerificationBottomSheetCheck}>
            확인했어요
          </BoxButton>
        </>
      );
    }

    return (
      <>
        <BoxButton size="xlarge" width="100%" onClick={handleOpenVerificationStep}>
          30초 만에 인증하기
        </BoxButton>
        <Spacing height={14} />
        <TextButton variant="secondary" bold onClick={handlePendingClick} width="100%">
          다음에 할게요
        </TextButton>
      </>
    );
  };

  return (
    <BottomSheet
      onOutsideClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        pop();
      }}
    >
      <Spacing height={40} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={ImgIdentification} alt="본인인증" width={140} height={93} />
      </div>
      <Spacing height={30} />
      {renderText()}
      <Spacing height={24} />
      {renderButtons()}
    </BottomSheet>
  );
};
