import { groupClient } from '@community-group/api';
import {
  AsyncBoundary,
  Divider,
  Typography,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { BoxButton } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill } from '@seed-design/icon';
import { Dispatch, SetStateAction } from 'react';

import { useGetMeetupSearchList } from '@/api/hooks/useGetMeetupSearchList';
import { MeetupHomeListCard } from '@/components/group/Home/components/MeetupTab/ExplorerMeetupSection/MeetupHomeListCardItem';
import { useFlow } from '@/stackflow';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { trackEvent } from '@/utils/analytics';

import * as s from './index.css';

type Props = {
  setCurrentTab: Dispatch<SetStateAction<'all' | 'group' | 'meetup' | 'onlyGroup'>>;
};

const SearchResultAllFilterMeetup = ({ setCurrentTab }: Props) => {
  const { query = '' } = usePathParams();

  const { data } = useGetMeetupSearchList({
    order: 'meet_at_asc',
    searchQuery: decodeURIComponent(query),
  });

  const meetups = data?.pages.map(({ data }) => data.meetups);
  const flatten = meetups?.flat(1) ?? ([] as groupClient.model.GroupMeetupSearchPresentation[]);

  const { push } = useFlow();

  if (flatten.length === 0) return <></>;

  return (
    <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
      <Typography
        typography="subtitle1Bold"
        style={{
          padding: '1.25rem 1rem 0.5rem 1rem',
        }}
      >
        일정
      </Typography>
      {flatten.map((meetup, index) => {
        if (index > 2) return <></>;
        return (
          <MeetupHomeListCard
            key={meetup.meetupId}
            title={meetup.title}
            headerLabel={[meetup.groupInfo.region.name]}
            meetupAtDisplayText={meetup.meetupAtDisplayText}
            currentParticipantsCount={meetup.currentParticipantCount}
            maximumParticipantsCount={meetup.maximumParticipantCount}
            image={meetup.image?.medium ?? ''}
            hostInfo={{
              image: meetup.groupInfo?.profileImage?.small ?? '',
              displayText: meetup.groupInfo?.name,
              imageShape: 'square',
            }}
            onClick={() => {
              trackEvent({
                event: 'click_group_meetup_item',
                params: {
                  meetupId: meetup.meetupId,
                  meetupName: meetup.title,
                  groupId: meetup.groupInfo?.groupId,
                  utm_medium: 'search_result',
                  referrer: 'search_result',
                  query: decodeURIComponent(query ?? ''),
                },
                sample: true,
              });
              push('GroupMeetupDetailPage', {
                groupId: (meetup.groupInfo?.groupId ?? '').toString(),
                meetupId: meetup.meetupId.toString(),
              });
            }}
            viewCount={meetup.viewCount}
            style={{
              borderBottom: 2 === index ? 'none' : `1px solid ${vars.$scale.color.gray100}`,
            }}
          />
        );
      })}
      {flatten.length > 3 && (
        <div className={s.buttonWrapper}>
          <BoxButton
            variant="secondary"
            size="medium"
            width="100%"
            onClick={() => {
              setCurrentTab('meetup');
            }}
            suffix={
              <div className={s.iconWrapper}>
                <IconChevronRightFill width={14} fill={vars.$scale.color.gray900} />
              </div>
            }
          >
            일정 더보기
          </BoxButton>
        </div>
      )}
      <Divider height={8} padding={0} />
    </AsyncBoundary>
  );
};

export default SearchResultAllFilterMeetup;
