import { Spacing, Typography, withAsyncBoundary } from '@community-group/components';
import { AvoidSnackbarOverlap, BoxButton } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconConfirmationProfileFill } from '@seed-design/icon';
import { forwardRef, useEffect, useMemo } from 'react';

import { HeartButton } from '@/components/common/HeartButton';
import { VerticalSpacing } from '@/components/common/Spacing';
import { useJoinGroup } from '@/hooks/useJoinGroup';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { useJoinGroupState } from '../../JoinGroupState/hooks/useJoinGroupState';
import { useWatchGroup } from '../hooks/useWatchGroup';
import * as s from './FixedBottomSection.css';

type FixedBottomSectionProps = {
  enableNeedVerificationNotice: boolean;
};

const FixedBottomSectionCore = forwardRef<HTMLDivElement, FixedBottomSectionProps>(
  ({ enableNeedVerificationNotice }, ref) => {
    const { groupId } = usePathParams();

    const { groupInfo, userState, joinApprovalRequireReason } = useJoinGroupState({ groupId });

    const isAppliedGroup = userState === 'applied';

    const groupJoinButtonText = useMemo(() => {
      //바로 가입 가능한 경우
      if (joinApprovalRequireReason === 'none') return '입장하기';
      return '모임 가입하기';
    }, [joinApprovalRequireReason]);

    const { watched, onWatchButtonClick } = useWatchGroup({ groupId });

    const { buttonText, handleClick } = useJoinGroup({
      groupId,
      buttonText: groupJoinButtonText,
      referrerPage: 'group_detail',
    });

    const { meetupId } = useQueryParams();

    useEffect(() => {
      if (meetupId) {
        if (!isAppliedGroup) {
          handleClick();
        }
      }
    }, [meetupId, isAppliedGroup]);

    const TypeSection = () => {
      if (isAppliedGroup) {
        return (
          <div className={s.PendingContainer}>
            <Typography typography="label3Bold" color="gray700">
              가입 승인을 기다리고 있어요
            </Typography>
            <BoxButton size="medium" variant="secondary" onClick={handleClick}>
              {buttonText}
            </BoxButton>
          </div>
        );
      }

      // 본인인증이 필요한 모임인지 안내하는 텍스트 노출 분기
      const isRenderNeedVerificationNotice =
        enableNeedVerificationNotice && groupInfo.needVerification;

      return (
        <div className={s.AccessoryBarBox}>
          {isRenderNeedVerificationNotice && (
            <>
              <div className={s.VerificationTextBox}>
                <IconConfirmationProfileFill
                  color={vars.$scale.color.green500}
                  width={16}
                  height={16}
                />
                <VerticalSpacing height={8} />
                <Typography typography="bodyM2Regular" color="green500">
                  본인인증을 완료한 이웃만 가입할 수 있는 모임이에요.
                </Typography>
              </div>
              <Spacing height={20} />
            </>
          )}
          <div className={s.ButtonContainer}>
            <div className={s.HeartButtonWrapper}>
              <HeartButton filled={watched} onClick={onWatchButtonClick} />
            </div>
            <VerticalSpacing height={20} />
            <BoxButton width="100%" size="xlarge" onClick={handleClick}>
              {buttonText}
            </BoxButton>
          </div>
        </div>
      );
    };

    return (
      <div ref={ref} className={s.Wrapper}>
        <TypeSection />
      </div>
    );
  }
);

const FixedBottomSection = ({
  enableNeedVerificationNotice = true,
}: {
  enableNeedVerificationNotice?: boolean;
}) => {
  return (
    <AvoidSnackbarOverlap>
      <FixedBottomSectionCore enableNeedVerificationNotice={enableNeedVerificationNotice} />
    </AvoidSnackbarOverlap>
  );
};

export default withAsyncBoundary(FixedBottomSection, {
  pendingFallback: <></>,
  rejectedFallback: <></>,
});
