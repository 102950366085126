import { z } from 'zod';

const name = z
  .string()
  .trim()
  .min(2, '별명 메시지를 두 글자 이상 입력해주세요')
  .max(24, '24자 이하로 입력해주세요.');

export const groupSubNicknameQuestionFieldSchema = {
  name,
};

export const groupSubNicknameQuestionFormSchema = z.object(groupSubNicknameQuestionFieldSchema);
