import {
  UserMeetupMemberReview,
  UserMeetupMemberReviewMessage,
} from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { useMemo } from 'react';

import { ReviewLabelItem } from './ReviewLabelItem';
import { ReviewMessageList } from './ReviewMessageList';
import * as s from './style.css';

type Props = {
  meetupMemberReviews: UserMeetupMemberReview[];
  meetupMemberReviewMessages: UserMeetupMemberReviewMessage[];
  isMyProfile: boolean;
  userId: string;
  groupId?: string;
};

export const MemberProfileMeetupMemberReviews = ({
  meetupMemberReviews,
  meetupMemberReviewMessages,
  isMyProfile,
  userId,
  groupId,
}: Props) => {
  const hasMeetupMemberReviews = useMemo(() => {
    return meetupMemberReviews.length > 0;
  }, [meetupMemberReviews]);

  const hasMeetupMemberReviewMessages = useMemo(() => {
    return meetupMemberReviewMessages.length > 0;
  }, [meetupMemberReviewMessages]);

  const filteredMeetupMemberReviewMessages = useMemo(() => {
    if (isMyProfile) return meetupMemberReviewMessages;

    return meetupMemberReviewMessages.filter(
      ({ reviewExposureRange }) => reviewExposureRange === 'ALL'
    );
  }, [meetupMemberReviewMessages, isMyProfile]);

  return (
    <section className={s.Wrapper}>
      <Typography as="h6" typography="title3Bold">
        받은 후기
      </Typography>
      <Spacing height={6} />
      <Typography typography="label3Regular" color="gray600">
        일정에 참여하고 멤버들에게 받은 후기예요.
      </Typography>
      {hasMeetupMemberReviews && (
        <>
          <Spacing height={16} />
          <div className={s.LabelListWrapper}>
            {meetupMemberReviews.map((meetupMemberReview) => (
              <ReviewLabelItem
                key={meetupMemberReview.feedbackTypeId}
                meetupMemberReview={meetupMemberReview}
              />
            ))}
          </div>
        </>
      )}
      {hasMeetupMemberReviewMessages && (
        <>
          <Spacing height={16} />
          <ReviewMessageList
            meetupMemberReviewMessages={filteredMeetupMemberReviewMessages}
            userId={userId}
            groupId={groupId}
            isMyProfile={isMyProfile}
          />
        </>
      )}
    </section>
  );
};
