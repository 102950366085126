import { groupClient } from '@community-group/api';
import { model } from '@community-group/api/lib/group';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useThrottleMutation } from '@/utils/ReactQuery/useThrottleMutation';

import { useFetchInstance } from './instance/useFetchInstance';
import { usePutEventLogLocation } from './usePutEventLogLocation';

type RequestParams = {
  isGroupV2?: boolean;
  joinGrowth?: model.GroupGrowthEnum;
  joinRunningGrowth?: boolean;
};

type Props = UseMutationOptions<
  AxiosResponse<model.GroupCreationResponse>,
  Error,
  RequestParams & model.GroupCreateForm
>;

export const usePostNewGroup = ({ onError, onSuccess }: Props) => {
  const fetchInstance = useFetchInstance();
  const postGroup = groupClient.api.GroupApi.apiV1GroupsPost({ axios: fetchInstance });

  const fetchPostGroup = async (params: RequestParams & model.GroupCreateForm) => {
    const { isGroupV2, joinGrowth, joinRunningGrowth, ...groupCreateForm } = params;

    return await postGroup(isGroupV2, joinGrowth, joinRunningGrowth, groupCreateForm);
  };

  const { mutate: mutateEventLogLocation } = usePutEventLogLocation();

  return useThrottleMutation(fetchPostGroup, {
    onError,
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);

      mutateEventLogLocation({
        eventName: 'create_group_detail',
        groupId: data.data.group?.id,
      });
    },
  });
};
