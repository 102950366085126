import { AsyncBoundary, Typography } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { FormInput } from '@/components/common/base/Input/Text';
import { FixedButton } from '@/components/common/Form/components/FixedButton';
import { Spacing } from '@/components/common/Spacing';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { groupSubNicknameQuestionFieldSchema } from '@/utils/validate/formSchema/subNickname';
import { validateSchemaWithHandleToast } from '@/utils/validate/util';

const NewGroupSubnicknameQuestionBottomSheet = () => {
  return (
    <BottomSheet>
      <AsyncBoundary pendingFallback={<></>} rejectedFallback={<></>}>
        <NewGroupSubnicknameQuestionBottomSheetWrapper />
      </AsyncBoundary>
    </BottomSheet>
  );
};

const NewGroupSubnicknameQuestionBottomSheetWrapper = () => {
  const { pop } = useConnectedActions();
  const { subNicknameQuestion } = useQueryParams();

  const { watch, register } = useForm({
    defaultValues: {
      subNicknameQuestion: subNicknameQuestion ?? '',
    } as FieldValues,
  });

  const nameFieldValue = watch('subNicknameQuestion');

  const handleNewSubnickname = async () => {
    pop().send({
      name: nameFieldValue,
    });
  };

  const isDisableButton = useMemo(() => {
    if (nameFieldValue.trim().length === 0) return true;

    return false;
  }, [nameFieldValue]);

  const validateForm = () => {
    if (!validateSchemaWithHandleToast(groupSubNicknameQuestionFieldSchema.name, nameFieldValue))
      return false;

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    handleNewSubnickname();
  };

  const getErrorMessage = () => {
    if (!nameFieldValue) return '';
    const result = groupSubNicknameQuestionFieldSchema.name.safeParse(nameFieldValue);
    if (result.success) return '';
    const parsedError = JSON.parse(result.error.message)[0];
    return parsedError.message ?? '';
  };

  return (
    <>
      <Spacing height={16} />
      <Typography typography="title3Bold" color="gray900">
        별명 입력 요청 문구
      </Typography>
      <Spacing height={6} />
      <Typography typography="subtitle1Regular" color="gray700">
        새로운 멤버가 가입했을 때 어떻게 별명을 작성하면 되는지 안내하는 문구를 적어주세요.
      </Typography>
      <Spacing height={16} />
      <FormInput
        register={register}
        name="subNicknameQuestion"
        placeholder="별명 입력 요청 문구를 적어주세요."
        minLength={groupSubNicknameQuestionFieldSchema.name.minLength ?? 0}
        maxLength={groupSubNicknameQuestionFieldSchema.name.maxLength ?? 0}
        isInvalid={
          nameFieldValue?.length > 0 &&
          groupSubNicknameQuestionFieldSchema.name.safeParse(nameFieldValue).success === false
        }
        errorMessage={getErrorMessage()}
        value={nameFieldValue}
      />
      <Spacing height={16} />
      <FixedButton disabled={isDisableButton} onClick={handleSubmit}>
        저장
      </FixedButton>
    </>
  );
};

export default NewGroupSubnicknameQuestionBottomSheet;
