import { TypeActivities } from '@/stackflow';

export const CREATE_GROUP_PAGE_LIST: { [key: string]: keyof TypeActivities } = {
  SET_CATEGORY: 'GroupNewSetGroupCategoryPage',
  SET_NAME: 'GroupNewSetGroupNamePage',
  SET_APPROVAL: 'GroupNewSetApprovalPage',
  SET_RECRUITMENT_CONDITION: 'GroupNewSetRecruitmentConditionPage',
  SET_REGION_RANGE: 'GroupNewSetGroupRegionRangePage',
  SET_VERIFICATION: 'GroupNewSetVerificationPage',
  SET_SUB_NICKNAME: 'GroupNewSetSubNicknamePage',
  SET_PROFILE_IMAGE: 'GroupNewSetProfileImagePage',
  MOVE_TO_CREATE_GROUP_DETAIL: 'GroupDetailPage',
  MOVE_TO_CREATE_GROUP_DETAIL_V2: 'GroupDetailPage',
} as const;

export type CREATE_GROUP_FLOW = 'DEFAULT' | 'GROUP_V2';

// 일반적인 생성 플로우
export const CREATE_GROUP_DEFAULT_FLOW = [
  CREATE_GROUP_PAGE_LIST.SET_CATEGORY,
  CREATE_GROUP_PAGE_LIST.SET_NAME,
  CREATE_GROUP_PAGE_LIST.SET_REGION_RANGE,
  CREATE_GROUP_PAGE_LIST.SET_APPROVAL,
  CREATE_GROUP_PAGE_LIST.SET_RECRUITMENT_CONDITION,
  CREATE_GROUP_PAGE_LIST.SET_VERIFICATION,
  CREATE_GROUP_PAGE_LIST.SET_SUB_NICKNAME,
  CREATE_GROUP_PAGE_LIST.SET_PROFILE_IMAGE,
  CREATE_GROUP_PAGE_LIST.MOVE_TO_CREATE_GROUP_DETAIL,
];

export const CREATE_GROUP_V2 = [
  CREATE_GROUP_PAGE_LIST.SET_CATEGORY,
  CREATE_GROUP_PAGE_LIST.SET_NAME,
  CREATE_GROUP_PAGE_LIST.SET_REGION_RANGE,
  CREATE_GROUP_PAGE_LIST.SET_APPROVAL,
  CREATE_GROUP_PAGE_LIST.SET_RECRUITMENT_CONDITION,
  CREATE_GROUP_PAGE_LIST.SET_VERIFICATION,
  CREATE_GROUP_PAGE_LIST.SET_SUB_NICKNAME,
  CREATE_GROUP_PAGE_LIST.SET_PROFILE_IMAGE,
  CREATE_GROUP_PAGE_LIST.MOVE_TO_CREATE_GROUP_DETAIL_V2,
];

// 개발 환경 생성 플로우
export const CREATE_GROUP_DEV_FLOW = [
  CREATE_GROUP_PAGE_LIST.SET_CATEGORY,
  CREATE_GROUP_PAGE_LIST.SET_NAME,
  CREATE_GROUP_PAGE_LIST.SET_REGION_RANGE,
  CREATE_GROUP_PAGE_LIST.SET_APPROVAL,
  CREATE_GROUP_PAGE_LIST.SET_RECRUITMENT_CONDITION,
  CREATE_GROUP_PAGE_LIST.SET_VERIFICATION,
  CREATE_GROUP_PAGE_LIST.SET_SUB_NICKNAME,
  CREATE_GROUP_PAGE_LIST.SET_PROFILE_IMAGE,
  CREATE_GROUP_PAGE_LIST.MOVE_TO_CREATE_GROUP_DETAIL,
];
